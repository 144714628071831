import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_UPDATE } from "../../../store/mutations.js";
import { USER_DETAILS } from "../../../store/queries.js";
import { updateUser } from "../../../store/redux/actions";

import { PulseLoader } from "react-spinners";
import Bg from "../../../assets/fetch-BG.svg";
import cookie from "js-cookie";
import {
  OnboardingHeader,
  LabeledTextBox,
  Button,
} from "../../../globalComponents/";
import SelectAddress from "../../../globalComponents/FormComponents/selectAddress.jsx";
import { placeAutoComplete, placeDetails } from "../../../utils/placeapis.jsx";

const stateAbbreviations = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

class Address extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    initialize: true,
    suggestions: [],
    selectedAddress: "",
  };
  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }

  getAddressSuggestion = async (e) => {
    let payload = {
      input: e.target.value,
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      radius: "100",
    };
    let suggest = await placeAutoComplete(payload);
    this.setState({
      suggestions: suggest,
    });
  };

  handleSelecteAddress = async (address) => {
    let place_id = address.place_id;
    let payload = {
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      place_id,
    };
    let getAddress = await placeDetails(payload);
    try {
      getAddress.address_components.map((item) => {
        switch (item.types[0]) {
          case "country":
            this.props.change("address", getAddress.name);
            this.setState({
              selectedAddress: getAddress.name,
            });
            break;
          case "postal_code":
            this.props.change("zip", item.long_name);
            break;
          case "administrative_area_level_1":
            this.props.change("state", item.short_name);
            break;
          case "locality":
            this.props.change("city", item.long_name);
            break;
          default:
            console.log("Hittttt");
        }
      });
    } catch (error) {
      alert(error.message);
    }
  };
  render() {
    const { initialize, selectedAddress, suggestions } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, change, history } = this.props;
    console.log("Thisss----", this.props.history);
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <div
                name="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: width,
                  minHeight: height,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`,
                }}
              >
                <PulseLoader
                  style={{ margin: "auto" }}
                  size={width / 10}
                  color={Theme.LINK_FONT_COLOR}
                  loading={loading}
                />
              </div>
            );
          // if (error) return `Error: ${error.message}`;
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          if (initialize) {
            change("address", data.userDetail.address);
            change("address2", data.userDetail.address2);
            change("city", data.userDetail.city);
            change("state", data.userDetail.state);
            change("zip", data.userDetail.zip);
            this.setState({ initialize: false });
          }

          return (
            <OnboardingHeader
              back={() =>
                this.props.global.currentUser.type === "Employee"
                  ? this.props.history.push("/dashboard")
                  : this.props.global.currentUser.type === "Manager" ||
                    this.props.global.currentUser.type === "Admin"
                  ? this.props.history.push("/manager/members")
                  : this.props.history.push("/")
              }
              progress={2}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "5%",
                alignContent: "center",
              }}
              checkInStatus={false}
            >
              <div
                style={{
                  marginBottom: "2%",
                  textAlign: "center",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getHeaderFont(width),
                  color: Theme.HEADER_FONT_COLOR,
                }}
              >
                Member Address
              </div>
              {/* <LabeledTextBox
                name="address"
                width={width}
                height={height}
                widthpercent={"100%"}
                label="Address"
                validate={["required"]}
              /> */}
              <SelectAddress
                alt={true}
                options={suggestions}
                handleSelect={(address, name) => {
                  this.handleSelecteAddress(address);
                }}
                selected={selectedAddress}
                name="address"
                width={width}
                height={height}
                label="Address"
                validate={["required"]}
                getAddressSuggestion={this.getAddressSuggestion}
              />
              <LabeledTextBox
                name="address2"
                width={width}
                height={height}
                widthpercent={"100%"}
                label="Address Line 2"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: Theme.getLongInputWidth(width) * 1.06,
                  justifyContent: "space-between",
                }}
              >
                <LabeledTextBox
                  name="city"
                  validate={["required"]}
                  widthFactor={2.5}
                  width={width}
                  height={height}
                  label="City"
                />
                <LabeledTextBox
                  name="state"
                  widthFactor={6}
                  options={stateAbbreviations}
                  validate={["required"]}
                  width={width}
                  height={height}
                  label="State"
                />
                <LabeledTextBox
                  name="zip"
                  validate={["required"]}
                  widthFactor={3.5}
                  width={width}
                  height={height}
                  label="Zipcode"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  text="Back"
                  alt={true}
                  width={width}
                  onClick={() =>
                    this.props.history.push(
                      history.location?.state?.from || "/login"
                    )
                  }
                />
                <Button
                  containerStyle={{ marginLeft: "auto" }}
                  text="Save and Continue"
                  width={width}
                  onClick={handleSubmit}
                />
              </div>
            </OnboardingHeader>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  var newValues = values;
  newValues.id =
    props.global.currentUser.selectedUser || props.global.currentUser.id;
  newValues.step = 2;
  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
    .then((data) => {
      if (data.errors && data.errors.length > 0) {
        console.error("Submission Error: ", data.errors);
      } else
        props.history.push({
          pathname: "terms",
          state: { from: props.location.pathname },
        });
    })
    .catch((error) => {
      console.error("Error: ", error);
    });
  return true;
};

Address = reduxForm({
  form: "Address",
  onSubmit: formSubmit,
})(Address);
const mapDispatchToProps = { updateUser };
export default compose(withApollo, connect(mapStateToProps, mapDispatchToProps))(Address);
