import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_EVENT_LIST_WITH_TAG } from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import moment from "moment";
import {
  Theme,
  SearchBar,
  EventTagTable,
  Button,
} from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import EventModal from "../../../globalComponents/FetchComponents/EventModal.jsx";
import { DELETE_EVENT_TAG } from "../../../store/mutations.js";
import { convertTo12HrsFormat } from "../../../utils/session.js";

class Events extends Component {
  state = {
    tab: 0,
    search: "",
    page: 1,
    per: 20,
    isOpen: false,
    eventTagData: null,
    data: this.props.data,
    currentIndex: 0,
    isUpdate: false,
    eventList: [],
    sortAttr: "",
    sort: "",
    isModalDisplayed: false,
  };

  componentDidMount() {
    this.handleEventList();
  }

  openModal = (eventTagData, currentIndex, isUpdate) => {
    this.setState({ eventTagData, currentIndex, isUpdate }, () => {
      this.setState({ isModalDisplayed: true });
    });
  };

  closeModal = () => {
    this.setState({ isModalDisplayed: false });
  };

  handleEventList = () => {
    const { client } = this.props;
    let { search, sortAttr, sort } = this.state;
    let variables = {
      keyword: search,
      sortAttr: sortAttr,
      sort: sort,
    };
    client
      .query({
        query: FETCH_EVENT_LIST_WITH_TAG,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          eventList: response.data?.fetchEvent.reverse(),
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  updateEvents = (newEvent) => {
    let newState = { ...this.state };
    console.log("--->", newEvent);
    if (this.state.isUpdate) {
      newState.eventList[this.state.currentIndex] = newEvent;
    } else {
      newState.eventList.splice(0, 0, newEvent);
    }
    this.setState({ eventList: newState.eventList });
  };

  sorting = (e, sortAttr, sort) => {
    e.preventDefault();
    this.setState({
      sortAttr: sortAttr,
      sort: sort,
    });
    let { search } = this.state;
    let variables = {
      keyword: search,
      sortAttr: sortAttr,
      sort: sort,
    };
    this.props.client
      .query({
        query: FETCH_EVENT_LIST_WITH_TAG,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          eventList: response.data?.fetchEvent,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  deleteEventTag = (index) => {
    let newState = { ...this.state };
    newState.eventList.splice(index, 1);
    this.setState({ eventList: newState.eventList });
  };

  handleDelete = (id, currentIndex) => {
    const { client } = this.props;
    let confirmDelete = window.confirm(
      "Are you sure that you want to delete this note?"
    );
    if (confirmDelete) {
      client
        .mutate({ mutation: DELETE_EVENT_TAG, variables: { id: id } })
        .then((response) => {
          if (response.data.destroyEvent.success) {
            this.deleteEventTag(currentIndex);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  handleSearch = () => {
    let { search } = this.state;
    let variables = {
      keyword: search,
      sortAttr: "",
      sort: "",
    };
    this.props.client
      .query({
        query: FETCH_EVENT_LIST_WITH_TAG,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          eventList: response.data?.fetchEvent,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  formatAMPM = (date) => {
    console.log("******** Date  ********", date);
    // let hours = date.getHours();
    // let minutes = date.getMinutes();
    // const ampm = hours >= 12 ? 'PM' : 'AM';
    // hours %= 12;
    // hours = hours || 12;
    // minutes = minutes < 10 ?  (`0${minutes}`) : minutes;
    // console.log(" -------------Minutes--------->",minutes);
    // const strTime = `${hours}:${minutes} ${ampm}`;
    let strTime = moment(date).format("h:mm A");
    return strTime;
  };

  getTagName = (eventTag) => {
    const renderEventTagName = eventTag.map((item, index) => {
      let tag_length = eventTag.length;
      let tag_name = item.tagName;

      if (tag_length === index + 1) {
        return ` ${tag_name}`;
      } else if (tag_length >= 1) {
        return ` ${tag_name}`;
      }
    });
    return renderEventTagName;
  };

  render() {
    const {
      search,
      per,
      page,
      eventTagData,
      isModalDisplayed,
      isUpdate,
      currentIndex,
      eventList,
    } = this.state;
    const { global, change } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 700;
    const verySmall = width < 568;

    const columnNames = [
      "dateTime",
      "startTime",
      "endTime",
      "park",
      "createdBy",
      "eventName",
      "eventTypeName",
      "tagTitle",
      "action",
    ];

    const columns = {
      dateTime: {
        label: "Date & Time",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {moment(data.eventDate).format("MM/DD/YYYY")}
          </div>
        ),
      },

      startTime: {
        label: "Start Time",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {/* {this.formatAMPM(new Date((data.startTime)))} */}
            {/* {moment(data.startTime).format("H:mm A")} */}
            {/* {data.startTime
              ? new Date(data.startTime).toLocaleString("en-US", {
                  hour: "numeric",
                  hour12: true,
                  minute: "numeric",
                })
              : "NA"} */}
            {convertTo12HrsFormat(data.startTime)}
          </div>
        ),
      },

      endTime: {
        label: "End Time",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {/* {moment(data.endTime).format("H:mm A")} */}
            {convertTo12HrsFormat(data.endTime)}
            {/* {data.startTime
              ? new Date(data.endTime).toLocaleString("en-US", {
                  hour: "numeric",
                  hour12: true,
                  minute: "numeric",
                })
              : "NA"} */}
            {/* {this.formatAMPM(new Date((data.endTime)))} */}
          </div>
        ),
      },
      park: {
        label: "Park",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.park ? data.park?.name : "All Parks"}
          </div>
        ),
      },
      createdBy: {
        label: "Created By",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.createdBy}
          </div>
        ),
      },

      eventName: {
        label: "Event Name",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.eventName}
          </div>
        ),
      },
      eventTypeName: {
        label: "Event Type",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.eventType ? data.eventType : "Do Not Repeat"}
          </div>
        ),
      },
      tagTitle: {
        label: "Tag",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {this.getTagName(data.eventTags)}
          </div>
        ),
      },

      action: {
        label: "Action",
        renderCell: (data, index) => (
          <>
            <button
              onClick={() => this.openModal(data, index, true)}
              style={{
                fontWeight: 100,
                outline: "none",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                color: Theme.MAIN_COLOR,
                // fontSize: Theme.getNormalFont(width),
                fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                fontFamily: Theme.INPUT_FONT,
                paddingLeft: 0,
              }}
            >
              Edit
            </button>
            <span
              style={{
                fontWeight: 100,
                outline: "none",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                color: Theme.MAIN_COLOR,
                // fontSize: Theme.getNormalFont(width),
                fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                fontFamily: Theme.INPUT_FONT,
                paddingLeft: 0,
              }}
            >
              |
            </span>
            <button
              onClick={() => this.handleDelete(data.id, index)}
              style={{
                fontWeight: 100,
                outline: "none",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                color: Theme.MAIN_COLOR,
                // fontSize: Theme.getNormalFont(width),
                fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                fontFamily: Theme.INPUT_FONT,
              }}
            >
              Remove
            </button>
          </>
        ),
      },
    };

    return (
      <div>
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            marginTop: "0%",
            marginBottom: "4%",
            width: "100%",
            color: "white",
            alignItems: "center",
            alignContent: "center",
            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          Manage Events
          <button
            onClick={() => this.openModal(null, null, false)}
            style={{
              outline: "none",
              backgroundColor: "rgba(250,250,250, .3)",
              borderRadius: 5,
              padding: "5px 10px 5px 10px",
              border: "none",
              marginLeft: "auto",
              color: "white",
              fontFamily: Theme.HEADER_FONT,
              // fontSize: Theme.getSmallFont(width),
              fontSize: shrink ? "12px" : Theme.getSmallFont(width),
              cursor: "pointer",
            }}
          >
            Add New Event
          </button>
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              width: shrink ? "80%" : "50%",
              margin: shrink ? "90px auto 0" : "auto",
              display: "block",
            }}
          >
            <SearchBar
              value={search}
              containerStyle={{
                marginBottom: "2%",
                marginRight: "1%",
                position: "relative",
                display: `${width < 900 ? "inline-block" : "inline-block "}`,
                width: shrink ? "89%" : "89%",
              }}
              placeholder="Search"
              onChange={(v) => this.setState({ search: v, page: 1 })}
              width={verySmall ? "94.5%" : "98%"}
              height={verySmall ? "48px" : height / 25}
            />

            <div
              style={{
                margin: `${
                  width < 900
                    ? width < 568
                      ? "15px auto 10px"
                      : "0px auto 10px"
                    : "0 0 0 auto "
                }`,
                display: `${width < 900 ? "inline-block" : "inline-block "}`,
                flexDirection: `${width < 900 ? "column" : "row"}`,
                width: "10%",
              }}
            >
              <Button
                text={`Search`}
                fullwidth={false}
                onClick={() => this.handleSearch()}
                disabled={search === "" ? true : false}
                width="100%"
                height={height / 25}
                href="/staff"
              />
            </div>
          </div>
        </div>
        <EventTagTable
          // total={!loading && data.eventList.totalCount}
          total={5}
          per={per}
          page={page}
          onPage={(page) => this.setState({ page: page })}
          onPer={(per) => this.setState({ per: per })}
          containerStyle={{ flex: 1 }}
          names={columnNames}
          pages={["", "", ""]}
          columns={columns}
          rows={eventList}
          sorting={this.sorting}
          change={change}
          onClick={(id) => {
            this.props.updateUser({ selectedUser: id });
          }}
        />

        {isModalDisplayed && (
          <EventModal
            eventTagData={eventTagData}
            isModalDisplayed={isModalDisplayed}
            closeModal={this.closeModal}
            updateEvents={this.updateEvents}
            deleteEventTag={this.deleteEventTag}
            isUpdate={isUpdate}
            currentIndex={currentIndex}
            global={global}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Events);
