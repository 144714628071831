import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_COUPON_TYPES } from "../../../store/queries.js";
import { CREATE_COUPON_CODE } from "../../../store/mutations";
import { compose, withApollo, Query } from "react-apollo";
import {
    Table,
    Theme,
    SearchBar,
    StaffNavBar,
    PawIndicator,
    Select,
    Button
} from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import Bg from "../../../assets/fetch-BG.svg";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import CouponTypeTab from "./couponTypeTab";

class Coupons extends Component {
    state = {
        couponType: { name: "Please select a coupon type" },
        couponCodes: [],
        type: "parkClosure",
        per: 20,
        page: 1,
        tab: 0
    };

    handleSelect = option => {
        this.setState({ couponType: option });
    };

    generateCode = () => {
        if (this.state.couponType.id) {
            let couponId = this.state.couponType.id;
            this.props.client
                .mutate({
                    mutation: CREATE_COUPON_CODE,
                    variables: {
                        input: {
                            couponTypeId: couponId,
                            count: 1
                        }
                    }
                })
                .then(response => {
                    let newState = { ...this.state };
                    response.data.createCouponCodes.codes.map(code => {
                        newState.couponCodes.push({
                            type: newState.couponType.name,
                            coupon: code
                        });
                    });
                    this.setState({ couponCodes: newState.couponCodes });
                })
                .catch(error => {
                    console.error("Error: ", error);
                });
        } else {
            alert("Must select a coupon type in order to generate coupon codes.");
        }
    };

    render() {
        const { couponType, couponCodes, tab } = this.state;
        const { global } = this.props;
        const obj = global || { dimensions: { height: 0, width: 1000 } };
        const height = obj.dimensions.height;
        const width = obj.dimensions.width;
        const shrink = width < 700;
        const styles = {
            navButton: {
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                padding: 10,
                margin: "auto 2% auto 2%",
                color: "white",
                fontFamily: Theme.INPUT_FONT,
                // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            },
            selectedButton: {
                outline: "none",
                backgroundColor: "white",
                borderRadius: 5,
                padding: 10,
                border: "none",
                color: Theme.MAIN_COLOR,
                margin: "auto 2% auto 2%",
                fontFamily: Theme.HEADER_FONT,
                // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            },
            deactivate: {
                outline: "none",
                backgroundColor: "rgba(250,250,250, .3)",
                borderRadius: 5,
                padding: 5,
                border: "none",
                color: "white",
                margin: "auto",
                marginRight: 10,
                fontFamily: Theme.HEADER_FONT,
                // fontSize: Theme.getSmallFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            }
        };
      
        return (
            <div
                style={{
                    height: `${this.props.global.dimensions.height +
                        50 * couponCodes.length}px`,
                    backgroundColor: Theme.BACKGROUND_COLOR,
                    backgroundRepeat: "round",
                    backgroundImage: `url(${Bg})`
                }}
            >
                <StaffNavBar
                    tab={4}
                    history={this.props.history}
                    width={this.props.global.dimensions.width}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexShrink: 0,
                        width: "90%",
                        justifyContent: "center",
                        marginTop: -80,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                >
                    <div
                        style={{
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "-3%",
                            marginBottom: "4%",
                            width: "100%",
                            color: "white",
                            alignItems: "center",
                            alignContent: "center",
                            // fontSize: Theme.getHeaderFont(width),
                            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                            fontFamily: Theme.HEADER_FONT
                        }}
                    >
                        {(this.props.global.currentUser.type === "Admin" || this.props.global.currentUser.type === "Manager") && (<button
                            onClick={() =>
                                this.setState({ tab: 0, })
                            }
                            style={
                                tab === 0 ? styles.selectedButton : styles.navButton
                            }
                        >
                           Coupon Type
                        </button>)}
                        {/* {(this.props.global.currentUser.type === "Admin" || this.props.global.currentUser.type === "Manager") && (<button
                            onClick={() =>
                                this.setState({ tab: 1, })
                            }
                            style={
                                tab === 1 ? styles.selectedButton : styles.navButton
                            }
                        >
                            Generated Coupon
                        </button>)} */}
                    </div>
                    {(tab === 0) && <CouponTypeTab
                        height={height}
                        width={width}
                        client={this.props.client}
                    />}

                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
    withApollo,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Coupons);
