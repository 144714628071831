import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../";
import { ADD_EVENT_TAG, UPDATE_EVENT_TAG } from "../../store/mutations";
import { reduxForm } from "redux-form";
import DatePicker from "react-datepicker";
import { compose, Query, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { FETCH_TAG_LIST, PARK_NAMES } from "../../store/queries.js";
import TagSelect from "../FormComponents/TagSelect.js";
import CheckedNeutered from "../../assets/check_neutered.svg";
import moment from "moment";
import momentTime from "moment-timezone";
import { uniq as _uniq } from "lodash";
import Select from "../FormComponents/Select.js";
import SelectParkForEvent from "../FormComponents/SleectParkForEvent.jsx";
import {
  convertDateFormat,
  convertTimeFormat,
  convertTo12HrsFormat,
  convertTo24HrsFormat,
  formattedDate,
  getDate,
  getDateTime,
  getTimeZone,
  getTimeZoneWithoutColon,
} from "../../utils/session.js";

const styles = {
  pageContainer: {
    position: "absolute",
    // display: "flex",
    flex: 1,
    alignItems: "center",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0,0,0,.2)",
    height: "100vh",
    width: "100vw",
    zIndex: 100,
  },

  modalContainer: function (width) {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "1vw",
      height: "auto",
      width: width,
      fontSize: Theme.getNormalFont(width),
      fontFamily: Theme.HEADER_FONT,
      backgroundColor: "white",
      color: Theme.DARK_TEXT_COLOR,
      boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    };
  },

  header: {
    borderBottom: "3px solid",
    borderBottomColor: Theme.INACTIVE_GREY_COLOR,
    width: "97%",
    textAlign: "center",
    fontSize: "30px",
    marginBottom: "5px",
  },

  heading: {
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "18px",
    fontFamily: "Lato",
  },

  subtext: function (width) {
    return {
      fontSize: Theme.getNormalFont(width),
      color: Theme.LIGHT_TEXT_COLOR,
    };
  },

  tagSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1vw",
    paddingLeft: "0",
  },

  tagListContainer: function (width) {
    return {
      listStyleType: "none",
      margin: "0",
      width: width / 1.2,
      paddingLeft: "1vw",
      color: Theme.INACTIVE_GREY_COLOR,
    };
  },

  textArea: function (width, textFontSize) {
    return {
      resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
      borderRadius: 5,
      height: width / 6, // UPDATE HEIGHT CALULCATION
      fontSize: textFontSize,
    };
  },

  submitButtonWrapper: {
    alignSelf: "flex-end",
    margin: ".5em 0",
  },
};

const WEEKLY = "weekly";
const ONCE = "once";
const MONTHLY = "monthly";
const DAILY = "daily";
class EventModal extends Component {
  state = {
    hover: false,
    tagDescription: "",

    width: 0,
    height: 0,
    tagName: "Choose Tag ",
    tagId: null,
    tagNameTwo: "Choose Tag ",
    tagTwoId: null,
    tagNameThree: "Choose Tag ",
    tagThreeId: null,
    eventType: "Does Not Repeat",
    eventTypeName: ONCE,
    eventId: "",
    startTime: "",
    endTime: "",
    eventName: "",
    eventDate: "",
    createdEmail: "",
    createdBy: "",
    dayName: "",
    monthDate: "",
    allDayEventStatus: false,
    eventStartDate: "",
    eventEndDate: "",
    evenDescription: "",
    parkName: "All Parks",
    parkId: "",
  };

  componentDidMount() {
    const { eventTagData, global } = this.props;
    if (eventTagData) {
      // console.log("eventTagData", eventTagData);
      // console.log(
      //   "Foramted",
      //   new Date(
      //     convertTo24HrsFormat(eventTagData.eventDate, eventTagData.startTime)
      //   )
      // );
      this.setState(
        {
          eventDate: new Date(eventTagData.eventDate),
          eventId: eventTagData.id,
          eventName: eventTagData.eventName,
          tagId: eventTagData.tageId,
          tagName: eventTagData.tagName,
          dayName: this.getDayName(new Date(eventTagData.eventDate)).dayName,
          monthDate: this.getDayName(new Date(eventTagData.eventDate))
            .monthDate,
          // eventTypeName: eventTagData.eventType,
          // eventType: eventTagData.eventType,
          evenDescription: eventTagData.description,
          allDayEventStatus: eventTagData.allDayStatus,

          startTime: new Date(
            convertTo24HrsFormat(eventTagData.eventDate, eventTagData.startTime)
          ),
          endTime: new Date(
            convertTo24HrsFormat(eventTagData.eventDate, eventTagData.endTime)
          ),
        },
        () => this.checkEventStatus(eventTagData.eventType)
      );

      // this.setState({
      //
      //  endTime: eventTagData.endTime,
      // });
      if (eventTagData.park) {
        this.setState({
          parkName: eventTagData.park?.name,
          parkId: eventTagData.park?.id,
        });
      }
      let eventTags = eventTagData?.eventTags;
      if (eventTags) {
        let eventTagLength = eventTags.length;
        console.log("-------------");
        switch (eventTagLength) {
          // case 0:
          //     this.setState({
          //         tagName: "Choose Tag ",
          //         tagId: "",
          //         tagNameTwo: "Choose Tag ",
          //         tagTwoId: "",
          //         tagNameThree: "Choose Tag ",
          //         tagThreeId: "",
          //     })
          // break;
          case 1:
            this.setState({
              tagName: eventTags[0].tagName,
              tagId: eventTags[0].tageId,
            });
            break;
          case 2:
            this.setState({
              tagName: eventTags[0].tagName,
              tagId: eventTags[0].tageId,
              tagNameTwo: eventTags[1].tagName,
              tagTwoId: eventTags[1].tageId,
            });
            break;
          case 3:
            this.setState({
              tagName: eventTags[0].tagName,
              tagId: eventTags[0].tageId,
              tagNameTwo: eventTags[1].tagName,
              tagTwoId: eventTags[1].tageId,
              tagNameThree: eventTags[2].tagName,
              tagThreeId: eventTags[2].tageId,
            });
            break;
          default:
            this.setState({
              tagName: "Choose Tag ",
              tagId: null,
              tagNameTwo: "Choose Tag ",
              tagTwoId: null,
              tagNameThree: "Choose Tag ",
              tagThreeId: null,
            });
            break;
        }
      }
      if (eventTagData.allDayStatus) {
        this.setState({
          allDayEventStatus: eventTagData.allDayStatus,
          eventEndDate: new Date(eventTagData.endDate),
          eventStartDate: new Date(eventTagData.eventDate),
        });
      }
    }
    this.setState({
      createdEmail: global.currentUser.email,
      createdBy: global.currentUser.type,
    });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillReceiveProps(props) {}

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("mousedown", this.handleClick, false);
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  handleTagFields = (e) => {
    let name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getTextAreaWidth(width) {
    if (width > 1900) return "54.5vw";
    else if (width > 1650) return "64.5vw";
    else if (width > 1200) return "70.5vw";
    else if (width > 800) return "84.5vw";
    else return "79vw";
  }

  getModalWidth(width) {
    if (width > 1900) return "55vw";
    else if (width > 1650) return "65vw";
    else if (width > 1200) return "75vw";
    else if (width > 800) return "85vw";
    else return "80vw";
  }

  handleClick = (e) => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.props.closeModal();
        }
      } catch (error) {}
    }
  };

  handleSubmit = () => {
    const {
      createdBy,
      createdEmail,
      tagId,
      startTime,
      endTime,
      eventName,
      eventDate,
      tagName,
      tagTwoId,
      tagThreeId,
      eventId,
      eventType,
      eventTypeName,
      allDayEventStatus,
      eventEndDate,
      eventStartDate,
      evenDescription,
      parkName,
      parkId,
    } = this.state;
    const { client, global, closeModal, eventTagData } = this.props;
    const start = moment().format("YYYY-MM-DD 00:01:00");
    const end = moment().format("YYYY-MM-DD 23:59:00");
    // console.log("Start", new Date(start));
    // console.log("End", new Date(end));
    let selectedTags = {
      tagId,
      tagTwoId,
      tagThreeId,
    };
    let selectedTagsIds = [];
    const checkedMembers = Object.entries(selectedTags);
    const tagsIDs = _uniq(checkedMembers.map((val) => val[1]));
    for (const tagsId of tagsIDs) {
      selectedTagsIds.push(tagsId);
    }

    if (this.props.isUpdate) {
      let values = {
        id: eventId,
        eventName: eventName,
        tagIds: selectedTagsIds,
        createdEmail: createdEmail,
        createdBy: createdBy,
        description: evenDescription,
        parkId,
        eventType: eventTypeName, //Done
        createdTimezone: getTimeZoneWithoutColon(), // "+0530", "+0300", "-0700", "-0400  //Done
        allDayStatus: allDayEventStatus,
        eventDate: !allDayEventStatus
          ? convertDateFormat(eventDate)
          : convertDateFormat(eventStartDate),
        endDate: !allDayEventStatus ? null : convertDateFormat(eventEndDate),
        startTime: !allDayEventStatus ? convertTimeFormat(startTime) : null,
        endTime: !allDayEventStatus ? convertTimeFormat(endTime) : null,
      };
      console.log("Al day ", values);
      // if (allDayEventStatus) {
      //   values = {
      //     ...values,
      //     // eventDate: eventStartDate,
      //     // startTime: new Date(start),
      //     // endTime: new Date(end),
      //     // endDate: eventEndDate,
      //     eventDate: getDate(eventStartDate),
      //     startTime: new Date(start),
      //     endTime: new Date(end),
      //     endDate: getDate(eventEndDate),
      //   };
      // } else {
      //   values = {
      //     ...values,
      //     // eventDate: new Date(eventDate),
      //     // startTime: startTime,
      //     // endTime: endTime,
      //     // endDate: null,
      //     eventDate: getDate(eventDate),
      //     startTime: getDateTime(eventDate, startTime),
      //     endTime: getDateTime(eventDate, endTime),
      //     endDate: null,
      //   };
      // }
      client
        .mutate({ mutation: UPDATE_EVENT_TAG, variables: { input: values } })
        .then((response) => {
          let newEvent = response.data.updateEvent.event;
          // newEvent = { ...newEvent, eventType: eventType }
          this.props.updateEvents(newEvent);
          closeModal();
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      const values = {
        eventName: eventName, //Done
        tagIds: selectedTagsIds, //Done
        createdEmail: createdEmail, //Done
        createdBy: createdBy, //Done
        // eventSchedule: eventTypeName, // NOt required
        description: evenDescription, //Done
        parkId, //Done
        eventType: eventTypeName, //Done
        createdTimezone: getTimeZoneWithoutColon(), // "+0530", "+0300", "-0700", "-0400  //Done
        allDayStatus: allDayEventStatus,
        eventDate: !allDayEventStatus
          ? convertDateFormat(eventDate)
          : convertDateFormat(eventStartDate),
        endDate: !allDayEventStatus ? null : convertDateFormat(eventEndDate),
        startTime: !allDayEventStatus ? convertTimeFormat(startTime) : null,
        endTime: !allDayEventStatus ? convertTimeFormat(endTime) : null,
        // eventDate: !allDayEventStatus
        //   ? getDate(eventDate)
        //   : getDate(eventStartDate),
        // startTime: !allDayEventStatus ? getDateTime(eventDate, startTime) : "",
        // endTime: !allDayEventStatus ? getDateTime(eventDate, endTime) : "",
      };
      console.log("Values", values);
      client
        .mutate({ mutation: ADD_EVENT_TAG, variables: { input: values } })
        .then((response) => {
          let newEvent = response.data.addEvent.event;
          // newEvent = { ...newEvent, eventType: eventType }
          this.props.updateEvents(newEvent);
          closeModal();
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    }
  };
  getFont(width) {
    if (width > 400) return 18;
    else return 12;
  }

  setTime = (event) => {
    this.setState({
      startTime: event,
    });
  };
  setTimeTwo = (event) => {
    this.setState({
      endTime: event,
    });
  };
  getDayName = (eventDate) => {
    let { eventTypeName } = this.state;
    var d = new Date(eventDate);
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var dayName = days[d.getDay()];

    function nth(n) {
      // console.log("Number", Number(n));
      return [, "st", "nd", "rd"][(n / 10) % 10 ^ 1 && n % 10] || "th";
    }
    // let today = new Date(eventDate);  // get the date
    let day = ("0" + d.getDate()).slice(-2); //get day with slice to have double digit day
    // let month = ("0" + (today.getMonth() + 1)).slice(-2); //get your zero in front of single month digits so you have 2 digit months
    // let date = month + '/' + day + '/' + today.getFullYear();
    // console.log("Dsy", nth(day));
    if (eventTypeName !== ONCE) {
      if (eventTypeName !== DAILY) {
        if (eventTypeName !== MONTHLY) {
          this.setState({
            eventType: `Weekly on ${dayName}`,
            eventTypeName: dayName,
          });
        } else {
          this.setState({
            eventType: `Monthly on ${day}${nth(day)}`,
            eventTypeName: MONTHLY,
          });
        }
      } else {
        this.setState({
          eventType: `Daily`,
          eventTypeName: DAILY,
        });
      }
    }

    let dayData = {
      dayName: dayName,
      monthDate: `${day}${nth(day)} `,
    };
    return dayData;
  };

  checkEventStatus = (_name) => {
    switch (_name) {
      case ONCE:
        this.setState({
          eventType: "Does not repeat",
          eventTypeName: ONCE,
        });
        break;
      case DAILY:
        this.setState({
          eventType: `Daily`,
          eventTypeName: DAILY,
        });
        break;
      case MONTHLY:
        this.setState({
          eventType: `Monthly on ${this.state.monthDate}`,
          eventTypeName: MONTHLY,
        });
        break;
      case WEEKLY:
        this.setState({
          eventType: `Weekly on ${this.state.dayName}`,
          eventTypeName: WEEKLY,
        });
        break;

      default:
        break;
    }
  };

  setStartDate = (event) => {
    let { eventType } = this.state;
    // let modifyDate =getDateTime(event)
    // // let
    // console.log("Event Type Start", modifyDate);
    this.setState({
      eventDate: event,
      dayName: this.getDayName(event).dayName,
      monthDate: this.getDayName(event).monthDate,
    });
  };

  setAllDayStartDate = (event) => {
    // let { eventType } = this.state;
    console.log("Event Type All Start", event);

    this.setState({
      eventStartDate: event,
      dayName: this.getDayName(event).dayName,
      monthDate: this.getDayName(event).monthDate,
    });
  };
  setAllDayEndDate = (event) => {
    console.log("Event Type All Start", event);

    let { eventType } = this.state;
    this.setState({
      eventEndDate: event,
      // dayName: this.getDayName(event).dayName,
      // monthDate: this.getDayName(event).monthDate
    });
  };
  selectAllDayEvent = (e) => {
    let newState = { ...this.state };
    if (this.props.isUpdate) {
      if (this.state.eventEndDate && this.state.eventStartDate) {
        this.setState({
          allDayEventStatus: !newState.allDayEventStatus,
          eventDate: "",
          startTime: "",
          endTime: "",
        });
      } else {
        this.setState({
          allDayEventStatus: !newState.allDayEventStatus,
          eventEndDate: "",
          eventStartDate: "",
        });
      }
    } else {
      newState.allDayEventStatus = !newState.allDayEventStatus;
      this.setState({
        allDayEventStatus: newState.allDayEventStatus,
        eventDate: "",
        startTime: "",
        endTime: "",
      });
    }
  };
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const height = obj.dimensions.height;
    const shrink = this.state.width < 700;
    const textFontSize = Theme.getNormalFont(width);
    const { isModalDisplayed, isUpdate, closeModal } = this.props;
    const {
      tagName,
      tagNameTwo,
      tagNameThree,
      startTime,
      endTime,
      eventName,
      eventDate,
      eventType,
      dayName,
      monthDate,
      allDayEventStatus,
      eventEndDate,
      eventStartDate,
      evenDescription,
      parkName,
      parkId,
    } = this.state;
    let eventTypeList = [
      {
        title: "Does not repeat",
        name: ONCE,
      },
      {
        title: "Daily",
        name: DAILY,
      },
      {
        title: `Weekly on ${dayName}`,
        name: WEEKLY,
      },
      {
        title: `Monthly on ${monthDate}`,
        name: MONTHLY,
      },
    ];

    // console.log(eventTypeList, "Event", eventType);
    if (!isModalDisplayed) {
      return null;
    } else
      return (
        // Page Container
        <div style={styles.pageContainer}>
          {/* Modal Container */}
          <div
            ref={(node) => (this.node = node)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10%",
              marginTop: "5%",
              padding: "2vw",
              height: "auto",
              width: this.getModalWidth(this.state.width),
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.HEADER_FONT,
              backgroundColor: "white",
              color: Theme.DARK_TEXT_COLOR,
              boxShadow:
                "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            }}
          >
            <div style={styles.header}>
              {" "}
              {isUpdate === true ? "Update Tags" : "Add Tags"} by Events{" "}
            </div>
            {/* Header */}
            <div style={styles.heading}>Event Name </div>
            <input
              value={eventName}
              name="eventName"
              style={{
                // width: this.getTextAreaWidth(this.state.width),
                width: "100%",
                height: shrink ? this.state.width / 20 : this.state.width / 40,
                border: "1px solid",
                borderColor: "#767676",
                borderRadius: 5,
                fontSize: textFontSize,
                paddingLeft: 2,
                fontFamily: Theme.MAIN_FONT,
              }}
              placeholder={"Event Name"}
              type={"type"}
              onChange={this.handleTagFields}
            />
            {/* Park Dropdown Start */}
            <div style={{ marginTop: 20 }}>
              <div style={styles.heading}>Park </div>
              <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return <div></div>;
                  if (error) return `Error: ${error.message}`;
                  return (
                    <div>
                      <div
                        style={{
                          width: shrink ? "80%" : "100%",
                          margin: "auto",
                        }}
                      >
                        <SelectParkForEvent
                          alt={true}
                          options={data?.restrictedFetchParks}
                          handleSelect={(park, name) => {
                            this.setState({
                              parkName: park.name,
                              parkId: park.id,
                            });
                          }}
                          selected={parkName}
                          name="tagId"
                          width={"100%"}
                          height={height / 25}
                          label="Parks"
                          statusField="all"
                        />
                      </div>
                    </div>
                  );
                }}
              </Query>
            </div>
            {/* Park Dropdown End */}

            <div
              style={{
                marginTop: 20,
                width: "100%",
                display: shrink ? "block" : "flex",
              }}
            >
              <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return <div></div>;
                  if (error) return `Error: ${error.message}`;
                  return (
                    <div
                      style={{
                        width: shrink ? "100%" : "33.3%",
                        margin: shrink ? "10px 0 10px 0" : "",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: shrink ? "80%" : "100%",
                          // margin: "auto"
                        }}
                      >
                        <TagSelect
                          alt={true}
                          options={data.fetchTags}
                          handleSelect={(tag, name) => {
                            this.setState({
                              tagName: tag.title,
                              tagId: tag.id,
                            });
                          }}
                          selected={tagName}
                          name="tagId"
                          // width={"100%"}
                          width={shrink ? "200px" : "300px"}
                          height={height / 25}
                          label="Tag One"
                        />
                      </div>
                    </div>
                  );
                }}
              </Query>
              <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return <div></div>;
                  if (error) return `Error: ${error.message}`;
                  return (
                    <div
                      style={{
                        width: shrink ? "100%" : "33.3%",
                        marginTop: shrink ? "10px 0 10px 0" : "",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: shrink ? "80%" : "100%",
                          // margin: "auto"
                        }}
                      >
                        <TagSelect
                          alt={true}
                          options={data.fetchTags}
                          handleSelect={(tag, name) => {
                            this.setState({
                              tagNameTwo: tag.title,
                              tagTwoId: tag.id,
                            });
                          }}
                          selected={tagNameTwo}
                          name="tagId"
                          // width={"100%"}
                          width={shrink ? "200px" : "300px"}
                          height={height / 25}
                          label="Tag Two"
                        />
                      </div>
                    </div>
                  );
                }}
              </Query>
              <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return <div></div>;
                  if (error) return `Error: ${error.message}`;
                  return (
                    <div
                      style={{
                        width: shrink ? "100%" : "33.3%",
                        marginTop: shrink ? "10px 0 10px 0" : "",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: shrink ? "80%" : "100%",
                          // margin: "auto"
                        }}
                      >
                        <TagSelect
                          alt={true}
                          options={data.fetchTags}
                          handleSelect={(tag, name) => {
                            this.setState({
                              tagNameThree: tag.title,
                              tagThreeId: tag.id,
                            });
                          }}
                          selected={tagNameThree}
                          name="tagId"
                          // width={"100%"}
                          width={shrink ? "200px" : "300px"}
                          height={height / 25}
                          label="Tag Three"
                        />
                      </div>
                    </div>
                  );
                }}
              </Query>
            </div>

            <div style={{ marginTop: 20 }}>
              <div
                style={{
                  // width: shrink ? "80%" : "50%",
                  margin: "auto",
                  maxHeight: "100px",
                  overflowY: "auto",
                  paddingLeft: "2px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      marginBottom: "10px",
                    }}
                  >
                    <button
                      onClick={(e) => this.selectAllDayEvent(e)}
                      type="button"
                      style={{
                        width: 25,
                        height: 25,
                        border: "2px solid",
                        cursor: "pointer",
                        borderColor: "#49c172",
                        outline: "none",
                        borderRadius: 5,
                        padding: "0px",
                        background:
                          allDayEventStatus || false
                            ? "#49c172"
                            : "transparent",
                      }}
                    >
                      {allDayEventStatus || false ? (
                        <img
                          active={false}
                          alt="Active"
                          src={CheckedNeutered}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      ) : null}
                    </button>
                    <div
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "5px",
                        width: "100%",
                      }}
                    >
                      <span> All Day Event </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {allDayEventStatus ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    display: "inline-flex",
                    flexDirection: "column",
                    margin: "0 10px 0 0",
                  }}
                >
                  <div style={styles.heading}>Event Start Date </div>

                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={eventStartDate}
                    onChange={(date) => this.setAllDayStartDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required={true}
                    customInput={
                      <input
                        value={eventStartDate}
                        name="eventDate"
                        style={{
                          width: "100%",
                          height: "35px",
                          border: "1px solid",
                          borderColor: "#000",
                          borderRadius: 5,
                          fontSize: textFontSize,
                          padding: "0px 2px",
                          fontFamily: Theme.MAIN_FONT,
                        }}
                        type={"type"}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    width: "33%",
                    display: "inline-flex",
                    flexDirection: "column",
                    margin: "0 10px 0 0",
                  }}
                >
                  <div style={styles.heading}>Event End Date </div>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={eventEndDate}
                    onChange={(date) => this.setAllDayEndDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    customInput={
                      <input
                        value={eventEndDate}
                        name="eventDate"
                        style={{
                          width: "100%",
                          height: "35px",
                          border: "1px solid",
                          borderColor: "#000",
                          borderRadius: 5,
                          fontSize: textFontSize,
                          padding: "0px 2px",
                          fontFamily: Theme.MAIN_FONT,
                        }}
                        required={true}
                        type={"type"}
                      />
                    }
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    width: "33%",
                    display: "inline-flex",
                    flexDirection: "column",
                    margin: "0 10px 0 0",
                  }}
                >
                  <div style={styles.heading}>Event Date </div>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    selected={eventDate}
                    onChange={(date) => this.setStartDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required={true}
                    customInput={
                      <input
                        value={eventDate}
                        name="eventDate"
                        style={{
                          width: "100%",
                          height: "35px",
                          border: "1px solid",
                          borderColor: "#000",
                          borderRadius: 5,
                          fontSize: textFontSize,
                          padding: "0px 2px",
                          fontFamily: Theme.MAIN_FONT,
                        }}
                        type={"type"}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    width: "33%",
                    display: "inline-flex",
                    flexDirection: "column",
                    margin: "0 0px 0 10px",
                  }}
                >
                  <div style={styles.heading}>Start Time</div>
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => this.setTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Start Time"
                    required={true}
                    customInput={
                      <input
                        value={startTime}
                        name="startTime"
                        style={{
                          width: "100%",
                          height: "35px",
                          border: "1px solid",
                          borderColor: "#000",
                          borderRadius: 5,
                          fontSize: textFontSize,
                          padding: "0px 2px",
                          fontFamily: Theme.MAIN_FONT,
                        }}
                        type={"type"}
                        onChange={this.handleTagFields}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    width: "33%",
                    display: "inline-flex",
                    flexDirection: "column",
                    margin: "0 0px 0 10px",
                  }}
                >
                  <div style={styles.heading}>End Time</div>
                  <DatePicker
                    selected={endTime}
                    // onChange={(date) => this.setTimeTwo(date)}
                    onChange={(date) => this.setTimeTwo(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="End Time"
                    required={true}
                    customInput={
                      <input
                        value={endTime}
                        name="endTime"
                        style={{
                          width: "100%",
                          height: "35px",
                          border: "1px solid",
                          borderColor: "#000",
                          borderRadius: 5,
                          fontSize: textFontSize,
                          padding: "0px 2px",
                          fontFamily: Theme.MAIN_FONT,
                        }}
                        placeholder="End Time"
                        type={"type"}
                        onChange={this.handleTagFields}
                      />
                    }
                  />
                </div>
              </div>
            )}
            {((allDayEventStatus && eventStartDate !== "") ||
              eventDate !== "") && (
              <div style={{ marginTop: 20 }}>
                <div>
                  <div
                    style={{
                      width: shrink ? "80%" : "100%",
                      // margin: "auto"
                    }}
                  >
                    <TagSelect
                      alt={true}
                      options={eventTypeList}
                      handleSelect={(tag, name) => {
                        this.setState({
                          eventType: tag.title,
                          eventTypeName: tag.name,
                        });
                      }}
                      selected={eventType}
                      name="tagId"
                      width={shrink ? "200px" : "300px"}
                      height={height / 25}
                      label="Event Type"
                    />
                  </div>
                </div>
              </div>
            )}
            <div style={{ marginTop: 20, width: "100%", display: "block" }}>
              <div style={styles.heading}> Description </div>
              <textarea
                value={evenDescription}
                name="evenDescription"
                style={{
                  outline: "none",
                  resize: "none",
                  borderRadius: 5,
                  // width: this.getTextAreaWidth(this.state.width),
                  width: "100%",
                  height: width / 12,
                  fontSize: textFontSize,
                }}
                onChange={this.handleTagFields}
              />
            </div>

            {/* Button Wraper to Right-Align */}
            <div style={styles.submitButtonWrapper}>
              {!isUpdate && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Add Event"
                    type="button"
                    width={width}
                    onClick={this.handleSubmit}
                    containerStyle={{ marginRight: 10 }}
                  />
                  <Button
                    text="Cancel"
                    type="button"
                    width={width}
                    onClick={() => closeModal()}
                  />
                </div>
              )}
              {isUpdate && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Update Event"
                    type="button"
                    width={width}
                    onClick={this.handleSubmit}
                    containerStyle={{ marginRight: 10 }}
                  />
                  <Button
                    text="Cancel"
                    type="button"
                    width={width}
                    onClick={() => closeModal()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

EventModal = reduxForm({
  form: "EventModal",
})(EventModal);

export default compose(withApollo, connect(mapStateToProps))(EventModal);
