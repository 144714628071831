import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FETCH_PARK_STATS } from "../../../store/queries.js";
import { Query } from "react-apollo";
import { ActivityCard, Theme, StaffNavBar } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import Sample from "../../../assets/sample_profile.jpeg";
import ArrowLeft from "../../../assets/left-arrow.svg";
import ArrowRight from "../../../assets/right-arrow.svg";
import { PulseLoader } from "react-spinners";
import Bg from "../../../assets/fetch-BG.svg";
import moment from "moment";

class Stats extends Component {
  navToUpdate = (parkData, rangerData) => {
    let {
      address,
      address2,
      city,
      generalManager,
      generalManagerEmail,
      generalManagerPhoneNumber,
      generalManagerProfilePicture,
      hours,
      id,
      name,
      phoneNumber,
      state,
      selfCheckinEnabled,
      timezone,
      timezoneFullname
    } = parkData;

    hours = hours.map((day) => {
      let days = day.daysOfWeek.split("-");
      let times = day.hours.split("-");

      return {
        dayStart: days[0],
        dayEnd: days[1],
        timeStart: times[0],
        timeEnd: times[1],
      };
    });

    generalManagerProfilePicture =
      generalManagerProfilePicture && generalManagerProfilePicture.url;

    this.props.history.push("./new", {
      parkData: {
        address,
        address2,
        city,
        generalManager,
        generalManagerEmail,
        generalManagerPhoneNumber,
        generalManagerProfilePicture,
        hours,
        id,
        name,
        phoneNumber,
        state,
        selfCheckinEnabled,
        timezone,
        timezoneFullname
      },
      rangerData,
    });
  };

  renderNoData = () => {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "80%",
          margin: "2% 0 2% 0",
          fontSize: Theme.getSmallFont(width),
        }}
      >
        No Data Available
      </div>
    );
  };

  renderLoader() {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;
    return (
      <div
        name="container"
        style={{
          display: "flex",
          width: width,
          minHeight: height,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: Theme.BACKGROUND_COLOR,
          backgroundRepeat: "round",
          backgroundImage: `url(${Bg})`,
        }}
      >
        <PulseLoader
          style={{ margin: "auto" }}
          size={width / 10}
          color={Theme.LINK_FONT_COLOR}
          loading={true}
        />
      </div>
    );
  }

  renderScreenHeader(parkData, staff) {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        style={{
          marginRight: "auto",
          display: "flex",
          flexDirection: "row",
          marginTop: "-5%",
          marginBottom: "1%",
          width: "100%",
          color: "white",
          alignItems: "center",
          alignContent: "center",
          fontSize: Theme.getHeaderFont(width),
          fontFamily: Theme.HEADER_FONT,
        }}
      >
        <button
          style={{
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
            padding: 10,
            color: "white",
            fontFamily: Theme.MAIN_FONT,
            fontSize: Theme.getNormalFont(width),
            cursor: "pointer",
          }}
          onClick={() => this.props.history.goBack()}
        >
          <img
            style={{ opacity: 0.3, height: 40, marginTop: 8 }}
            alt=""
            src={ArrowLeft}
          />
        </button>
        {parkData.name}
        <button
          onClick={() => this.navToUpdate(parkData, staff)}
          style={{
            outline: "none",
            backgroundColor: "rgba(250,250,250, .3)",
            borderRadius: 5,
            padding: "5px 10px 5px 10px",
            border: "none",
            marginLeft: "auto",
            color: "white",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getSmallFont(width),
            cursor: "pointer",
          }}
        >
          Update Park
        </button>
      </div>
    );
  }

  renderParkDetailsBox = (parkData) => {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        style={{
          display: "flex",
          width: width / 3.5,
          flexDirection: "column",
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "3%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          Park Information
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div
            style={{
              paddingLeft: "5%",
              paddingTop: "2%",
              width: "50%",
            }}
          >
            {parkData.address || "NA"}
            <div />
            {parkData.address2}
            <div />
            {parkData.phoneNumber}
            <div />
            {parkData.city}, {parkData.state}
          </div>
          <div
            style={{ width: "50%", display: "flex", justifyContent: "center" }}
          >
            <label
              style={{
                position: "relative",
                display: "flex",
                margin: "auto",
              }}
            >
              <div>Self Check-in</div>
              <input
                type="checkbox"
                style={{
                  marginLeft: 10,
                  marginTop: "auto",
                  transform: "scale(2)",
                }}
                disabled
                checked={parkData.selfCheckinEnabled}
              />
            </label>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "3%",
            paddingBottom: "1%",
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          Timezone: {parkData.timezoneFullname ? parkData.timezoneFullname : "N/A"}
        </div>
        <div
          style={{
            paddingLeft: "5%",
            paddingTop: "3%",
            paddingBottom: "1%",
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          Hours:
        </div>
        <div
          style={{
            paddingLeft: "5%",
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          {parkData.hours.length === 0
            ? this.renderNoData()
            : parkData.hours.map((day, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "80%",
                      margin: "2% 0 2% 0",
                      fontSize: Theme.getSmallFont(width),
                    }}
                  >
                    {day.daysOfWeek}
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "10%",
                        color: Theme.LIGHT_TEXT_COLOR,
                      }}
                    >
                      {day.hours}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  };

  renderManagerDetailsBox = (parkData) => {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    const { generalManager, generalManagerPhoneNumber, generalManagerEmail } =
      parkData;
    return (
      <div
        id="manager"
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          width: width / 3.5,
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          marginTop: "2%",
        }}
      >
        <div
          id="header"
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "3%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          General Manager
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              paddingTop: "2%",
              paddingLeft: "3%",
              fontSize: Theme.getSmallFont(width),
            }}
          >
            Name
            <div
              style={{
                fontSize: Theme.getNormalFont(width),
                marginBottom: "10%",
              }}
            >
              {generalManager || "NA"}
            </div>
            Telephone Number
            <div
              style={{
                fontSize: Theme.getNormalFont(width),
                marginBottom: "10%",
              }}
            >
              {generalManagerPhoneNumber || "NA"}
            </div>
            Email
            <div
              style={{
                fontSize: Theme.getNormalFont(width),
                marginBottom: "10%",
              }}
            >
              {generalManagerEmail || "NA"}
            </div>
          </div>
          <img
            alt=""
            src={
              parkData.generalManagerProfilePicture &&
              process.env.REACT_APP_API_ENDPOINT +
                parkData.generalManagerProfilePicture.url
            }
            style={{
              borderRadius: width / 20,
              width: width / 15,
              height: width / 15,
              backgroundColor: "black",
            }}
          />
        </div>
      </div>
    );
  };

  renderStaffInformationBox = (rangerData) => {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        id="staffInfo"
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          width: width / 3.5,
          paddingBottom: 6,
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "5%",
            paddingRight: "5%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          Staff Information
          <div />
          {rangerData.totalCount}
        </div>
        <div
          style={{
            paddingLeft: "10%",
            overflow: "auto",
            maxHeight: height / 3.6,
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          {rangerData.edges.length === 0
            ? this.renderNoData()
            : rangerData.edges.map((staff, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      borderBottom:
                        index + 1 !== rangerData.length ? "1px solid" : "",
                      borderColor: "#c8cfd9",
                      paddingBottom: "5%",
                      flexDirection: "column",
                      width: "80%",
                      margin: "2% 0 2% 0",
                      fontSize: Theme.getNormalFont(width),
                    }}
                  >
                    {staff.firstName + " " + staff.lastName}
                    <div
                      style={{
                        marginTop: 5,
                        fontFamily: Theme.MAIN_FONT,
                        color: Theme.LIGHT_TEXT_COLOR,
                      }}
                    >
                      {staff.phoneNumber}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    );
  };

  render() {
    const { history, global, location } = this.props;
    const { width = 1000 } = this.props.global.dimensions;

    //console.log('-----> location.state', location.state);
    const parkId = location.state.id;

    return (
      <Query
        fetchPolicy="cache-and-network"
        query={FETCH_PARK_STATS}
        variables={{
          parkId: parkId,
          type: "Employee",
          page: 1,
          per: 100,
          start: moment().startOf("day").toISOString(),
          end: moment().toISOString(),
        }}
      >
        {({ loading, error, data: parkStats, refetch }) => {
          if (loading) return this.renderLoader();

          if (error) return `Error: ${error.message}`;

          //console.log('----->parkStats ', parkStats);

          let { park, staff, signUp, checkIn } = parkStats;
          park = park[0];
          return (
            <Fragment>
              <StaffNavBar tab={1} history={history} width={width} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  justifyContent: "center",
                  marginTop: -80,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {this.renderScreenHeader(park, staff)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <ActivityCard
                    width={width / 3.5}
                    stat={park.memberCount}
                    type="checkin"
                  />
                  <ActivityCard
                    width={width / 3.5}
                    stat={signUp.count}
                    type="signup"
                  />
                  <ActivityCard
                    width={width / 3.5}
                    stat={checkIn.count}
                    type="team"
                  />
                </div>
                <div
                  id="parkInfo"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {this.renderParkDetailsBox(park)}
                  {this.renderManagerDetailsBox(park)}
                  {this.renderStaffInformationBox(staff)}
                </div>
              </div>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
