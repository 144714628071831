import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Theme,
    StaffNavBar
} from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import Bg from "../../../assets/fetch-BG.svg";
import ParkClosure from "./parkClosure.jsx";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import ParkLog from "./parkLog";

class Operations extends Component {
    state = {
        type: "parkClosure",
        per: 20,
        page: 1,
        tab: 0
    };


    render() {
        const { type, per, page, tab, } = this.state;
        const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };

        const height = obj.dimensions.height;
        const width = obj.dimensions.width;
        const shrink = width < 700;

        const styles = {
            navButton: {
                outline: "none",
                backgroundColor: "transparent",
                border: "none",
                padding: 10,
                margin: "auto 2% auto 2%",
                color: "white",
                fontFamily: Theme.INPUT_FONT,
                // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            },
            selectedButton: {
                outline: "none",
                backgroundColor: "white",
                borderRadius: 5,
                padding: 10,
                border: "none",
                color: Theme.MAIN_COLOR,
                margin: "auto 2% auto 2%",
                fontFamily: Theme.HEADER_FONT,
                // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            },
            deactivate: {
                outline: "none",
                backgroundColor: "rgba(250,250,250, .3)",
                borderRadius: 5,
                padding: 5,
                border: "none",
                color: "white",
                margin: "auto",
                marginRight: 10,
                fontFamily: Theme.HEADER_FONT,
                // fontSize: Theme.getSmallFont(this.props.global.dimensions.width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
                cursor: "pointer"
            }
        };
        return (
            <div
                style={{
                    height: this.props.global.dimensions.height,
                    backgroundColor: Theme.BACKGROUND_COLOR,
                    backgroundRepeat: "round",
                    backgroundImage: `url(${Bg})`
                }}
            >
                <StaffNavBar
                    tab={6}
                    history={this.props.history}
                    width={this.props.global.dimensions.width}
                />

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flexShrink: 0,
                        width: "90%",
                        justifyContent: "center",
                        marginTop: -80,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                >
                    <div
                        style={{
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "-3%",
                            marginBottom: "4%",
                            width: "100%",
                            color: "white",
                            alignItems: "center",
                            alignContent: "center",
                            // fontSize: Theme.getHeaderFont(width),
                            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                            fontFamily: Theme.HEADER_FONT
                        }}
                    >
                        {(this.props.global.currentUser.type === "Admin" || this.props.global.currentUser.type === "Manager") && (<button
                            onClick={() =>
                                this.setState({ tab: 0, type: "parkClosure" })
                            }
                            style={
                                tab === 0 ? styles.selectedButton : styles.navButton
                            }
                        >
                            Park Closure
                        </button>)}
                        {(this.props.global.currentUser.type === "Admin" || this.props.global.currentUser.type === "Manager") && (<button
                            onClick={() =>
                                this.setState({ tab: 1, type: "parkClosure" })
                            }
                            style={
                                tab === 1 ? styles.selectedButton : styles.navButton
                            }
                        >
                            Park Log
                        </button>)}
                    </div>
                    {(tab === 0) && <ParkClosure
                        height={height}
                        width={width}
                        client={this.props.client}
                    />}
                    {(tab === 1) && <ParkLog
                        height={height}
                        width={width}
                        client={this.props.client}
                        global={this.props.global}
                    />}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
    withApollo,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Operations);
