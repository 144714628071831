import React, { Component } from "react";
import Clock from "../../assets/hour-arrow.svg";
import SignUp from "../../assets/plus-green.svg";
import Team from "../../assets/login-staff.svg";
import Theme from "../Theme";

const icons = {
  Clock,
  SignUp,
  Team
};

const styles = {
  web: borderColor => {
    return {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      width: "25%",
      minWidth: 300,
      border: "1px solid",
      borderColor: borderColor, // <<<< VARIABLE ELEMENT: Border Color
      borderRadius: 10,
      marginTop: "-4%"
    };
  },
  mobile: borderColor => {
    return {
      display: "flex",
      flexDirection: "column",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "5%",
      width: "25%",
      minWidth: 300,
      border: "1px solid",
      borderColor: borderColor, // <<<< VARIABLE ELEMENT: Border Color
      borderRadius: 10
    };
  },
  webLogo: {
    marginTop: "-5%",
    height: 250,
    marginLeft: "auto",
    marginRight: "auto"
  },
  mobileLogo: {
    marginTop: "-5%",
    height: 200,
    marginLeft: "auto",
    marginRight: "auto"
  }
};

class ActivityList extends Component {
  state = {
    isStatListType: false,
    borderColor: Theme.LINK_FONT_COLOR,
    headerBackgroundColor: Theme.LINK_FONT_COLOR,
    headerBorderBottom: "none",
    headerTitleColor: "white",
    countColor: "white",
    fontWeight: "normal"
  };

  setComponentFeatues(icon, isStatListType) {
    this.setState({ icon: icon });

    if (isStatListType === true) {
      this.setState({ fontWeight: "bold" });

      if (icon === "Clock") {
        this.setState({
          borderColor: Theme.INACTIVE_GREY_COLOR,
          headerBackgroundColor: "white",
          headerBorderBottom: "1px solid " + Theme.INACTIVE_GREY_COLOR,
          headerTitleColor: Theme.DARK_TEXT_COLOR,
          countColor: Theme.STANDARD_ORANGE
        });
      } else if (icon === "SignUp") {
        this.setState({
          borderColor: Theme.INACTIVE_GREY_COLOR,
          headerBackgroundColor: "white",
          headerBorderBottom: "1px solid " + Theme.INACTIVE_GREY_COLOR,
          headerTitleColor: Theme.DARK_TEXT_COLOR,
          countColor: Theme.STANDARD_GREEN
        });
      } else if (icon === "Team") {
        this.setState({
          borderColor: Theme.INACTIVE_GREY_COLOR,
          headerBackgroundColor: "white",
          headerBorderBottom: "1px solid " + Theme.INACTIVE_GREY_COLOR,
          headerTitleColor: Theme.DARK_TEXT_COLOR,
          countColor: Theme.DARK_TEXT_COLOR
        });
      }
    }
  }

  componentDidMount() {
    this.setComponentFeatues(this.props.icon, this.props.isStatListType);
  }

  render() {
    const { data, icon } = this.props;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    let listType = null;

    if (icon === "Clock") {
      listType = data.map(member => {
        return (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}
          >
            {/* VARIABLE SECTION: icon */}
            <img
              alt="icon"
              src={icons[this.props.icon]}
              style={{ margin: 5 }}
            />
            {/* END ICON SECTION */}

            {/* VARIABLE SECTION: Entry Section */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    color: Theme.LINK_FONT_COLOR,
                    fontFamily: Theme.INPUT_FONT,
                    fontSize: Theme.getNormalFont(width),
                    marginRight: 4
                  }}
                >
                  In:
                </div>
                <div
                  style={{
                    color: Theme.LIGHT_TEXT_COLOR,
                    fontFamily: Theme.INPUT_FONT,
                    fontSize: Theme.getNormalFont(width)
                  }}
                >
                  30 mo | 2:30 pm
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontSize: Theme.getNormalFont(width),
                    color: Theme.DARK_TEXT_COLOR,
                    fontFamily: Theme.HEADER_FONT
                  }}
                >
                  Name Here
                </div>
              </div>
            </div>
            {/* END ENTRY SECTION */}
          </div>
        );
      });
    } else if (icon === "SignUp") {
      listType = data.map(member => {
        return (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}
          >
            {/* VARIABLE SECTION: icon */}
            <img
              alt="icon"
              src={icons[this.props.icon]}
              style={{ margin: 5 }}
            />
            {/* END ICON SECTION */}

            {/* VARIABLE SECTION: Entry Section */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    color: Theme.LIGHT_TEXT_COLOR,
                    fontFamily: Theme.INPUT_FONT,
                    fontSize: Theme.getNormalFont(width),
                    marginRight: 4
                  }}
                >
                  In:
                </div>
                <div
                  style={{
                    color: Theme.LIGHT_TEXT_COLOR,
                    fontFamily: Theme.INPUT_FONT,
                    fontSize: Theme.getNormalFont(width)
                  }}
                >
                  30 R | Employee Name
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontSize: Theme.getNormalFont(width),
                    color: Theme.DARK_TEXT_COLOR,
                    fontFamily: Theme.HEADER_FONT
                  }}
                >
                  New Member Name
                </div>
              </div>
            </div>
            {/* END ENTRY SECTION */}
          </div>
        );
      });
    } else if (icon === "Team") {
      listType = data.map(member => {
        return (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "5%" }}
          >
            {/* VARIABLE SECTION: icon */}
            <img
              alt="icon"
              src={icons[this.props.icon]}
              style={{ margin: 5 }}
            />
            {/* END ICON SECTION */}

            {/* VARIABLE SECTION: Entry Section */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    fontSize: Theme.getNormalFont(width),
                    color: Theme.DARK_TEXT_COLOR,
                    fontFamily: Theme.HEADER_FONT,
                    marginTop: 7
                  }}
                >
                  Staff Name Here
                </div>
              </div>
            </div>
            {/* END ENTRY SECTION */}
          </div>
        );
      });
    }

    return (
      <div>
        <div
          style={
            width < 1011
              ? styles.mobile(this.state.borderColor)
              : styles.web(this.state.borderColor)
          }
        >
          {/* VARIABLE SECTION: Border Header */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: this.state.headerBackgroundColor,
              borderBottom: this.state.headerBorderBottom,
              height: 40,
              alignItems: "center",
              paddingLeft: "2%",
              paddingRight: "2%",
              borderTopRightRadius: 9,
              borderTopLeftRadius: 9
            }}
          >
            <div
              style={{
                color: this.state.headerTitleColor,
                fontSize: Theme.getSmallFont(width),
                marginRight: 10
              }}
            >
              {this.props.listTitle}
            </div>
            <div
              style={{
                color: this.state.countColor,
                marginLeft: "1%",
                fontSize: Theme.getNormalFont(width),
                fontWeight: this.state.fontWeight
              }}
            >
              30
            </div>
            <div
              style={{
                color: "white",
                marginLeft: "auto",
                marginRight: 10,
                fontSize: Theme.getSmallFont(width)
              }}
            >
              12/12/1222
            </div>
          </div>
          {/* END BORDER HEADER SECTION */}

          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: 10,
              paddingBottom: "5%",
              paddingLeft: "5%"
            }}
          >
            {listType}
          </div>
        </div>
      </div>
    );
  }
}

export default ActivityList;
