import cookie from "js-cookie";
import moment from "moment";

export const sessionCheck = (error, updateUser, history) => {
  if (error) {
    if (
      error.message ===
      "GraphQL error: You are not authorized to access that resource"
    ) {
      cookie.remove("token");
      updateUser("reset");
      history.push("/login");
    }
    return `Error: ${error.message}`;
  }
};

export const formattedDate = (CDate) => {
  console.log("Date", CDate);
  // let current_datetime = new Date(CDate)

  // let formatted_date = ('0' + (current_datetime.getMonth() + 1)).slice(-2) + "/" + ('0' + (current_datetime.getDate())).slice(-2) + "/" + current_datetime.getFullYear();
  // return formatted_date
  // function format(inputDate) {
  var date = new Date(CDate);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }
  // }
};

export const convertDateFormat = (date) => {
  console.log("Date", date);
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  } else {
    return null;
  }

  // }
};
export const convertTimeFormat = (date) => {
  console.log("Date-", date);
  if (date) {
    return moment(date).format("H:mm");
  } else {
    return null;
  }

  // }
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
};
export const getTimeZoneWithoutColon = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    // +":" +
    ("00" + (o % 60)).slice(-2)
  );
};

export const getDateTime = (date, time) => {
  // return `${formattedDate(date)} ${moment(time).format(
  //   "HH:mm"
  // )} ${getTimeZone()}`;
  if (date) {
    let newDT = `${formattedDate(date)} ${moment(time).format(
      "HH:mm"
    )} ${getTimeZone()}`;
    return new Date(newDT).toISOString();
  }
};
export const getDate = (date) => {
  if (date) {
    let newDT = `${formattedDate(date)} ${moment(date).format(
      "HH:mm"
    )} ${getTimeZone()}`;
    // console.log("Timezone",(newDT));
    // console.log("*********Timezone**********",(new Date(newDT).toISOString()));
    return new Date(newDT).toISOString();
  }
};

export const convertTo12HrsFormat = (h_24) => {
  var dt = moment(h_24, ["HH:mm"]).format("h:mm A");
  return dt;
};

export const convertTo24HrsFormat = (date, h_24) => {
  // 07:05:45PM
  // var dt = moment("12:15 AM", ["h:mm A"]).format("HH:mm");
  // return dt;
  let newDT = `${convertDateFormat(date)}T${moment(h_24, ["HH:mm"]).format(
    "HH:mm"
  )}${getTimeZone()}`;
  // console.log(h_24, "Time Zone", newDT);

  return newDT;
};
