import React from "react";
import Theme from "../../Theme";

const width = window.innerWidth;
const styles = {
  header: {
    // fontSize: "90%",
    fontSize: `${width > 1000 ? "14" : "12"}px`,
    fontFamily: Theme.MAIN_FONT,
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    color: "white"
  },
  altHeader: {
    fontSize: "90%",
    fontFamily: Theme.MAIN_FONT,
    color: Theme.DARK_TEXT_COLOR,
    borderBottom: "1.4px solid #C8CFD9"
  },
  noLabelHeader: {
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    width: 10
  },
  altNoLabel: {
    width: 10
  },
  row: {
    fontSize: 14,
    color: "#3F3F3F",
    position: "relative"
  }
};
export default function Cell({ label, renderCell, header, alt }) {
  return (
    <th
      style={
        header
          ? label
            ? alt
              ? styles.altHeader
              : styles.header
            : alt
              ? styles.altNoLabel
              : styles.noLabelHeader
          : styles.row
      }
    >
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
        <div
          style={{
            marginRight: 20,
            marginBottom: "auto",
            marginTop: "auto",
            width: "100%",
            height: "100%"

          }}
        >
          {renderCell ? renderCell : label}
        </div>
      </div>
    </th>
  );
}
