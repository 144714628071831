import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FETCH_MEMBERS_LIST,
  FETCH_MEMBERS_LIST_BY_TAG,
  FETCH_TAG_LIST,
} from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import { Theme, SearchBar, Button } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import TagSelect from "../../../globalComponents/FormComponents/TagSelect.js";
import { SEND_MESSAGE_MEMBERS } from "../../../store/mutations.js";
import CheckedNeutered from "../../../assets/check_neutered.svg";
import { uniq as _uniq } from "lodash";
// import DownloadCSV from "./DownloadMembersCSV.jsx";
import DownloadMembersCSV from "./DownloadMembersCSV.jsx";
import UserPlaceholder from "../../../assets/placeholder.jpeg";

class MemberFilterByTag extends Component {
  state = {
    tab: 0,
    search: "",
    page: 1,
    per: 20,
    tagName: "Choose tag to add",
    tagId: "",
    tagList: [],
    membersList: [],
    selectedMembers: {},
    isLoading: false,
  };

  handleSelect = (option, name) => {
    this.setState({ [name]: option });
  };

  handleChange = (value) => {
    console.log("Value---->", value);
    this.setState({ search: value });
  };

  searchMember = (_id) => {
    const { client } = this.props;
    const { search } = this.state;
    let searchValue = String(search).trim().replace(/\s/g, "");
    let searchArray = searchValue.split(",");
    this.setState({
      isLoading: true,
    });
    client
      .query({
        query: FETCH_MEMBERS_LIST_BY_TAG,
        fetchPolicy: "no-cache",
        variables: { tagId: _id },
      })
      .then((response) => {
        console.log("Member", response);
        this.setState({
          membersList: response.data?.fetchTagMembers,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  sendMessageMembers = () => {
    const { client } = this.props;
    const { tagId, selectedMembers } = this.state;
    let selectedMembersIds = [];
    const checkedMembers = Object.entries(selectedMembers);
    const membersIds = _uniq(checkedMembers.map((val) => val[1]));
    for (const userId of membersIds) {
      selectedMembersIds.push(userId);
    }
    let values = {
      tagId,
      userIds: selectedMembersIds,
    };
    if (tagId !== "" && selectedMembersIds.length > 0) {
      client
        .mutate({
          mutation: SEND_MESSAGE_MEMBERS,
          variables: { input: values },
        })
        .then((response) => {
          alert("Message sent successfully");
          this.setState({
            tagName: "Choose tag to add",
            tagId: "",
            search: "",
            selectedMembers: {},
            membersList: [],
          });
          selectedMembersIds = [];
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      alert("Please select Tag and Members");
    }
  };

  selectMembers = (_event, id, index) => {
    let { selectedMembers } = this.state;
    if (id in selectedMembers) {
      delete selectedMembers[id];
    } else {
      selectedMembers[id] = id;
    }
    this.setState({ selectedMembers });
  };

  displayMember = (data) => {
    // old logic for users
    this.props.updateUser({ selectedUser: data.id });
    this.props.history.push({
      pathname: `/staff/member`,
      params: { id: data.id },
    });
  };

  render() {
    const {
      search,
      per,
      page,
      tagName,
      tagId,
      membersList,
      selectedMembers,
      isLoading,
    } = this.state;
    const { global } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const role = global.currentUser.type;
    const verySmall = width < 568;
    const shrink = width < 700;
    return (
      <div>
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            marginTop: "0%",
            marginBottom: "4%",
            width: "100%",
            color: "white",
            alignItems: "center",
            alignContent: "center",
            // fontSize: Theme.getHeaderFont(width),
            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          Members By Tag
        </div>
        <div>
          <div style={{ marginTop: 20 }}>
            <h2
              style={{
                fontSize: "29px",
                fontFamily: "LatoBold",
                color: "#000",
                textAlign: "center",
              }}
            >
              Members By Tag
            </h2>
            <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
              {({ loading, error, data, refetch }) => {
                if (loading) return <div></div>;
                if (error) return `Error: ${error.message}`;
                return (
                  <div>
                    <div
                      style={{
                        width: shrink ? "80%" : "50%",
                        margin: "auto",
                      }}
                    >
                      <TagSelect
                        alt={true}
                        options={data.fetchTags}
                        handleSelect={(tag, name) => {
                          this.setState({
                            tagName: tag.title,
                            tagId: tag.id,
                          });
                          this.searchMember(tag.id);
                        }}
                        selected={tagName}
                        name="tagId"
                        width={"100%"}
                        height={height / 25}
                        label="Tags"
                      />
                    </div>
                  </div>
                );
              }}
            </Query>
          </div>

          { !isLoading &&tagId && (
            <div style={{ marginTop: 20 }}>
              <div
                style={{
                  width: shrink ? "80%" : "50%",
                  margin: "auto",
                }}
                className="row"
              >
                <h2
                  style={{
                    fontSize: "20px",
                    fontFamily: "LatoBold",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Member's List
                </h2>

                { membersList.length > 0 && (
                  //   !isLoading && fetchEventLists.length > 0 &&
                  <DownloadMembersCSV
                    list={membersList}
                    roll="memberList"
                    eventName={`Members List - ${tagName}`}
                    text={`Download  CSV`}
                    fullwidth={false}
                    // onClick={this.apllyFilter}
                    width="100%"
                    // disabled={fetchEventLists.length >= 0}
                    height={height / 20}
                    mobileShrink={shrink}
                  />
                )}
              </div>
              {!isLoading && membersList.length === 0 ? (
                <h2
                  style={{
                    fontSize: "20px",
                    fontFamily: "LatoBold",
                    color: "#000",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  No Member found
                </h2>
              ) : (
                <div
                  style={{
                    width: shrink ? "80%" : "50%",
                    margin: "auto",
                    maxHeight: "346px",
                    overflowY: "auto",
                    paddingLeft: "2px",
                  }}
                >
                  {membersList.map((data, index) => {
                    let member_id = data.id;
                    let members_name = `${data.firstName} ${data.lastName} `;
                    return (
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => this.displayMember(data)}
                        >
                          <div
                            style={{
                              display: "inline-flex",
                              // width: "45px",
                              width: "40px",
                              flexDirection: "column",
                              overflow: "hidden",
                              borderRadius: "5px",
                              height: "40px",
                              marginLeft: "3px",
                            }}
                          >
                            <img
                              // key={data.id}
                              // alt={data.profilePicture.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={
                                (data.profilePicture &&
                                  // process.env.REACT_APP_API_ENDPOINT +
                                  //   data.profilePicture.url) ||
                                  data.userDetail.profilePictureCdn) ||
                                UserPlaceholder
                                //   UserPlaceholder
                              }
                              onError={(event) =>
                                event.target.setAttribute(
                                  "src",
                                  UserPlaceholder
                                )
                              }
                            />
                          </div>
                          <div
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: "5px",
                              width: "100%",
                            }}
                          >
                            <span> {members_name}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {/* <div
            style={{
              width: Theme.getButtonWidth(width),
              margin: "auto",
              marginTop: 25,
            }}
          >
            <Button
              text="Submit"
              alt={false}
              width={width}
              onClick={() => this.sendMessageMembers()}
            />
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(MemberFilterByTag);
