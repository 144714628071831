import React, { Component } from "react";
import { Query } from "react-apollo";
import { PulseLoader } from "react-spinners";
import { Button, MemberTable, Theme } from "../../../../globalComponents";
import SelectDashBoardDateFilter from "../../../../globalComponents/FormComponents/SelectDashboardDateFilter";
import SelectDashBoardEventTypeFilter from "../../../../globalComponents/FormComponents/SelectDashboardEventTypeFilter";
import SelectDashBoard from "../../../../globalComponents/FormComponents/SelectDashboardFilter";
import {
  FETCH_EVENT_CREATOR,
  FETCH_EVENT_LISTS,
  FETCH_EVENT_PARK_LOG,
  FETCH_EVENT_TYPE,
} from "../../../../store/queries";
import DownloadCSV from "./downloadCSV";
import Bg from "../../../../assets/fetch-BG.svg";
import { DateArray } from "../../../../globalComponents/Constants";

class ParkLogEventTable extends Component {
  state = {
    park: "",
    page: 1,
    per: 20,
    date: "",
    dateName: "",
    by_type: "",
    created_by: "",
    sortAttr: "",
    sort: "",
    fetchEventLists: [],
    fetchEventListsCSV: [],
    totalCountEvent: 0,
    isLoading: true,
    isLoadingCSV: false,

    csvPer: 0,
  };

  componentDidMount() {
    this.apllyFilter();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.parkId !== this.props.parkId) {
      this.apllyFilter();
      this.setState({
        page: 1,
      });
    }
  }

  apllyFilter = () => {
    let { created_by, by_type, date, page, per } = this.state;
    this.setState({
      isLoading: true,
    });
    let variables = {
      parkId: this.props.parkId,
      sortAttr: "",
      sort: "",
      byType: by_type,
      date: date,
      createdBy: created_by,
      page: page,
    };

    this.props.client
      .query({
        query: FETCH_EVENT_PARK_LOG,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchEventLists: response.data?.parkEvent.edges,
          isLoading: response.loading,
          totalCountEvent: response.data?.parkEvent.totalCount,
          fetchEventListsCSV: [],
        });
        let total = response.data?.parkEvent.totalCount;
        let csvData = total / per;
        this.setState(
          {
            csvPer: total,
          },
          () => {
            this.downloadCSV();
          }
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  sorting = (e, sortAttr, sort) => {
    e.preventDefault();
    let { fetchEventLists, by_type, date, created_by, page } = this.state;

    if (fetchEventLists.length > 0) {
      this.setState({
        sortAttr: sortAttr,
        sort: sort,
      });
      let variables = {
        parkId: this.props.parkId,
        sortAttr: sortAttr,
        sort: sort,
        byType: by_type,
        date: date,
        createdBy: created_by,
        page: page,
      };
      this.props.client
        .query({
          query: FETCH_EVENT_PARK_LOG,
          variables: variables,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.setState({
            fetchEventLists: response.data?.parkEvent.edges,
            isLoading: response.loading,
            totalCountEvent: response.data?.parkEvent.totalCount,
          });
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      e.preventDefault();
    }
  };

  pagination = (e, page) => {
    e.preventDefault();
    let { sort, sortAttr, fetchEventLists, by_type, created_by, date } =
      this.state;
    if (fetchEventLists.length > 0) {
      this.setState({
        page: page,
      });
      let variables = {
        parkId: this.props.parkId,
        sortAttr: sortAttr,
        sort: sort,
        byType: by_type,
        date: date,
        createdBy: created_by,
        page: page,
      };
      this.props.client
        .query({
          query: FETCH_EVENT_PARK_LOG,
          variables: variables,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.setState({
            fetchEventLists: response.data?.parkEvent.edges,
            isLoading: response.loading,
            totalCountEvent: response.data?.parkEvent.totalCount,
          });
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      e.preventDefault();
    }
  };
  downloadCSV = () => {
    let { created_by, by_type, date, page, csvPer } = this.state;
    this.setState({
      isLoadingCSV: true,
    });
    let variables = {
      parkId: this.props.parkId,
      sortAttr: "",
      sort: "",
      byType: by_type,
      date: date,
      createdBy: created_by,
      page: page,
      per: csvPer,
    };

    this.props.client
      .query({
        query: FETCH_EVENT_PARK_LOG,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchEventListsCSV: response.data?.parkEvent.edges,
          isLoadingCSV: false,
          // totalCountEvent: response.data?.parkEvent.totalCount,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  render() {
    const { global } = this.props;
    const {
      date,
      dateName,
      isLoading,
      fetchEventLists,
      fetchEventListsCSV,
      page,
      per,
      totalCountEvent,
      by_type,
      isLoadingCSV,
      created_by,
    } = this.state;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const shrink = width < 768;
    const TillTab = width < 1024;
    const height = obj.dimensions.height;
    const small = width < 1170;

    const columnNames = ["date", "member", "type", "creator", "Message"];

    const eventColumns = {
      date: {
        label: "Date & Time",
        renderCell: (data) => {
          return (
            <div style={{ display: "flex" }}>
              <span
                style={{
                  lineHeight: "26px",
                  marginLeft: "10px",
                }}
              >
                {data.createdAt}
              </span>
            </div>
          );
        },
      },
      member: {
        label: "Member",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {/* {data?.currentUser} */}
            {/* {console.log(JSON.parse(data.eventObject))} */}
            {data.member
              ? `${data.member.firstName} ${data.member.lastName}`
              : "N/A"}
          </div>
        ),
      },
      type: {
        label: "Type",
        renderCell: (data) => (
          <div
            style={{
              display: TillTab ? "block" : "flex",
              cursor: "pointer",
              flexDirection: `${width > 1000 ? "row" : "column"}`,
            }}
          >
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: `${width > 1000 ? "12" : "12"}px`,
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginLeft: `${width > 1000 ? "10%" : "auto"}`,
                textAlign: "left",
                marginTop: "auto",
                marginBottom: "auto",
                display: TillTab ? "inline-block" : " ",
                float: TillTab ? "left" : " ",
              }}
            >
              {data.eventName}
            </div>
          </div>
        ),
      },
      creator: {
        label: "Created By",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {data?.currentUser}
          </div>
        ),
      },
      Message: {
        label: "Message",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {data.message === null ? "No Message" : data.message}
          </div>
        ),
      },
    };

    // if (isLoading)
    //     return (
    //         <div
    //             name="container"
    //             style={{
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 // width: width,
    //                 minHeight: height,
    //                 alignItems: "center",
    //                 alignContent: "center",
    //                 justifyContent: "center",
    //                 backgroundColor: Theme.BACKGROUND_COLOR,
    //                 backgroundRepeat: "round",
    //                 backgroundImage: `url(${Bg})`
    //             }}
    //         >

    //             <PulseLoader
    //                 style={{ margin: "auto" }}
    //                 size={width / 10}
    //                 color={Theme.LINK_FONT_COLOR}
    //                 loading={isLoading}
    //             />
    //         </div>
    //     );
    return (
      <div
        style={{
          flexDirection: "column",
          width: shrink ? "100%" : "100%",
          marginBottom: shrink ? "0" : "20px",
          display: shrink ? "block" : "flex",
          marginTop: shrink ? "0" : "20px",
        }}
      >
        {
          <div
            style={{
              flexDirection: "row",
              display: shrink ? "block" : "flex",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            {/* Date Dropdown */}
            <SelectDashBoardDateFilter
              alt={true}
              options={DateArray}
              handleSelect={(data, name) => {
                // this.change(name, park.id);
                this.setState({ date: data.value, dateName: data.name });
              }}
              selected={date === "" ? "Date Range" : dateName}
              name="parkId"
              width={"100%"}
              height={height / 25}
              label="Park Location"
              containerStyle={{
                margin: shrink ? "20px 10px 10px 0px" : "0 10px 0 0",
                display: shrink ? "inline-flex" : "",
                width: "48%",
              }}
            />

            {/* Event Type DropDown */}
            <Query fetchPolicy="no-cache" query={FETCH_EVENT_TYPE}>
              {({ loading, error, data, refetch }) => {
                if (error) return `Error: ${error.message}`;
                return (
                  <SelectDashBoardEventTypeFilter
                    alt={true}
                    options={data.fetchEventType}
                    handleSelect={(park, name) => {
                      this.setState({ by_type: park });
                    }}
                    selected={by_type === "" ? "Type" : by_type}
                    name="parkId"
                    width={"100%"}
                    height={height / 25}
                    label="Park Location"
                    containerStyle={{
                      margin: shrink ? "20px 10px 10px 0px" : "0 10px 0 0",
                      display: shrink ? "inline-flex" : "",
                      width: "48%",
                    }}
                  />
                );
              }}
            </Query>
            <Query fetchPolicy="no-cache" query={FETCH_EVENT_CREATOR}>
              {({ loading, error, data, refetch }) => {
                if (error) return `Error: ${error.message}`;

                return (
                  <>
                    <SelectDashBoard
                      alt={true}
                      options={data.fetchEventCreater?.employees}
                      handleSelect={(option, name) => {
                        // this.change(name, park.id);
                        this.setState({
                          created_by:
                            (option &&
                              option.first_name + " " + option.last_name) ||
                            "",
                        });
                        // console.log("-------> park", created_by, option);
                      }}
                      selected={created_by === "" ? "Creator" : created_by}
                      // name="parkId"
                      width={"100%"}
                      height={height / 25}
                      label="Creator"
                      containerStyle={{
                        margin: shrink ? "10px 10px 10px 0px" : "0 10px 0 0",
                        display: shrink ? "inline-flex" : "",
                        width: "48%",
                      }}
                    />
                  </>
                );
              }}
            </Query>
            <div
              style={{
                margin: `${
                  width < 900
                    ? width < 568
                      ? "15px auto 10px"
                      : "0px auto 10px"
                    : "0 0 0 auto "
                }`,
                display: `${width < 900 ? "inline-flex" : "flex "}`,
                flexDirection: `${width < 900 ? "column" : "row"}`,
              }}
            >
              <Button
                text={`Apply Filter`}
                fullwidth={false}
                onClick={this.apllyFilter}
                width="100%"
                // disabled={false}
                height={height / 20}
              />
            </div>
          </div>
        }

        {!isLoading && fetchEventLists.length > 0 && (
          <DownloadCSV
            list={fetchEventListsCSV}
            roll="parkEventList"
            eventName={`Park-Event-Logs`}
            text={`Download  CSV`}
            fullwidth={false}
            // onClick={this.apllyFilter}
            width="100%"
            disabled={isLoadingCSV}
            height={height / 20}
            mobileShrink={shrink}
            onClick={(e) => (isLoadingCSV ? e.preventDefault() : {})}
          />
        )}
        {!isLoading && (
          <MemberTable
            loading={isLoading}
            page={page}
            per={per}
            containerStyle={{ flex: 1, marginLeft: small ? 0 : "5%" }}
            names={columnNames}
            columns={eventColumns}
            // onPage={(page) => this.setState({ page: page })}
            onPage={this.pagination}
            rows={fetchEventLists}
            sorting={this.sorting}
            total={totalCountEvent}
            width={width}
          />
        )}
      </div>
    );
  }
}

export default ParkLogEventTable;
