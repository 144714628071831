import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { USER_DETAILS } from "../../store/queries.js";
import { USER_UPDATE } from "../../store/mutations";
import { Query, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import cookie from "js-cookie";
import {
  NavBar,
  Theme,
  PictureUpload,
  StaffNavBar,
  LoadingIndicator,
  Select
} from "../../globalComponents";
import Form from "./Form.js";
import RangerForm from "./RangerForm.js";
import Vaccinations from "./Vaccinations.js";
import Membership from "./Membership.js";
import Bg from "../../assets/fetch-BG.svg";
import { PulseLoader } from "react-spinners";
import { reduxForm } from "redux-form";
import { updateUser } from "../../store/redux/actions.js";

class StaffProfile extends Component {
  state = {
    tab: 0,
    picture: null,
    selectedOption: "Details"
  };

  isMobile = () => {
    if (window.innerWidth < 700) {
      return true;
    } else {
      return false;
    }
  };

  deactivateBarkRanger = () => {
    this.props.client
      .mutate({
        mutation: USER_UPDATE,
        variables: {
          input: {
            id: this.props.global.currentUser.selectedUser,
            deactivated: true
          }
        }
      })
      .then(() => {
        alert("Staff member has been deactivated!");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      })
      .catch(error => {
        console.error("Error: ", error);
        return false;
      });
  };

  activateBarkRanger = () => {
    this.props.client
      .mutate({
        mutation: USER_UPDATE,
        variables: {
          input: {
            id: this.props.global.currentUser.selectedUser,
            deactivated: false
          }
        }
      })
      .then(() => {
        alert("Staff member has been activated!");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      })
      .catch(error => {
        console.error("Error: ", error);
        return false;
      });
  };

  render() {
    const { tab, picture, selectedOption } = this.state;
    const { global, change, handleSubmit } = this.props;
    console.log("this.props.global.dimensions.width", this.props.global.dimensions.width);
    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        margin: "auto 2% auto 2%",
        color: "white",
        fontFamily: Theme.INPUT_FONT,
        fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        cursor: "pointer"
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "white",
        borderRadius: 5,
        padding: 10,
        height: 60,
        border: "none",
        color: Theme.MAIN_COLOR,
        margin: "auto 2% auto 2%",
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        cursor: "pointer"
      }
    };

    const admin =
      global.currentUser === "admin" || global.currentUser === "staff";

    const view = this.props.history.location.state
      ? this.props.history.location.state.view
      : "";

    const selectOptions =
      global.currentUser.type === "Member"
        ? [
          { name: "Details", tab: 0 },
          { name: "Membership", tab: 1 },
          { name: "Dog Info", tab: 2 }
        ]
        : [{ name: "Details", tab: 0 }, { name: "Dog Info", tab: 2 }];


    const handleOnboardingRedirect = (step) => {
      console.log("Step", step);
      let route;
      switch (step) {
        case 0:
          // route = "/members/onboarding/accountCreation";
          route = "/members/onboarding/ParkInfo"
          break;
        case 1:
          route = "/members/onboarding/info";
          break;
        case 2:
          route = "/members/onboarding/address";
          break;
        case 3:
          route = "/members/onboarding/terms";
          break;
        case 4:
          route = "/members/onboarding/dogInfo";
          break;
        case 5:
          route = "/members/onboarding/vaccinations";
          break;
        case 6: // Need to discuss
          route = "/members/onboarding/vaccinations";
          break;
        case 7: // Need to discuss
          route = "/members/onboarding/confirmAccount";
          break;
        case 8:
          route = "/members/onboarding/confirmAccount";
          break;
        default:
          break;
      }
      return this.props.history.push(route);
    };
    const checkSteps = (step) => {
      let status = false
      console.log("Stpe", (global.currentUser?.type=== "Member" && step>=0));
      if (global.currentUser?.type === "Member" && step>=0 && step !==null) {
        status = false
        return handleOnboardingRedirect(step)
      }
      else {
        status = true
      }
      return status;
    }
    console.log("Global-------------->", global);

    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{
          id: view
            ? this.props.global.currentUser.selectedUser
            : this.props.global.currentUser.id
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <LoadingIndicator
                width={this.props.global.dimensions.width}
                height={this.props.global.dimensions.height}
                loading={loading}
              />
            );
          if (error) {
            if (
              error.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error.message}`;
          }
          delete data.userDetail.__typename;
          let step = data?.userDetail?.step

          // console.log("steo",data.userDetail);
          checkSteps(step)
          return (
            <div
              style={{
                minHeight: this.props.global.dimensions.height,
                backgroundColor: Theme.BACKGROUND_COLOR,
                backgroundRepeat: "round",
                backgroundImage: `url(${Bg})`,
                width: window.innerWidth
              }}
            >
              {admin ? (
                <StaffNavBar
                  history={this.props.history}
                  width={this.props.global.dimensions.width}
                  title={
                    (global.currentUser.firstName || "First") +
                    " " +
                    (global.currentUser.lastName || "Last")
                  }
                />
              ) : global.currentUser.type === "Employee" ||
                global.currentUser.type === "Manager" ||
                global.currentUser.type === "Admin" ? (
                    <NavBar
                      history={this.props.history}
                      width={this.props.global.dimensions.width}
                      title={
                        (data.userDetail.firstName || "First") +
                        " " +
                        (data.userDetail.lastName || "Last")
                      }
                      backButton={true}
                    />
                  ) : (
                    <NavBar
                      history={this.props.history}
                      width={this.props.global.dimensions.width}
                      title={
                        (data.userDetail.firstName || "First") +
                        " " +
                        (data.userDetail.lastName || "Last")
                      }
                      backButton={false}
                    />
                  )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexShrink: 0,
                  marginTop: -110,
                  marginLeft: "2%",
                  marginRight: "5%"
                }}
              >
                <PictureUpload
                  width={this.props.global.dimensions.width}
                  name="profilePicture"
                  picture={
                    picture ||
                    (data &&
                      data.userDetail &&
                      process.env.REACT_APP_API_ENDPOINT +
                      data.userDetail.profilePicture.url)
                  }
                  containerStyle={{
                    marginTop: 20,
                    marginLeft: `${this.isMobile() ? "5px" : "8%"}`,
                    marginRight: `${this.isMobile() ? "5px" : "2%"}`
                  }}
                  label="Picture"
                  onUpload={(v, pic) => {
                    this.setState({ picture: pic });
                    change("profilePicture", v);
                    handleSubmit();
                  }}
                  disableForMembers={
                    global.currentUser.type === "Member" ? true : false
                  }
                  picturePresent={data.userDetail.profilePicture.url}
                />
                {!this.isMobile() && (
                  <Fragment>
                    <button
                      onClick={() => this.setState({ tab: 0 })}
                      style={
                        tab === 0 ? styles.selectedButton : styles.navButton
                      }
                    >
                      Details
          </button>
                    {data.userDetail.type === "Employee" ||
                      data.userDetail.type === "Manager" ||
                      data.userDetail.type === "Admin" ? null : (
                        <button
                          onClick={() => this.setState({ tab: 1 })}
                          style={
                            tab === 1 ? styles.selectedButton : styles.navButton
                          }
                        >
                          Membership Type
                        </button>
                      )}
                    <button
                      onClick={() => this.setState({ tab: 2 })}
                      style={
                        tab === 2 ? styles.selectedButton : styles.navButton
                      }
                    >
                      Dog Info & Vaccination
          </button>
                    {view && (
                      <button
                        onClick={() => {
                          if (data.userDetail.deactivated) {
                            this.activateBarkRanger();
                          } else {
                            this.deactivateBarkRanger();
                          }
                        }}
                        style={{
                          outline: "none",
                          border: "none",
                          padding: 10,
                          margin: "auto 2% auto 2%",
                          color: "white",
                          borderRadius: 2,
                          marginLeft: "auto",
                          backgroundColor: "rgba(255,255,255,.3)",
                          fontFamily: Theme.INPUT_FONT,
                          fontSize: Theme.getNormalFont(
                            this.props.global.dimensions.width
                          ),
                          cursor: "pointer"
                        }}
                      >
                        {view === "ranger"
                          ? data.userDetail.deactivated
                            ? "Activate Staff Member"
                            : "Deactivate Staff Member"
                          : "Deactivate Member"}
                      </button>
                    )}
                  </Fragment>
                )}
                {this.isMobile() && (
                  <div
                    style={{
                      marginLeft: 5,
                      marginRight: "auto",
                      marginTop: 70
                    }}
                  >
                    <Select
                      alt={false}
                      options={selectOptions}
                      handleSelect={selection => {
                        this.setState({
                          tab: selection.tab,
                          selectedOption: selection.name
                        });
                      }}
                      selected={selectedOption}
                      name="parkId"
                      width={300}
                      height={40}
                      label="Tab"
                    />
                  </div>
                )}
              </div>
              {tab === 0 ? (
                view === "ranger" ? (
                  <RangerForm
                    refetch={refetch}
                    initialValues={data.userDetail}
                  />
                ) : (
                    <Form refetch={refetch} initialValues={data.userDetail} />
                  )
              ) : tab === 1 ? (
                <Membership />
              ) : (
                    <Vaccinations
                      initialValues={data.userDetail}
                      isManager={view === "ranger"}
                      refetch={refetch}
                    />
                  )}
            </div>
          );


        }}
      </Query>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

const formSubmit = (values, dispatch, props) => {
  let id = parseInt(props.global.currentUser.id);
  if (props.history.location.state) {
    if (props.history.location.state.view === "ranger") {
      id = parseInt(props.global.currentUser.selectedUser);
    }
  }
  return props.client
    .mutate({
      mutation: USER_UPDATE,
      variables: {
        input: {
          id,
          profilePicture: values.profilePicture
        }
      }
    })
    .then(() => {
      // eslint-disable-next-line no-restricted-globals
      //location.reload();
      // props.refetch();
    })
    .catch(error => {
      console.error("Error: ", error);
      return false;
    });
};

StaffProfile = reduxForm({
  form: "StaffProfile",
  onSubmit: formSubmit,
  enableReinitialize: true
})(StaffProfile);

export default compose(
  withApollo,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(StaffProfile);
