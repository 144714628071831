import React, { Component } from "react";
import Theme from "../Theme.js";
import Add from "../../assets/plus-add.svg";
import NotesModal from "./NotesModal.js";



let checkedBox = {
  display: "flex",
  outline: "none",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: Theme.MAIN_COLOR,
  // fontSize: Theme.getSmallFont(width),
  border: "2px solid #ff7d50",
  borderRadius: "5px",
  boxShadow: " #606267 3px 3px 10px",
  width: "110px",
  padding: "10px",
  textAlign: "center",
  borderRadius: "5px"
}
let uncheckedBox = {
  display: "flex",
  outline: "none",
  cursor: "pointer",
  backgroundColor: "transparent",
  borderColor: "transparent",
  color: Theme.MAIN_COLOR,
  border: "transparent",
  width: "110px",
  padding: "10px",
  textAlign: "center",
  borderRadius: "5px"
}
export default class ActivityLog extends Component {
  state = {
    isOpen: false,
    noteData: null,
    data: this.props.data,
    currentIndex: 0,
    isUpdate: false
  };

  openModal = (noteData, currentIndex, isUpdate) => {
    this.setState({ noteData, currentIndex, isUpdate }, () => {
      this.setState({ isModalDisplayed: true });
    });
  };

  closeModal = () => {
    this.setState({ isModalDisplayed: false });
  };

  getHeight(width) {
    if (width > 450) return 200;
    else if (width > 400) return 150;
    return 200;
  }

  updateNotes = newNote => {
    let newState = { ...this.state };
    if (this.state.isUpdate) {
      newState.data[this.state.currentIndex] = newNote;
    } else {
      newState.data.splice(0, 0, newNote);
    }
    this.setState({ data: newState.data });
  };

  deleteNotes = index => {
    let newState = { ...this.state };
    newState.data.splice(index, 1);
    this.setState({ data: newState.data });
  };

  render() {
    const { width, containerStyle,bannedMessageList } = this.props;
    const {
      noteData,
      isModalDisplayed,
      data,
      isUpdate,
      currentIndex
    } = this.state;
    // console.log("-------------------> ", this.props.activityStatus);
    return (
      <div style={containerStyle}>
        <div
          style={{
            justifyContent: "center",
            width: "100%"
            // width: Theme.getLongInputWidth(width) / (widthFactor || 1)
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "2%",
              marginTop: "5%",
              // maxWidth: Theme.getLongInputWidth(width) / (widthFactor || 1),
              fontFamily: Theme.HEADER_FONT,
              fontSize: Theme.getHeaderFont(width),
              color: Theme.HEADER_FONT_COLOR
            }}
          >
            Account Activity

            
             <button
              onClick={() => this.openModal(null, null, false)}
              type="button"
              style={{
                display: "flex",
                outline: "none",
                cursor: "pointer",
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: Theme.MAIN_COLOR,
                width: "110px",
                padding: "10px",
                textAlign: "center",
                borderRadius: "5px",
                fontSize: Theme.getSmallFont(width)
              }}
            >
              <img
                src={Add}
                alt=""
                style={{ marginTop: "2%", marginRight: "10%" }}
              />
              add
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              outline: "none",
              height: this.getHeight(width),
              border: "1px solid",
              borderColor: Theme.LIGHT_TEXT_COLOR,
              borderRadius: 10,
              padding: "2%",
              textDecoration: "none",
              width: "95%",
              // width: Theme.getLongInputWidth(width) / (widthFactor || 1),
              textAlign: "left",
              overflow: "-moz-scrollbars-vertical",
              overflowY: "scroll"
            }}
          >
            {data.map((row, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontSize: Theme.getSmallFont(width),
                    borderBottom: "1px solid",
                    paddingBottom: "2%",
                    marginBottom: "2%",
                    color: Theme.LIGHT_TEXT_COLOR
                  }}
                >
                  <div
                    style={{
                      alignContent: "center",
                      justifyItems: "center",
                      alignItems: "center",
                      display: "flex",
                      height: this.getHeight(width) / 10,
                      marginBottom: "2%",
                      flexDirection: "row",
                      fontFamily: Theme.HEADER_FONT,
                      fontSize: Theme.getHeaderFont(width),
                      color: Theme.HEADER_FONT_COLOR
                    }}
                  >
                    {`${(row.tags[0] === "Account Note") ? "Note" : row.tags[0]
                      } :`}
                    <div
                      style={{
                        fontSize: Theme.getNormalFont(width),
                        marginLeft: "1%",
                        color: Theme.MAIN_COLOR,
                        height: "100%"
                      }}
                    >
                      {`${row.author.firstName || "First"} ${row.author
                        .lastName || "Last"}`}
                    </div>
                    <button
                      type="button"
                      onClick={() => this.openModal(row, index, true)}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        textDecoration: "underline",
                        outline: "none",
                        height: "100%",
                        marginLeft: "auto",
                        cursor: "pointer",
                        color: Theme.MAIN_COLOR
                      }}
                    >
                      See Details
                    </button>
                  </div>
                  <div style={{ marginTop: "2%" }}>{row.content}</div>
                </div>
              );
            })}

          {
          bannedMessageList.map((row, index) => {
            let dogBannedStatus = row.dogBannedStatus;
            let dogName = row.dogName;
            let dogId = row.dogId;
            let dogBannedMessage= row.dogBannedMessage

            if(dogBannedStatus){
              return (
                <div
                  key={dogId}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontSize: Theme.getSmallFont(width),
                    borderBottom: "1px solid",
                    paddingBottom: "2%",
                    marginBottom: "2%",
                    color: Theme.LIGHT_TEXT_COLOR
                  }}
                >
                  <div
                    style={{
                      alignContent: "center",
                      justifyItems: "center",
                      alignItems: "center",
                      display: "flex",
                      height: this.getHeight(width) / 10,
                      marginBottom: "2%",
                      flexDirection: "row",
                      fontFamily: Theme.HEADER_FONT,
                      fontSize: Theme.getHeaderFont(width),
                      color: Theme.HEADER_FONT_COLOR
                    }}
                  >
                    {`Banned comment : `}
                    <div
                      style={{
                        fontSize: Theme.getNormalFont(width),
                        marginLeft: "1%",
                        color: Theme.MAIN_COLOR,
                        height: "100%"
                      }}
                    >
                     {dogName}
                    </div>
                  </div>
                  <div style={{ marginTop: "2%" }}>{dogBannedMessage}</div>
                </div>
              );
            }
            else{
              return null
            }
              
            })}
          </div>
        </div>
        {isModalDisplayed && (
          <NotesModal
            noteData={noteData}
            isModalDisplayed={isModalDisplayed}
            closeModal={this.closeModal}
            updateNotes={this.updateNotes}
            deleteNotes={this.deleteNotes}
            isUpdate={isUpdate}
            currentIndex={currentIndex}
          />
        )}
      </div>
    );
  }
}
