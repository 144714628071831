import Axios from "axios";

export async function placeAutoComplete(payload) {
  if (true) {
    return Axios.get(
      `https://frozen-tundra-07943.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${payload.input}&key=${payload.key}&radius=${payload.radius}`
    )
      .then((response) => {
        // console.log('Auto Complete Response', response.data.predictions)
        return response.data.predictions;
      })
      .catch((error) => {
        console.log("error", error);
        return error.response;
      });
  }
}

export function placeDetails(payload) {
  if (true) {
    return Axios.get(
      `https://frozen-tundra-07943.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?key=${payload.key}&placeid=${payload.place_id}`
    ).then(
      (result) => {
        return result.data.result;
      },
      (error) => {
        console.log("error", error);
        if (error.status !== 500) {
        } else {
        }
        return error;
      }
    );
  } else {
    return new Error("Please check internet connection");
  }
}
