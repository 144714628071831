import moment from "moment";
import { getDate } from "./session";
export const setOtherZone = (date, timezone) => {
    // const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    // const otherZone = moment.tz(date, "US/Pacific").format("Z")
    // const dateWithOtherZone = [dateWithoutZone, otherZone].join("")
    // console.log("New Date",date );
    const dateWithOtherZone = getDate(date)
// console.log("dateWithOtherZone", dateWithOtherZone);
    return (dateWithOtherZone)
}

export const convertUTCToLocalDate = (date) => {
  if (!date) {
    return date;
  }
  date = new Date(date);
  // date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  date = new Date(date.getUTCHours(), date.getUTCMinutes());
  return date;
};

export const convertUTCDateToLocalDate = (date) => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};
