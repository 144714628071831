import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_MEMBERS } from "../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import {
  Table,
  Theme,
  SearchBar,
  StaffNavBar,
  PawIndicator
} from "../../globalComponents";
import { updateUser } from "../../store/redux/actions";
import Bg from "../../assets/fetch-BG.svg";
import EmployeeDashboard from "../../pages/staff/dashboard";
import moment from "moment";

class Rangers extends Component {
  state = {
    tab: 0,
    search: "",
    page: 1,
    per: 20
  };
  handleSelect = (option, name) => {
    this.setState({ [name]: option });
  };

  handleChange = value => {
    this.setState({ search: value });
  };

  render() {
    const { search, per, page } = this.state;
    const { global } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const role = global.currentUser.type;

    const adminColumnNames = [
      "status",
      "date",
      "name",
      "location",
      "dog",
      "type"
    ];
    const managerColumnNames = ["status", "date", "name", "dog", "type"];
    const columns = {
      box: {
        label: "",
        renderCell: () => <input type="checkbox" />
      },
      status: {
        label: "Status",
        renderCell: data => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <text
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: Theme.getNormalFont(width),
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginRight: "10%"
              }}
            >
              {data.statusLabel}
            </text>
            <PawIndicator
              active={data.status === "active"}
              style={{ marginLeft: "5%" }}
            />
          </div>
        )
      },
      date: {
        label: "Date",
        renderCell: data => (
          <text
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: Theme.getNormalFont(width),
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT
            }}
          >
            {moment(data.signUpDate).format("MMMM Do YYYY")}
          </text>
        )
      },
      name: {
        label: "Name",
        renderCell: data => (
          <text
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT
            }}
          >
            {data.firstName + " " + data.lastName}
          </text>
        )
      },
      location: {
        label: "Location",
        renderCell: data => (
          <text
            style={{
              color: Theme.DARK_TEXT_COLOR,
              fontWeight: 700,
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT
            }}
          >
            {data.park.name}
          </text>
        )
      },
      dog: {
        label: "Dog Name",
        renderCell: data => {
          return (
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontWeight: 100,
                fontSize: Theme.getNormalFont(width),
                fontFamily: Theme.INPUT_FONT,
                textTransform: "capitalize"
              }}
            >
              {data.dogs.length > 0 && data.dogs[0].name}
            </div>
          );
        }
      },
      type: {
        label: "Member Type",
        renderCell: data => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT
            }}
          >
            {data.package &&
              `${data.package.amountString} / ${data.package.name}`}
          </div>
        )
      }
    };

    return (
      <div>
        <StaffNavBar
          tab={2}
          history={this.props.history}
          width={this.props.global.dimensions.width}
        />
        <EmployeeDashboard isManager={true} history={this.props.history} />
        {/* <Query
          fetchPolicy="no-cache"
          query={FETCH_MEMBERS}
          variables={{ type: "Member", per, page, search }}
        >
          {({ loading, error, data, refetch }) => {
            if (error) return `Error: ${error.message}`;
            if (data) {
              return (
                <div
                  style={{
                    height: this.props.global.dimensions.height,
                    backgroundColor: Theme.BACKGROUND_COLOR,
                    backgroundRepeat: "round",
                    backgroundImage: `url(${Bg})`
                  }}
                >
                  <StaffNavBar
                    tab={2}
                    history={this.props.history}
                    width={this.props.global.dimensions.width}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexShrink: 0,
                      width: "90%",
                      justifyContent: "center",
                      marginTop: -100,
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "4em"
                    }}
                  >
                    <div
                      style={{
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "-2%",
                        marginBottom: "2.5%",
                        width: "100%",
                        color: "white",
                        alignItems: "center",
                        alignContent: "center",
                        fontSize: Theme.getHeaderFont(width),
                        fontFamily: Theme.HEADER_FONT
                      }}
                    >
                      Members
                      <button
                        style={{
                          outline: "none",
                          backgroundColor: "rgba(250,250,250, .3)",
                          borderRadius: 5,
                          padding: "5px 10px 5px 10px",
                          border: "none",
                          marginLeft: "auto",
                          color: "white",
                          fontFamily: Theme.HEADER_FONT,
                          fontSize: Theme.getSmallFont(width),
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          this.props.updateUser({ selectedUser: true });
                          this.props.history.push("/members/onboarding");
                        }}
                      >
                        Add New Member
                      </button>
                    </div>
                    <div style={{ display: "flex", marginBottom: 12 }}>
                      <SearchBar
                        onChange={v => this.setState({ search: v, page: 1 })}
                        value={search}
                        placeholder="Search"
                        width={width / 3}
                        height={height / 25}
                      />
                    </div>
                    <Table
                      loading={loading}
                      per={per}
                      page={page}
                      onPage={page => this.setState({ page: page })}
                      onPer={per => this.setState({ per: per })}
                      total={!loading && data.fetchUsers.totalCount}
                      containerStyle={{ flex: 1 }}
                      names={
                        role === "Admin" ? adminColumnNames : managerColumnNames
                      }
                      alt={true}
                      columns={columns}
                      rows={!loading && data.fetchUsers.edges}
                      onClick={id => {
                        this.props.updateUser({ selectedUser: id });
                        this.props.history.push("/staff/member", {
                          view: "member"
                        });
                      }}
                    />
                  </div>
                </div>
              );
            }
          }}
        </Query> */}
      </div>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Rangers);
