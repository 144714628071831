import React, { Component } from "react";
import EventCell from "./Cell";
import { connect } from "react-redux";

const styles = {
  pageLabel: {
    color: "black",
    textDecoration: "none",
    marginLeft: "1%",
    marginRight: "1%",
    fontSize: "80%",
    marginTop: "auto",
    marginBottom: "auto",
    fontColor: "#3D3D3D",
    fontWeight: 600,
    border: "none",
    outline: "none",
    background: "none",
    cursor: "pointer"
  },
  emptyButton: {
    outline: "none",
    border: "none",
    background: "none",
    cursor: "pointer"
  }
};

export class EventTable extends Component {
  changePage(page) {
    if (page === "next") {
      if (this.props.page * this.props.per < this.props.total) {
        this.props.onPage(this.props.page + 1);
      }
      return;
    } else if (page === "prev") {
      if (this.props.page !== 1) this.props.onPage(this.props.page - 1);
      return;
    } else {
      this.props.onPage(page);
    }
  }
  renderHeaderRow = (name, cellIndex) => {
    const { columns, alt, sorting } = this.props;
    const column = columns[name];

    return (
      <EventCell
        key={`header-${cellIndex}`}
        label={column.label}
        header={true}
        alt={alt}
        sorting={sorting}
      />
    );
  };

  renderRow = (_row, rowIndex) => {
    const { rows, names, columns, onClick, alt } = this.props;
    return (
      <tr
        onClick={() => onClick(_row.id)}
        key={`row-${rowIndex}`}
        style={
          rowIndex % 2 === 0
            ? { backgroundColor: "white", height: 40, cursor: onClick && 'pointer' }
            : { backgroundColor: "#ECECEC", height: 40, cursor: onClick && 'pointer' }
        }
      >
        {names.map((name, cellIndex) => {
          const column = columns[name];
          return (
            <EventCell
              key={`${rowIndex}-${cellIndex}`}
              alt={alt}
              renderCell={column.renderCell(rows[rowIndex], rowIndex)}
            />
          );
        })}
      </tr>
    );
  };

  renderPaginationNumbers = (pages) => {
    for (var i = 1; i <= pages; i++) {
      return (
        <button
          onClick={() => this.changePage(i)}
          style={styles.pageLabel}
        >
          {i}
        </button>
      )
    }
  }

  render() {
    const { rows, names, total, page = 1, per = 10, loading, global } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const shrink = width < 700;
    let dogs = []
    if (rows) {
      for (let val of rows) {
        dogs = dogs.concat(val.dogs)
      }
    }
    const start = page * per - per;
    const end = page * per < total ? page * per : total;
    const pages = Math.ceil(total / per)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <table
          cellSpacing={0}
          cellPadding={5}
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead style={{ cursor: "pointer" }}>
            <tr
              style={{
                backgroundColor: "white",
                height: 40
              }}
            >
              {names.map(this.renderHeaderRow)}
            </tr>
          </thead>
          {
            loading ?
              <tbody>
                {/* <PropagateLoader
                size={40}
                color={Theme.LINK_FONT_COLOR}
                loading={loading}
              /> */}
              </tbody>
              :
              <tbody>{rows && rows.map(this.renderRow)}</tbody>
          }
        </table>
      
      </div>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });

export default connect(
  mapStateToProps,
  null
)(EventTable);