import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "js-cookie";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import {
  OnboardingHeader,
  LabeledTextBox,
  Button,
  CheckBox,
  Select
} from "../../../globalComponents/";
import { reduxForm, SubmissionError } from "redux-form";
import { updateUser } from "../../../store/redux/actions";
import { USER_SIGN_UP, USER_UPDATE } from "../../../store/mutations.js";
import { PARK_NAMES } from "../../../store/queries.js";
import Tag from "../../../globalComponents/FetchComponents/Tag.js";
import EmailPreType from "../../../globalComponents/FetchComponents/EmailPreType.jsx";
import jstz from 'jstz';
import { trackEvent, trackPageView } from '../../../utils/pixelTracker';
const timezone = jstz.determine();

const styles = {
  pageContainer: {
    // border: "solid blue",
    position: "absolute",
    display: "flex",
    flex: 1,
    alignItems: "center",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0,0,0,.2)",
    height: "100vh",
    width: "100vw",
    zIndex: 100
  },

  modalContainer: function (width) {
    return {
      // border: "solid green",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      marginLeft: "auto",
      marginRight: "auto",
      padding: "1vw",
      height: "auto",
      width: width,
      fontSize: Theme.getNormalFont(width),
      fontFamily: Theme.HEADER_FONT,
      backgroundColor: "white",
      color: Theme.DARK_TEXT_COLOR,
      boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
    };
  },

  header: {
    borderBottom: "3px solid",
    borderBottomColor: Theme.INACTIVE_GREY_COLOR,
    width: "97%"
  },

  subtext: function (width) {
    return {
      fontSize: Theme.getNormalFont(width),
      color: Theme.LIGHT_TEXT_COLOR
    };
  },

  tagSection: {
    // border:"solid black",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1vw",
    paddingLeft: "0"
  },

  tagListContainer: function (width) {
    return {
      // border:"solid red",
      listStyleType: "none",
      margin: "0",
      // width: width / 1.2,
      width: "100%",
      // paddingLeft: "1vw",
      paddingLeft: "0",
      color: Theme.INACTIVE_GREY_COLOR
    };
  },

  textArea: function (width, textFontSize) {
    return {
      resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
      borderRadius: 5,
      height: width / 6, // UPDATE HEIGHT CALULCATION
      fontSize: textFontSize
    };
  },

  submitButtonWrapper: {
    alignSelf: "flex-end",
    margin: ".5em 0"
  }
};

class AccountCreation extends Component {
  state = {
    park: "",
    selectedTags: "",
    email: ""
  };


  componentDidMount() {
    if (this.props.global && (this.props.global?.currentUser.type === null)) {
      alert(
        "Hello, please make sure to have a PDF, picture, or hard copy of your dog’s vaccinations records before beginning the registration process. Our check-in staff will need this to verify your dog’s vaccinations at the end of the registration process."
      );
    }
    this.props.change("acceptsSms", false)
  }

  handleEmailPreType = val => {

    this.setState({ selectedTags: val });
    let value = this.state.email + val
    this.props.change("email", (value))
  };

  handleOnChangeEmail = (event) => {
    let Value = event.target.value
    this.setState({
      email: Value
    })
  }

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const {
      pristine,
      submitting,
      handleSubmit,
      error,
      history,
      touched,
      change
    } = this.props;
    const { selectedTags, email } = this.state;
    console.log(selectedTags, "Email", email);
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={1}
        handleSubmit={handleSubmit}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center"
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Create an Account
        </div>
        <LabeledTextBox
          name="email"
          required
          validate={["required", "email"]}
          width={width}
          height={height}
          widthpercent={"100%"}
          label="Email"
          error={error}
          touched={touched}
          onChange={(e) => this.handleOnChangeEmail(e)}
        />
        <div style={styles.tagSection}>
          {/* <div style={styles.subtext(width)}>Tags:</div> */}
          <ul style={styles.tagListContainer(width)}>
            <li style={{ marginTop: "1%" }}>
              <EmailPreType
                handleEmailPreType={this.handleEmailPreType}
                selectedEmailPreTypes={selectedTags}
                emailPreTypeText="@gmail.com"
              />
            </li>
            <li style={{ marginTop: "1%" }}>
              <EmailPreType
                handleEmailPreType={this.handleEmailPreType}
                selectedEmailPreTypes={selectedTags}
                emailPreTypeText="@outlook.com"
              />
            </li>
            <li style={{ marginTop: "1%" }}>
              <EmailPreType
                handleEmailPreType={this.handleEmailPreType}
                selectedEmailPreTypes={selectedTags}
                emailPreTypeText="@hotmail.com"
              />
            </li>
            <li style={{ marginTop: "10%" }}>
              <EmailPreType
                handleEmailPreType={this.handleEmailPreType}
                selectedEmailPreTypes={selectedTags}
                emailPreTypeText="@yahoo.com"
              />
            </li>
          </ul>
        </div>
        <LabeledTextBox
          name="phoneNumber"
          validate={["required", "number"]}
          width={width}
          height={height}
          widthpercent={"100%"}
          label="Telephone Number"
        />
        <CheckBox
          name="acceptsSms"
          containerStyle={{ width: Theme.getLongInputWidth(width) }}
          width={width}
          label="Accepts text updates and specials?"
        />
        <LabeledTextBox
          name="password"
          type="password"
          validate={["required"]}
          width={width}
          height={height}
          widthpercent={"100%"}
          label="Password"
        />
        <LabeledTextBox
          name="passwordConfirmation"
          validate={["required"]}
          type="password"
          width={width}
          height={height}
          widthpercent={"100%"}
          label="Password Confirmation"
        />
        {error && <strong style={{ color: "red" }}>{error}</strong>}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
            width: Theme.getLongInputWidth(width) * 1.07
          }}
        >
          {/* <Button
            text="Cancel"
            alt={true}
            width={width}
            onClick={() => history.push("/login")}
          /> */}
          <Button
            type="submit"
            disabled={pristine || submitting}
            containerStyle={{ marginLeft: "auto" }}
            text="Save and Continue"
            width={width}
            onClick={handleSubmit}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

const formSubmit = (values, dispatch, { global: { currentUser }, client, history, ...props }) => {
  // if (values.password === values.passwordConfirmation && values.parkId > 0) {
  console.log("Value", values);
  values.timezone = timezone.name()
  if (values.password === values.passwordConfirmation) {
    return client
      .mutate({ mutation: USER_SIGN_UP, variables: { input: values } })
      .then(response => {
        const user = response.data.signUp.user;
        const errors = response.data.signUp.errors;
        if (errors[0]) throw new SubmissionError({ _error: errors[0] });
        else {
          if (currentUser.type !== "Employee" &&
            currentUser.type !== "Manager" &&
            currentUser.type !== "Admin"
          ) {
            cookie.set("token", response.data.signUp.auth.authenticationToken);
            if (currentUser.selectedUser) {
              props.updateUser({ selectedUser: user.id });
              } else {
              props.updateUser({
                id: user.id,
                type: "Member",
                email: user.email
              });
              }
              trackEvent('AccountCreation', {
                content_name: 'Account Started',
                status: 'success'
                });
              history.push("/members/onboarding/parkInfo");
          }
          console.log("Current Type", currentUser.type);


          if (currentUser.selectedUser) {
            props.updateUser({ selectedUser: user.id });
          } else {
            props.updateUser({
              id: user.id,
              type: "Member",
              email: user.email
            });
            let newValues = {
              id: user.id,
              step: 0
            }
            client
              .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
              .then(({ data: { updateUser } }) => {
                console.log("response", updateUser);
                if (updateUser.errors && updateUser.errors.length > 0) {
                  console.error("Submission Error: ", updateUser.errors);
                } else history.push("/members/onboarding/parkInfo");
                // console.log("Hits,", props.location);
              })
              .catch(error => {
                console.error("Error: ", error);
              });
          }

          history.push("/members/onboarding/parkInfo");
        }
      })
      .catch((response, error) => {
        throw new SubmissionError({ _error: response.errors?._error.message });
      });
  } else {
    if (values.parkId > 0) {
      throw new SubmissionError({
        _error: "Passwords must match"
      });
    }
    // throw new SubmissionError({
    //   _error: "Select a Park"
    // });
  }
};

AccountCreation = reduxForm({
  form: "AccountCreation",
  onSubmit: formSubmit
})(AccountCreation);

export default compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AccountCreation);
