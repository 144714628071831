import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { USER_DETAILS, PARK_NAMES } from "../../../store/queries.js";
import {
  USER_UPDATE,
  CREATE_EMPLOYEE,
  CREATE_MANAGER
} from "../../../store/mutations";
import { Query, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  NavBar,
  Theme,
  PictureUpload,
  StaffNavBar,
  LabeledTextBox,
  Button,
  PawIndicator,
  Select,
  DatePickerBox
} from "../../../globalComponents";
import RangerForm from "./RangerForm.js";
import Vaccinations from "./Vaccinations.js";
import Bg from "../../../assets/fetch-BG.svg";
import { PulseLoader } from "react-spinners";
import { reduxForm, formValueSelector } from "redux-form";
import { updateUser } from "../../../store/redux/actions";
import cookie from "js-cookie";

class NewBarkRanger extends Component {
  state = {
    tab: 0,
    newBarkRanger: true,
    isManager: false,
    isCheckIn: false,
    isBarkRanger: true,
    isTrainee: false,
    userId: null,
    picture: null,
    park: ""
  };

  componentDidMount = () => {
    this.props.change("emailNew", "");
    this.props.change("passwordNew", "");
    this.props.change("passwordNewConfirm", "");
  };

  handleManagerCheckbox = event => {
    let newState = { ...this.state };
    // newState.isManager = !newState.isManager;
    this.setState({ isManager: true, isBarkRanger: false, isCheckIn: false, isTrainee: false });
  };
  handleCheckInCheckbox = event => {
    let newState = { ...this.state };
    if (this.state.isBarkRanger && this.state.isCheckIn) {
      newState.isCheckIn = !newState.isCheckIn;
      this.setState({ isCheckIn: newState.isCheckIn, isManager: false, });
    }
    else {
      // newState.isCheckIn = !newState.isCheckIn;
      this.setState({ isCheckIn: true, isManager: false, });
    }

  };
  handleBarkRangerCheckbox = event => {
    let newState = { ...this.state };
    if (this.state.isBarkRanger && this.state.isCheckIn) {
      newState.isBarkRanger = !newState.isBarkRanger;
      this.setState({ isBarkRanger: newState.isBarkRanger, isManager: false, });
    }
    else {
      // newState.isBarkRanger = !newState.isBarkRanger;
      this.setState({ isBarkRanger: true, isManager: false, });
    }
  };
  handleTraineeCheckbox = event => {
    let newState = { ...this.state };
    newState.isTrainee = !newState.isTrainee;
    this.setState({ isTrainee: newState.isTrainee, isManager: false, });
  };

  addBarkRanger = () => {
    if (
      this.props.emailNew &&
      this.props.passwordNew &&
      this.props.passwordNewConfirm &&
      this.props.firstNameNew &&
      this.props.lastNameNew &&
      this.props.hireDateNew &&
      this.props.parkIdNew
    ) {
      if (this.props.passwordNew === this.props.passwordNewConfirm) {
        let newRanger = {
          email: this.props.emailNew,
          password: this.props.passwordNew,
          firstName: this.props.firstNameNew,
          lastName: this.props.lastNameNew,
          hireDate: this.props.hireDateNew,
          parkId: this.props.parkIdNew
        };
        let newRangerEmployee = {
          email: this.props.emailNew,
          password: this.props.passwordNew,
          firstName: this.props.firstNameNew,
          lastName: this.props.lastNameNew,
          hireDate: this.props.hireDateNew,
          parkId: this.props.parkIdNew,
          isCheckinEmployee: this.state.isCheckIn,
          isTrainee: this.state.isTrainee,
          isRangerEmployee:this.state.isBarkRanger
        };
        if (this.state.isManager) {
          this.props.client
            .mutate({
              mutation: CREATE_MANAGER,
              variables: {
                input: newRanger
              }
            })
            .then(response => {
              this.props.updateUser({
                selectedUser: response.data.createManager.user.id
              });
              this.setState({
                newBarkRanger: false,
                userId: response.data.createManager.user.id
              });
            })
            .catch(error => {
              console.error("Error: ", error);
              return false;
            });
        } else {
          this.props.client
            .mutate({
              mutation: CREATE_EMPLOYEE,
              variables: {
                input: newRangerEmployee
              }
            })
            .then(response => {
              this.props.updateUser({
                selectedUser: response.data.createEmployee.user.id
              });
              this.setState({
                newBarkRanger: false,
                userId: response.data.createEmployee.user.id
              });
            })
            .catch(error => {
              console.error("Error: ", error);
              return false;
            });
        }
      } else {
        alert("Password and Password Confirmation must match!");
      }
    } else {
      alert("All fields are required to create a Staff member!");
    }
  };

  render() {
    const { tab, newBarkRanger, isManager, picture, isCheckIn, isBarkRanger, isTrainee } = this.state;
    const { global, change, handleSubmit } = this.props;

    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 700;
    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        margin: "auto 2% auto 2%",
        color: "white",
        fontFamily: Theme.INPUT_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink ? "11px" : Theme.getNormalFont(this.props.global.dimensions.width),
        cursor: "pointer"
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "white",
        borderRadius: 5,
        padding: 10,
        height: 60,
        border: "none",
        color: Theme.MAIN_COLOR,
        margin: "auto 2% auto 2%",
        fontFamily: Theme.HEADER_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink ? "11px" : Theme.getNormalFont(this.props.global.dimensions.width),
        cursor: "pointer"
      }
    };

    // const view = "ranger";
    // console.log("isCheckIn->", isCheckIn, "isManager->", isManager, "isBarkRanger->", isBarkRanger, "isTrainee->", isTrainee);
    return (
      <Fragment>
        {newBarkRanger ? (
          <div
            id="newBarkRangerModal"
            style={{
              display: `${newBarkRanger ? "flex" : "none"}`,
              position: "fixed",
              zIndex: 999,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              overflow: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.8)"
            }}
          >
            <div
              style={{
                margin: "auto",
                width: "500px",
                height: "650px",
                position: "relative",
                backgroundColor: "white",
                padding: 10,
                borderRadius: "15px",
                minHeight:"700px"
              }}
            >
              <div
                id="newBarkRangerModalContainer"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex"
                  }}
                >
                  <div
                    style={{
                      width: "75%",
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "5%",
                        // fontSize: 18,
                        fontSize: shrink ? "11px" : "18px",
                        fontFamily: "Lato"
                      }}
                    >
                      Add New Staff Member
                    </div>
                    <div
                      style={{
                        width: "100%"
                      }}
                    >
                      <LabeledTextBox
                        onBlur={() => { }}
                        name={`firstNameNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="First Name"
                      />
                      <LabeledTextBox
                        onBlur={() => { }}
                        name={`lastNameNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Last Name"
                      />
                      <LabeledTextBox
                        onBlur={() => { }}
                        name={`emailNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Email"
                      />
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                          {({ loading, error, data, refetch }) => {
                            return (
                              <Select
                                alt={true}
                                options={data?.restrictedFetchParks}
                                handleSelect={(park, name) => {
                                  change(name, park.id);
                                  this.setState({ park: park.name });
                                }}
                                selected={this.state.park}
                                name="parkIdNew"
                                width={"100%"}
                                height={height / 25}
                                label="Park Location"
                              />
                            );
                          }}
                        </Query>
                      </div>
                      {/* <LabeledTextBox
                        onBlur={() => { }}
                        name={`hireDateNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Hire Date"
                        type="date"
                      /> */}

                      <DatePickerBox
                        onBlur={() => { }}
                        name={`hireDateNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Hire Date"
                        type="date"
                        placeholder="MM/DD/YYYY"
                        change={change}
                        dateType="hireDate"
                      />
                      {(this.state.isBarkRanger || this.state.isCheckIn) &&
                        <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
                          <button
                            onClick={e => this.handleTraineeCheckbox(e)}
                            type="button"
                            style={{
                              width: 25,
                              height: 25,
                              border: "2px solid",
                              cursor: "pointer",
                              borderColor: Theme.LINK_FONT_COLOR,
                              outline: "none",
                              borderRadius: 5,
                              backgroundColor: isTrainee
                                ? Theme.LINK_FONT_COLOR
                                : "white"
                            }}
                          >
                            {isTrainee ? (
                              <PawIndicator
                                active={false}
                                style={{
                                  marginLeft: -5,
                                  marginTop: 1,
                                  width: 16,
                                  height: 16,
                                  color: "white"
                                }}
                              />
                            ) : null}
                          </button>
                          <div
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: 10
                            }}
                          >
                            Training
                          </div>
                        </div>
                      }
                      <LabeledTextBox
                        onBlur={() => { }}
                        name={`passwordNew`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Password"
                        type="password"
                      />
                      <LabeledTextBox
                        onBlur={() => { }}
                        name={`passwordNewConfirm`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Password Confirmation"
                        type="password"
                      />
                      {this.props.global.currentUser.type === "Admin" && (
                        <>
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <button
                              onClick={e => this.handleManagerCheckbox(e)}
                              type="button"
                              style={{
                                width: 25,
                                height: 25,
                                border: "2px solid",
                                cursor: "pointer",
                                borderColor: Theme.LINK_FONT_COLOR,
                                outline: "none",
                                borderRadius: 5,
                                backgroundColor: isManager
                                  ? Theme.LINK_FONT_COLOR
                                  : "white"
                              }}
                            >
                              {isManager ? (
                                <PawIndicator
                                  active={false}
                                  style={{
                                    marginLeft: -5,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                  }}
                                />
                              ) : null}
                            </button>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: 10
                              }}
                            >
                              Manager
                            </div>
                            <button
                              onClick={e => this.handleCheckInCheckbox(e)}
                              type="button"
                              style={{
                                width: 25,
                                height: 25,
                                border: "2px solid",
                                cursor: "pointer",
                                borderColor: Theme.LINK_FONT_COLOR,
                                outline: "none",
                                borderRadius: 5,
                                backgroundColor: isCheckIn
                                  ? Theme.LINK_FONT_COLOR
                                  : "white",
                                marginLeft: "10px"

                              }}
                            >
                              {isCheckIn ? (
                                <PawIndicator
                                  active={false}
                                  style={{
                                    marginLeft: -5,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                  }}
                                />
                              ) : null}
                            </button>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: 10
                              }}
                            >
                              Check In
                          </div>
                            <button
                              onClick={e => this.handleBarkRangerCheckbox(e)}
                              type="button"
                              style={{
                                width: 25,
                                height: 25,
                                border: "2px solid",
                                cursor: "pointer",
                                borderColor: Theme.LINK_FONT_COLOR,
                                outline: "none",
                                borderRadius: 5,
                                backgroundColor: isBarkRanger
                                  ? Theme.LINK_FONT_COLOR
                                  : "white",
                                marginLeft: "10px"
                              }}
                            >
                              {isBarkRanger ? (
                                <PawIndicator
                                  active={false}
                                  style={{
                                    marginLeft: -5,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                  }}
                                />
                              ) : null}
                            </button>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: 10
                              }}
                            >
                              Bark Ranger
                          </div>
                          </div>

                        </>
                      )}
                      {this.props.global.currentUser.type === "Manager" && (
                        <>
                          <div style={{ display: "flex", marginTop: 5 }}>
                            <button
                              onClick={e => this.handleCheckInCheckbox(e)}
                              type="button"
                              style={{
                                width: 25,
                                height: 25,
                                border: "2px solid",
                                cursor: "pointer",
                                borderColor: Theme.LINK_FONT_COLOR,
                                outline: "none",
                                borderRadius: 5,
                                backgroundColor: isCheckIn
                                  ? Theme.LINK_FONT_COLOR
                                  : "white",
                                marginLeft: "10px"

                              }}
                            >
                              {isCheckIn ? (
                                <PawIndicator
                                  active={false}
                                  style={{
                                    marginLeft: -5,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                  }}
                                />
                              ) : null}
                            </button>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: 10
                              }}
                            >
                              Check In
                          </div>
                            <button
                              onClick={e => this.handleBarkRangerCheckbox(e)}
                              type="button"
                              style={{
                                width: 25,
                                height: 25,
                                border: "2px solid",
                                cursor: "pointer",
                                borderColor: Theme.LINK_FONT_COLOR,
                                outline: "none",
                                borderRadius: 5,
                                backgroundColor: isBarkRanger
                                  ? Theme.LINK_FONT_COLOR
                                  : "white",
                                marginLeft: "10px"
                              }}
                            >
                              {isBarkRanger ? (
                                <PawIndicator
                                  active={false}
                                  style={{
                                    marginLeft: -5,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                  }}
                                />
                              ) : null}
                            </button>
                            <div
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginLeft: 10
                              }}
                            >
                              Bark Ranger
                          </div>
                          </div>

                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 20
                      }}
                    >
                      <Button
                        type="button"
                        containerStyle={{ margin: "auto" }}
                        text="Cancel"
                        width={width}
                        onClick={() =>
                          this.props.history.push("/manager/rangers")
                        }
                      />
                      <Button
                        type="button"
                        containerStyle={{ margin: "auto" }}
                        text="Add"
                        width={width}
                        onClick={() => this.addBarkRanger()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
            <Query
              fetchPolicy="no-cache"
              query={USER_DETAILS}
              variables={{
                id: this.state.userId
              }}
            >
              {({ loading, error, data, refetch }) => {
                if (loading)
                  return (
                    <div
                      name="container"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: this.props.global.dimensions.width,
                        minHeight: this.props.global.dimensions.height,
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        backgroundColor: Theme.BACKGROUND_COLOR,
                        backgroundRepeat: "round",
                        backgroundImage: `url(${Bg})`
                      }}
                    >
                      <PulseLoader
                        style={{ margin: "auto" }}
                        size={this.props.global.dimensions.width / 10}
                        color={Theme.LINK_FONT_COLOR}
                        loading={loading}
                      />
                    </div>
                  );
                // if (error) return `Error: ${error.message}`;
                if (error) {
                  if (
                    error?.message ===
                    "GraphQL error: You are not authorized to access that resource"
                  ) {
                    cookie.remove("token");
                    this.props.updateUser("reset");
                    this.props.history.push("/login");
                  }
                  return `Error: ${error?.message}`;
                  // sessionCheck(error, this.props.updateUser)
                }
                delete data.userDetail.__typename;
                console.log(data);
                return (
                  <div
                    style={{
                      minHeight: this.props.global.dimensions.height,
                      backgroundColor: Theme.BACKGROUND_COLOR,
                      backgroundRepeat: "round",
                      backgroundImage: `url(${Bg})`
                    }}
                  >
                    <NavBar
                      history={this.props.history}
                      width={this.props.global.dimensions.width}
                      title={
                        (data.userDetail.firstName || "First") +
                        " " +
                        (data.userDetail.lastName || "Last")
                      }
                      backButton={true}
                    />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexShrink: 0,
                        marginTop: -110,
                        marginLeft: "2%",
                        marginRight: "5%"
                      }}
                    >
                      <PictureUpload
                        width={this.props.global.dimensions.width}
                        name="profilePicture"
                        picture={
                          picture ||
                          (data &&
                            data?.userDetail &&
                            // process.env.REACT_APP_API_ENDPOINT +
                            // data.userDetail.profilePicture.url)
                            data.userDetail.profilePictureCdn)
                          
                        }
                        containerStyle={{
                          marginTop: 20,
                          marginLeft: "8%",
                          marginRight: "2%"
                        }}
                        label="Picture"
                        onUpload={(v, pic) => {
                          this.setState({ picture: pic });
                          change("profilePicture", v);
                          handleSubmit();
                        }}
                        disableForMembers={
                          global.currentUser.type === "Member" ? true : false
                        }
                      />
                      <button
                        onClick={() => this.setState({ tab: 0 })}
                        style={
                          tab === 0 ? styles.selectedButton : styles.navButton
                        }
                      >
                        Detail
                    </button>
                      <button
                        onClick={() => this.setState({ tab: 1 })}
                        style={
                          tab === 1 ? styles.selectedButton : styles.navButton
                        }
                      >
                        Dog Info & Vaccination
                    </button>
                      {/* {view && (
                      <button
                        onClick={() => console.log("Deactivate")}
                        style={{
                          outline: "none",
                          border: "none",
                          padding: 10,
                          margin: "auto 2% auto 2%",
                          color: "white",
                          borderRadius: 2,
                          marginLeft: "auto",
                          backgroundColor: "rgba(255,255,255,.3)",
                          fontFamily: Theme.INPUT_FONT,
                          fontSize: Theme.getNormalFont(
                            this.props.global.dimensions.width
                          ),
                          cursor: "pointer"
                        }}
                      >
                        {view === "ranger"
                          ? "Deactivate Bark Ranger"
                          : "Deactivate Member"}
                      </button>
                    )} */}
                    </div>
                    {tab === 0 && (
                      // <h1>d</h1>
                      <RangerForm
                        refetch={refetch}
                        initialValues={data.userDetail}
                      />
                    )}
                    {tab === 1 && (
                      <Vaccinations initialValues={data.userDetail} refetch={refetch} />
                    )}
                  </div>
                );
              }}
            </Query>
          )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

const formSubmit = (values, dispatch, props) => {
  return props.client
    .mutate({
      mutation: USER_UPDATE,
      variables: {
        input: {
          id: parseInt(props.global.currentUser.selectedUser),
          profilePicture: values.profilePicture
        }
      }
    })
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.error("Error: ", error);
      return false;
    });
};

const selector = formValueSelector("NewBarkRanger");
NewBarkRanger = connect(state => {
  const firstNameNew = selector(state, "firstNameNew");
  const lastNameNew = selector(state, "lastNameNew");
  const parkIdNew = selector(state, "parkIdNew");
  const hireDateNew = selector(state, "hireDateNew");
  const emailNew = selector(state, "emailNew");
  const passwordNew = selector(state, "passwordNew");
  const passwordNewConfirm = selector(state, "passwordNewConfirm");
  return {
    firstNameNew,
    lastNameNew,
    parkIdNew,
    hireDateNew,
    emailNew,
    passwordNew,
    passwordNewConfirm
  };
})(NewBarkRanger);

NewBarkRanger = reduxForm({
  form: "NewBarkRanger",
  onSubmit: formSubmit,
  enableReinitialize: true
})(NewBarkRanger);

export default compose(
  withApollo,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewBarkRanger);
