import React, { Component } from "react";
import Theme from '../Theme.js'
import { Field } from 'redux-form'
const required = value => (value ? undefined : "Required");
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? "Invalid email address"
    : "";

const number = value =>
  value && isNaN(Number(value)) ? "Numbers only" : undefined;

const validations = {
  required,
  email,
  number
};


const renderField = ({ input,placeholder, label, type, center, height, width, onFocus, meta: { touched, error, warning } }) => (

  <>
    <input
      {...input}
      placeholder={placeholder}
      // type={"email"}
      // value={input.value}
      style={{
        width: width, marginTop: height / 100,
        height: height,
        border: '1px solid',
        fontSize: height / 1.8,
        fontFamily: Theme.MAIN_FONT,
        paddingLeft: center ? 0 : 20,
        textAlign: center ? 'center' : ""
      }}

    />
    {touched && (error &&
      <span
        style={{
          fontSize: "14px",
          color: "red",
          margin: "10px auto 0",
          textAlign: "center",
          display: "block"
        }}
      >
        {error}
      </span>
    )}
  </>
)
export default class TextBox extends Component {

  render() {
    const { onClick, type, containerStyle, placeholder, width, height,
      validate, center, name, onFocus } = this.props
    const validates = [];
    validate &&
      validate.map(valid => {
        return validates.push(validations[`${valid}`]);
      });

    return (
      <div style={containerStyle}>
        <Field
          name={name}
          component={name === "email" ? renderField : 'input'}
          autoCapitalize='none'
          style={{
            width: width, marginTop: height / 100,
            height: height,
            border: '1px solid',
            fontSize: height / 1.8,
            fontFamily: Theme.MAIN_FONT,
            paddingLeft: center ? 0 : 20,
            textAlign: center ? 'center' : ''
          }}
          parse={value => value}
          placeholder={placeholder}
          onClick={onClick}
          validate={validates}
          onFocus={onFocus || null}
          width={width}
          height={height}
          center={center}
          type={type} />

      </div>
    )
  }
}
