import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";

const styles = {
    emailPreType: {
        float: "left",
        padding: "0 1%",
        margin: "0 .75%",
        border: "2px solid",
        borderColor: Theme.INACTIVE_GREY_COLOR,
        borderRadius: 5,
        transition: "transform 300ms ease-in-out",
        cursor: "pointer"
    },

    emailPreTypeOnHover: {
        float: "left",
        padding: "0 1%",
        margin: "0 .75%",
        border: "2px solid",
        borderColor: Theme.STANDARD_ORANGE,
        color: Theme.STANDARD_ORANGE,
        borderRadius: 10,
        transition: "transform 300ms ease-in-out",
        cursor: "pointer"
    }
};

export default class EmailPreType extends Component {
    state = {
        hover: false
    };

    toggleHover = () => {
        // console.log("HIt ",this.state.hover );
        this.setState({ hover: !this.state.hover });
    };

    render() {
        const { emailPreTypeText, handleEmailPreType, selectedEmailPreTypes } = this.props;
        const { hover } = this.state;
        // console.log("Check-----------------", selectedEmailPreTypes.includes(emailPreTypeText));
        return (
            <div
                onClick={() => handleEmailPreType(emailPreTypeText)}
                style={
                    hover
                        ? styles.emailPreTypeOnHover
                        : selectedEmailPreTypes.includes(emailPreTypeText)
                            ? styles.emailPreTypeOnHover
                            : styles.emailPreType
                }
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
            >
                {emailPreTypeText}
            </div>
        );
    }
}
