import React, { Component } from 'react';

import { CSVLink } from "react-csv";
import { Theme } from '../../../globalComponents';
// import { formattedDate } from '../methods';



const DownloadMembersCSV = ({ list, roll, eventName, width, fullwidth, mobileShrink, disabled, height, alt, text }) => {


    const MembersList = [
        { label: "Name", key: "Name" },
        { label: "Email", key: "Email" },
        { label: "Tag", key: "Tag" }
    ];

    const data = []

    list.forEach(memberData => {

        // let rollmemberData = {}
        // define the columns we want and their titles
        let membersTag = memberData.memberTags;
        const renderMembersTagName = membersTag.map((item, index) => {
          let tag_length = membersTag.length;
          let tag_name = item.title;
    
          if (tag_length === index + 1) {
            return ` ${tag_name}`;
          } else if (tag_length >= 1) {
            return ` ${tag_name}`;
          }
        });
        if (roll === "memberList") {
            let rollMemberData =
            {
                Name: `${memberData.firstName} ${memberData.lastName} `,
                Email: memberData.email,
                Tag: renderMembersTagName,
                
            }
            data.push(rollMemberData);
        }


        // push each tickcet's info into a row
    });
    // data.push(Listdata)
    const csvReport = {
        data: data,
        headers: MembersList,
        filename: `${eventName}.csv`
    };

    return (
        <CSVLink
            type="button"
            {...csvReport}
            style={{
                // width: fullwidth
                //     ? Theme.getButtonFullWidth(width)
                //     : Theme.getButtonWidth(width, mobileShrink),
                width: "35%",
                borderRadius: 5,
                outline: "none",
                borderColor: "transparent",
                cursor: "pointer",
                // height: height || Theme.getButtonHeight(width),
                textAlign: "center",
                backgroundColor: disabled
                    ? Theme.DISABLED_COLOR
                    : alt
                        ? Theme.ALT_BUTTON_COLOR
                        : Theme.LINK_FONT_COLOR,
                textDecoration: "none",
                margin: "1% 2px 1% 2px",
                verticalAlign: "center",
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                color: "#ffff",
                marginLeft:"auto",
                marginTop:"auto"
            }}
            
            // disabled={disabled}
        >
            {text}
        </CSVLink>
    );
}

export default DownloadMembersCSV
