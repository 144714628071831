import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../";
import Tag from "./Tag.js";
import { CREATE_NOTE, UPDATE_NOTE, DELETE_NOTE } from "../../store/mutations";
import { reduxForm } from "redux-form";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";

const styles = {
  pageContainer: {
    // border: "solid blue",
    position: "absolute",
    display: "flex",
    flex: 1,
    alignItems: "center",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0,0,0,.2)",
    height: "100vh",
    width: "100vw",
    zIndex: 100
  },

  modalContainer: function (width) {
    return {
      // border: "solid green",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",

      marginLeft: "auto",
      marginRight: "auto",
      padding: "1vw",
      height: "auto",
      width: width,
      fontSize: Theme.getNormalFont(width),
      fontFamily: Theme.HEADER_FONT,
      backgroundColor: "white",
      color: Theme.DARK_TEXT_COLOR,
      boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
    };
  },

  header: {
    borderBottom: "3px solid",
    borderBottomColor: Theme.INACTIVE_GREY_COLOR,
    width: "97%"
  },

  subtext: function (width) {
    return {
      fontSize: Theme.getNormalFont(width),
      color: Theme.LIGHT_TEXT_COLOR
    };
  },

  tagSection: {
    // border:"solid black",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1vw",
    paddingLeft: "0"
  },

  tagListContainer: function (width) {
    return {
      // border:"solid red",
      listStyleType: "none",
      margin: "0",
      width: width / 1.2,
      paddingLeft: "1vw",
      color: Theme.INACTIVE_GREY_COLOR
    };
  },

  textArea: function (width, textFontSize) {
    return {
      resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
      borderRadius: 5,
      height: width / 6, // UPDATE HEIGHT CALULCATION
      fontSize: textFontSize
    };
  },

  submitButtonWrapper: {
    alignSelf: "flex-end",
    margin: ".5em 0"
  }
};

class NotesModal extends Component {
  state = {
    hover: false,
    userNote: "",
    width: 0,
    height: 0,
    selectedTags: ["Account Note"]
  };

  componentDidMount() {
    const { noteData } = this.props;
    console.log(noteData, "NOTE DATA");

    if (noteData) {
      this.setState({
        userNote: noteData.content,
        selectedTags: [...noteData.tags]
      });
    }
    else {
      // this.handleTag("Account Note")
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("mousedown", this.handleClick, false);

  }

  componentWillReceiveProps(props) { }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("mousedown", this.handleClick, false);
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  recordNote = e => {
    this.setState({ userNote: e.target.value });
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getTextAreaWidth(width) {
    if (width > 1900) return "54.5vw";
    else if (width > 1650) return "64.5vw";
    else if (width > 1200) return "74.5vw";
    else if (width > 800) return "84.5vw";
    else return "79vw";
  }

  getModalWidth(width) {
    if (width > 1900) return "55vw";
    else if (width > 1650) return "65vw";
    else if (width > 1200) return "75vw";
    else if (width > 800) return "85vw";
    else return "80vw";
  }

  handleClick = e => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.props.closeModal();
        }
      } catch (error) { }
    }
  };

  handleTag = val => {
    let tags = [];
    tags = this.state.selectedTags;

    if (tags.indexOf(val) === -1) {
      tags.splice(0, 1);
      tags.push(val);

    }
    // else {
    //   let index = tags.indexOf(val);
    //   // console.log(index, "-------- Else ---------->", tags.splice(index, 1));
    //   tags.splice(index, 1);
    // }
    if (tags.length < 1) {
      this.setState({ selectedTags: tags });
    }
    else {
      this.setState({ selectedTags: tags });
    }

    // console.log("Tags------------------>", tags);
  };

  handleSubmit = () => {
    const { userNote, selectedTags } = this.state;
    const { client, global, closeModal } = this.props;

    if (this.props.isUpdate) {
      const values = {
        id: this.props.noteData.id,
        content: userNote,
        tags: selectedTags
      };
      client
        .mutate({ mutation: UPDATE_NOTE, variables: { input: values } })
        .then(response => {
          let newNote = response.data.updateUserNote.note;
          this.props.updateNotes(newNote);
          closeModal();
        })
        .catch(error => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      const values = {
        userId: global.currentUser.selectedUser,
        employeeId: global.currentUser.id,
        content: userNote,
        tags: selectedTags
      };
      client
        .mutate({ mutation: CREATE_NOTE, variables: { input: values } })
        .then(response => {
          let newNote = response.data.createUserNote.note;
          this.props.updateNotes(newNote);
          closeModal();
        })
        .catch(error => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  handleDelete = () => {
    const { client, noteData, closeModal } = this.props;
    let confirmDelete = window.confirm(
      "Are you sure that you want to delete this note?"
    );
    if (confirmDelete) {
      client
        .mutate({ mutation: DELETE_NOTE, variables: { id: noteData.id } })
        .then(response => {
          if (response.data.deleteUserNote.success) {
            this.props.deleteNotes(this.props.currentIndex);
            closeModal();
          }
        })
        .catch(error => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const textFontSize = Theme.getNormalFont(width);
    const { selectedTags, userNote } = this.state;
    const { isModalDisplayed, noteData, isUpdate, closeModal } = this.props;

    if (!isModalDisplayed) {
      return null;
    } else
      return (
        // Page Container
        <div style={styles.pageContainer}>
          {/* Modal Container */}
          <div
            ref={node => (this.node = node)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10%",
              padding: "2vw",
              height: "auto",
              width: this.getModalWidth(this.state.width),
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.HEADER_FONT,
              backgroundColor: "white",
              color: Theme.DARK_TEXT_COLOR,
              boxShadow:
                "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
            }}
          >
            {/* Header */}
            <div style={styles.header}> Notes </div>

            {/* Tag Section */}
            <div style={styles.tagSection}>
              <div style={styles.subtext(width)}>Tags:</div>
              <ul style={styles.tagListContainer(width)}>
                <li>
                  {" "}
                  <Tag
                    handleTag={this.handleTag}
                    selectedTags={selectedTags}
                    tagText="Strike"
                  />{" "}
                </li>
                <li>
                  {" "}
                  <Tag
                    handleTag={this.handleTag}
                    selectedTags={selectedTags}
                    tagText="Comment"
                  />{" "}
                </li>
                <li>
                  {" "}
                  <Tag
                    handleTag={this.handleTag}
                    selectedTags={selectedTags}
                    tagText="Account Note"
                  />{" "}
                </li>
              </ul>
            </div>

            {/* Text Box */}
            <textarea
              value={userNote}
              style={{
                outline: "none",
                resize: "none",
                borderRadius: 5,
                // width: this.getTextAreaWidth(this.state.width),
                width:"100%",
                height: width / 6,
                fontSize: textFontSize
              }}
              onChange={this.recordNote}
            />

            {/* Button Wraper to Right-Align */}
            <div style={styles.submitButtonWrapper}>
              {!isUpdate && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Add Note"
                    type="button"
                    width={width}
                    onClick={this.handleSubmit}
                    containerStyle={{ marginRight: 10 }}
                  />
                  <Button
                    text="Cancel"
                    type="button"
                    width={width}
                    onClick={() => closeModal()}
                  />
                </div>
              )}
              {isUpdate && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Update Note"
                    type="button"
                    width={width}
                    onClick={this.handleSubmit}
                    containerStyle={{ marginRight: 10 }}
                  />
                  <Button
                    text="Delete Note"
                    type="button"
                    width={width}
                    onClick={this.handleDelete}
                    containerStyle={{ marginRight: 10 }}
                  />
                  <Button
                    text="Cancel"
                    type="button"
                    width={width}
                    onClick={() => closeModal()}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = state => ({ ...state.global });

NotesModal = reduxForm({
  form: "NotesModal"
})(NotesModal);

export default compose(
  withApollo,
  connect(mapStateToProps)
)(NotesModal);
