import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { TextBox, Button } from "../../globalComponents";
import { connect } from "react-redux";
import Logo from "../../assets/fetch-logo.svg";
import Bg from "../../assets/fetch-BG.svg";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { REQUEST_RESET } from "../../store/mutations.js";

class RequestReset extends Component {
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit } = this.props;

    return (
      <form>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundRepeat: "round",
            backgroundColor: Theme.BACKGROUND_COLOR,
            height: height,
            backgroundImage: `url(${Bg})`
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
              height: "60%",
              width: "40%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              alt=""
              src={Logo}
              style={{ height: "35%", marginBottom: "10%" }}
            />
            <div
              style={{
                marginBottom: "2%",
                fontSize: Theme.getHeaderFont(width),
                fontFamily: Theme.HEADER_FONT,
                color: Theme.HEADER_FONT_COLOR
              }}
            >
              Password Reset
            </div>
            <TextBox
              name="email"
              containerStyle={{ margin: "2% 0 2% 0" }}
              width={Theme.getInputWidth(width)}
              height={height / 20}
              placeholder="Enter Your Email"
              type="text"
            />
            <Button
              text="Submit"
              fullwidth={true}
              width={width}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  props.client
    .mutate({
      mutation: REQUEST_RESET,
      variables: { input: { email: values.email } }
    })
    .then(data => {
      alert("Password Reset has been sent to your email.");
      console.log(data);
    })
    .error(error => {
      alert(error);
    });
  return true;
};

RequestReset = reduxForm({
  form: "DogInfo",
  onSubmit: formSubmit,
  onSubmitFail: e => {
    console.error(e);
  }
})(RequestReset);

export default compose(
  withApollo,
  connect(mapStateToProps)
)(RequestReset);
