import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import { reduxForm, formValueSelector } from "redux-form";
import UserPlaceholder from "../../../assets/placeholder.jpeg";
import Bg from "../../../assets/fetch-BG.svg";

import {
  USER_UPDATE,
  CONFIRM,
  ADD_DOGS,
  DELETE_DOG,
  DELETE_ACCOUNT,
  USER_PARK_UPDATE,
  SUBCRIPTION_UPDATE,
  USER_UPDATE_ADDRESS,
  USER_UPDATE_INFO,
  BULK_CHECKIN,
} from "../../../store/mutations.js";
import {
  ACTIVITY_FEED,
  FETCH_EVENT_CREATOR,
  FETCH_EVENT_LISTS,
  FETCH_EVENT_TYPE,
  PARK_NAMES,
  PARK_RULE_CHECK,
  USER_DETAILS,
  USER_FEED,
} from "../../../store/queries";
import {
  OnboardingHeader,
  LabeledTextBox,
  PictureUpload,
  Button,
  ActivityLog,
  VaccinationUpload,
  MembershipInfo,
  PawIndicator,
  DatePickerBox,
  MemberTable,
  CheckInList,
} from "../../../globalComponents/";

import {
  addOrUpdateDogVaccinations,
  addOrUpdateDogVaccinationsNew,
  updateDogsInfo,
  updateDogsInfoNew,
} from "../../../utils/dog";

import DeleteIcon from "../../../assets/delete_forever.svg";
import BirthCheckBox from "../../../globalComponents/FetchComponents/BirthCheckBox.jsx";
import StrikesCheckBox from "../../../globalComponents/FetchComponents/StrikesCheckbox.jsx";

import OwnerInfo from "./OwnerInfo.js";
import EventTable from "./EventTable.js";
import BannedModal from "../../../globalComponents/FetchComponents/BannedModal.jsx";
import DogPictureUpload from "../../../globalComponents/FormComponents/DogPictureUpload.jsx";
import CheckedInModal from "../../../globalComponents/FetchComponents/CheckedInModal.jsx";
import moment from "moment";
import ConnectActionCable from "../../../globalComponents/Connection/index.js";
import { PulseLoader } from "react-spinners";

const stateAbbreviations = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

class ConfirmAccountForm extends Component {
  state = {
    initialize: true,
    texts: false,
    picture: null,
    selected: 0,
    vacs: this.props.initialValues.dogs[0]
      ? this.props.initialValues.dogs[0].vaccinationPictures
      : [],
    currentVac: "Rabies",
    dogs: this.props.initialValues.dogs,
    dogModalOpen: false,
    deleting: false,
    birthStatus: false,
    errorStatus: false,
    park: "",
    selectedDogSubscription: false,
    activityStatus: "Notes",
    page: 1,
    per: 20,
    date: "",
    dateName: "",
    by_type: "",
    created_by: "",
    sortAttr: "",
    sort: "",
    fetchEventLists: [],
    isLoading: true,
    totalCountEvent: 0,
    isModalDisplayed: false,
    bannedMessageList: [],
    parkRuleStatus: false,
    dogIds: [],
    checkedInModal: false,
    checkInResponse: [],
    checkInLoading: false,
    checkINparkId: "",
    checkInpage: 1,
    checkInperPage: 10,
    checkInList: [],
  };

  handleFile = (blob, file) => {
    var tempFiles = this.state.vacs;
    tempFiles.push({ name: blob.filename, file: file });

    var formFiles = [];
    for (var i = 0; i < tempFiles.length; i++) {
      formFiles.push(tempFiles[i].signed_id);
    }

    this.props.change("vaccinationPictures", formFiles);
    this.setState({ vacs: tempFiles });
  };

  getPicture() {
    if (this.state.picture) {
      return this.state.picture;
    } else if (this.props.initialValues.profilePicture?.url) {
      return (
        // UserPlaceholder
        // process.env.REACT_APP_API_ENDPOINT +
        // this.props.initialValues.profilePicture.url
        this.props.initialValues.profilePictureCdn
      );
      // return ('http://fetch-backend-staging.us-east-1.elasticbeanstalk.com/' + this.props.initialValues.profilePicture.url)
    } else {
      return null;
    }
  }

  handleActivityStatus = (status) => {
    this.setState({ activityStatus: status });
  };

  componentDidMount() {
    console.log('Component mounting. Initial props:', this.props);

    const { initialValues, change, global } = this.props;
    const dogs = initialValues?.dogs || [];

    try {
      // Initialize user data
      change("prevFirstName", initialValues.firstName);
      change("prevLastName", initialValues.lastName);
      change("prevAddress", initialValues.address);
      change("prevPhoneNumber", initialValues.phoneNumber);
      change("prevAddressTwo", initialValues.address2);
      change("prevAdd", initialValues.address2);
      change("prevCity", initialValues.city);
      change("prevState", initialValues.state);
      change("prevZip", initialValues.zip);
      change("prevEmail", initialValues.email);

      // Safely initialize park rule acceptance
      if (initialValues?.park) {
        change("isAcceptedParkRule", initialValues.park.isAcceptedParkRule || false);
      } else {
        console.warn('Park data not available in initialValues');
        change("isAcceptedParkRule", false);
      }

      // Initialize member tags
      const membersTag = initialValues.memberTags || [];
      const renderMembersTagName = membersTag.map((item, index) => {
        return ` ${item.title}`;
      }).join('');
      change("tagName", renderMembersTagName);

      // Initialize dog data
      if (dogs.length > 0) {
        dogs.forEach((dog, i) => {
          change(`name${i}`, dog.name);
          change(`id${i}`, dog.id);
          change(`sex${i}`, dog.sex === "MALE" ? "M" : "F");
          change(`color${i}`, dog.color);
          change(`breed${i}`, dog.breed);
          change(`birthdate${i}`, dog.birthdate);
          change(`isNeutered${i}`, dog.isNeutered);
          change(`oldRabiesDate${i}`, dog.rabies);
          change(`olddhlppDate${i}`, dog.dhlpp);
          change(`oldLeptospirosisDate${i}`, dog.leptospirosis);
          change(`oldBordetellaDate${i}`, dog.bordetella);
          change(`oldSeparateLeptospirosisStatus${i}`, dog.separateLeptospirosis);
          change(`isBanned${i}`, dog.isBanned);
          change(`bannedMessage${i}`, dog.bannedMessage);
          change(`strikeCount${i}`, dog.strikeCount);
          change(`profilePicture${i}`, dog.profilePicture?.url);
          change(`profilePictureCdn${i}`, dog.profilePictureCdn);
        });

        // Set initial values for the first dog
        change("vaccinationPictures", dogs[0].vaccinationPictures || []);
        change("rabies", dogs[0].rabies);
        change("dhlpp", dogs[0].dhlpp);
        change("leptospirosis", dogs[0].leptospirosis);
        change("bordetella", dogs[0].bordetella);
        change("separateLeptospirosis", dogs[0].separateLeptospirosis);
        change("vaccineDog", parseInt(dogs[0].id));
        change("pausedSubscription", dogs[0].pausedSubscription);
      }

      // Initialize banned message list
      const bannedMessageList = dogs.map(dog => ({
        dogId: dog.id,
        dogName: dog.name,
        dogBannedMessage: dog.bannedMessage,
        dogBannedStatus: dog.isBanned,
      }));
      this.setState({ bannedMessageList });

      // Initialize other necessary state
      this.setState({
        checkINparkId: global.currentUser?.park?.id || '',
      });

      // Check park rule and handle check-in list
      this.checkParkRule();
      this.handleCheckInList();

      console.log('Component mounted successfully');
    } catch (error) {
      console.error('Error in componentDidMount:', error);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.initialValues.dogs !== prevProps.initialValues.dogs) {
      const dogs = this.props.initialValues.dogs;

      this.props.change("prevFirstName", this.props.initialValues.firstName);
      this.props.change("prevLastName", this.props.initialValues.lastName);
      this.props.change(
        "prevProfile",
        this.props.initialValues.profilePicture.url
        // this.props.change(`profilePicture${i}`,
        // this.props.initialValues.profilePictureCdn
      );

      this.props.change("prevAddress", this.props.initialValues.address);
      this.props.change("prevAddressTwo", this.props.initialValues.address2);
      this.props.change("prevAdd", this.props.initialValues.address2);
      this.props.change(
        "prevPhoneNumber",
        this.props.initialValues.phoneNumber
      );
      this.props.change("prevCity", this.props.initialValues.city);
      this.props.change("prevState", this.props.initialValues.state);
      this.props.change("prevZip", this.props.initialValues.zip);
      this.props.change("prevEmail", this.props.initialValues.email);
      this.props.change(
        "isAcceptedParkRule",
        this.props.initialValues.park.isAcceptedParkRule
      );

      if (dogs[0]) {
        for (var i = 0; i < dogs.length; i++) {
          this.props.change(`name${i}`, dogs[i].name);
          this.props.change(`id${i}`, dogs[i].id);
          this.props.change(`sex${i}`, dogs[i].sex === "MALE" ? "M" : "F");
          this.props.change(`color${i}`, dogs[i].color);
          this.props.change(`breed${i}`, dogs[i].breed);
          this.props.change(`birthdate${i}`, dogs[i].birthdate);
          this.props.change(`isNeutered${i}`, dogs[i].isNeutered);
          this.props.change(`oldRabiesDate${i}`, dogs[i].rabies);
          this.props.change(`olddhlppDate${i}`, dogs[i].dhlpp);
          this.props.change(`oldLeptospirosisDate${i}`, dogs[i].leptospirosis);
          this.props.change(`oldBordetellaDate${i}`, dogs[i].bordetella);
          this.props.change(
            `oldSeparateLeptospirosisStatus${i}`,
            dogs[i].separateLeptospirosis
          );
          this.props.change(`isBanned${i}`, dogs[i].isBanned);
          this.props.change(`bannedMessage${i}`, dogs[i].bannedMessage);
          this.props.change(`strikeCount${i}`, dogs[i].strikeCount);
          this.props.change(`profilePicture${i}`, dogs[i].profilePicture?.url);
        }
        this.props.change(
          "vaccinationPictures",
          dogs[0].vaccinationPictures || []
        );
        this.props.change("rabies", dogs[0].rabies);
        // this.props.change("oldRabiesDate", dogs[0].rabies)
        this.props.change("dhlpp", dogs[0].dhlpp);
        this.props.change("leptospirosis", dogs[0].leptospirosis);
        this.props.change("bordetella", dogs[0].bordetella);
        this.props.change(
          "separateLeptospirosis",
          dogs[0].separateLeptospirosis
        );
        this.props.change("isLeptospirosis", dogs[0].isLeptospirosis);
        this.props.change("vaccineDog", parseInt(dogs[0].id));
        this.props.change("pausedSubscription", dogs[0].pausedSubscription);
        // this.props.change(`isBanned`, dogs[0].isBanned);
        // this.setState({
        //   selectedDogSubscription: dogs[0].pausedSubscription
        // })
      }
    }
  }

  selectCurrentVac = (selectedVac) => {
    this.setState({ currentVac: selectedVac });
  };

  dogCount = (dogs) => {
    if (!dogs[0]) {
      return 0;
    } else if (!dogs[1]) {
      return 1;
    } else if (!dogs[2]) {
      return 2;
    } else {
      return 3;
    }
  };

  handleDogModal = () => {
    let newState = { ...this.state };
    this.props.change("pictureNew", "");
    this.props.change("nameNew", "");
    this.props.change("birthdateNew", null);
    this.props.change("sexNew", "");
    this.props.change("colorNew", "");
    this.props.change("isNeuteredNew", "");
    this.props.change("breedNew", "");
    this.setState({ dogModalOpen: !newState.dogModalOpen });
  };

  addDog = () => {
    if (
      this.props.nameNew &&
      this.props.sexNew &&
      this.props.colorNew &&
      this.props.pictureNew
    ) {
      let dogs = {
        userId: parseInt(this.props.global.currentUser.selectedUser),
        profilePicture: this.props.pictureNew,
        name: this.props.nameNew,
        birthdate: this.props.birthdateNew,
        sex: this.props.sexNew === "F" ? "FEMALE" : "MALE",
        color: this.props.colorNew,
        isNeutered: this.props.isNeuteredNew,
        breed: this.props.breedNew,
      };
      this.props.client
        .mutate({ mutation: ADD_DOGS, variables: { input: dogs } })
        .then((data) => {
          if (data.errors && data.errors.length > 0) {
            console.error("Submission Error: ", data.errors);
          } else {
            let newState = { ...this.state };
            newState.dogs.push(data.data.addDogs.dogs[0]);
            for (var i = 0; i < newState.dogs.length; i++) {
              this.props.change(`name${i}`, newState.dogs[i].name);
              this.props.change(`id${i}`, newState.dogs[i].id);
              this.props.change(
                `sex${i}`,
                newState.dogs[i].sex === "MALE" ? "M" : "F"
              );
              this.props.change(`color${i}`, newState.dogs[i].color);
              this.props.change(`breed${i}`, newState.dogs[i].breed);
              this.props.change(`birthdate${i}`, newState.dogs[i].birthdate);
              this.props.change(`isNeutered${i}`, newState.dogs[i].isNeutered);
              this.props.change(
                `strikeCount${i}`,
                newState.dogs[i].strikeCount
              );
            }
            this.props.change(
              "vaccinationPictures",
              newState.dogs[0].vaccinationPictures || []
            );
            this.props.change("rabies", newState.dogs[0].rabies);
            this.props.change("dhlpp", newState.dogs[0].dhlpp);
            this.props.change("leptospirosis", newState.dogs[0].leptospirosis);
            this.props.change("bordetella", newState.dogs[0].bordetella);
            this.props.change(
              "separateLeptospirosis",
              newState.dogs[0].separateLeptospirosis
            );
            this.props.change("vaccineDog", parseInt(newState.dogs[0].id));

            this.setState({ dogs: newState.dogs });
            this.handleDogModal();
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert(
        "Error: All fields must be filled out and a dog picture must be uploaded in order to add a new dog."
      );
    }
  };

  handleDeleteDog = (index) => {
    let newState = { ...this.state };
    let confirmDelete = window.confirm(
      `Are you sure that you want to delete ${newState.dogs[index].name}?`
    );
    if (confirmDelete) {
      this.props.client
        .mutate({
          mutation: DELETE_DOG,
          variables: { id: newState.dogs[index].id },
        })
        .then((response) => {
          if (response.data.deleteDog.success) {
            alert("Success");
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  handleDeleteAccount = () => {
    let confirmDelete = window.confirm(
      "Are you sure that you want to delete this user? Warning: This is permanent and cannot be undone!"
    );
    if (confirmDelete) {
      this.props.client
        .mutate({
          mutation: DELETE_ACCOUNT,
          variables: { id: this.props.global.currentUser.selectedUser },
        })
        .then((response) => {
          if (response.data.deleteAccount.success) {
            alert("Success");
            this.props.history.push("/manager/members");
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  openModal = () => {
    this.setState(() => {
      this.setState({ isModalDisplayed: true });
    });
  };

  closeModal = () => {
    this.setState({ isModalDisplayed: false });
  };

  handleBannedAccount = (dogInfo) => {
    let confirmDelete = window.confirm(
      `Are you sure that you want to Banned this  ${dogInfo} ?`
    );
    if (confirmDelete) {
      // this.openModal()
      this.setState({
        isModalDisplayed: true,
      });
    }
  };

  isPortrait = () => {
    if (window.innerHeight > window.innerWidth) {
      return true;
    } else {
      return false;
    }
  };

  handleBirthCheckbox = () => {
    let newState = { ...this.state };
    this.setState({ birthStatus: newState.birthStatus });
  };

  handleParkName = (parkId) => {
    let newValues = {
      userId: this.props.global.currentUser.selectedUser,
      parkId: parkId,
    };
    this.props.client
      .mutate({ mutation: USER_PARK_UPDATE, variables: { input: newValues } })
      .then((response) => {
        // props.refetch();
        // props.history.push("profile");
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  handleUserDeactivation = (deactivated, refetch) => {
    const { client } = this.props;
    const { selectedUser } = this.props.global.currentUser.selectedUser;

    const input = {
      id: this.props.global.currentUser.selectedUser,
      deactivated: !deactivated, // Flip the deactivation status
    };

    client
      .mutate({
        mutation: USER_UPDATE,
        variables: {
          input,
          fetchPolicy: "no-cache",
        },
      })
      .then(() => {
        // Handle the successful deactivation, if needed
        console.log("User deactivation status updated");
        if (deactivated) {
          alert('Member was re-activated successfully.');
        } else {
          alert('Member was deactivated successfully.');
        }
        refetch();
      })
      .catch((error) => {
        alert(error);
        console.error("--->", error);

        this.setState({ validation: error });
      });
  };

  handlePausedSubscription = (id, status, refetch) => {
    let { client } = this.props;
    client
      .mutate({
        mutation: SUBCRIPTION_UPDATE,
        variables: {
          input: {
            id: id,
            isPausedSubscription: !status,
          },
          fetchPolicy: "no-cache",
        },
      })
      .then((response) => {
        this.setState({
          selectedDogSubscription: !status,
        });
        this.props.change("pausedSubscription", !status);
        // refetch()
      })
      .catch((error) => {
        alert(error);
        console.error("--->", error);
        this.setState({ validation: error });
      });
  };

  checkParkRule = () => {
    let parkId = this.props.global?.currentUser?.park?.id;
    let variables = {
      userId: parseInt(this.props.global.currentUser.selectedUser),
      parkId,
    };
    this.props.client
      .query({
        query: PARK_RULE_CHECK,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          parkRuleStatus: response.data?.fetchUserParkRuleStatus?.status,
        });
        this.props.change(
          "parkRuleStatus",
          response.data?.fetchUserParkRuleStatus?.status
        );
      })
      .catch((error) => {
        console.error("Park Error: ", error);
        return false;
      });
  };
  handleCheckInList = () => {
    const { client } = this.props;
    // let { search, sortAttr, sort } = this.state;
    let variables = {
      page: String(this.state.checkInpage),
      perPage: String(this.state.checkInperPage),
      parkId:
        // parkId: this.state.parkId,
        this.props.global.currentUser.park &&
        this.props.global.currentUser.park.id,
      type: "check_in",
      start: moment().subtract(12, "hours").toISOString(),
      end: moment().toISOString(),
      requestPage: "dashboard",
    };
    client
      .query({
        query: ACTIVITY_FEED,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        console.log("___", response);

        let arr = [];
        arr.push(
          ...this.state.checkInList,
          ...response.data?.fetchActivityList.events
        );
        this.setState({
          checkInLoading: true,
          checkInList: arr,
          checkInResponse: response,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  openCheckedInModal = () => {
    this.setState({
      checkedInModal: true,
    });
  };
  closeCheckedInModal = () => {
    this.setState({
      checkedInModal: false,
    });
  };
  setCheckIN = (_data) => {
    this.handleCheckInList();
  };
  loadMoreData = async () => {
    this.setState(
      {
        checkInpage: this.state.checkInpage + 1,
      },
      () => this.handleCheckInList()
    );
  };
  render() {
    const {
      handleSubmit,
      change,
      history,
      initialValues,
      global,
      rabiesDate,
      dhlppDate,
      leptospirosisDate,
      bordetellaDate,
      vaccinationNew0,
      vaccinationNew1,
      vaccinationNew2,
      separateLeptospirosisStatus,
      selectedDogSubscriptionStatus,
      isLeptospirosisStatus,
      selectedIsBanned,
      containerStyle,
    } = this.props;
    const { dogs, deleting, isModalDisplayed, parkRuleStatus } = this.state;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 768;
    const role = global.currentUser.type;
    const {
      initialize,
      selected,
      checkedInModal,
      checkInResponse,
      checkInLoading,
      checkINparkId,
      checkInList,
      checkInpage,
    } = this.state;

    if (initialize) {
      this.setState({ initialize: false });
    }
    const mobile = width < 800;
    let numDogs = this.dogCount(dogs);

    if (!checkInLoading) return null;

    let checkInProps = {
      checkInList,
      checkInResponse,
      checkInpage,
      loadMoreData: this.loadMoreData,
    };
    return (
      <>
        <ConnectActionCable
          parkId={checkINparkId}
          status={true}
          checkInResponse={checkInResponse}
          setCheckIN={this.setCheckIN}
        >
          <Query
            fetchPolicy="no-cache"
            query={USER_DETAILS}
            variables={{ id: Number(global.currentUser.selectedUser) }}
          >
            {({ loading, error, data, refetch }) => {
              if (error) return `Error: ${error.message}`;
              if (loading) return null;
              let userData = data;
              if (data && !loading) {
                return (
                  <>
                    <OnboardingHeader
                      back={() =>
                        role === "Employee"
                          ? history.push("/dashboard")
                          : history.push("/manager/members")
                      }
                      progress={false}
                      containerStyle={{
                        display: "flexs",
                        flexDirection: "column",
                        width: "100%",
                        paddingTop: "30vh",
                      }}
                      recenterLogo={true}
                      checkInStatus={true}
                      // checkInResponse={checkInResponse}
                      // loadMoreData={this.loadMoreData}
                      {...checkInProps}
                      saveBtn={
                        <Button
                          type="button"
                          containerStyle={{ marginBottom: "2%" }}
                          text={`${data.userDetail.confirmed ? "Save" : "Save and Confirm"
                            }`}
                          width={width}
                          onClick={() => {
                            change(
                              "acceptedRules",
                              data.userDetail.acceptedRules
                            );

                            // change(
                            //   "isAcceptedParkRule",
                            //   data.userDetail.park.isAcceptedParkRule
                            // );

                            if (!data.userDetail.confirmed) {
                              if (
                                window.confirm(
                                  "Do you want to confirm this member?"
                                )
                              ) {
                                handleSubmit();
                              }
                            } else {
                              handleSubmit();
                            }
                          }}
                        />
                      }
                      checkInBtn={
                        <Button
                          mobileShrink={true}
                          floatState={true}
                          containerStyle={
                            mobile
                              ? { marginRight: "auto" }
                              : { marginBottom: "13%", display: "flex" }
                          }
                          text="Check IN"
                          fullwidth={false}
                          width={width}
                          onClick={() => {
                            this.openCheckedInModal();
                          }}
                          // disabled={this.state.dogIds.length <= 0}
                          // memberStatus={memberStatus}
                          memberStatus={
                            role === "Member" || role === null ? true : false
                          }
                        />
                      }
                    >

                      <div className="Heloo">
                        {data?.userDetail?.legacyPlan && (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "center",
                              flexDirection: "column",
                              width: "80%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: 10,
                              padding: 10,
                              border: `2px solid ${Theme.MAIN_COLOR}`,
                              backgroundColor: "white",
                            }}
                          >
                            This member is on a Legacy Plan
                          </div>
                        )}
                        {/* {!parkRuleStatus && (   Rollback FP-665  */}
                        {/* // !data.userDetail.park.isAcceptedParkRules && ( */}
                        {!data.userDetail.acceptedRules && ( // FP-504 Changes
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "80%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: 20,
                              padding: 20,
                              border: `2px solid ${Theme.MAIN_COLOR}`,
                              backgroundColor: "white",
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              Pet owner has not yet accepted the park rules yet!
                            </div>
                            <Button
                              type="button"
                              containerStyle={{
                                marginTop: 10,
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                              text="Open Park Rules"
                              width={width}
                              onClick={() =>
                                this.props.history.push("/members/parkrules", { returnTo: "/staff/member" })
                              }
                            />
                          </div>
                        )}

                        {/* <Button
                        mobileShrink
                        floatState={true}
                        containerStyle={
                          mobile
                            ? { marginRight: "auto" }
                            : { marginBottom: "auto" }
                        }
                        text="Check IN"
                        fullwidth={false}
                        width={width}
                        onClick={() => {
                          this.openCheckedInModal();
                        }}
                        // disabled={this.state.dogIds.length <= 0}
                        // memberStatus={memberStatus}
                        memberStatus={
                          role === "Member" || role === null ? true : false
                        }
                      /> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: shrink ? "column" : "row",
                            alignItems: shrink ? "center" : "",
                            width: "100%",
                          }}
                        >

                          <OwnerInfo
                            global={global}
                            stateAbbreviations={stateAbbreviations}
                            userData={userData}
                            getPicture={() => this.getPicture()}
                            change={change}
                            setState={(state) =>
                              this.setState({ picture: state })
                            }
                            setStatePark={(name) =>
                              this.setState({ park: name })
                            }
                            handleParkName={this.handleParkName}
                            parkName={this.state.park}
                            role={role}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: shrink ? "" : "2%",
                              alignItems: shrink ? "center" : "",
                              width: "48%",
                            }}
                          >
                            <ActivityLog
                              data={data.userDetail.notes}
                              height={height}
                              width={width}
                              handleActivityStatus={(e) =>
                                this.handleActivityStatus(e)
                              }
                              activityStatus={this.state.activityStatus}
                              bannedMessageList={this.state.bannedMessageList}
                            />

                            <div
                              style={{
                                marginBottom: "2%",
                                marginTop: "5%",
                                fontFamily: Theme.HEADER_FONT,
                                fontSize: Theme.getHeaderFont(width),
                                color: Theme.HEADER_FONT_COLOR,
                              }}
                            >

                              Dog Info
                            </div>

                            {dogs.map((dog, index) => {
                              return (
                                <div
                                  key={dog.id}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: "white",
                                    border:
                                      selected === index
                                        ? "10px solid"
                                        : "1px solid",
                                    borderColor:
                                      selected === index
                                        ? Theme.MAIN_COLOR
                                        : Theme.LIGHT_TEXT_COLOR,
                                    borderRadius: 10,
                                    boxShadow: "3px 3px 10px #606267",
                                    padding: 20,
                                    marginBottom: `${deleting ? "45px" : "20px"
                                      }`,
                                    position: "relative",
                                  }}
                                  onClick={() => {
                                    let newState = { ...this.state };
                                    newState.dogs[selected].rabies = rabiesDate;
                                    newState.dogs[selected].dhlpp = dhlppDate;
                                    newState.dogs[
                                      selected
                                    ].separateLeptospirosis =
                                      separateLeptospirosisStatus;
                                    newState.dogs[selected].leptospirosis =
                                      leptospirosisDate;
                                    newState.dogs[selected].bordetella =
                                      bordetellaDate;

                                    newState.dogs[selected].isLeptospirosis =
                                      isLeptospirosisStatus;
                                    newState.dogs[selected].pausedSubscription =
                                      selectedDogSubscriptionStatus;
                                    // newState.dogs[selected].isBanned = selectedIsBanned

                                    // this.props.change(`isBanned`, dogs[0].isBanned);

                                    if (selected === 0 && vaccinationNew0) {
                                      newState.dogs[selected].vaccinations =
                                        vaccinationNew0;
                                    } else if (
                                      selected === 1 &&
                                      vaccinationNew1
                                    ) {
                                      newState.dogs[selected].vaccinations =
                                        vaccinationNew1;
                                    } else if (
                                      selected === 2 &&
                                      vaccinationNew2
                                    ) {
                                      newState.dogs[selected].vaccinations =
                                        vaccinationNew2;
                                    }

                                    change("dogsNew", newState.dogs);

                                    change(
                                      "vaccinationPictures",
                                      dog.vaccinationPictures || []
                                    );
                                    change("rabies", dog.rabies);
                                    // change("oldRabiesDate", dogs.rabies)
                                    change("dhlpp", dog.dhlpp);
                                    change("leptospirosis", dog.leptospirosis);
                                    change("bordetella", dog.bordetella);
                                    change(
                                      "separateLeptospirosis",
                                      dog.separateLeptospirosis
                                    );

                                    change(
                                      "isLeptospirosis",
                                      dog.isLeptospirosis
                                    );
                                    change("vaccineDog", parseInt(dog.id));
                                    change(
                                      "pausedSubscription",
                                      dog.pausedSubscription
                                    );
                                    // change(
                                    //   "isBanned",
                                    //   dog.isBanned
                                    // );
                                    this.setState({
                                      selected: index,
                                      vacs: dog.vaccinationPictures || [],
                                      dogs: newState.dogs,
                                      // selectedDogSubscription: dog.pausedSubscription
                                    });
                                    // this.setState({
                                    //   selectedDogSubscription: dogs[0].pausedSubscription
                                    // })
                                  }}
                                >
                                  {deleting && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: -35,
                                        right: -35,
                                        border: "2px solid black",
                                        borderRadius: "100px",
                                        backgroundColor: Theme.MAIN_COLOR,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.handleDeleteDog(index)
                                      }
                                    >
                                      <img
                                        alt="icon"
                                        src={DeleteIcon}
                                        style={{
                                          margin: 5,
                                          width: 50,
                                        }}
                                      />
                                    </div>
                                  )}
                                  <DogPictureUpload
                                    width={width}
                                    containerStyle={{
                                      marginRight: "20px",
                                      display: "block",
                                    }}
                                    picture={
                                      dog.profilePicture.tempPic ||
                                      (dog.profilePicture.url
                                        ? // process.env.REACT_APP_API_ENDPOINT +
                                        //   dog.profilePicture.url
                                        dog.profilePictureCdn
                                        : null)
                                    }
                                    name={`picture${index}`}
                                    onUpload={(v, pic) => {
                                      let newState = { ...this.state };
                                      newState.dogs[
                                        index
                                      ].profilePicture.tempPic = pic;
                                      this.setState({ dogs: newState.dogs });
                                      change(`profilePicture${index}`, v);
                                    }}
                                    height={height}
                                    label="Picture"
                                    verticalCenter={true}
                                    notSelected={!(selected === index)}
                                    renderStrikes={
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          display: "block",
                                        }}
                                      >
                                        {Array(3)
                                          .fill()
                                          .map((data, i) => {
                                            let status = (
                                              <StrikesCheckBox
                                                isStrikeStatus={false}
                                                name={`strikeCount${index}`}
                                                change={change}
                                                index={index}
                                                dog_id={dog.id}
                                                client={this.props.client}
                                                strikeCount={0}
                                              />
                                            );

                                            if (i + 1 <= dog.strikeCount) {
                                              status = (
                                                <StrikesCheckBox
                                                  isStrikeStatus={true}
                                                  // this.props.change(`strikeCount${i}`, dogs[i].strikeCount);
                                                  name={`strikeCount${index}`}
                                                  change={change}
                                                  index={index}
                                                  dog_id={dog.id}
                                                  client={this.props.client}
                                                  strikeCount={
                                                    dog.strikeCount + 1
                                                  }
                                                  refetch={refetch}
                                                  banneName={`isBanned${index}`}
                                                />
                                              );
                                            } else
                                              status = (
                                                <StrikesCheckBox
                                                  isStrikeStatus={false}
                                                  name={`strikeCount${index}`}
                                                  change={change}
                                                  index={index}
                                                  dog_id={dog.id}
                                                  client={this.props.client}
                                                  strikeCount={
                                                    dog.strikeCount + 1
                                                  }
                                                  refetch={refetch}
                                                  banneName={`isBanned${index}`}
                                                />
                                              );
                                            return status;
                                          })}
                                      </div>
                                    }
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "2%",
                                      flexDirection: "column",
                                      width: "calc(100% - 150px)",
                                    }}
                                  >
                                    {dogs[index]["isBanned"] && (
                                      <h3
                                        style={{
                                          flexDirection: "row",
                                          backgroundColor: "white",
                                          border: "2px solid",
                                          borderColor: "red",
                                          textAlign: "center",
                                          color: "red",
                                          borderRadius: 10,
                                          boxShadow: "3px 3px 10px #606267",
                                          padding: 10,
                                          marginBottom: `${deleting ? "45px" : "20px"
                                            }`,
                                          // position: "relative"s
                                        }}
                                      >
                                        Banned
                                      </h3>
                                    )}
                                    <LabeledTextBox
                                      onBlur={() => { }}
                                      name={`name${index}`}
                                      width={width}
                                      widthpercent={"100%"}
                                      height={height}
                                      label="Dog Name"
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div style={{ width: "55%" }}>
                                        <LabeledTextBox
                                          onBlur={() => { }}
                                          name={`color${index}`}
                                          widthpercent={"100%"}
                                          width={width}
                                          height={height}
                                          label="Color"
                                        />
                                      </div>

                                      <div style={{ width: "40%" }}>
                                        <LabeledTextBox
                                          name={`sex${index}`}
                                          onBlur={() => { }}
                                          options={["M", "F"]}
                                          // validate={["required"]}
                                          widthpercent={"100%"}
                                          width={width}
                                          height={height}
                                          label="Sex"
                                        />
                                      </div>
                                    </div>
                                    <LabeledTextBox
                                      onBlur={() => { }}
                                      name={`breed${index}`}
                                      width={width}
                                      widthpercent={"100%"}
                                      height={height}
                                      label="Dog Breed"
                                    />

                                    {/* ************************************************************************************************ */}
                                    <BirthCheckBox
                                      isNeuteredStatus={dog.isNeutered}
                                      name={`isNeutered${index}`}
                                      change={change}
                                      index={index}
                                      dog_id={dog.id}
                                      client={this.props.client}
                                    />
                                    {/* ************************************************************************************************ */}

                                    <div style={{ width: "100%" }}>
                                      {/* <LabeledTextBox
                                  onBlur={() => { }}
                                  name={`birthdate${index}`}
                                  widthpercent={"100%"}
                                  width={width}
                                  height={height}
                                  label="Birth Date"
                                  type="date"
                                /> */}
                                      <DatePickerBox
                                        onBlur={() => { }}
                                        name={`birthdate${index}`}
                                        widthpercent={"100%"}
                                        width={width}
                                        height={height}
                                        label="Birth Date"
                                        type="date"
                                        placeholder="MM/DD/YYYY"
                                        change={change}
                                        dateType="birthDate"
                                      />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                      <LabeledTextBox
                                        onBlur={() => { }}
                                        name={`dogs[${index}['package']['name']`}
                                        widthpercent={"100%"}
                                        width={width}
                                        height={height}
                                        label="Package"
                                      />
                                    </div>
                                    {dogs[index]["isBanned"] && (
                                      <div style={{ width: "100%" }}>
                                        <LabeledTextBox
                                          onBlur={() => { }}
                                          name={`dogs[${index}['bannedMessage']`}
                                          widthpercent={"100%"}
                                          width={width}
                                          height={height}
                                          onChange={(e) => e.preventDefault()}
                                          label="Banned Comment"
                                          disabled={dogs[index]["isBanned"]}
                                        />
                                      </div>
                                    )}
                                    <div style={{ width: "100%" }}>
                                      <Button
                                        type="button"
                                        containerStyle={{ margin: "auto" }}
                                        text={`Ban Dog : ${dogs[index] && dogs[index]["name"]
                                          } `}
                                        width={width}
                                        disabled={
                                          dogs[index] && dogs[index]["isBanned"]
                                        }
                                        onClick={() => {
                                          this.handleBannedAccount(
                                            dogs[index] && dogs[index]["name"]
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ marginTop: 10, textAlign: "center" }}>
                              {numDogs} / 3 Dogs
                            </div>
                            {/* Checked In Modal */}
                            {checkedInModal && (
                              <CheckedInModal
                                isModalDisplayed={checkedInModal}
                                closeModal={this.closeCheckedInModal}
                                dogs={dogs}
                                userData={userData}
                                history={this.props.history}
                              />
                            )}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: `${this.isPortrait() ? "column" : "row"
                                  }`,
                                margin: 20,
                              }}
                            >
                              {numDogs < 3 && (
                                <Button
                                  type="button"
                                  containerStyle={{
                                    marginTop: 10,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  text="Add Dog"
                                  width={width}
                                  onClick={() => this.handleDogModal()}
                                />
                              )}
                              <Button
                                type="button"
                                containerStyle={{
                                  marginTop: 10,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                text={`${deleting ? "Cancel Deleting" : "Delete Dog"
                                  }`}
                                width={width}
                                onClick={() =>
                                  this.setState({
                                    deleting: !this.state.deleting,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <h2 style={{ display: "inline-flex" }}>
                          Membership For:{" "}
                          {dogs[selected] ? dogs[selected]["name"] : ""}
                        </h2>

                        {/* <Button
                          type="button"
                          style={{
                            margin: "5px 5px 5px 5px",
                            display: "inline-flex",
                            borderRadius: 5,
                            height: 30,
                          }}
                          // text={this.state.selectedDogSubscription ? `Paused` : "Unpaused"}
                          text={
                            selectedDogSubscriptionStatus
                              ? `Paused`
                              : "Unpaused"
                          }
                          width={width}
                          onClick={() => {
                            this.handlePausedSubscription(
                              dogs[selected]["id"],
                              selectedDogSubscriptionStatus,
                              refetch
                            );
                          }}
                        /> */}


                        {dogs[selected] && dogs[selected]["package"] ? (
                          <MembershipInfo
                            package={dogs[selected]["package"]}
                            subscription={
                              dogs[selected]["package"]["subscription"]
                            }
                            width={width}
                            shrink={shrink}
                            onClick={() => {
                              // history.push(`/dog/packages/${dogs[selected]['id']}`)
                              history.push(
                                `/staff/member/packages?dogId=${dogs[selected]["id"]}`
                              );
                            }}
                            users={data?.userDetail}
                            dogsStatus={dogs[selected]}
                          />
                        ) : (
                          <Fragment>
                            No Membership Package.
                            {dogs.length > 0 ? (
                              <a
                                href={`/staff/member/packages?dogId=${dogs[selected]["id"]}`}
                                style={{ marginLeft: 5 }}
                              >
                                Choose a package
                              </a>
                            ) : (
                              ""
                            )}
                          </Fragment>
                        )}

                        {dogs[selected] && (
                          <VaccinationUpload
                            dog={dogs[selected]}
                            vacDates={{
                              rabiesDate,
                              dhlppDate,
                              leptospirosisDate,
                              bordetellaDate,
                              separateLeptospirosisStatus,
                            }}
                            change={change}
                            selected={selected}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "5%",
                            marginBottom: "5%",
                          }}
                        >

                          {/* <Button
                          type="button"
                          containerStyle={{ margin: "auto" }}
                          text={`Save and ${
                            data.userDetail.confirmed ? "Continue" : "Confirm"
                          }`}
                          width={width}
                          onClick={() => {
                            // change("acceptedRules", data.userDetail.acceptedRules);

                            change(
                              "isAcceptedParkRule",
                              data.userDetail.park.isAcceptedParkRule
                            );

                            if (!data.userDetail.confirmed) {
                              if (
                                window.confirm(
                                  "Do you want to confirm this member?"
                                )
                              ) {
                                handleSubmit();
                              }
                            } else {
                              handleSubmit();
                            }
                          }}
                        /> */}

                          {(this.props.global.currentUser.type === "Manager" ||
                            this.props.global.currentUser.type === "Admin") && (
                              <Button
                                type="button"
                                containerStyle={{
                                  margin: "auto",
                                }}
                                text={data?.userDetail?.deactivated ? "DEACTIVATED" : "Deactivate User"}
                                width={width}
                                onClick={() => {
                                  const deactivated = data?.userDetail?.deactivated;
                                  this.handleUserDeactivation(deactivated, refetch);
                                }}
                              />
                            )}
                          {(this.props.global.currentUser.type === "Manager" ||
                            this.props.global.currentUser.type === "Admin") && (
                              <Button
                                type="button"
                                containerStyle={{ margin: "auto" }}
                                text={"Archive Member"}
                                width={width}
                                onClick={() => {
                                  this.handleDeleteAccount();
                                }}
                              />
                            )}

                          {/* <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text={`Banned : ${dogs[selected] && dogs[selected]['name']} `}
                      width={width}
                      disabled={dogs[selected] && dogs[selected]['isBanned']}
                      onClick={() => {
                        this.handleBannedAccount(dogs[selected] && dogs[selected]['name']);
                      }}
                    /> */}
                        </div>
                        {/******  Event Table starts  *****/}
                        <EventTable
                          global={global}
                          client={this.props.client}
                        />
                        {/****** Event Table Ends ******/}

                        {/* Add Dog Modal */}

                        <div
                          id="dogModal"
                          style={{
                            display: `${this.state.dogModalOpen ? "flex" : "none"
                              }`,
                            position: "fixed",
                            zIndex: 999,
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "auto",
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                          }}
                        >
                          <div
                            style={{
                              margin: "auto",
                              width: `${width < 600 ? "100%" : "600px"}`,
                              height: "400px",
                              position: "relative",
                              backgroundColor: "white",
                              padding: 10,
                              borderRadius: "15px",
                            }}
                          >
                            <div
                              id="dogModalContainer"
                              style={{ position: "relative" }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    width: "75%",
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "auto",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "5%",
                                      fontSize: 18,
                                      fontFamily: "Lato",
                                    }}
                                  >
                                    Add Dog
                                  </div>
                                  {/* Dog Card */}
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "white",
                                        border: "3px solid",
                                        borderColor: Theme.MAIN_COLOR,
                                        borderRadius: 10,
                                        boxShadow: "3px 3px 10px #606267",
                                        padding: 20,
                                        marginBottom: "auto",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                      }}
                                    >
                                      <PictureUpload
                                        width={width}
                                        containerStyle={{
                                          marginRight: "20px",
                                          display: "flex",
                                        }}
                                        picture={null}
                                        name={`pictureNew`}
                                        onUpload={(v, pic) => {
                                          change(`pictureNew`, v);
                                        }}
                                        height={height}
                                        label="Picture"
                                        disableForMembers={false}
                                        verticalCenter={true}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          marginTop: "2%",
                                          flexDirection: "column",
                                          width: "calc(100% - 150px)",
                                        }}
                                      >
                                        <LabeledTextBox
                                          onBlur={() => { }}
                                          name={`nameNew`}
                                          width={width}
                                          widthpercent={"100%"}
                                          height={height}
                                          label="Dog Name"
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div style={{ width: "65%" }}>
                                            <LabeledTextBox
                                              onBlur={() => { }}
                                              name={`colorNew`}
                                              widthpercent={"100%"}
                                              width={width}
                                              height={height}
                                              label="Color"
                                            />
                                          </div>
                                          <div style={{ width: "25%" }}>
                                            <LabeledTextBox
                                              name={`sexNew`}
                                              onBlur={() => { }}
                                              options={["M", "F"]}
                                              widthpercent={"100%"}
                                              width={width}
                                              height={height}
                                              label="Sex"
                                            />
                                          </div>
                                        </div>
                                        <LabeledTextBox
                                          onBlur={() => { }}
                                          name={`breedNew`}
                                          width={width}
                                          widthpercent={"100%"}
                                          height={height}
                                          label="Dog Breed"
                                        />
                                        {/* ************************************************************************************************ */}
                                        <BirthCheckBox
                                          isNeuteredStatus={false}
                                          name={`isNeuteredNew`}
                                          change={change}
                                          // dog_id={dog.id}
                                          client={this.props.client}
                                        // index={index}
                                        //  selected={selected}
                                        />
                                        {/* ************************************************************************************************ */}

                                        <div style={{ width: "100%" }}>
                                          {/* <LabeledTextBox
                                      onBlur={() => { }}
                                      name={`birthdateNew`}
                                      widthpercent={"100%"}
                                      width={width}
                                      height={height}
                                      label="Birth Date"
                                      type="date"
                                    /> */}
                                          <DatePickerBox
                                            onBlur={() => { }}
                                            name={`birthdateNew`}
                                            widthpercent={"100%"}
                                            width={width}
                                            height={height}
                                            label="Birth Date"
                                            type="date"
                                            placeholder="MM/DD/YYYY"
                                            change={change}
                                            dateType="birthDate"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      marginTop: 20,
                                    }}
                                  >
                                    <Button
                                      type="button"
                                      containerStyle={{ margin: "auto" }}
                                      text="Cancel"
                                      width={width}
                                      onClick={() => this.handleDogModal()}
                                    />
                                    <Button
                                      type="button"
                                      containerStyle={{ margin: "auto" }}
                                      text="Add Dog"
                                      width={width}
                                      onClick={() => this.addDog()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* End of Dog Modal */}
                      </div>
                    </OnboardingHeader>
                  </>
                );
              }
            }}
          </Query>
        </ConnectActionCable>
        <div style={containerStyle}>
          {isModalDisplayed && (
            <BannedModal
              isModalDisplayed={isModalDisplayed}
              closeModal={this.closeModal}
              selectedDog={dogs[selected]}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = async (values, dispatch, props) => {
  console.log("____", values);
  const fetchUserDash = () => {
    let parkId = props.global?.currentUser?.park?.id;
    props.client
      .query({
        query: USER_FEED,
        variables: {
          parkId: parkId,
          type: "Member",
          page: 1,
          per: 5,
          search: "",
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => { });
  };
  var userValues = {
    firstName: values.firstName,
    lastName: values.lastName,
    authorizedUser: values.authorizedUser,
    address: values.address,
    address2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    phoneNumber: values.phoneNumber,
    id: parseInt(props.global.currentUser.selectedUser),
    step: null,
    email: values.email,
  };

  const valueGet = (oldValue, newValue) => {
    return !oldValue
      ? newValue === ""
        ? ""
        : newValue === null && null
      : oldValue;
  };
  let newOwnerInfo = {
    firstName: values.firstName,
    lastName: values.lastName,
    authorizedUser: values.authorizedUser,
    id: parseInt(props.global.currentUser.selectedUser),
    phoneNumber: values.phoneNumber,
    email: values.email,
    // profilePicture: (values.profilePicture.url ? values.profilePicture?.url : values.profilePicture)
    // step: null,
  };
  let newOwnerInfoWithImage = {
    ...newOwnerInfo,
    // ...newOwnerInfo,
    profilePicture: values.profilePicture?.url
      ? values.profilePicture?.url
      : values.profilePicture ? values.profilePicture : null
  };
  let prevOwnerInfo = {
    prevFirstName: valueGet(values.prevFirstName, newOwnerInfo.firstName),
    prevLastName: valueGet(values.prevLastName, newOwnerInfo.lastName),
    prevPhoneNumber: valueGet(values.prevPhoneNumber, newOwnerInfo.phoneNumber),
    prevEmail: valueGet(values.prevEmail, newOwnerInfo.email),
    prevProfile: valueGet(
      values.prevProfile,
      newOwnerInfoWithImage.profilePicture
    ),
  };

  var newOwnerAddress = {
    address: values.address,
    address2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    id: parseInt(props.global.currentUser.selectedUser),
  };

  var prevOwnerAddress = {
    prevAddress: valueGet(values.prevAddress, newOwnerAddress.address),
    prevAddressTwo: valueGet(values.prevAddressTwo, newOwnerAddress.address2),
    prevCity: valueGet(values.prevCity, newOwnerAddress.city),
    prevState: valueGet(values.prevState, newOwnerAddress.state),
    prevZip: valueGet(values.prevZip, newOwnerAddress.zip),
  };

  if (values.profilePicture.url === undefined) {
    userValues.profilePicture = values.profilePicture;
  }
  let noErrorOwnerInfo = false;
  let noErrorOwnerAddress = false;
  let noErrorsDogInfo = false;
  let noErrorsDogVac = false;

  // User Info Mutation
  if (
    newOwnerInfo.firstName !== prevOwnerInfo.prevFirstName ||
    newOwnerInfo.lastName !== prevOwnerInfo.prevLastName ||
    newOwnerInfo.phoneNumber !== prevOwnerInfo.prevPhoneNumber ||
    newOwnerInfo.email !== prevOwnerInfo.prevEmai
  ) {
    if (newOwnerInfoWithImage.profilePicture !== prevOwnerInfo.prevProfile) {

      let ownerInfoChanged = await props.client
        .mutate({
          mutation: USER_UPDATE_INFO,
          variables: { input: newOwnerInfoWithImage },
        })
        .then((res) => {
          return true;
        })
        .catch((error) => {
          let error_msg = error.message.replace("GraphQL error:", "");
          alert(error_msg);
          return false;
        });
      noErrorOwnerInfo = ownerInfoChanged;
    } else {
      let ownerInfoChanged = await props.client
        .mutate({
          mutation: USER_UPDATE_INFO,
          variables: { input: newOwnerInfo },
        })
        .then((res) => {
          return true;
        })
        .catch((error) => {
          let error_msg = error.message.replace("GraphQL error:", "");
          alert(error_msg);
          return false;
        });
      noErrorOwnerInfo = ownerInfoChanged;
    }
  } else {
    if (newOwnerInfoWithImage.profilePicture !== prevOwnerInfo.prevProfile) {
      let ownerInfoChanged = await props.client
        .mutate({
          mutation: USER_UPDATE_INFO,
          variables: { input: newOwnerInfoWithImage },
        })
        .then((res) => {
          return true;
        })
        .catch((error) => {
          let error_msg = error.message.replace("GraphQL error:", "");
          alert(error_msg);
          return false;
        });
      noErrorOwnerInfo = ownerInfoChanged;
    } else {
      noErrorOwnerInfo = true;
    }
  }
  // User Address Mutation

  if (
    newOwnerAddress.address !== prevOwnerAddress.prevAddress ||
    newOwnerAddress.address2 !== prevOwnerAddress.prevAddressTwo ||
    newOwnerAddress.city !== prevOwnerAddress.prevCity ||
    newOwnerAddress.zip !== prevOwnerAddress.prevZip ||
    newOwnerAddress.state !== prevOwnerAddress.prevState
  ) {
    let ownerAddressChanged = await props.client
      .mutate({
        mutation: USER_UPDATE_ADDRESS,
        variables: { input: newOwnerAddress },
      })
      .then((res) => {
        return true;
      })
      .catch((error) => {
        let error_msg = error.message.replace("GraphQL error:", "");
        alert(error_msg);
        return false;
      });
    noErrorOwnerAddress = ownerAddressChanged;
  } else {
    noErrorOwnerAddress = true;
  }
  // Dog Info Mutation
  noErrorsDogInfo = await updateDogsInfoNew(
    values,
    props.client,
    props.refetch,
    props.global,
    fetchUserDash
  );

  // Dog Vaccination Mutation
  noErrorsDogVac = await addOrUpdateDogVaccinationsNew(
    values,
    props.client,
    props.refetch,
    props.global,
    fetchUserDash
  );

  if (
    noErrorOwnerInfo &&
    noErrorOwnerAddress &&
    noErrorsDogInfo &&
    noErrorsDogVac
  ) {
    if (!values.confirmed) {
      props.client
        .mutate({
          mutation: CONFIRM,
          variables: {
            input: {
              id: parseInt(props.global.currentUser.selectedUser),
            },
          },
        })
        .then((res) => {
          if (!values.acceptedRules) {
            // if (!values.park.isAcceptedParkRules) {
            // if (!values.parkRuleStatus) {  Rollback FP-665
            alert(
              "Please have the member accept the park rules before continuing."
            );
            console.log("Member Form: Setting the return to")
            props.history.push("/members/parkrules", { returnTo: "/staff/member" });
          } else {
            alert("Confirmed!");
            if (props.global.currentUser.type === "Employee") {
              props.history.replace('/staff/member');
            } else {
              props.history.replace('/staff/member');
            }
            window.location.reload();
          }
        })
        .catch((error) => {
          let error_msg = error.message.replace("GraphQL error:", "");
          alert(error_msg);
          // window.location.reload()
          return false;
        });
    } else {
      console.log("Save operation completed successfully"); // Add this line
      alert("Saved!");
      console.log("About to navigate away from /staff/member"); // Add this line
      if (props.global.currentUser.type === "Employee") {
        console.log("Destination: Dashboard"); // Add this line
        // props.history.push("/dashboard");
      } else {
        console.log("Destination: Members"); // Add this line
        //props.history.push("/manager/members");
      }
      window.location.reload();

    }
  }
  // return true;
};

ConfirmAccountForm = reduxForm({
  form: "ConfirmAccountForm",
  enableReinitialize: true,
  onSubmit: formSubmit,
})(ConfirmAccountForm);

// Decorate with connect to read form values
const selector = formValueSelector("ConfirmAccountForm"); // <-- same as form name
ConfirmAccountForm = connect((state) => {
  const rabiesDate = selector(state, "rabies");
  const dhlppDate = selector(state, "dhlpp");
  const leptospirosisDate = selector(state, "leptospirosis");
  const bordetellaDate = selector(state, "bordetella");
  const oldRabiesDate = selector(state, "oldRabiesDate");
  const olddhlppDate = selector(state, "oldDhlppDate");
  const oldBordetellaDate = selector(state, "oldBordetellaDate");
  const oldLeptospirosisDate = selector(state, "oldLeptospirosisDate");
  const oldSeparateLeptospirosisStatus = selector(
    state,
    "oldSeparateLeptospirosis"
  );

  const prevFirstName = selector(state, "prevFirstName");
  const prevLastName = selector(state, "prevLastName");
  const prevProfile = selector(state, "prevProfile");
  const prevAddress = selector(state, "prevAddress");
  const prevPhoneNumber = selector(state, "prevPhoneNumber");
  const prevAddressTwo = selector(state, "prevAddressTwo");
  const prevAdd = selector(state, "prevAdd");

  const prevCity = selector(state, "prevCity");
  const prevState = selector(state, "prevState");
  const prevZip = selector(state, "prevZip");
  const prevEmail = selector(state, "prevEmail");

  const vaccinationNew0 = selector(state, "vaccinationNew0");
  const vaccinationNew1 = selector(state, "vaccinationNew1");
  const vaccinationNew2 = selector(state, "vaccinationNew2");
  const nameNew = selector(state, "nameNew");
  const pictureNew = selector(state, "pictureNew");
  const birthdateNew = selector(state, "birthdateNew");
  const sexNew = selector(state, "sexNew");
  const colorNew = selector(state, "colorNew");
  const isNeuteredNew = selector(state, "isNeuteredNew");
  const breedNew = selector(state, "breedNew");
  const separateLeptospirosisStatus = selector(state, "separateLeptospirosis");
  const isLeptospirosisStatus = selector(state, "isLeptospirosis");
  const selectedDogSubscriptionStatus = selector(state, "pausedSubscription");
  const selectedIsBanned = selector(state, "isBanned");

  return {
    rabiesDate,
    dhlppDate,
    leptospirosisDate,
    bordetellaDate,

    oldRabiesDate,
    oldBordetellaDate,
    oldLeptospirosisDate,
    olddhlppDate,
    vaccinationNew0,
    vaccinationNew1,
    vaccinationNew2,
    nameNew,
    pictureNew,
    birthdateNew,
    sexNew,
    colorNew,
    breedNew,
    separateLeptospirosisStatus,
    isLeptospirosisStatus,
    isNeuteredNew,
    selectedDogSubscriptionStatus,

    prevFirstName,
    prevLastName,
    prevProfile,
    prevAddress,
    prevAddressTwo,
    prevAdd,
    prevZip,
    prevCity,
    prevEmail,
    prevPhoneNumber,
    prevState,
    oldSeparateLeptospirosisStatus,
    selectedIsBanned,
  };
})(ConfirmAccountForm);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(ConfirmAccountForm);
