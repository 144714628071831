import React, { Component } from "react";
import Cell from "./Cell";
import LeftArrow from "../../../assets/left-arrow.svg";
import RightArrow from "../../../assets/right-arrow.svg";
import Theme from "../../Theme.js";

const styles = {
  pageLabel: {
    color: "black",
    textDecoration: "none",
    marginLeft: "1%",
    marginRight: "1%",
    fontSize: "80%",
    marginTop: "auto",
    marginBottom: "auto",
    fontColor: "#3D3D3D",
    fontWeight: 600,
    border: "none",
    outline: "none",
    background: "none",
    cursor: "pointer"
  },
  emptyButton: {
    outline: "none",
    border: "none",
    background: "none",
    cursor: "pointer"
  }
};

export default class PhoneDogTable extends Component {

  renderHeaderRow = (name, cellIndex) => {
    const { columns, alt } = this.props;
    const column = columns[name];

    return (
      <Cell
        key={`header-${cellIndex}`}
        label={column.label}
        header={true}
        alt={alt}
      />
    );
  };

  renderRow = (_row, rowIndex) => {
    const { names, columns, onClick, alt, width } = this.props;
    return (
      <tr
        onClick={() => onClick(_row.id)}
        key={`row-${rowIndex}`}
        style={
          rowIndex % 2 === 0
            ? { backgroundColor: "white", height: 40, cursor: onClick && 'pointer' }
            : { backgroundColor: "#ECECEC", height: 40, cursor: onClick && 'pointer' }
        }
      >
        {
          (_row.rowType && _row.rowType == 'user') ?
            // <td colSpan="6">
            //   {`${_row.firstName} ${_row.lastName}`}

            // </td>
            // <Cell
            //   key={`1`}
            //   alt={alt}
            //   renderCell={columns['box'].renderUserName(_row)}
            // />

            columns['box'].renderUserName(_row)
            :
            names.map((name, cellIndex) => {
              const column = columns[name];
              return (
                <Cell
                  key={`${rowIndex}-${cellIndex}`}
                  alt={alt}
                  renderCell={column.renderCell(_row, this.props.refetch)}
                />
              );
            })
        }
        {/* {  console.log("-------------------",this.props.refetch )} */}
      </tr>
    );
  };
  render() {
    const { user, names, total, page = 1, per = 10, loading, width } = this.props;
    // console.log('----->for dog this.props ', user);
    const verySmall = width < 768;
    let dogs = []
    const customRows = []

    if (user) {
      const { completed, confirmed } = user;
      customRows.push({
        rowType: 'user',
        ...user
      })
      for (const dog of user?.dogs) {
        customRows.push({
          rowType: 'dog',
          completed,
          confirmed,

          userId: user.id,
          parkId: dogs && user.park?.id,
          notes: user.notes,
          ...dog
        })
      }
      dogs = dogs.concat(user.dogs)
    }
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowX: verySmall ? "scroll" : "",
      }}>
        <table
          cellSpacing={0}
          cellPadding={5}
          style={{
            tableLayout: "fixed",
            width: verySmall ? "550px" : "100%"
          }}
        >
          <thead style={{ cursor: "pointer" }}>
            <tr
              style={{
                backgroundColor: "white",
                height: 40
              }}
            >
              {names.map(this.renderHeaderRow)}
            </tr>
          </thead>
          {
            loading ? <thead></thead> : <tbody>
              {customRows && customRows.map(this.renderRow)}
            </tbody>
          }
        </table>
      </div>
    );
  }
}
