import React, { Component } from "react";
import Theme from "../Theme.js";

export default class DogCard extends Component {
  getHeight(width) {
    if (width > 450) return 80;
    else if (width > 400) return 60;
    return 50;
  }
  getWidth(width) {
    if (width > 450) {
      return 130;
    } else if (width > 400) {
      return 80;
    }
    return 50;
  }
  render() {
    const {
      compact,
      widthFactor,
      dogName,
      selected,
      onClick,
      picture,
      // width,
      containerStyle
    } = this.props;

    const width = window.innerWidth;
    const verySmall = width < 568;
    return (
      <div style={containerStyle}>
        <button
          style={{
            display: "flex",
            flexDirection: compact ? "column-reverse" : "row",
            backgroundColor: "white",
            opacity: selected ? 1 : 0.7,
            outline: "none",
            border: selected ? "3px solid" : "1px solid",
            borderColor: selected ? Theme.MAIN_COLOR : Theme.LIGHT_TEXT_COLOR,
            borderRadius: 10,
            boxShadow: "3px 3px 10px #606267",
            padding: 20,
            textDecoration: "none",
            alignItems: "center",
            // width: compact
            //   ? this.getWidth(width) * 1.5
            //   : Theme.getLongInputWidth(width) / widthFactor,
              width:verySmall ? "95%" : Theme.getLongInputWidth(width) / widthFactor,
            textAlign: "left"
          }}
          type="button"
          onClick={() => {
            onClick();
            return false;
          }}
        >
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              border: "2px solid",
              borderRadius: 10,
              height: "140px",
              minHeight: 50,
              width: "140px",
              marginRight: "20px",
              borderColor: Theme.LINK_FONT_COLOR
            }}
          >
            <img
              style={{ borderRadius: 8, height: "140px", width: "140px" }}
              alt=""
              src={picture}
            />
          </label>
          <div
            style={{
              display: verySmall ? "block" : "flex",
              flexDirection: "column",
              width:verySmall ?"90%" : "70%",
              textAlign: compact ? "center" : "",
              fontSize: compact
                ? Theme.getNormalFont(width)
                : Theme.getSmallFont(width),
              marginTop: "auto",
              marginBottom: "auto",
              color: selected ? Theme.DARK_TEXT_COLOR : Theme.LIGHT_TEXT_COLOR
            }}
          >
            {compact ? dogName : "Dog Name"}
            <div
              style={{
                display: compact ? "none" : "",
                marginTop: "1%",
                borderRadius: 5,
                border: "1.2px solid",
                padding: "3%"
              }}
            >
              {dogName}
            </div>
          </div>
        </button>
      </div>
    );
  }
}
