import React, { Component } from "react";
import Theme from "../Theme.js";
import { DirectUpload } from "@rails/activestorage";
import { PulseLoader } from "react-spinners";
export default class DogPictureUpload extends Component {
    state = {
        tempPhoto: null,
        loading: false,
        nameDog: ""
    };
    getHeight(width) {
        if (width > 450) return 140;
        if (width > 400) return 110;
        if (width > 300) return 80;
        return 90;
    }
    getWidth(width) {
        if (width > 450) return 140;
        if (width > 400) return 100;
        if (width > 300) return 60;
        else return 60;
    }
    componentDidUpdate() {
        if (this.props.name !== this.state.nameDog) {
            // console.log("-------------->",this.props.name !== this.state.nameDog);
            this.setState({ nameDog: this.props.name, tempPhoto: null });
        }
    }

    uploadFile = event => {
        const url =
            process.env.REACT_APP_API_ENDPOINT +
            "/rails/active_storage/direct_uploads";
        const upload = new DirectUpload(event.target.files[0], url);
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({ tempPhoto: reader.result, loading: true });
        };
        if (file !== undefined) {
            reader.readAsDataURL(file);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }

            upload.create((error, blob) => {
                if (error) {
                    console.error(error);
                    alert("failed upload");
                    return;
                }
                // console.log("-----------------Blob-----------------------", blob);
                this.props.onUpload(blob.signed_id, reader.result);
                this.setState({ loading: false });
            });
        } else alert("failed");
        this.setState({ loading: false });
    };

    render() {
        const {
            picture,
            name,
            width,
            containerStyle,
            disableForMembers,
            verticalCenter,
            notSelected
        } = this.props;
        const { tempPhoto, loading } = this.state;
        const shrink = width < 725;
        // console.log("Picture--------------", tempPhoto, "--->",);
        if (loading) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: this.getHeight(width),
                        width: this.getWidth(width),
                        marginBottom: 15,
                        border: "3px solid transparent",
                        ...containerStyle
                    }}
                >
                    <PulseLoader
                        style={{ margin: "auto" }}
                        size={10}
                        color={"#fff"}
                        loading={loading}
                    />
                </div>
            );
        } else
            return (
                <div style={containerStyle}>
                    <div style={
                        {
                            width: "100%",
                            display: "block",
                            float: "left",
                            marginBottom: "30px"
                        }
                    }>
                        <input
                            id={name}
                            name={name}
                            type="file"
                            style={{
                                width: 0.1,
                                height: 0.1,
                                opacity: 0,
                                overflow: "hidden",
                                position: "absolute",
                                zIndex: -1
                            }}
                            onChange={event => {
                                if (disableForMembers && !(this.props.picturePresent === null)) {
                                    alert("Please see an employee to update this image.");
                                } else {
                                    this.uploadFile(event);
                                }
                            }}
                        />
                        <label
                            htmlFor={name}
                            style={{
                                display: shrink ? "block" : "flex",
                                flexDirection: "column",
                                backgroundColor: "white",
                                marginBottom: `${verticalCenter ? "auto" : "15px"}`,
                                marginTop: `${verticalCenter ? "100px" : "100px"}`,
                                marginLeft: shrink ? "auto" : "auto",
                                marginRight: shrink ? "auto" : "auto",
                                border: "3px solid",
                                borderRadius: 10,
                                cursor: "pointer",
                                height: this.getHeight(width),
                                width: this.getWidth(width),
                                minHeight: 140,
                                minWidth: 140,
                                borderColor: notSelected
                                    ? Theme.LIGHT_TEXT_COLOR
                                    : Theme.LINK_FONT_COLOR
                            }}
                        >
                            {picture || tempPhoto ? (
                                <img
                                    alt=""
                                    style={{
                                        borderRadius: 7,
                                        height: this.getHeight(width),
                                        width: this.getWidth(width),
                                        minHeight: 140,
                                        minWidth: 140
                                    }}
                                    src={picture || tempPhoto}
                                />
                            ) : (
                                    <div
                                        style={{
                                            color: Theme.LIGHT_TEXT_COLOR,
                                            fontSize: Theme.getSmallFont(width),
                                            fontFamily: Theme.MAIN_FONT,
                                            margin: "auto"
                                        }}
                                    >
                                        + Picture
                                    </div>
                                )}
                        </label>
                    </div>
                    {this.props.bannedComponent}
                    {this.props.renderStrikes}
                </div>
            );
    }
}
