import React, { Component } from "react";
import { TextBox, Button, LabeledTextBox } from "../globalComponents";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { compose, withApollo } from "react-apollo";
import Logo from "../assets/fetch-logo.svg";
import Bg from "../assets/fetch-BG.svg";
import Theme from "../globalComponents/Theme.js";
import { updateUser } from "../store/redux/actions";
import { reduxForm, SubmissionError } from "redux-form";
import { USER_LOG_IN } from "../store/mutations.js";
import jstz from "jstz";
const timezone = jstz.determine();

class Login extends Component {
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, error, touched } = this.props;

    const styles = {
      login: {
        marginBottom: "2%",
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getHeaderFont(width) * 1.2,
        color: Theme.HEADER_FONT_COLOR,
      },
      header: {
        marginBottom: "2%",
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getHeaderFont(width),
        color: Theme.HEADER_FONT_COLOR,
      },
      link: {
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getHeaderFont(width),
        color: Theme.LINK_FONT_COLOR,
        marginLeft: 5,
      },
      password: {
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getNormalFont(width),
        color: Theme.LINK_FONT_COLOR,
        marginLeft: 5,
        textDecoration: "none",
      },
    };
    return (
      <form>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundRepeat: "round",
            backgroundColor: Theme.BACKGROUND_COLOR,
            backgroundImage: `url(${Bg})`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              height: height,
              width: "40%",
              marginTop: "10%",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              src={Logo}
              style={{ height: "20%", marginBottom: "10%" }}
            />
            <div style={styles.login}> Login </div>
            <TextBox
              containerStyle={{ margin: "2% 0 2% 0" }}
              width={Theme.getInputWidth(width)}
              height={height / 20}
              name="email"
              placeholder="Email"
              type="text"
              validate={["required", "email"]}
            />
            <TextBox
              containerStyle={{ margin: "2% 0 2% 0" }}
              width={Theme.getInputWidth(width)}
              height={height / 20}
              name="password"
              placeholder="Password"
              type="password"
            />
            <Button
              text="Submit"
              type="submit"
              onClick={handleSubmit}
              fullwidth={true}
              width={width}
            />
            {error && <strong style={{ color: "red" }}>{error}</strong>}
            <a href="/reset" style={styles.password}>
              Forgot Your Password?
            </a>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "7%",
                height: height / 6,
                width: width > 900 ? width / 1.8 : width / 1.1,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {/* <div style={styles.header}>
                {" "}
                Are you at the park?
                <a href="/checkin/phone" style={styles.link}>
                  Check In Here
                </a>
              </div> */}
              <div style={{ height: "15%" }} />
              <div style={styles.header}>
                New to the Park? Lets get you Started
                <a href="/members/onboarding" style={styles.link}>
                  Start Here
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

const formSubmit = (values, dispatch, props) => {
  console.log("----", values);
  values.timezone = timezone.name();
  return props.client
    .mutate({ mutation: USER_LOG_IN, variables: { input: values } })
    .then((response) => {
      cookie.set("token", response.data.signIn.auth.authenticationToken);
      const user = response.data.signIn.user;
      props.updateUser({
        id: user.id,
        type: user.type,
        email: user.email,
        park: user.park,
        // picture: process.env.REACT_APP_API_ENDPOINT + user.profilePicture.url,
        picture: user.profilePictureCdn,

      });
      handleRouting(user, props.history, response);
    })
    .catch((error) => {
      if (
        error.graphQLErrors &&
        error.graphQLErrors.length &&
        "message" in error.graphQLErrors[0]
      ) {
        throw new SubmissionError({ _error: error.graphQLErrors[0].message });
      } else console.log(error);
    });
};

const handleRouting = (user, history, response) => {
  if (user.type === "Employee") {
    // history.push("/staff"); // Change For Park selection
    history.push("/staff/parkInfo");
  } else if (user.type === "Member") {
    if (user.completed) {
      history.push("/profile");
    } else if (user.step !== null || user.step <= 8) {
      handleOnboardingRedirect(user.step, history);
    }
  } else if (user.type === "Admin") {
    // history.push("/manager"); // Change For Park selection
    history.push("/staff/parkInfo");
  } else if (user.type === "Manager") {
    // history.push("/manager"); // Change For Park selection
    history.push("/staff/parkInfo");
  } else {
    cookie.remove("token");
    console.warn("Failed login: ", response.data.signIn);
    history.push("/login");
  }
};

const handleOnboardingRedirect = (step, history) => {
  let route;
  switch (step) {
    case 0:
      // route = "/members/onboarding/accountCreation";
      route = "/members/onboarding/ParkInfo";
      break;
    case 1:
      route = "/members/onboarding/info";
      break;
    case 2:
      route = "/members/onboarding/address";
      break;
    case 3:
      route = "/members/onboarding/terms";
      break;
    case 4:
      route = "/members/onboarding/dogInfo";
      break;
    case 5:
      route = "/members/onboarding/vaccinations";
      break;
    case 6: // Need
      // route = "/members/onboarding/packages";
      route = "/members/onboarding/vaccinations";
      break;
    case 7:
      // route = "/members/onboarding/payment";
      route = "/members/onboarding/confirmAccount";
      break;
    case 8:
      route = "/members/onboarding/confirmAccount";
      break;
    default:
      break;
  }
  return history.push(route);
};

Login = reduxForm({
  form: "Login",
  onSubmit: formSubmit,
})(Login);

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
