import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixel = () => {
  useEffect(() => {
    ReactPixel.init('1962972904101898'); // Replace with your actual Pixel ID
    ReactPixel.pageView(); 
  }, []);

  return null;
};

export default FacebookPixel;

