import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ACTIVITY_FEED,
  ACTIVITY_FEED_TIME_SERIES,
  FETCH_PARK_NAME,
} from "../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import { Theme, StaffNavBar, Select } from "../../globalComponents";
import { AreaChart } from "react-chartkick";
import "chart.js";
import Calendar from "../../assets/calendar.svg";
import Bg from "../../assets/fetch-BG.svg";
import moment from "moment";

import clock from "../../assets/hour-arrow.svg";
import plus from "../../assets/plus-green.svg";
import user from "../../assets/login-staff.svg";
import cookie from "js-cookie";
import { updateUser } from "../../store/redux/actions";

const options = [
  { name: "Total Members Checked In", value: "check_in" },
  { name: "New Members Signed Up", value: "sign_up" },
  { name: "Employees Signed In", value: "sign_in" },
];
const dates = [
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
  { name: "Annually", value: "annually" },
];
const locations = [
  { name: "Atlanta", value: 2 },
  { name: "Nashville", value: 1 },
];

function Stat({ type, name, timestamp }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        margin: "2em 0px",
        height: 45,
      }}
    >
      <img
        alt=""
        src={type === "inPark" ? clock : type === "signedIn" ? user : plus}
        style={{
          marginRight: "1.75em",
          marginLeft: "1em",
          width: "35px",
        }}
      />

      <div>
        {type === "inPark" && (
          <h3
            style={{
              fontWeight: 100,
              outline: "none",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color:
                type === "inPark"
                  ? Theme.MAIN_COLOR
                  : type === "signedIn"
                  ? Theme.LIGHT_TEXT_COLOR
                  : Theme.GREEN_COLOR,
              fontFamily: Theme.INPUT_FONT,
              paddingLeft: 0,
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            In:{" "}
            <span style={{ color: Theme.INPUT_FONT_COLOR }}>
              {(timestamp && moment(timestamp).fromNow()) ||
                "Time not available"}
            </span>
          </h3>
        )}
        {type === "signedUp" && (
          <h3
            style={{
              fontWeight: 100,
              outline: "none",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color:
                type === "inPark"
                  ? Theme.MAIN_COLOR
                  : type === "signedIn"
                  ? Theme.LIGHT_TEXT_COLOR
                  : Theme.GREEN_COLOR,
              fontFamily: Theme.INPUT_FONT,
              paddingLeft: 0,
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            {moment(timestamp).format("MMM Do")}{" "}
            {/* <span style={{ color: Theme.INPUT_FONT_COLOR }}>Employee name</span> */}
          </h3>
        )}
        <div>
          <h3 style={{ margin: "6px 0" }}>{name}</h3>
        </div>
      </div>
    </div>
  );
}

function QuickStat({ title, stat, statData, type }) {
  return (
    <div
      style={{
        border: ".5px solid #ccc",
        borderRadius: "8px",
        background: "#fff",
        paddingBottom: "4em",
        width: "100%",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          borderBottom: ".5px solid #ccc",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            fontFamily: Theme.HEADER_FONT,
            color: Theme.INPUT_FONT_COLOR,
            fontWeight: 300,
            margin: "0 1em",
          }}
        >
          {title}
        </h3>
        <h3
          style={{
            fontFamily: Theme.HEADER_FONT,
            color:
              type === "inPark"
                ? Theme.MAIN_COLOR
                : type === "signedIn"
                ? Theme.LIGHT_TEXT_COLOR
                : Theme.GREEN_COLOR,
            fontWeight: 300,
          }}
        >
          {stat}
        </h3>
      </div>
      <div style={{ overflowY: "scroll", height: "100%" }}>
        <div
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          {statData &&
            statData.map((stat, index) => {
              return (
                <Stat
                  key={index}
                  name={`${stat.user.firstName} ${stat.user.lastName}`}
                  type={type}
                  timestamp={stat.timestamp}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

class StaffProfile extends Component {
  state = {
    tab: 0,
    type: {},
    date: {},
    park: {},
  };

  componentDidMount() {
    let today = moment().format();
    let weekly = moment().startOf("week").format();
    this.setState({
      date: { range: [weekly, today], name: "Weekly" },
      type: { name: "Total members checked in", value: "check_in" },
    });
  }

  handleSelect = (option, name) => {
    let { value } = option;
    switch (name) {
      case "type":
        this.setState({ type: option });
        break;
      case "date":
        this.handleDateRange(value);
        break;
      case "park":
        this.setState({ park: option });
        break;
      default:
        break;
    }
  };

  handleDateRange = (value) => {
    let today = moment().toISOString();
    let weekly = moment().startOf("week").toISOString();
    let monthly = moment().startOf("month").toISOString();
    let annually = moment().startOf("year").toISOString();
    switch (value) {
      case "weekly":
        this.setState({
          date: { range: [weekly, today], name: "Weekly" },
        });
        break;
      case "monthly":
        this.setState({ date: { range: [monthly, today], name: "Monthly" } });
        break;
      case "annually":
        this.setState({
          date: { range: [annually, today], name: "Annually" },
        });
        break;
      default:
        break;
    }
  };

  handlePark = (role) => {
    const { park } = this.state;
    const { global } = this.props;
    let parkId = "";
    if (role === "Admin") {
      parkId = park.value;
    } else {
      parkId = global.currentUser.park.id;
    }
    return parkId;
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { type, date, park } = this.state;
    const { global } = this.props;
    const role = global.currentUser.type;
    const selectedPark = this.handlePark(role);
    const start = date.range && date.range.length > 0 && date.range[0];
    const end = date.range && date.range.length > 0 && date.range[1];
    const today = moment().toISOString();
    // const width = obj.dimensions.width;
    const shrink = width < 700;
    const yesterday = moment().subtract(1, "days").toISOString();
    return (
      <Query
        fetchPolicy="no-cache"
        query={ACTIVITY_FEED_TIME_SERIES}
        variables={{
          type: type.value,
          parkId: park.id || global.currentUser.park.id,
          start,
          end,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <StaffNavBar
                tab={0}
                history={this.props.history}
                width={this.props.global.dimensions.width}
                title="Home"
              />
            );
          if (error) return `Error: ${error.message}`;
          if (!loading) {
            return (
              <div
                style={{
                  height: "100%",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`,
                }}
              >
                <StaffNavBar
                  tab={0}
                  history={this.props.history}
                  width={this.props.global.dimensions.width}
                  title="Home"
                />
                <div
                  style={{
                    display: shrink ? "block" : "flex",
                    marginLeft: "4.5%",
                  }}
                >
                  <Select
                    containerStyle={{ margin: 5 }}
                    options={options}
                    handleSelect={this.handleSelect}
                    name="type"
                    selected={(type && type.name) || ""}
                    // width={width / 4.5}
                    width={shrink ? "100%" : width / 4.5}
                    height={height / 25}
                  />
                  {role === "Admin" && (
                    <Query
                      fetchPolicy="cache-and-network"
                      query={FETCH_PARK_NAME}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (error) {
                          if (
                            error.message ===
                            "GraphQL error: You are not authorized to access that resource"
                          ) {
                            cookie.remove("token");
                            this.props.updateUser("reset");
                            this.props.history.push("/login");
                          }
                          return `Error: ${error.message}`;
                        }
                        return (
                          <Select
                            containerStyle={{ margin: 5 }}
                            options={data.fetchParks}
                            handleSelect={this.handleSelect}
                            selected={
                              (park && park.name) ||
                              global.currentUser.park.name ||
                              ""
                            }
                            name="park"
                            // width={width / 5}
                            width={shrink ? "100%" : width / 4.5}
                            height={height / 25}
                          />
                        );
                      }}
                    </Query>
                  )}
                  <Select
                    containerStyle={{ margin: 5 }}
                    options={dates}
                    icon={Calendar}
                    selected={(date && date.name) || ""}
                    name="date"
                    handleSelect={this.handleSelect}
                    // width={width / 5}
                    width={shrink ? "100%" : width / 4.5}
                    height={height / 25}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexShrink: 0,
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginTop: "1em",
                    borderRadius: 2,
                    padding: "2%",
                    border: "1px solid",
                    borderColor: Theme.LIGHT_TEXT_COLOR,
                    backgroundColor: "white",
                  }}
                >
                  <AreaChart
                    colors={[Theme.MAIN_COLOR]}
                    data={data && data.fetchActivityTimeSeries}
                  />
                </div>
                <div style={{ width: "90%", margin: "2em auto" }}>
                  <h2>Latest Activity</h2>
                  <div
                    style={{
                      display: "grid",
                      gridGap: "25px",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(275px, 1fr))",
                      gridAutoRows: "450px",
                    }}
                  >
                    <Query
                      fetchPolicy="no-cache"
                      query={ACTIVITY_FEED}
                      variables={{
                        type: "check_in",
                        parkId: park.id || global.currentUser.park.id,
                        start: yesterday,
                        end: today,
                        page: "1",
                        perPage: "20",
                        requestPage: "dashboard",
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading) return null;
                        if (!loading) {
                          return (
                            <QuickStat
                              statData={data && data?.fetchActivityList.events}
                              title="Total members in park:"
                              type="inPark"
                              stat={
                                (data && data?.fetchActivityList.count) || 0
                              }
                            />
                          );
                        }
                      }}
                    </Query>

                    <Query
                      fetchPolicy="no-cache"
                      query={ACTIVITY_FEED}
                      variables={{
                        type: "sign_up",
                        parkId: park.id || global.currentUser.park.id,
                        start,
                        page: "1",
                        perPage: "20",
                        requestPage: "dashboard",
                        end: today,
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading) return null;
                        if (!loading) {
                          return (
                            <QuickStat
                              statData={
                                data?.fetchActivityList &&
                                data?.fetchActivityList?.events
                              }
                              title="New member sign ups:"
                              type="signedUp"
                              stat={(data && data.fetchActivityList.count) || 0}
                            />
                          );
                        }
                      }}
                    </Query>

                    <Query
                      fetchPolicy="no-cache"
                      query={ACTIVITY_FEED}
                      variables={{
                        type: "sign_in",
                        parkId: park.id || global.currentUser.park.id,
                        start: yesterday,
                        end: today,
                        page: "1",
                        perPage: "20",
                        requestPage: "dashboard",
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading) return null;
                        if (!loading) {
                          return (
                            <QuickStat
                              statData={data && data?.fetchActivityList.events}
                              title="Team signed in:"
                              type="signedIn"
                              stat={(data && data.fetchActivityList.count) || 0}
                            />
                          );
                        }
                      }}
                    </Query>
                  </div>
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };
export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(StaffProfile);
