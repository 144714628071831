import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_UPDATE } from "../../../store/mutations.js";
import { USER_DETAILS } from "../../../store/queries.js";
import { PulseLoader } from "react-spinners";
import { updateUser } from "../../../store/redux/actions";

import Bg from "../../../assets/fetch-BG.svg";
import {
  OnboardingHeader,
  LabeledTextBox,
  PictureUpload,
  Button
} from "../../../globalComponents/";
import cookie from "js-cookie"
class Info extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    signedId: null,
    initialize: true
  };

  handleUpload = signedId => {
    this.setState({ signedId });
  };

  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }

  render() {
    const { picture, initialize } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, change, history } = this.props;
    console.log("Hits", history);
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <div
                name="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: width,
                  minHeight: height,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`
                }}
              >
                <PulseLoader
                  style={{ margin: "auto" }}
                  size={width / 10}
                  color={Theme.LINK_FONT_COLOR}
                  loading={loading}
                />
              </div>
            );
          // if (error) return `Error: ${error.message}`;
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          if (initialize) {
            change("authorizedUser", data.userDetail.authorizedUser);
            change("firstName", data.userDetail.firstName);
            change("lastName", data.userDetail.lastName);
            this.setState({
              initialize: false,
              picture: data.userDetail.profilePicture.url
            });
          }
          console.log(data);
          console.log(this.props.history);
          return (
            <OnboardingHeader
              back={() =>
                this.props.global.currentUser.type === "Employee"
                  ? this.props.history.push("/dashboard")
                  : this.props.global.currentUser.type === "Manager" ||
                    this.props.global.currentUser.type === "Admin"
                    ? this.props.history.push("/manager/members")
                    : this.props.history.push("/")
              }
              progress={1}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center"
              }}
              checkInStatus={false}
            >
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "5%",
                  textAlign: "center",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getHeaderFont(width),
                  color: Theme.HEADER_FONT_COLOR
                }}
              >
                Pet Owner Info
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginBottom: 5
                }}
              >
                <PictureUpload
                  width={width}
                  name="profilePicture"
                  picture={
                    picture && process.env.REACT_APP_API_ENDPOINT + picture
                  }
                  onUpload={(v, pic) => {
                    change("profilePicture", v);
                    this.setState({ picture: false });
                  }}
                  containerStyle={{ marginRight: "3%" }}
                  height={height}
                  label="Picture"
                />
                <div
                  style={{
                    display: "flex",
                    marginTop: "2%",
                    flexDirection: "column"
                  }}
                >
                  <LabeledTextBox
                    name="firstName"
                    validate={["required"]}
                    width={width}
                    widthFactor={1.8}
                    height={height}
                    label="First Name"
                  />
                  <LabeledTextBox
                    name="lastName"
                    validate={["required"]}
                    width={width}
                    widthFactor={1.8}
                    height={height}
                    label="Last Name"
                  />
                </div>
              </div>
              <LabeledTextBox
                name="authorizedUser"
                width={width}
                height={height}
                label="Authorized User"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                  width: Theme.getLongInputWidth(width) * 1.07
                }}
              >
                <Button
                  text="Back"
                  alt={true}
                  width={width}
                  onClick={() => this.props.history.push(history.location?.state?.from || "/login")}
                />
                <Button
                  containerStyle={{ marginLeft: "auto" }}
                  text="Save and Continue"
                  width={width}
                  onClick={handleSubmit}
                />
              </div>
            </OnboardingHeader>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global, ...state.currentUser });

const formSubmit = (values, dispatch, props) => {
  var newValues = values;
  newValues.id =
    props.global.currentUser.selectedUser || props.global.currentUser.id;
  newValues.step = 1;
  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
    .then(({ data: { updateUser } }) => {
      console.log("response", updateUser);
      if (updateUser.errors && updateUser.errors.length > 0) {
        console.error("Submission Error: ", updateUser.errors);
      } else props.history.push({
        pathname: 'address',
        state: { from: props.location.pathname }
      });
    })
    .catch(error => {
      console.error("Error: ", error);
    });
  return true;
};
const mapDispatchToProps = { updateUser };

Info = reduxForm({
  form: "Info",
  onSubmit: formSubmit
})(Info);

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Info);
