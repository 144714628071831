import React, { Component } from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { Theme, StaffNavBar } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import Bg from "../../../assets/fetch-BG.svg";
import Tags from "./tags.js";
import MembersTags from "./membersTags.jsx";
import SendMessageTags from "./sendMessageTags.jsx";
import { compose } from "redux";
import Events from "./events.jsx";
import EventCalendar from "./eventCalendar";
import MemberFilterByTag from "./MemberFilterByTag";

class Marketing extends Component {
  state = {
    type: "Employee",
    employeeType: "Ranger",
    per: 20,
    page: 1,
    tab: 0,
  };

  render() {
    const { tab } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };

    const width = obj.dimensions.width;
    const shrink = width < 700;
    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        margin: "auto 2% auto 2%",
        color: "white",
        fontFamily: Theme.INPUT_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "white",
        borderRadius: 5,
        padding: 10,
        border: "none",
        color: Theme.MAIN_COLOR,
        margin: "auto 2% auto 2%",
        fontFamily: Theme.HEADER_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
      deactivate: {
        outline: "none",
        backgroundColor: "rgba(250,250,250, .3)",
        borderRadius: 5,
        padding: 5,
        border: "none",
        color: "white",
        margin: "auto",
        marginRight: 10,
        fontFamily: Theme.HEADER_FONT,
        // fontSize: Theme.getSmallFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
    };

    return (
      <div
        style={{
          // height: this.props.global.dimensions.height,
          backgroundColor: Theme.BACKGROUND_COLOR,
          backgroundRepeat: "round",
          backgroundImage: `url(${Bg})`,
          minHeight: "100vh",
          maxHeight: "100%",
        }}
      >
        <StaffNavBar
          tab={5}
          history={this.props.history}
          width={this.props.global.dimensions.width}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            width: "90%",
            justifyContent: "center",
            marginTop: -80,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              marginRight: "auto",
              display: "flex",
              flexDirection: "row",
              marginTop: "-3%",
              marginBottom: "4%",
              width: "100%",
              color: "white",
              alignItems: "center",
              alignContent: "center",
              fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
              fontFamily: Theme.HEADER_FONT,
            }}
          >
            <button
              onClick={() =>
                this.setState({
                  tab: 0,
                  type: "Employee",
                  employeeType: "Ranger",
                })
              }
              style={tab === 0 ? styles.selectedButton : styles.navButton}
            >
              Tags
            </button>

            {(this.props.global.currentUser.type === "Admin" ||
              this.props.global.currentUser.type === "Manager") && (
              <button
                onClick={() =>
                  this.setState({ tab: 1, type: "Manager", employeeType: "" })
                }
                style={tab === 1 ? styles.selectedButton : styles.navButton}
              >
                Members Tags
              </button>
            )}
            {(this.props.global.currentUser.type === "Admin" ||
              this.props.global.currentUser.type === "Manager") && (
              <button
                onClick={() =>
                  this.setState({ tab: 5, type: "Manager", employeeType: "" })
                }
                style={tab === 5 ? styles.selectedButton : styles.navButton}
              >
                Members By Tag
              </button>
            )}
            <button
              onClick={() =>
                this.setState({
                  tab: 2,
                  type: "Employee",
                  employeeType: "CheckIn",
                })
              }
              style={tab === 2 ? styles.selectedButton : styles.navButton}
            >
              Send Message
            </button>
            <button
              onClick={() =>
                this.setState({
                  tab: 3,
                  type: "Employee",
                  employeeType: "CheckIn",
                })
              }
              style={tab === 3 ? styles.selectedButton : styles.navButton}
            >
              Events
            </button>
            <button
              onClick={() =>
                this.setState({
                  tab: 4,
                  type: "Employee",
                  employeeType: "CheckIn",
                })
              }
              style={tab === 4 ? styles.selectedButton : styles.navButton}
            >
              Event Calendar
            </button>
          </div>

          {tab === 0 && (
            <Tags global={this.props.global} history={this.props.history} />
          )}

          {tab === 1 && (
            <MembersTags
              global={this.props.global}
              history={this.props.history}
            />
          )}
          {tab === 2 && (
            <SendMessageTags
              global={this.props.global}
              history={this.props.history}
            />
          )}
          {tab === 3 && (
            <Events global={this.props.global} history={this.props.history} />
          )}
          {tab === 4 && (
            <EventCalendar
              global={this.props.global}
              history={this.props.history}
            />
          )}
          {tab === 5 && (
            <MemberFilterByTag
              global={this.props.global}
              history={this.props.history}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Marketing);
