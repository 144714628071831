import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import global from './redux/reducers/global'
import defaultState from "./redux/defaults";
import { reducer as formReducer } from 'redux-form'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const combined = combineReducers({
  form: formReducer,
  global,
})
const persistConfig = {
  key: 1,
  storage,
}
const persistedReducer = persistReducer(persistConfig, combined)

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    defaultState,
    applyMiddleware(thunk),
  )
  const persistor = persistStore(store)

  return { store, persistor }
}
