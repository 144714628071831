import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  UPDATE_VACCINATIONS,
  DELETE_VACCINATIONS,
} from "../../store/mutations.js";
import {
  LabeledTextBox,
  FileUpload,
  Button,
  PawIndicator,
  Dta,
  DatePickerBox,
} from "../../globalComponents/";
import HoverTag from "./HoverTag.js";
import { change } from "redux-form";

class VaccinationUpload extends Component {
  state = {
    vacs: [],
    currentVac: "Rabies",
    separateLeptospirosis: this.props?.dog.separateLeptospirosis,
    originalVacs: this.props.vacDates,
    dog: this.props.dog,
    uploading: false,
  };

  componentDidMount() {
    // console.log("-------------- Data ------------", this.props);
    // console.log("-------------- separateLeptospirosis ------------", this.props.dog.separateLeptospirosis);

    if (this.props.dog.separateLeptospirosis === true) {
      // console.log("******************* Case 0 ********************* ", this.props.dog.separateLeptospirosis);
      this.setState({ separateLeptospirosis: true });
    } else if (
      this.props.vacDates.leptospirosisDate &&
      this.props.vacDates.leptospirosisDate !== null
    ) {
      // console.log("******************* Case 1 ********************* ", this.props.dog.separateLeptospirosis);
      this.setState({ separateLeptospirosis: true });
    } else if (
      this.props.vacDates.leptospirosisDate === null &&
      (this.props.dog.separateLeptospirosis === false ||
        this.props.dog.separateLeptospirosis === null)
    ) {
      // console.log("******************* Case 2 *********************-- ", this.props.dog.separateLeptospirosis);

      this.setState({ separateLeptospirosis: false });
    } else if (
      this.props.vacDates.leptospirosisDate === null &&
      this.props.dog.separateLeptospirosis === true
    ) {
      // console.log("******************* Case 3 ********************* ", this.props.dog.separateLeptospirosis);

      this.setState({ separateLeptospirosis: true });
    } else {
      // console.log("******************* Case 4 ********************* ", this.props.dog.separateLeptospirosis);

      this.setState({ separateLeptospirosis: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dog.id !== this.state.dog.id) {
      this.setState({ dog: this.props.dog });
    }
    // console.log(prevProps.dog.separateLeptospirosis, "******************* Case 4 ********************* ", this.props.dog.separateLeptospirosis);
    if (
      this.props.dog.separateLeptospirosis !==
      prevProps.dog.separateLeptospirosis
    ) {
      this.setState({
        separateLeptospirosis:
          this.props.dog.separateLeptospirosis === null ||
          this.props.dog.separateLeptospirosis === false
            ? false
            : true,
      });
    }
  }

  handleFile = async (blob, file, dogId, type, vacDates) => {
    let tempFiles = this.state.vacs;
    tempFiles[this.state.currentVac] = {
      name: blob.filename,
      file: file,
      blob: blob,
    };

    if (
      blob.content_type === "image/jpeg" ||
      blob.content_type === "image/png" ||
      blob.content_type === "application/pdf"
    ) {
      const signedId = blob.signed_id;

      const datesToUpdate = [
        {
          type: "Rabies",
          expiresOn: vacDates.rabiesDate,
        },
        {
          type: "DHLPP",
          expiresOn: vacDates.dhlppDate,
        },
        {
          type: "Leptospirosis",
          expiresOn: vacDates.leptospirosisDate,
        },
        {
          type: "Bordetella",
          expiresOn: vacDates.bordetellaDate,
        },
      ];

      await this.props.client
        .mutate({
          mutation: UPDATE_VACCINATIONS,
          variables: {
            input: {
              dogId,
              entries: [
                {
                  type,
                  pictures: [signedId],
                },
                ...datesToUpdate,
              ],
            },
          },
        })
        .then((response) => {
          alert("Success!");
          // console.log("Response", response);
          const updatedDog = response.data.updateVaccinations.dog;
          // console.log(`vaccinationNew${this.props.selected}`);
          this.props.change(
            `vaccinationNew${this.props.selected}`,
            updatedDog.vaccinations
          );
          this.setVaccinations(updatedDog.vaccinations);
          this.setState({ dog: updatedDog, uploading: false });
        })
        .catch((error) => {
          console.error("Error: ", error);
        });

      // var formFiles = [];
      // for (var i = 0; i < tempFiles.length; i++) {
      //   formFiles.push(tempFiles[i].signed_id);
      // }

      // this.props.change("vaccinationPictures", formFiles);
      this.setState({ vacs: tempFiles });
    } else {
      alert(
        "Vaccination documents should be in the form of a pdf file, jpeg image, or png image."
      );
    }
  };

  handleDeleteVaccinations = async (dogId, type) => {
    await this.props.client
      .mutate({
        mutation: DELETE_VACCINATIONS,
        variables: {
          input: {
            dogId,
            types: [type],
          },
        },
      })
      .then((response) => {
        alert("Success!");
        const updatedDog = response.data.deleteVaccinations.dog;
        this.props.change(
          `vaccinationNew${this.props.selected}`,
          updatedDog.vaccinations
        );
        this.setVaccinations(updatedDog.vaccinations);
        this.setState({ dog: updatedDog });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  selectCurrentVac = (selectedVac) => {
    this.setState({ currentVac: selectedVac });
  };

  handleLeptospirosisCheckbox = () => {
    let newState = { ...this.state };
    newState.separateLeptospirosis = !newState.separateLeptospirosis;
    // console.log(" newState.separateLeptospirosis -------------------------", newState.separateLeptospirosis);
    this.props.change("leptospirosis", null);
    this.props.change("separateLeptospirosis", newState.separateLeptospirosis);
    this.setState({ separateLeptospirosis: newState.separateLeptospirosis });
  };

  handleUpdateVaccinations = (dogId, vacDates) => {
    const datesToUpdate = [
      {
        type: "Rabies",
        expiresOn: vacDates.rabiesDate,
      },
      {
        type: "DHLPP",
        expiresOn: vacDates.dhlppDate,
      },
      {
        type: "Leptospirosis",
        expiresOn: vacDates.leptospirosisDate,
      },
      {
        type: "Bordetella",
        expiresOn: vacDates.bordetellaDate,
      },
    ];
    this.props.client
      .mutate({
        mutation: UPDATE_VACCINATIONS,
        variables: {
          input: {
            dogId,
            entries: datesToUpdate,
          },
        },
      })
      .then((response) => {
        const updatedDog = response.data.updateVaccinations.dog;
        this.props.change("vaccinationNew", updatedDog.vaccinations);
        this.setVaccinations(updatedDog.vaccinations);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  setVaccinations = (vacs) => {
    for (let i = 0; i < vacs.length; i++) {
      if (vacs[i].type === "Rabies") {
        this.props.change("rabies", vacs[i].expiresOn);
      } else if (vacs[i].type === "DHLPP") {
        this.props.change("dhlpp", vacs[i].expiresOn);
      } else if (vacs[i].type === "Leptospirosis") {
        this.props.change("leptospirosis", vacs[i].expiresOn);
      } else if (vacs[i].type === "Bordetella") {
        this.props.change("bordetella", vacs[i].expiresOn);
      }
    }
  };

  checkForDateChanges = () => {
    if (this.state.originalVacs.rabiesDate !== this.props.vacDates.rabiesDate) {
      return true;
    } else if (
      this.state.originalVacs.dhlppDate !== this.props.vacDates.dhlppDate
    ) {
      return true;
    } else if (
      this.state.originalVacs.leptospirosisDate !==
      this.props.vacDates.leptospirosisDate
    ) {
      return true;
    } else if (
      this.state.originalVacs.bordetellaDate !==
      this.props.vacDates.bordetellaDate
    ) {
      return true;
    }
    // else if (
    //   this.state.separateLeptospirosis !==
    //   this.props.dog.separateLeptospirosis
    // ) {
    //   return true;
    // }
    return false;
  };

  renderVaccinationPicture = (type) => {
    const dogVacs = this.state.dog.vaccinations;
    for (let i = 0; i < dogVacs.length; i++) {
      if (dogVacs[i].type === type && dogVacs[i].pictures[0]) {
        const vacIndex = dogVacs[i].pictures.length - 1;
        return dogVacs[i].pictures[vacIndex].url;
      }
    }
    return false;
  };

  openDocument = (type) => {
    window.open(
      `${process.env.REACT_APP_API_ENDPOINT}${this.renderVaccinationPicture(
        type
      )}`
    );
  };

  isMobile = () => {
    // console.log("------");
    if (window.innerWidth < 700) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 725;

    const { vacs, currentVac, separateLeptospirosis, dog, uploading } =
      this.state;
    const { global, vacDates, change } = this.props;

    // console.log("Dog***********", dog)
    return (
      <Fragment>
        <div
          style={{
            display: shrink ? "block" : "flex",
            flexDirection: "row",
            width: shrink ? "90%" : "96%",
            margin: shrink ? "5% auto 2% auto" : "5% 0 2% auto",
            // marginTop: "10%"
          }}
        >
          <div
            style={{
              textAlign: "left",
              fontFamily: Theme.HEADER_FONT,
              fontSize: Theme.getHeaderFont(width),
              color: Theme.HEADER_FONT_COLOR,
              width: shrink ? "90%" : "65%",
              marginTop: "auto",
              marginBottom: shrink ? "15px" : "auto",
              marginRight: "5%",
              flexDirection: "column",
            }}
          >
            <div>Vaccinations</div>
            <div style={{ fontSize: "1rem" }}>
              (Enter Expiration Dates below)
            </div>
          </div>
          <div
            style={{
              display: shrink ? "block" : "flex",
              flexDirection: "row",
              width: shrink ? "90%" : "30%",
              justifyContent: "flex-end",
            }}
          >
            <FileUpload
              name="file"
              width={width}
              containerStyle={{
                marginTop: "auto",
                marginBottom: "auto",
              }}
              height={height}
              onUpload={(blob, file) => {
                this.setState({ uploading: true });
                this.handleFile(blob, file, dog.id, currentVac, vacDates);
              }}
              label={`Upload ${
                currentVac === "DHLPP" ? "DHPP" : currentVac
              } Document`}
            />
          </div>
        </div>
        <div
          style={{
            display: shrink ? "block" : "flex",
            flexDirection: this.isMobile() ? "column" : "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: shrink ? "block" : "inline-flex",
              flexDirection: "column",
              width: shrink ? "80%" : "31%",
              marginRight: shrink ? "auto" : "5%",
              marginLeft: shrink ? "auto" : "4%",
            }}
          >
            {/* <Button
              type="button"
              containerStyle={{
                margin: "auto",
                marginBottom: 20,
                marginTop: this.isMobile() ? 20 : ""
              }}
             
              text="Save Dates"
              width={width}
              onClick={() => {
                if (this.checkForDateChanges()) {
                  this.handleUpdateVaccinations(dog.id, vacDates);
                } else {
                  console.log("No changes");
                }
              }}
              disabled={!this.checkForDateChanges()}
            /> */}
            <div
              style={{
                display: "flex",
                position: "relative", // Add style for info hover
                flexDirection: "row",
                width: this.isMobile() ? "90%" : "100%",
                backgroundColor: "white",
                border: currentVac === "Rabies" ? "3px solid" : "1px solid",
                borderColor:
                  currentVac === "Rabies"
                    ? Theme.MAIN_COLOR
                    : Theme.LIGHT_TEXT_COLOR,
                borderRadius: 10,
                boxShadow: "3px 3px 10px #606267",
                padding: 10,
                marginBottom: 20,
              }}
              onClick={() => this.selectCurrentVac("Rabies")}
            >
              <div
                style={{
                  width: "85%",
                }}
              >
                {/* <LabeledTextBox
                  onBlur={() => { }}
                  name="rabies"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="Rabies"
                  info={<HoverTag text="Rabies vaccine is a vaccine used to prevent rabies for pets. When entering the date, make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record." />}
                /> */}
                <DatePickerBox
                  onBlur={() => {}}
                  name="rabies"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="Rabies Expiration Date"
                  info={
                    <HoverTag text="Rabies vaccine is a vaccine used to prevent rabies for pets. When entering the date, make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record." />
                  }
                  // placeholder="Rabies"
                  placeholder="MM/DD/YYYY"
                  change={change}
                  dateType="Vaccine"
                />
              </div>
              <div
                style={{
                  width: "10%",
                  marginLeft: "5%",
                  display: "flex",
                }}
              >
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <PawIndicator
                    active={
                      vacDates.rabiesDate &&
                      this.renderVaccinationPicture("Rabies")
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                position: "relative", // Add style for info hover
                flexDirection: "row",
                width: this.isMobile() ? "90%" : "100%",
                backgroundColor: "white",
                border: currentVac === "DHLPP" ? "3px solid" : "1px solid",
                borderColor:
                  currentVac === "DHLPP"
                    ? Theme.MAIN_COLOR
                    : Theme.LIGHT_TEXT_COLOR,
                borderRadius: 10,
                boxShadow: "3px 3px 10px #606267",
                padding: 10,
                marginBottom: 20,
              }}
              onClick={() => this.selectCurrentVac("DHLPP")}
            >
              <div
                style={{
                  width: "85%",
                }}
              >
                {/* <LabeledTextBox
                  onBlur={() => { }}
                  name="dhlpp"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label={`DHLPP ${separateLeptospirosis ? "" : "/ Leptospirosis"
                    }`}
                  info={<HoverTag text="DHLPP (Distemper, hepatitis, parainfluenza, parvovirus, and leptospirosis) is a core vaccine that protects pets against a number of serious contagious canine viral diseases. This combo vaccine can be listed as DHLPP, DA2PPv-L, DA2PP-L. When entering the date make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record" />}
                /> */}
                <DatePickerBox
                  onBlur={() => {}}
                  name="dhlpp"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label={`DHPP ${
                    separateLeptospirosis ? "" : "/ Leptospirosis" // Change Text DHLPP to DHPP
                  } Expiration Date`}
                  // Remove Text Leptospirosis
                  info={
                    <HoverTag text="DHPP (Distemper, hepatitis, parainfluenza and parvovirus ) is a core vaccine that protects pets against a number of serious contagious canine viral diseases. This combo vaccine can be listed as DHPP, DA2PPv-L, DA2PP-L. When entering the date make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record" />
                  }
                  // placeholder="DHLPP"
                  placeholder="MM/DD/YYYY"
                  change={change}
                  dateType="Vaccine"
                />
              </div>
              <div
                style={{
                  width: "10%",
                  marginLeft: "5%",
                  display: "flex",
                }}
              >
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <PawIndicator
                    active={
                      vacDates.dhlppDate &&
                      this.renderVaccinationPicture("DHLPP")
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            {/* <div style={{ width: "100%", margin: 10, marginBottom: 30 }}>
              <div style={{ display: "flex", position: "relative" }}>
                <button
                  onClick={e => this.handleLeptospirosisCheckbox(e)}
                  type="button"
                  style={{
                    width: 25,
                    height: 25,
                    border: "2px solid",
                    cursor: "pointer",
                    borderColor: Theme.LINK_FONT_COLOR,
                    outline: "none",
                    borderRadius: 5,
                    marginLeft: "-9px",
                    backgroundColor: separateLeptospirosis
                      ? Theme.LINK_FONT_COLOR
                      : "white"
                  }}
                >
                  {separateLeptospirosis ? (
                    <PawIndicator
                      active={false}
                      style={{
                        marginLeft: -5,
                        marginTop: 1,
                        width: 16,
                        height: 16,
                        color: "white"
                      }}
                    />
                  ) : null}
                </button>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                    width: "100%",

                  }}
                >
                  <span>Separate Leptospirosis Document</span>   <HoverTag text="Some vets give dogs the leptospirosis vaccine as its own separate vaccination. If your vaccination records show leptospirosis with its own expiration date, then click the separate leptospirosis document button and an orange paw icon will be show." />
                </div>
              </div>
            </div> */}

            {/* <CheckBox
                name="separateLeptospirosis"
                width={width}
                containerStyle={{
                  display: "flex",
                  alignItems: "center",
                  width: Theme.getLongInputWidth(width) / 1.4
                }}
                label="Separate Leptospirosis Document"
                onClick={e => this.handleLeptospirosisCheckbox(e)}
                value={separateLeptospirosis}
              /> */}
            {separateLeptospirosis && (
              <div
                style={{
                  display: "flex",
                  position: "relative", // Add style for info hover
                  flexDirection: "row",
                  width: this.isMobile() ? "90%" : "100%",
                  backgroundColor: "white",
                  border:
                    currentVac === "Leptospirosis" ? "3px solid" : "1px solid",
                  borderColor:
                    currentVac === "Leptospirosis"
                      ? Theme.MAIN_COLOR
                      : Theme.LIGHT_TEXT_COLOR,
                  borderRadius: 10,
                  boxShadow: "3px 3px 10px #606267",
                  padding: 10,
                  marginBottom: 20,
                }}
                onClick={() => this.selectCurrentVac("Leptospirosis")}
              >
                <div
                  style={{
                    width: "85%",
                  }}
                >
                  {/* <LabeledTextBox
                    onBlur={() => { }}
                    name="leptospirosis"
                    type="date"
                    widthpercent={"100%"}
                    width={width}
                    height={height}
                    label="Leptospirosis"
                    info={<HoverTag text="Leptospirosis is a shared water vaccine that protects pets against leptospires found in shared water sources. When entering the date, make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record." />}
                  /> */}
                  <DatePickerBox
                    onBlur={() => {}}
                    name="leptospirosis"
                    type="date"
                    widthpercent={"100%"}
                    width={width}
                    height={height}
                    label="Leptospirosis Expiration Date"
                    info={
                      <HoverTag text="Leptospirosis is a shared water vaccine that protects pets against leptospires found in shared water sources. When entering the date, make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record." />
                    }
                    // placeholder="Leptospirosis"
                    placeholder="MM/DD/YYYY"
                    change={change}
                    dateType="Vaccine"
                  />
                </div>
                <div
                  style={{
                    width: "10%",
                    marginLeft: "5%",
                    display: "flex",
                  }}
                >
                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                    <PawIndicator
                      active={
                        vacDates.leptospirosisDate &&
                        this.renderVaccinationPicture("Leptospirosis")
                      }
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative", // Add style for info hover
                width: this.isMobile() ? "90%" : "100%",
                backgroundColor: "white",
                border: currentVac === "Bordetella" ? "3px solid" : "1px solid",
                borderColor:
                  currentVac === "Bordetella"
                    ? Theme.MAIN_COLOR
                    : Theme.LIGHT_TEXT_COLOR,
                borderRadius: 10,
                boxShadow: "3px 3px 10px #606267",
                padding: 10,
                marginBottom: 20,
              }}
              onClick={() => this.selectCurrentVac("Bordetella")}
            >
              <div
                style={{
                  width: "85%",
                }}
              >
                {/* <LabeledTextBox
                  onBlur={() => { }}
                  name="bordetella"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="Bordetella"
                  info={<HoverTag text="Bordetella, also labeled as “kennel cough”, vaccine protects pets against Bordetella bronchiseptica. When entering the date make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record.                 "
                  />}
                /> */}
                <DatePickerBox
                  onBlur={() => {}}
                  name="bordetella"
                  type="date"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="Bordetella Expiration Date"
                  info={
                    <HoverTag text="Bordetella, also labeled as “kennel cough”, vaccine protects pets against Bordetella bronchiseptica. When entering the date make sure to enter the date your vaccination expires. Vets label this as “Next Due Date” or “Expiration Date” on a dog’s vaccination record. Having trouble finding the vaccine? Our check-in staff is knowledgeable on vet records and can assist in finding the correct record.                 " />
                  }
                  // placeholder="Bordetella"
                  placeholder="MM/DD/YYYY"
                  change={change}
                  dateType="Vaccine"
                />
              </div>
              <div
                style={{
                  width: "10%",
                  marginLeft: "5%",
                  display: "flex",
                }}
              >
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <PawIndicator
                    active={
                      vacDates.bordetellaDate &&
                      this.renderVaccinationPicture("Bordetella")
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              width: shrink ? "90%" : "60%",
            }}
          >
            <div
              style={{
                border: "2px solid grey",
                borderRadius: 5,
                height: "500px",
                width: "100%",
                backgroundColor: "white",
                position: "relative",
              }}
            >
              {uploading ? (
                <div style={{ textAlign: "center", padding: 20 }}>
                  Uploading...
                </div>
              ) : (
                <Fragment>
                  {this.renderVaccinationPicture(currentVac) ? (
                    <div>
                      <div className="vaccinationIFrame1">
                        <div className="vaccinationIFrame2">
                          <iframe
                            title={`${currentVac} Document`}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            seamless
                            src={`${
                              process.env.REACT_APP_API_ENDPOINT
                            }${this.renderVaccinationPicture(currentVac)}`}
                          ></iframe>
                        </div>
                      </div>
                      <div style={{ position: "absolute", top: 0, left: 0 }}>
                        <Button
                          type="button"
                          containerStyle={{ margin: "auto" }}
                          text="Open Document"
                          width={width}
                          onClick={() => {
                            this.openDocument(currentVac);
                          }}
                        />
                      </div>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <Button
                          type="button"
                          containerStyle={{ margin: "auto" }}
                          text="Delete Document"
                          width={width}
                          onClick={() => {
                            this.handleDeleteVaccinations(dog.id, currentVac);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center", padding: 20 }}>
                      {currentVac === "DHLPP" ? "DHPP" : currentVac} document
                      has not been uploaded yet. Click on the upload button to
                      upload this document.
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(VaccinationUpload);
