import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/redux.js";
import { ApolloProvider } from "react-apollo";
import client from "./store/apollo.js";
import { PersistGate } from "redux-persist/integration/react";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const config = {
  apiKey: "hbp_sk4v6RNOUZlCHIXi5IOclGNj311lcK37FEW9",
  environment: process.env.REACT_APP_SERVER,
};

const honeybadger = Honeybadger.configure(config);

const { store, persistor } = configureStore();

Sentry.init({
  dsn: "https://5715b25581324ec18279295531373fb1@o523678.ingest.sentry.io/5635932",
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <HoneybadgerErrorBoundary honeybadger={honeybadger}>
          <App />
        </HoneybadgerErrorBoundary>
      </Provider>
    </PersistGate>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
