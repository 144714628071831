import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_RANGERS } from "../../store/queries.js";
import { Query } from "react-apollo";
import {
  Table,
  Theme,
  StaffNavBar,
  PawIndicator,
} from "../../globalComponents";
import { updateUser } from "../../store/redux/actions";
import Bg from "../../assets/fetch-BG.svg";
import moment from "moment";
import CheckedNeutered from "../../assets/check_neutered.svg";
import { PulseLoader } from "react-spinners";

class Rangers extends Component {
  state = {
    type: "Employee",
    employeeType: "Ranger",
    per: 20,
    page: 1,
    tab: 0,
  };

  render() {
    const { type, per, page, tab, employeeType } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = window.innerHeight;

    const width = obj.dimensions.width;
    const shrink = width < 700;
    const columnNames = [
      "status",
      "training",
      "date",
      "name",
      "location",
      "positions",
      "action",
    ];

    const columns = {
      box: {
        label: "",
        renderCell: () => <input type="checkbox" />,
      },
      status: {
        label: "Status",
        renderCell: (data) => (
          <div
            style={{
              display: shrink ? "block" : "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                // fontSize: Theme.getNormalFont(width),
                fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginRight: "10%",
              }}
            >
              {data.deactivated ? "Inactive" : "Active"}
            </div>
            <PawIndicator
              active={!data.deactivated}
              style={{ marginLeft: "5%", display: shrink ? "block" : " " }}
            />
          </div>
        ),
      },
      training: {
        label: "Training",
        renderCell: (data) => (
          <div
            style={{
              display: shrink ? "block" : "flex",
              flexDirection: "row",
            }}
          >
            <button
              type="button"
              style={{
                width: 25,
                height: 25,
                border: "2px solid",
                cursor: "pointer",
                borderColor: "#49c172",
                outline: "none",
                borderRadius: 5,
                padding: "0px",
                background: data.isTrainee ? "#49c172" : "transparent",
                marginLeft: "5%",
                display: shrink ? "block" : " ",
              }}
            >
              {data.isTrainee ? (
                <img
                  active={false}
                  alt="Active"
                  src={CheckedNeutered}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : null}
            </button>
          </div>
        ),
      },
      date: {
        label: "Hire Date",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {!data.hireDate
              ? "-"
              : moment(data.hireDate, "YYYY-MM-DD").format("L")}
          </div>
        ),
      },
      name: {
        label: "Name",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.firstName || data.lastName
              ? (data.firstName || "") + " " + (data.lastName || "")
              : "Not Set!"}
          </div>
        ),
      },
      location: {
        label: "Location",
        renderCell: (data) => {
          return (
            <div
              style={{
                color: Theme.DARK_TEXT_COLOR,
                fontWeight: 700,
                // fontSize: Theme.getNormalFont(width),
                fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                fontFamily: Theme.INPUT_FONT,
              }}
            >
              {data.park ? data.park.name : "No Park Set"}
            </div>
          );
        },
      },
      positions: {
        label: "Positions",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.positions[0] ||
              (this.state.type === "Manager"
                ? "Manager"
                : this.state.employeeType === "CheckIn"
                ? "CheckIn"
                : "Ranger")}
          </div>
        ),
      },
      action: {
        label: "Action",
        renderCell: () => (
          <button
            style={{
              fontWeight: 100,
              outline: "none",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color: Theme.MAIN_COLOR,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "12px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
              paddingLeft: 0,
            }}
          >
            View
          </button>
        ),
      },
    };

    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        margin: "auto 2% auto 2%",
        color: "white",
        fontFamily: Theme.INPUT_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "white",
        borderRadius: 5,
        padding: 10,
        border: "none",
        color: Theme.MAIN_COLOR,
        margin: "auto 2% auto 2%",
        fontFamily: Theme.HEADER_FONT,
        // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
      deactivate: {
        outline: "none",
        backgroundColor: "rgba(250,250,250, .3)",
        borderRadius: 5,
        padding: 5,
        border: "none",
        color: "white",
        margin: "auto",
        marginRight: 10,
        fontFamily: Theme.HEADER_FONT,
        // fontSize: Theme.getSmallFont(this.props.global.dimensions.width),
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
      },
    };

    return (
      <div
        style={{
          height: this.props.global.dimensions.height,
          backgroundColor: Theme.BACKGROUND_COLOR,
          backgroundRepeat: "round",
          backgroundImage: `url(${Bg})`,
        }}
      >
        <StaffNavBar
          tab={3}
          history={this.props.history}
          width={this.props.global.dimensions.width}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            width: "90%",
            justifyContent: "center",
            marginTop: -80,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              marginRight: "auto",
              display: "flex",
              flexDirection: "row",
              marginTop: "-3%",
              marginBottom: "4%",
              width: "100%",
              color: "white",
              alignItems: "center",
              alignContent: "center",
              // fontSize: Theme.getHeaderFont(width),
              fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
              fontFamily: Theme.HEADER_FONT,
            }}
          >
            <button
              onClick={() =>
                this.setState({
                  tab: 0,
                  type: "Employee",
                  employeeType: "Ranger",
                })
              }
              style={tab === 0 ? styles.selectedButton : styles.navButton}
            >
              Rangers
            </button>
            {(this.props.global.currentUser.type === "Admin" ||
              this.props.global.currentUser.type === "Manager") && (
              <button
                onClick={() =>
                  this.setState({ tab: 1, type: "Manager", employeeType: "" })
                }
                style={tab === 1 ? styles.selectedButton : styles.navButton}
              >
                Managers
              </button>
            )}
            <button
              onClick={() =>
                this.setState({
                  tab: 2,
                  type: "Employee",
                  employeeType: "CheckIn",
                })
              }
              style={tab === 2 ? styles.selectedButton : styles.navButton}
            >
              Check In
            </button>

            <button
              onClick={() => this.props.history.push("new/ranger")}
              style={{
                outline: "none",
                backgroundColor: "rgba(250,250,250, .3)",
                borderRadius: 5,
                padding: "5px 10px 5px 10px",
                border: "none",
                marginLeft: "auto",
                color: "white",
                fontFamily: Theme.HEADER_FONT,
                // fontSize: Theme.getSmallFont(width),
                fontSize: shrink ? "12px" : Theme.getSmallFont(width),
                cursor: "pointer",
              }}
            >
              Add Staff Member
            </button>
          </div>

          <Query
            fetchPolicy="no-cache"
            query={FETCH_RANGERS}
            variables={{
              type,
              page,
              per,
              employeeType,
            }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div
                    name="container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // width: width,
                      minHeight: height,
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      backgroundColor: Theme.BACKGROUND_COLOR,
                      backgroundRepeat: "round",
                      backgroundImage: `url(${Bg})`,
                    }}
                  >
                    <PulseLoader
                      style={{ margin: "auto" }}
                      size={width / 10}
                      color={Theme.LINK_FONT_COLOR}
                      loading={loading}
                    />
                  </div>
                );
              if (error) return `Error: ${error.message}`;
              console.log("Loading", loading, data);
              if (!loading)
                return (
                  <Table
                    total={!loading && data.fetchUsers.totalCount}
                    loading={loading}
                    per={per}
                    page={page}
                    onPage={(page) => this.setState({ page: page })}
                    onPer={(per) => this.setState({ per: per })}
                    containerStyle={{ flex: 1 }}
                    names={columnNames}
                    pages={["", "", ""]}
                    alt={true}
                    columns={columns}
                    rows={!loading && data.fetchUsers.edges}
                    onClick={(id) => {
                      this.props.updateUser({ selectedUser: id });
                      this.props.history.push("/profile", { view: "ranger" });
                    }}
                  />
                );
            }}
          </Query>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Rangers);
