import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { OnboardingHeader, PaymentForm } from "../../../globalComponents/";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { USER_DETAILS } from "../../../store/queries";
import { USER_UPDATE } from "../../../store/mutations";
import cookie from "js-cookie"
import { updateUser } from "../../../store/redux/actions";

class Payment extends Component {
  constructor(props) {
    super(props)
    const { dogId, isRecurring, packageId, onboardingDogIdx, allDogIds, forUserId, packageIds } = queryString.parse(props.location.search);
    console.log({ dogId, isRecurring, packageId })
    this.state = {
      dogId,
      packageId,
      isRecurring,
      onboardingDogIdx,
      currentUserDogs: [],
      allDogIds, forUserId,
      packageIds,
      dogPaymentDetails: []
    };
  }

  onCancel = () => {
    // this.props.history.push("packages");
     this.props.history.push((this.props.history.location?.state?.from + this.props.history.location.search ) || "/login")

  };
  componentDidMount() {

    this.getCurrentUserDogs()
    this.createDogpayment()
  }

  onUserUpdated = () => {
    console.log(" ** onUserUpdated **")
    if (
      this.state.onboardingDogIdx != undefined
      && this.state.onboardingDogIdx >= 0
    ) {
      console.log(" ***************** Finish ***********************");
      // for onboarding we have to loop through all the dogs to selet packages
      const currentIndex = Number(this.state.onboardingDogIdx)
      if (currentIndex == this.state.currentUserDogs.length - 1) {
        // we have finished iterating all dogs of the user
        // Add for steps Update start 
        console.log("---->Cj\heck", this.props.global);

        let userId = this.props.global.currentUser.selected || this.props.global.currentUser?.id;

        this.props.client.mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 8 } } })
          .then(({ data: { updateUser } }) => {
            console.log("response", updateUser);
            // props.history.push("dogInfo");
            // props.history.push("vaccinations")
            // this.props.history.push("confirmAccount");
            this.props.history.push({
              pathname: `confirmAccount`,
              state: { from: this.props.location.pathname }
            });

          })
          .catch(error => {
            console.error("Error: ", error);
          });
        // Add for steps Update End 
      }
      else {
        console.log("Next");
        const nextOnboardingDogIdx = Number(this.state.onboardingDogIdx) + 1
        const queryParamObj = {
          onboardingDogIdx: nextOnboardingDogIdx,
          forUserId: this.state.forUserId,
          allDogIds: this.state.allDogIds,
          dogId: this.state.dogId
        }
        this.props.history.push({
          pathname: `/members/onboarding/packages?${queryString.stringify(queryParamObj)}`,
          state: { from: this.props.location.pathname }
        });
        // this.props.history.push(`/members/onboarding/packages?${queryString.stringify(queryParamObj)}`);
      }
    }
    else {
      console.log(" ***************** Finish Else ***********************");

      // this.props.history.push("confirmAccount");
      this.props.history.push({
        pathname: `confirmAccount`,
        state: { from: this.props.location.pathname }
      });
    }
  };

  getCurrentUserDogs() {
    const { client, global, closeModal } = this.props;
    const { currentUser } = global;
    if (this.state.forUserId) {
      const values = {
        id: Number(this.state.forUserId)
      }
      client
        .query({ query: USER_DETAILS, variables: values })
        .then(response => {
          const { data: { userDetail: { dogs } } } = response
          console.log("Hiy", response);

          this.setState({
            currentUserDogs: dogs
          })
        })
        .catch(error => {
          console.log("Hiyssss");

          // console.error("Error;;;;: ", error);
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          return false;
        })
    }
  }


  createDogpayment = () => {
    let new_array = []
    let package_array = this.state.packageIds.split(",")
    let dog_array = this.state.allDogIds.split(",")

    // console.log("Alll  Split Adog Id", dog_array);
    // console.log("All Split Package IDs--->", package_array);
    // console.log("This------->", this.state.allDogIds );

    let array3 = [];
    for (let ii = 0; ii < dog_array.length; ii++) {
      array3.push({ dogId: Number(dog_array[ii]) });
    }
    for (let jj = 0; jj < package_array.length; jj++) {
      if (array3[jj]) {
        array3[jj].packageId = package_array[jj];
      } else {

        array3.push({ packageId: package_array[jj] });

      }
    }
    this.setState({
      dogPaymentDetails: array3
    })
    console.log(array3);
  }

  render() {
    const { history } = this.props;
    console.log("Alll  Split Adog Id",history);
    // console.log("All Split Package IDs--->", package_array);
    // console.log("Alll Adog Id", this.state.allDogIds);
    // console.log("All Package IDs--->", this.state.packageIds);
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={7}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center"
        }}
        checkInStatus={false}
      >
        <PaymentForm
          onCancel={this.onCancel}
          dogId={this.state.dogId}
          packageId={this.state.packageId}
          isRecurring={this.state.isRecurring}
          onUserUpdated={this.onUserUpdated}
          dogPaymentDetails={this.state.dogPaymentDetails}
          forUserId={this.state.forUserId}
        />
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

Payment = reduxForm({
  form: "Payment"
})(Payment);
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Payment);
