import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { LabeledTextBox, TextBox, Button, DogTable } from "../../globalComponents";
import { connect } from "react-redux";
import Logo from "../../assets/fetch-logo.svg";
import Bg from "../../assets/fetch-BG.svg";
import { compose, Query, withApollo } from "react-apollo";
import { reduxForm, SubmissionError } from "redux-form";
import { VERIFY_CODE } from "../../store/mutations.js";
import { CHECK_IN_PHONE_USER } from "../../store/queries.js";
import CheckedNeutered from "../../assets/check_neutered.svg"
import Warning from "../../assets/paw_warning.svg";
import Vaccinated from "../../assets/active_green.svg";
import Expired from "../../assets/paw-expired.svg";
import Active from "../../assets/paw-active.svg";
import Inactive from "../../assets/active_green.svg";
import DogPlaceholder from "../../assets/dog_placeholder.png"
import moment from "moment"
import cookie from "js-cookie";
import { PulseLoader } from "react-spinners";
import PhoneDogTable from "../../globalComponents/FormComponents/PhoneDogTable/index";
import { uniq as _uniq } from "lodash"

class Code extends Component {

  state = {
    page: 1,
    per: Math.ceil(window.innerHeight / 100),
    // per: 5,
    search: "",
    checkingIn: {},
    checkingInDogs: {},
    membersInPark: [],
    checkInButtonDisabled: false,
    park: "",
    parkId: "",
    selectedUserId: "",
    selectedNotes: [],
    spayedStatus: false,
    selectedSpayed: "",
    spayedDog: {},
    isLoading: false,
    userDetail: "",
    membersList: [],
    selectedFiltered: {}
  };
  componentDidMount() {
    this.getUserDetail()
  }


  getUserDetail = async () => {
    let { created_by, by_type, date, page, per } = this.state;
    this.setState({
      isLoading: true
    })
    let variables = {
      contact: this.props.location.state.phoneNumber
    }

    this.props.client
      .query({ query: CHECK_IN_PHONE_USER, variables: variables, fetchPolicy: "no-cache" })
      .then(response => {
        console.log("Response", response);

        this.setState({
            userDetail: response.data?.getUser,
            isLoading: false,
        })
      })
      .catch(error => {
        console.error("Error: ", error);
        return false;
      })
  }
  handleActiveStatus = (confirmed, subActive, subPaused, isBanned) => {
    let status = {
      text: "",
      icon: null
    };
    if (isBanned) {
      status.text = "Banned";
      status.icon = Active;
    }
    else if (subPaused) {
      status.text = "Paused";
      status.icon = Active;
    }
    else if (confirmed && subActive) {
      status.text = "Active";
      status.icon = Active;
    } else if (!confirmed) {
      status.text = "Not Confirmed";
      status.icon = Inactive;
    } else if (!subActive) {
      status.text = "Inactive";
      status.icon = Inactive;
    }

    return status;
  };


  selectFilteredName = (_event, id) => {
    let { selectedFiltered } = this.state;
    if (id in selectedFiltered) {
      delete selectedFiltered[id];
    } else {
      selectedFiltered[id] = (id)
    }

    console.log("------->", selectedFiltered);
    this.setState({ selectedFiltered });

    let selectedFilteredsName = []
    const checkedFiltereds = Object.entries(selectedFiltered)
    const membersIds = _uniq(checkedFiltereds.map(val => val[1]))
    for (const userId of membersIds) {
      selectedFilteredsName.push(userId)
    }

    let dogCount = selectedFilteredsName.length
    this.props.change("dogIds", selectedFilteredsName)
    console.log(dogCount, "--------Selected---------", selectedFilteredsName);
    this.props.change("dogCount", dogCount)
    // name="dogCount"

  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, error } = this.props;
    const columnNames = ["box", "status", "type"];
    const shrink = width > 991;
    const verySmall = width < 568;
    const TillTab = width < 1024;
    console.log(this.props.global);
    const { page, per, checkingInDogs, userDetail, selectedFiltered } = this.state;
    const small = width < 1170;


    const dogColumns = {

      box: {

        label: "Check-in ",
        renderUserName: data => {
          return (
            <td colSpan="3">
              {`${data.firstName} ${data.lastName}`}
            </td>
          )
        },
        renderCell: data => {
          const { id: dogId, confirmed, vaccineStatus, pausedSubscription, isBanned } = data;
          return (!isBanned && ((confirmed && vaccineStatus !== "expired") || pausedSubscription)) ? (
            <div style={{
              display: 'flex',
              //  width: '210px',
              width: "100%",
              flexDirection: 'row'
            }}>
              <div
                style={{
                  display: "inline-flex",
                  width: "15%",
                  flexDirection: "column"
                }}>
                <input
                  style={{
                    width: "20px",
                    // width:"100%",
                    height: "20px"
                  }}
                  type="checkbox"
                  // checked={checkingInDogs[dogId] || false}
                  checked={selectedFiltered[data.id]}
                  // onChange={e => this.selectBulkCheckboxForDogs(e, data)}
                  onClick={(e) => this.selectFilteredName(e, data.id)}
                />
              </div>
              <div style={{
                display: "inline-flex",
                // width: "165px",
                width: "60%",
                flexDirection: "column",
                padding: "0px 2px",
              }}>
                <span style={{
                  lineHeight: TillTab ? "14px" : "26px",
                  marginLeft: "10px",
                  fontSize: TillTab ? "12px" : "14px",
                }}>
                  {data.name}
                </span>
              </div>
              <div style={{
                display: "inline-flex",
                // width: "45px",
                width: "25%",
                flexDirection: "column",
                overflow: "hidden",
                borderRadius: "5px",
                height: "100%",
                marginLeft: "3px",
              }}>
                <img
                  key={data.id}
                  alt={data?.profilePicture?.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }
                  }
                  src={process.env.REACT_APP_API_ENDPOINT + data?.profilePicture?.url
                  }
                  onError={(event) => event.target.setAttribute("src", DogPlaceholder)}

                />
              </div>
            </div>
          ) : (
              <div style={{
                display: 'flex',
                width: "100%",
                flexDirection: 'row'
              }}>
                <div
                  style={{
                    display: "inline-flex",
                    width: "15%",
                    flexDirection: "column"
                  }}>
                  <input
                    style={{
                      width: "20px",
                      height: "20px"
                    }}
                    type="checkbox"
                    checked={false}
                    disabled
                  />
                </div> <div style={{
                  display: "inline-flex",
                  width: "60%",
                  flexDirection: "column",
                  padding: "0px 2px",
                }}>
                  <span style={{
                    lineHeight: TillTab ? "14px" : "26px",
                    marginLeft: "10px",
                    fontSize: TillTab ? "12px" : "14px",
                  }}>
                    {data.name}
                  </span>
                </div>
                <div style={{
                  display: "inline-flex",
                  // width: "45px",
                  width: "25%",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderRadius: "5px",
                  height: "100%",
                  marginLeft: "3px",
                }}>
                  <img
                    key={data.id}
                    alt={data?.profilePicture?.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }
                    }
                    src={
                      process.env.REACT_APP_API_ENDPOINT + data?.profilePicture?.url
                    }
                    onError={(event) => event.target.setAttribute("src", DogPlaceholder)}
                  />
                </div>
              </div>
            );
        }

      },

      status: {
        label: "Status",
        onClickFunction: e => e.preventDefault(),
        renderCell: data => (
          <div
            style={{
              display: TillTab ? "block" : "flex",
              cursor: "pointer",
              flexDirection: `${width > 1000 ? "row" : "column"}`
            }}
          // onClick={() => this.displayMember(data)}
          >
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: `${width > 1000 ? "12" : "12"}px`,
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginLeft: `${width > 1000 ? "10%" : "auto"}`,
                textAlign: "left",
                marginTop: "auto",
                marginBottom: "auto",
                display: TillTab ? "inline-block" : " ",
                float: TillTab ? "left" : " "
              }}
            >
              {
                this.handleActiveStatus(data.confirmed, data.subscriptionActive, data.pausedSubscription, data.isBanned)
                  .text
              }
            </div>
          </div>
        )
      },
      type: {
        label: "Member Type",
        renderCell: data => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer"
            }}
            onClick={() => this.displayMember(data)}
          >
            {data.packageName || "No Package"}
          </div>
        )
      }
    };
    return (
      <form>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundRepeat: "round",
            backgroundColor: Theme.BACKGROUND_COLOR,
            height: height,
            backgroundImage: `url(${Bg})`
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
              height: "60%",
              width: "40%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              alt=""
              src={Logo}
              style={{ height: "35%", marginBottom: "10%" }}
            />
            <div
              style={{
                marginBottom: "2%",
                fontSize: Theme.getHeaderFont(width),
                fontFamily: Theme.HEADER_FONT,
                color: Theme.HEADER_FONT_COLOR
              }}
            >
              Check In
            </div>

            <TextBox
              name="code"
              width={Theme.getInputWidth(width)}
              height={height / 20}
              placeholder="XXXX"
              center={true}
              type="text"
              containerStyle={{ margin: "2% 0 2% 0" }}
            />
            {/* <LabeledTextBox
              name="dogCount"
              width={width}
              height={height}
              center={true}
              type="number"
              containerStyle={{ margin: "2% 0 2% 0" }}
              label="Dogs:"
            /> */}

            {
              userDetail && <PhoneDogTable
                containerStyle={{ flex: 1, marginLeft: small ? 0 : "5%" }}
                names={columnNames}
                columns={dogColumns}
                user={userDetail}
                onClick={() => { }}
                width={width}
              />
            }

            {error && (
              <div
                style={{
                  width: "60%",
                  margin: "1em auto",
                  textAlign: "center"
                }}
              >
                <strong style={{ color: "red" }}>
                  {`${error}. Please see a staff member!`}
                </strong>
              </div>
            )}
            <Button
              text="Submit"
              fullwidth={true}
              width={width}
              onClick={handleSubmit}
            // onClick={this.handleSubmitStatus}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = ({ code, dogCount, dogIds }, dispatch, props) => {
  const { phoneNumber } = props.location.state;

  console.log(code, "DogIds", dogIds);

  if (dogCount > 0 && code) {
    return props.client
      .mutate({
        mutation: VERIFY_CODE,
        variables: {
          input: {
            phoneNumber,
            verificationCode: Number(code),
            dogCount,
            dogIds
          }
        }
      })
      .then(() => {
        props.history.push({
          pathname: "/checkin/complete"
        });
      })
      .catch(error => {
        if (error?.graphQLErrors) {
          throw new SubmissionError({ _error: error.graphQLErrors[0].message });
        } else {
          console.log(error);
        }
      });
  }
  else {
    if (!code) {
      alert("Please Enter Verification code.")
    }
    else if (dogCount <= 0) {
      alert("Please selectdog for check In.")
    }

  }

};

Code = reduxForm({
  form: "VerifyCode",
  onSubmit: formSubmit
})(Code);

export default compose(
  withApollo,
  connect(mapStateToProps)
)(Code);
