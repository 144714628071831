import React, { Component } from "react";
import Theme from "../Theme.js";
import { LabeledTextBox } from "../index.js";

export default class SelectAddress extends Component {
    state = {
        open: false
    };

    componentWillMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    handleClick = e => {
        if (e.target) {
            try {
                if (this.node.contains(e.target)) {
                    return;
                } else {
                    this.setState({ open: false });
                }
            } catch (error) { }
        }
    };

    render() {
        const {
            label,
            selected,
            name,
            width,
            options,
            containerStyle = {},
            alt,
            icon,
            handleSelect,
            height,
            validate

        } = this.props;

        const { open } = this.state;
        return (
            <div
                ref={node => (this.node = node)}
                style={Object.assign({}, containerStyle, {
                    position: "relative"
                })}
                onClick={() => this.setState({ open: !open })}
            >
                <LabeledTextBox
                    name={name}
                    onBlur={() => { }}
                    width={width}
                    widthpercent={"100%"}
                    height={height}
                    // height="37px"
                    label="Address"
                    validate={validate}

                    onChange={(e) => { this.props.getAddressSuggestion(e); }}
                />

                <div
                    style={
                        open
                            ? {
                                backgroundColor: "white",
                                width: `99.9%`,
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid",
                                borderTop: "none",
                                borderColor: alt ? "black" : Theme.MAIN_COLOR,
                                marginTop: "-10px",
                                maxHeight: height / 4,
                                overflow: "auto",
                                position: "absolute",
                                fontSize: "18px",
                                fontFamily: Theme.INPUT_FONT,
                                zIndex: 1000
                            }
                            : { display: "none" }
                    }
                >


                    {/* Address List */}
                    {options &&
                        options.map((option, index) => {
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={() => {
                                        handleSelect(option, name);
                                        this.setState({ open: false });
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        textAlign: "left",
                                        fontSize: "18px",
                                        backgroundColor: "white",
                                        outline: "none",
                                        borderBottom: "1px solid #ccc",
                                        padding: ".5em",
                                        minHeight: 43,
                                        fontFamily: Theme.INPUT_FONT,
                                    }}
                                >
                                    {option.description}
                                </button>
                            )
                        }
                        )}
                </div>
            </div>
        );
    }
}
