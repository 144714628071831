import React, { Component } from "react";
import { Query } from "react-apollo";
import { Button, Select, Theme } from "../../../globalComponents";
import ParkSelect from "../../../globalComponents/FormComponents/parkSelect";
import { PARK_CLOSURE } from "../../../store/mutations";
import { PARK_NAMES } from "../../../store/queries";

class ParkClosure extends Component {
    state = {
        parkName: "Select Park",
        parkId: "",
        message: "",
        isSubmit: false

    };

    handleMessage = (event) => {
        this.setState({
            message: event.target.value
        })
    }

    handleParkClosure = () => {

        let { parkId, message } = this.state;
        if (parkId && message) {
            let newValues = {
                parkId,
                message
            }
            this.props.client
                .mutate({
                    mutation: PARK_CLOSURE, variables: {
                        input: newValues
                    }
                })
                .then(({ data: { updateUser } }) => {

                    alert("Success")
                    this.setState({
                        parkId: "",
                        message: "",
                        parkName: "Select Park"
                    })

                })
                .catch(error => {
                    console.error("Error: ", error);
                });
        }
        else {
            alert("Please Fill all fields.")
        }
    }

    render() {
        const { width, height } = this.props;
        const shrink = width < 700;
        const textFontSize = Theme.getNormalFont(width);
        let { parkName, parkId, message, isSubmit } = this.state;
        return (
            <div
                style={{
                    marginTop: 50,
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "90%",
                    alignItems: "center",
                    alignContent: "center"
                }}
            >
                <div
                    style={{ marginTop: 75, marginLeft: "auto", marginRight: "auto" }}
                >
                    <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                        {({ loading, error, data, refetch }) => {
                            if (loading) return <div></div>;
                            if (error) return `Error: ${error.message}`;
                            if (!loading) {
                                return (
                                    <div>
                                        <div style={{
                                            width: shrink ? "80%" : "50%",
                                            margin: "auto"
                                        }}>
                                            <ParkSelect
                                                alt={true}
                                                options={data?.restrictedFetchParks}
                                                handleSelect={(park, name) => {
                                                    this.setState({
                                                        parkName: park.name,
                                                        parkId: park.id
                                                    })
                                                }}
                                                selected={parkName}
                                                name="parkId"
                                                width={"100%"}
                                                height={height / 25}
                                                label="Park Location"
                                            />
                                        </div>
                                    </div>
                                );
                            }

                        }}
                    </Query>


                    <div style={{ marginTop: 20 }}>
                        <div style={{
                            width: shrink ? "80%" : "50%",
                            margin: "auto"
                        }}>
                            <div
                                style={{
                                    fontSize: (height / 25) / 2.2,
                                    fontFamily: Theme.HEADER_FONT,
                                    color: true ? Theme.DARK_TEXT_COLOR : "white",
                                    marginBottom: "10px"
                                }}
                            >
                                Message
                                    </div>
                            <textarea
                                value={message}
                                style={{
                                    outline: "none",
                                    resize: "none",
                                    borderRadius: 5,
                                    width: "100%",
                                    height: width / 6,
                                    fontSize: textFontSize
                                }}
                                onChange={this.handleMessage}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: Theme.getButtonWidth(width),
                            margin: "auto",
                            marginTop: 25
                        }}
                    >
                        <Button
                            text="Submit"
                            alt={false}
                            width={width}
                            onClick={() => this.handleParkClosure()}
                        />
                    </div>
                </div>
            </div>


        )
    }
}

export default ParkClosure;