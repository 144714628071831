import React, { Component } from "react";
import Theme from "../Theme.js";

export default class ActivityCard extends Component {
  getHeight(width) {
    if (width > 450) return 100;
    else if (width > 400) return 80;
    return 60;
  }
  getWidth(width) {
    if (width > 450) {
      return 160;
    } else if (width > 400) {
      return 120;
    }
    return 90;
  }
  getColor(type) {
    if (type === "checkin") return Theme.MAIN_COLOR;
    else if (type === "signup") return Theme.GREEN_COLOR;
    else if (type === "team") return "black";
  }
  getDescription(type) {
    if (type === "checkin") return "Total Members in Park";
    else if (type === "signup") return "New Member Sign ups";
    else if (type === "team") return "Team Clocked In";
  }
  render() {
    const { type, stat, width, containerStyle } = this.props;

    return (
      <div style={containerStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            boxShadow: "0px 0px 10px grey",
            alignItems: "center",
            width: width,
            textAlign: "left"
          }}
        >
          <div
            style={{
              color: this.getColor(type),
              fontSize: width / 3
            }}
          >
            {stat}
          </div>
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: width / 15,
              margin: "4%"
            }}
          >
            {this.getDescription(type)}
          </div>
        </div>
      </div>
    );
  }
}
