import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../";
import Tag from "./Tag.js";
import { ADD_TAG, UPDATE_TAG } from "../../store/mutations";
import { reduxForm } from "redux-form";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";

const styles = {
    pageContainer: {
        // border: "solid blue",
        position: "absolute",
        display: "flex",
        flex: 1,
        alignItems: "center",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0,0,0,.2)",
        height: "100vh",
        width: "100vw",
        zIndex: 100
    },

    modalContainer: function (width) {
        return {
            // border: "solid green",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",

            marginLeft: "auto",
            marginRight: "auto",
            padding: "1vw",
            height: "auto",
            width: width,
            fontSize: Theme.getNormalFont(width),
            fontFamily: Theme.HEADER_FONT,
            backgroundColor: "white",
            color: Theme.DARK_TEXT_COLOR,
            boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
        };
    },

    header: {
        borderBottom: "3px solid",
        borderBottomColor: Theme.INACTIVE_GREY_COLOR,
        width: "97%",
        textAlign: "center",
        fontSize: "30px",
        marginBottom: "5px"
    },

    heading: {
        // borderBottom: "3px solid",
        // borderBottomColor: Theme.INACTIVE_GREY_COLOR,
        // width: "97%",
        marginBottom: "10px",
        marginTop: "10px"

    },


    subtext: function (width) {
        return {
            fontSize: Theme.getNormalFont(width),
            color: Theme.LIGHT_TEXT_COLOR
        };
    },

    tagSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "1vw",
        paddingLeft: "0"
    },

    tagListContainer: function (width) {
        return {
            listStyleType: "none",
            margin: "0",
            width: width / 1.2,
            paddingLeft: "1vw",
            color: Theme.INACTIVE_GREY_COLOR
        };
    },

    textArea: function (width, textFontSize) {
        return {
            resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
            borderRadius: 5,
            height: width / 6, // UPDATE HEIGHT CALULCATION
            fontSize: textFontSize
        };
    },

    submitButtonWrapper: {
        alignSelf: "flex-end",
        margin: ".5em 0"
    }
};

class TagModal extends Component {
    state = {
        hover: false,
        tagDescription: "",
        tagTitle: "",
        tagId: "",
        width: 0,
        height: 0,
    };

    componentDidMount() {
        const { tagData } = this.props;
        console.log("---", tagData);
        if (tagData) {
            this.setState({
                tagDescription: tagData.description,
                tagTitle: tagData.title,
                tagId: tagData.id
            });
        }
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        document.addEventListener("mousedown", this.handleClick, false);

    }

    componentWillReceiveProps(props) { }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
        document.addEventListener("mousedown", this.handleClick, false);
    }

    toggleHover = () => {
        this.setState({ hover: !this.state.hover });
    };

    handleTagFields = e => {
        let name = e.target.name;
        this.setState({
            [name]: e.target.value
        });
    };

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    getTextAreaWidth(width) {
        if (width > 1900) return "54.5vw";
        else if (width > 1650) return "64.5vw";
        else if (width > 1200) return "74.5vw";
        else if (width > 800) return "84.5vw";
        else return "79vw";
    }

    getModalWidth(width) {
        if (width > 1900) return "55vw";
        else if (width > 1650) return "65vw";
        else if (width > 1200) return "75vw";
        else if (width > 800) return "85vw";
        else return "80vw";
    }

    handleClick = e => {
        if (e.target) {
            try {
                if (this.node.contains(e.target)) {
                    return;
                } else {
                    this.props.closeModal();
                }
            } catch (error) { }
        }
    };


    getHash = (title) => {
        let index = String(title).indexOf("#");
        if (index === 0) {
            return title
        }
        else {
            return `#${title}`
        }
    }
    handleSubmit = () => {
        const { tagDescription, tagTitle } = this.state;
        const { client, closeModal } = this.props;
        let getTitle = this.getHash(tagTitle)
        if (this.props.isUpdate) {
            const values = {
                id: this.props.tagData.id,
                title: getTitle,
                description: tagDescription
            };
            client
                .mutate({ mutation: UPDATE_TAG, variables: { input: values } })
                .then(response => {
                    let newTag = response.data.updateTag.tag;
                    this.props.updateTags(newTag);
                    closeModal();
                })
                .catch(error => {
                    console.error("Error: ", error);
                    return false;
                });
        } else {

            const values = {
                title: getTitle,
                description: tagDescription
            }
            client
                .mutate({ mutation: ADD_TAG, variables: { input: values } })
                .then(response => {
                    let newTag = response.data.addTag.tag;
                    this.props.updateTags(newTag);
                    closeModal();
                })
                .catch(error => {
                    console.error("Error: ", error);
                    return false;
                });
        }
    };
    getFont(width) {
        if (width > 400) return 18;
        else return 12;
    }

    // handleDelete = () => {
    //     const { client, tagData, closeModal } = this.props;
    //     let confirmDelete = window.confirm(
    //         "Are you sure that you want to delete this note?"
    //     );
    //     if (confirmDelete) {
    //         client
    //             .mutate({ mutation: DELETE_NOTE, variables: { id: tagData.id } })
    //             .then(response => {
    //                 if (response.data.deleteUserNote.success) {
    //                     this.props.deleteNotes(this.props.currentIndex);
    //                     closeModal();
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error("Error: ", error);
    //                 return false;
    //             });
    //     }
    // };

    render() {
        const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
        const width = obj.dimensions.width;
        const height = obj.dimensions.height;
        const shrink = this.state.width < 700;
        const textFontSize = Theme.getNormalFont(width);
        const { isModalDisplayed, tagData, isUpdate, closeModal } = this.props;
        const { tagId, tagTitle, tagDescription } = this.state;
        if (!isModalDisplayed) {
            return null;
        } else
            return (
                // Page Container
                <div style={styles.pageContainer}>
                    {/* Modal Container */}

                    <div
                        ref={node => (this.node = node)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "10%",
                            padding: "2vw",
                            height: "auto",
                            width: this.getModalWidth(this.state.width),
                            fontSize: Theme.getNormalFont(width),
                            fontFamily: Theme.HEADER_FONT,
                            backgroundColor: "white",
                            color: Theme.DARK_TEXT_COLOR,
                            boxShadow:
                                "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
                        }}
                    >

                        <div style={styles.header}> {isUpdate === true ? "Update Tag" : "Add Tag"} </div>
                        {/* Header */}
                        <div style={styles.heading}>Title </div>
                        <input
                            value={tagTitle}
                            name="tagTitle"
                            style={{
                                // width: this.getTextAreaWidth(this.state.width),
                                width:"100%",
                                height: shrink ? this.state.width / 20 : this.state.width / 40,
                                border: "1px solid",
                                borderColor: "#767676",
                                borderRadius: 5,
                                fontSize: textFontSize,
                                paddingLeft: 2,
                                fontFamily: Theme.MAIN_FONT
                            }}
                            placeholder={"Title"}
                            type={"type"}
                            onChange={this.handleTagFields}
                        />

                        {/* Text Box */}
                        <div style={styles.heading}> Description </div>
                        <textarea
                            value={tagDescription}
                            name="tagDescription"
                            style={{
                                outline: "none",
                                resize: "none",
                                borderRadius: 5,
                                width:"100%",
                                // width: this.getTextAreaWidth(this.state.width),
                                height: width / 6,
                                fontSize: textFontSize
                            }}
                            onChange={this.handleTagFields}
                        />

                        {/* Button Wraper to Right-Align */}
                        <div style={styles.submitButtonWrapper}>
                            {!isUpdate && (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Button
                                        text="Add Tag"
                                        type="button"
                                        width={width}
                                        onClick={this.handleSubmit}
                                        containerStyle={{ marginRight: 10 }}
                                    />
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        width={width}
                                        onClick={() => closeModal()}
                                    />
                                </div>
                            )}
                            {isUpdate && (
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Button
                                        text="Update Tag"
                                        type="button"
                                        width={width}
                                        onClick={this.handleSubmit}
                                        containerStyle={{ marginRight: 10 }}
                                    />
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        width={width}
                                        onClick={() => closeModal()}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
    }
}

const mapStateToProps = state => ({ ...state.global });

TagModal = reduxForm({
    form: "TagModal"
})(TagModal);

export default compose(
    withApollo,
    connect(mapStateToProps)
)(TagModal);
