import React, { Component } from "react";
import Search from "../../assets/search.svg";
import Theme from "../Theme.js";

export default class SearchBar extends Component {
  getWidth(width) {
    if (width > 400) return 355;
    else return 250;
  }
  getHeight(width) {
    if (width > 400) return 1.7;
    else return 1.4;
  }
  getFont(width) {
    if (width > 400) return 18;
    else return 12;
  }

  render() {
    const {
      type,
      placeholder,
      width,
      height,
      value,
      containerStyle,
      onChange
    } = this.props;
    return (
      <div style={containerStyle}>
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          style={{
            width: width,
            height: height,
            border: "1px solid",
            borderColor: "#FD7D50",
            borderRadius: 5,
            fontSize: this.getFont(height),
            paddingLeft: 20,
            fontFamily: Theme.MAIN_FONT
          }}
          placeholder={placeholder}
          type={type}
        />
        <img
          alt="search"
          src={Search}
          style={{ height: 15, position: "absolute", right: "5%", top: "35%" }}
        />
      </div>
    );
  }
}
