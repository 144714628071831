import React, { Component } from "react";
import { connect } from "react-redux";
import { USER_DETAILS } from "../../../store/queries.js";
import { Query } from "react-apollo";
import cookie from "js-cookie";
import Form from "./Form.js";
import { updateUser } from "../../../store/redux/actions";

class MemberProfile extends Component {
  render() {
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.props.global.currentUser.selectedUser }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return null;
          if (error) {
            if (
              error.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error.message}`;
          } else
            return <Form refetch={refetch} initialValues={data.userDetail} />;
        }}
      </Query>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfile);
