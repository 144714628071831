import React, { Component } from "react";
import Theme from "../Theme.js";
import Arrow from "../../assets/arrow-down.svg";

export default class SelectDashBoardMemerFilter extends Component {
    state = {
        open: false
    };

    componentWillMount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    componentWillUnmount() {
        document.addEventListener("mousedown", this.handleClick, false);
    }

    handleClick = e => {
        if (e.target) {
            try {
                if (this.node.contains(e.target)) {
                    return;
                } else {
                    this.setState({ open: false });
                }
            } catch (error) { }
        }
    };

    render() {
        const {
            label,
            selected,
            name,
            width,
            options,
            containerStyle = {},
            alt,
            icon,
            handleSelect,
            height
        } = this.props;
        let optionAll = {
            id: "",
            name: "All Parks"
        }
        const { open } = this.state;
        return (
            <div
                ref={node => (this.node = node)}
                style={Object.assign({}, containerStyle, {
                    position: "relative"
                })}
            >
                <button
                    type="button"
                    onClick={() => this.setState({ open: !open })}
                    style={{
                        outline: "none",
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                        backgroundColor: "white",
                        borderRadius: 5,
                        border: alt ? "1px solid #f17348" : "",
                        width: width || "100%",
                        padding: "8px",
                        width: "100%",
                        minWidth: "168px",
                        lineHeight: "31px"

                    }}
                >
                    <div
                        style={{
                            // fontSize: height / 2,
                            fontSize: "13px",
                            fontFamily: Theme.HEADER_FONT,
                            color: Theme.DARK_TEXT_COLOR,

                            //   margin: "auto auto auto 0"
                        }}
                    >
                        {selected}
                    </div>
                    <img
                        alt=""
                        style={{ margin: "auto 0 auto auto", height: height / 1.2 }}
                        src={icon ? icon : Arrow}
                    />
                </button>
                <div
                    style={
                        open
                            ? {
                                backgroundColor: "white",
                                width: `100%`,
                                display: "flex",
                                flexDirection: "column",
                                border: "1px solid",
                                borderTop: "none",
                                borderColor: alt ? "black" : Theme.MAIN_COLOR,
                                marginTop: -4,
                                maxHeight: height * 4,
                                overflow: "auto",
                                position: "absolute",
                                fontSize: height / 2,
                                fontFamily: Theme.INPUT_FONT,
                                zIndex: 1000
                            }
                            : { display: "none" }
                    }
                >

                    {/*  Creatoe  */}
                    {<button
                        type="button"
                        onClick={() => {
                            handleSelect("", "Creator");
                            this.setState({ open: false });
                        }}
                        style={{
                            cursor: "pointer",
                            border: "none",
                            textAlign: "left",
                            fontSize: height / 2,
                            backgroundColor: "white",
                            outline: "none",
                            borderBottom: "1px solid #ccc",
                            padding: ".5em",
                            minHeight: 50
                        }}
                    >
                        Creator
                    </button>}
                    {/* Creator List */}
                    {options &&
                        options.map((option, index) => {
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={() => {
                                        handleSelect(option, name);
                                        this.setState({ open: false });
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        textAlign: "left",
                                        fontSize: height / 2,
                                        backgroundColor: "white",
                                        outline: "none",
                                        borderBottom: "1px solid #ccc",
                                        padding: ".5em",
                                        minHeight: 50
                                    }}
                                >
                                    {option.firstName + " " + option.lastName}
                                </button>
                            )
                        }
                        )}
                </div>
            </div>
        );
    }
}
