import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { ACCEPT_RULES, BULK_CHECKIN } from "../../../store/mutations.js";
import { OnboardingHeader, Button } from "../../../globalComponents";
import SignaturePad from "../../../globalComponents/SignaturePad/SignaturePad.js";
import { USER_NOTES } from "../../../store/queries.js";
import { uniq as _uniq } from "lodash";
import { legalAgreementHTML } from "./legalAgreementHTML"; 
class ParkRules extends Component {
  state = {
    enabled: false,
    noOverflow: true,
    loading: false,
    signatureDataUrl: null,
  };

  constructor(props) {
    super(props);
    this.onSignatureDrawn = this.onSignatureDrawn.bind(this);
  }

  onSignatureDrawn(base64Url) {
    console.log(base64Url);
    this.props.change(`signatureDataUrl`, base64Url);
    this.setState({
      signatureDataUrl: base64Url,
    });
  }

  handleLoading = () => {
    if (this.state.signatureDataUrl) {
      this.setState({
        loading: true,
      });
      this.props.handleSubmit();
    } else {
      alert("Please have the member accept the park rules before continuing. ");
    }
  };
  render() {
    const { enabled, noOverflow, loading } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 767;
    const { handleSubmit, history } = this.props;

    // var divContainer = document.getElementById("termsContainer");
    // if (divContainer) {
    //   if (
    //     noOverflow !==
    //     divContainer.offsetHeight > divContainer.scrollHeight
    //   ) {
    //     this.setState({
    //       noOverflow: divContainer.offsetHeight > divContainer.scrollHeight
    //     });
    //   }
    // }
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
            ? this.props.history.push("/manager/members")
            : this.props.history.push("/")
        }
        progress={3}
        containerStyle={{
          display: shrink ? "block" : "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5%",
          alignContent: "center",
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginBottom: "2%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR,
          }}
        >
          Park Rules
        </div>
        <div
          style={{
            height: height / 2,
            border: "1px solid",
            width: shrink ? "95%" : width / 1.3,
            borderRadius: 10,
            padding: "2%",
            backgroundColor: "white",
            borderColor: Theme.MAIN_COLOR,
            margin: shrink ? "0 auto 15px" : " ",
          }}
        >
          <div
            id="termsContainer"
            style={{
              width: shrink ? "95%" : width / 1.38,
              height: height / 2.2,
              overflowY: "scroll",
              padding: "2%",
            }}
            onScroll={(event) => {
              if (
                event.currentTarget.scrollHeight -
                  event.currentTarget.scrollTop <
                Math.round(event.currentTarget.getBoundingClientRect().height)
              ) {
                console.log("hrere");
                this.setState({
                  enabled: true,
                });
              }
            }}
            dangerouslySetInnerHTML={{ __html: legalAgreementHTML }}
          />

        </div>

        <SignaturePad onSignatureDrawn={this.onSignatureDrawn}></SignaturePad>

        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10%",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <Button
            text="Cancel"
            alt={true}
            width={width}
            onClick={() =>
              this.props.global.currentUser.type === "Employee"
                ? this.props.history.push("/dashboard")
                : this.props.global.currentUser.type === "Manager" ||
                  this.props.global.currentUser.type === "Admin"
                ? this.props.history.push("/manager/members")
                : this.props.history.push("/")
            }
          />
          <Button
            containerStyle={{ marginLeft: "auto" }}
            text="Accept"
            width={width}
            loading={loading}
            loadingText="Wait"
            onClick={this.handleLoading}
            disabled={!enabled && !noOverflow || loading}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  console.log(props.global.currentUser);

  const getUserIdToUpdateAcceptRules = () => {
    try {
      if (props.global.currentUser.selectedUser) {
        // if employee accepts the rules on behalf of user
        return parseInt(props.global.currentUser.selectedUser);
      } else {
        // this is new flow to make it work for user who accept ruels by themself
        return parseInt(props.global.currentUser.id);
      }
    } catch (e) {
      return null;
    }
  };

  const input = {
    id: getUserIdToUpdateAcceptRules(),
    parkId: props.global.currentUser?.park?.id || "",
  };
  if (values.signatureDataUrl)
    input["signatureDataUrl"] = values.signatureDataUrl;

  if (values.signatureDataUrl) {
    console.log("input ", input);
    props.client
      .mutate({
        mutation: ACCEPT_RULES,
        variables: {
          input,
        },
      })

    .then(({ data: { updateUser } }) => {
  alert("Thanks. You're all set.");
  if (props.global.currentUser.checkingInDogs) {
    let checkingInDogs = props.global.currentUser.checkingInDogs;
    let selectedUserId = props.global.currentUser.selectedUser;
    startCheckIn(checkingInDogs, selectedUserId, props);
  } else {
    const returnTo = props.location.state && props.location.state.returnTo;
    if (returnTo) {
      console.log("ParkRules: Return to the member profile")
      props.history.push(returnTo);
    } else {
      // Fall back to the original switch statement if no returnTo is provided
      console.log("ParkRules: No Return to Set")
      const userType = props.global.currentUser.type.toLowerCase();
      switch (userType) {
        case "employee": {
          props.history.push("/dashboard");
          break;
        }
        case "manager":
        case "admin": {
          props.history.push("/manager/members");
          break;
        }
        case "member": {
          props.history.push("/profile");
          break;
        }
        default: {
          props.history.push("/");
        }
      }
    }}
      // Save and continue returns to dashboard
      //.then(({ data: { updateUser } }) => {
      //  alert("Thanks. You’re all set.");
      //  if (props.global.currentUser.checkingInDogs) {
      //    let checkingInDogs = props.global.currentUser.checkingInDogs;
      //    let selectedUserId = props.global.currentUser.selectedUser;
      //    startCheckIn(checkingInDogs, selectedUserId, props);
      //  } else {
      //    const userType = props.global.currentUser.type.toLowerCase();
      //    switch (userType) {
      //      case "employee": {
      //        props.history.push("/dashboard");
      //        break;
      //      }
      //      case "manager":
      //      case "admin": {
      //        props.history.push("/manager/members");
      //        break;
      //      }
      //      case "member": {
      //        props.history.push("/profile");
      //        break;
      //      }
      //      default: {
      //        props.history.push("/");
      //      }
      //    }
      //  }

        //old flow
        // const userType = (props.global.currentUser.type).toLowerCase()
        // userType === "employee"
        //   ? props.history.push("/dashboard")
        //   : props.global.currentUser.type === "manager" ||
        //     props.global.currentUser.type === "admin"
        //     ? props.history.push("/manager/members")
        //     : props.history.push("/");
        return true;
      })
      .catch((error) => {
        alert(error.message.slice(15));
        props.history.push("/");
        console.error(error.message);
      });
  } else {
    alert("Please have the member accept the park rules before continuing. ");
  }
};
const startCheckIn = (checkingInDogs, _selectedUserId, props) => {
  // let { selectedUserId } = this.state;
  if (_selectedUserId) {
    const checkedinArr = Object.entries(checkingInDogs);
    const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));
    for (const userId of allUsersIds) {
      if (_selectedUserId === userId) {
        checkedNoteCheckIn(checkingInDogs, userId, props);
        break;
      } else {
        checkedNoteCheckIn(checkingInDogs, userId, props);
        break;
      }
    }
  } else {
    alert("Need to select at least one dog.");
  }
};
const checkedNoteCheckIn = (checkingInDogs, userId, props) => {
  let { client } = props;
  if (userId) {
    // Check IN start

    const values = {
      id: Number(userId),
    };
    client
      .query({
        query: USER_NOTES,
        variables: values,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        const {
          data: {
            userDetail: { notes, firstName, lastName },
          },
        } = response;

        if (notes.length > 0) {
          let note = notes && notes[0].content;
          if (window.confirm(`${firstName + " " + lastName} NOTE : ${note}`)) {
            return new Promise((resolve, reject) => {
              handleBulkCheckIn(checkingInDogs, props);
            });
          }
        } else {
          if (window.confirm("Do you want to check-in the selected members?")) {
            return new Promise((resolve, reject) => {
              handleBulkCheckIn(checkingInDogs, props);
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
    // Check IN end
  }
};

const handleBulkCheckIn = (checkingInDogs, props) => {
  const checkingIn = [];
  const tempArr = [];
  // console.log("[handleBulkCheckIn] state.checkingIn: ", this.state.checkingInDogs)

  const checkedinArr = Object.entries(checkingInDogs);
  // console.log("[handleBulkCheckIn] checkedinArr: ", checkedinArr)
  const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));

  const countDogsCheckedInForUser = (forUserId) => {
    let count = 0;
    let dogIdArr = [];

    for (let [dogId, userId] of checkedinArr) {
      if (forUserId == userId) dogIdArr.push(Number(dogId));
    }
    return dogIdArr;
  };
  for (const userId of allUsersIds) {
    const dogIds = countDogsCheckedInForUser(userId);
    checkingIn.push({ userId, dogCount: dogIds.length, dogIds });
  }

  console.log("************ CheckingIn ***********", checkingIn);

  props.client
    .mutate({
      mutation: BULK_CHECKIN,
      variables: {
        input: {
          viaApp: "web",
          users: checkingIn,
          parkId:
            props.global.currentUser.park && props.global.currentUser.park.id,
          // ||
          // this.state.parkId,
        },
      },
    })
    .then((response) => {
      if (response.data.checkIn.errors[0]) {
        alert(response.data.checkIn.errors[0].message);
      } else {
        alert("All selected member(s) successfully checked in!");
      }
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      const userType = props.global.currentUser.type.toLowerCase();
      switch (userType) {
        case "employee": {
          props.history.push("/dashboard");
          break;
        }
        case "manager":
        case "admin": {
          props.history.push("/manager/members");
          break;
        }
        case "member": {
          props.history.push("/profile");
          break;
        }
        default: {
          props.history.push("/");
        }
      }
    })
    .catch((error) => {
      let error_msg = error.message.replace("GraphQL error:", "");
      alert(error_msg);
    });
};

ParkRules = reduxForm({
  form: "ParkRules",
  onSubmit: formSubmit,
})(ParkRules);

export default compose(withApollo, connect(mapStateToProps))(ParkRules);
