import React from "react";
import { connect } from "react-redux";
import Theme from "../../Theme";
const styles = {

  header: {
    fontSize: "90%",
    fontFamily: Theme.MAIN_FONT,
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    color: "white"
  },
  altHeader: {
    fontFamily: Theme.MAIN_FONT,
    color: Theme.DARK_TEXT_COLOR,
    borderBottom: "1.4px solid #C8CFD9"
  },
  noLabelHeader: {
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    width: 10
  },
  altNoLabel: {
    width: 10
  },
  row: {
    fontSize: 23,
    color: "#3F3F3F"
  }
};
function CouponCell({ label, renderCell, header, alt, global }) {
  const obj = global || { dimensions: { height: 0, width: 1000 } };
  const width = obj.dimensions.width;
  const shrink = width < 700;
  return (
    <th
      style={
        header
          ? label
            ? alt
              ? {
                ...styles.altHeader,
                fontSize: shrink ? "11px" : "90%",
              }
              : {
                ...styles.header,
                fontSize: shrink ? "11px" : "90%",
              }
            : alt
              ? styles.altNoLabel
              : styles.noLabelHeader
          : {
            ...styles.row,
            fontSize: shrink ? "11px" : "90%",
          }
      }
    >
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
        <div
          style={{
            marginRight: 20,
            marginBottom: "auto",
            marginTop: "auto",
            width: "100%",
            height: "100%"
          }}
        >
          {renderCell ? renderCell : label}
        </div>
      </div>
    </th>
  );
}

const mapStateToProps = state => ({ ...state.global });

export default connect(
  mapStateToProps,
  null
)(CouponCell);