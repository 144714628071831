import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "..";
import "./SignaturePad.css";

export default class SignaturePad extends Component {
  sigPad = {};
  canvasProps = {
    width: 500,
    height: 200,
    className: "sigCanvas m-auto",
  };

  constructor(props) {
    super(props);
    this.clearSignature = this.clearSignature.bind(this);
    this.onSignatureDrawn = this.onSignatureDrawn.bind(this);
  }

  clearSignature() {
    this.sigPad.clear();
    if (this.props.onSignatureDrawn) this.props.onSignatureDrawn(null);
  }

  onSignatureDrawn() {
    const dataUrl = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    if (this.props.onSignatureDrawn) this.props.onSignatureDrawn(dataUrl);
  }

  render() {
    return (
      <div  className={"flex-column d-flex justify-content-center signaturepad"}>
        <div className={"d-flex"}>
          <SignatureCanvas
            penColor="black"
            canvasProps={this.canvasProps}
            backgroundColor="#eed"
            ref={(ref) => {
              this.sigPad = ref;
            }}
            onEnd={this.onSignatureDrawn}
            style={{
              width: "100%",
            }}
          
          />
        </div>
        <div className={"d-flex justify-content-center button-wrapper"}>
          <Button
            text="Clear"
            alt={true}
            width={300}
            onClick={this.clearSignature}
          />
        </div>
      </div>
    );
  }
}
