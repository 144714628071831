import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import {
  LabeledTextBox,
  PictureUpload,
  Button,
  VaccinationUpload
} from "../../../globalComponents/";
import { ADD_DOGS } from "../../../store/mutations.js";
import {
  addOrUpdateDogVaccinations,
  updateDogsInfo
} from "../../../utils/dog.js";

class Vaccinations extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    selected: 0,
    vacs: this.props.initialValues.dogs[0]
      ? this.props.initialValues.dogs[0].vaccinationPictures
      : [],
    currentVac: "Rabies",
    dogs: this.props.initialValues.dogs,
    dogModalOpen: false
  };

  componentDidMount() {
    const dogs = this.props.initialValues.dogs;
    if (dogs[0]) {
      for (var i = 0; i < dogs.length; i++) {
        this.props.change(`name${i}`, dogs[i].name);
        this.props.change(`id${i}`, dogs[i].id);
        this.props.change(`sex${i}`, dogs[i].sex === "MALE" ? "M" : "F");
        this.props.change(`color${i}`, dogs[i].color);
        this.props.change(`birthdate${i}`, dogs[i].birthdate);
      }
      this.props.change(
        "vaccinationPictures",
        dogs[0].vaccinationPictures || []
      );
      this.props.change("rabies", dogs[0].rabies);
      this.props.change("dhlpp", dogs[0].dhlpp);
      this.props.change("leptospirosis", dogs[0].leptospirosis);
      this.props.change("bordetella", dogs[0].bordetella);
      this.props.change("separateLeptospirosis", dogs[0].separateLeptospirosis);
      this.props.change("vaccineDog", parseInt(dogs[0].id));
    }
  }

  selectCurrentVac = selectedVac => {
    this.setState({ currentVac: selectedVac });
  };

  dogCount = dogs => {
    if (!dogs[0]) {
      return 0;
    } else if (!dogs[1]) {
      return 1;
    } else if (!dogs[2]) {
      return 2;
    } else {
      return 3;
    }
  };

  handleDogModal = () => {
    let newState = { ...this.state };
    this.props.change("pictureNew", null);
    this.props.change("nameNew", "");
    this.props.change("birthdateNew", null);
    this.props.change("sexNew", "");
    this.props.change("colorNew", "");
    this.setState({ dogModalOpen: !newState.dogModalOpen });
  };

  addDog = () => {
    if (
      this.props.nameNew &&
      this.props.sexNew &&
      this.props.colorNew &&
      this.props.pictureNew
    ) {
      let dogs = {
        userId: parseInt(this.props.global.currentUser.selectedUser),
        profilePicture: this.props.pictureNew,
        name: this.props.nameNew,
        birthdate: this.props.birthdateNew,
        sex: this.props.sexNew === "F" ? "FEMALE" : "MALE",
        color: this.props.colorNew
      };
      this.props.client
        .mutate({ mutation: ADD_DOGS, variables: { input: dogs } })
        .then(data => {
          if (data.errors && data.errors.length > 0) {
            console.error("Submission Error: ", data.errors);
          } else {
            let newState = { ...this.state };
            newState.dogs.push(data.data.addDogs.dogs[0]);
            for (var i = 0; i < newState.dogs.length; i++) {
              this.props.change(`name${i}`, newState.dogs[i].name);
              this.props.change(`id${i}`, newState.dogs[i].id);
              this.props.change(
                `sex${i}`,
                newState.dogs[i].sex === "MALE" ? "M" : "F"
              );
              this.props.change(`color${i}`, newState.dogs[i].color);
              this.props.change(`birthdate${i}`, newState.dogs[i].birthdate);
            }
            this.props.change(
              "vaccinationPictures",
              newState.dogs[0].vaccinationPictures || []
            );
            this.props.change("rabies", newState.dogs[0].rabies);
            this.props.change("dhlpp", newState.dogs[0].dhlpp);
            this.props.change("leptospirosis", newState.dogs[0].leptospirosis);
            this.props.change("bordetella", newState.dogs[0].bordetella);
            this.props.change(
              "separateLeptospirosis",
              newState.dogs[0].separateLeptospirosis
            );
            this.props.change("vaccineDog", parseInt(newState.dogs[0].id));

            this.setState({ dogs: newState.dogs });
            this.handleDogModal();
          }
        })
        .catch(error => {
          console.error("Error: ", error);
        });
    } else {
      alert(
        "Error: All fields must be filled out and a dog picture must be uploaded in order to add a new dog."
      );
    }
  };

  render() {
    const { selected, vacs, currentVac, dogs } = this.state;
    const {
      handleSubmit,
      change,
      pristine,
      submitting,
      rabiesDate,
      dhlppDate,
      leptospirosisDate,
      bordetellaDate,
      vaccinationNew0,
      vaccinationNew1,
      vaccinationNew2,
      global
    } = this.props;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 1025;

    let numDogs = this.dogCount(dogs);

    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5%",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: shrink ? "80%" : "100%",
              borderBottom: "2px solid",
              borderColor: Theme.ALT_BUTTON_COLOR,
              paddingBottom: 20
              // marginRight: 20
            }}
          >
            {dogs.map((dog, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "white",
                    border: selected === index ? "10px solid" : "1px solid",
                    borderColor:
                      selected === index
                        ? Theme.MAIN_COLOR
                        : Theme.LIGHT_TEXT_COLOR,
                    borderRadius: 10,
                    boxShadow: "3px 3px 10px #606267",
                    padding: 20,
                    marginBottom: 20,
                    marginLeft: 10,
                    marginRight: 10
                    // justifyContent: "center"
                  }}
                  onClick={() => {
                    let newState = { ...this.state };
                    newState.dogs[selected].rabies = rabiesDate;
                    newState.dogs[selected].dhlpp = dhlppDate;
                    newState.dogs[selected].leptospirosis = leptospirosisDate;
                    newState.dogs[selected].bordetella = bordetellaDate;

                    if (selected === 0 && vaccinationNew0) {
                      newState.dogs[selected].vaccinations = vaccinationNew0;
                    } else if (selected === 1 && vaccinationNew1) {
                      newState.dogs[selected].vaccinations = vaccinationNew1;
                    } else if (selected === 2 && vaccinationNew2) {
                      newState.dogs[selected].vaccinations = vaccinationNew2;
                    }

                    change("dogsNew", newState.dogs);

                    change(
                      "vaccinationPictures",
                      dog.vaccinationPictures || []
                    );
                    change("rabies", dog.rabies);
                    change("dhlpp", dog.dhlpp);
                    change("leptospirosis", dog.leptospirosis);
                    change("bordetella", dog.bordetella);
                    change("separateLeptospirosis", dog.separateLeptospirosis);
                    change("vaccineDog", parseInt(dog.id));
                    this.setState({
                      selected: index,
                      vacs: dog.vaccinationPictures || [],
                      dogs: newState.dogs
                    });
                  }}
                >
                  <PictureUpload
                    width={width}
                    containerStyle={{ marginRight: "20px", display: "flex" }}
                    picture={
                      dog.profilePicture.tempPic ||
                      (dog.profilePicture.url
                        ? process.env.REACT_APP_API_ENDPOINT +
                          // dog.profilePicture.url
                          dog.profilePictureCdn

                        : null)
                    }
                    name={`picture${index}`}
                    onUpload={(v, pic) => {
                      let newState = { ...this.state };
                      newState.dogs[index].profilePicture.tempPic = pic;
                      this.setState({ dogs: newState.dogs });
                      change(`profilePicture${index}`, v);
                    }}
                    height={height}
                    label="Picture"
                    disableForMembers={
                      global.currentUser.type === "Member" ? true : false
                    }
                    verticalCenter={true}
                    notSelected={!(selected === index)}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "2%",
                      flexDirection: "column",
                      width: "calc(100% - 150px)"
                    }}
                  >
                    <LabeledTextBox
                      name={`name${index}`}
                      width={width}
                      widthpercent={"100%"}
                      height={height}
                      label="Dog Name"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ width: "65%" }}>
                        <LabeledTextBox
                          name={`color${index}`}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Color"
                        />
                      </div>
                      <div style={{ width: "25%" }}>
                        <LabeledTextBox
                          name={`sex${index}`}
                          options={["M", "F"]}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Sex"
                        />
                      </div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <LabeledTextBox
                        name={`birthdate${index}`}
                        widthpercent={"100%"}
                        width={width}
                        height={height}
                        label="Birth Date"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div style={{ marginTop: 10 }}>{numDogs} / 3 Dogs</div>
            {numDogs < 3 && (
              <Button
                type="button"
                containerStyle={{ marginTop: 10 }}
                text="Add Dog"
                width={width}
                onClick={() => this.handleDogModal()}
              />
            )}
          </div>
          <div
            style={{
              width: "90%",
              paddingBottom: 20
              // marginRight: 20
            }}
          >
            {dogs[selected] && (
              <VaccinationUpload
                dog={dogs[selected]}
                vacDates={{
                  rabiesDate,
                  dhlppDate,
                  leptospirosisDate,
                  bordetellaDate
                }}
                change={change}
                selected={selected}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "5%",
              marginBottom: "5%"
            }}
          >
            {numDogs > 0 && (
              <Button
                type="button"
                disabled={pristine || submitting}
                containerStyle={{ marginTop: "3%" }}
                text="Save"
                width={width}
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>

        {/* Add Dog Modal */}
        <div
          id="dogModal"
          style={{
            display: `${this.state.dogModalOpen ? "flex" : "none"}`,
            position: "fixed",
            zIndex: 999,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          }}
        >
          <div
            style={{
              margin: "auto",
              width: `${width < 600 ? "100%" : "600px"}`,
              height: "400px",
              position: "relative",
              backgroundColor: "white",
              padding: 10,
              borderRadius: "15px"
            }}
          >
            <div id="dogModalContainer" style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex"
                }}
              >
                <div
                  style={{
                    width: "75%",
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "5%",
                      fontSize: 18,
                      fontFamily: "Lato"
                    }}
                  >
                    Add Dog
                  </div>
                  {/* Dog Card */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "white",
                        border: "3px solid",
                        borderColor: Theme.MAIN_COLOR,
                        borderRadius: 10,
                        boxShadow: "3px 3px 10px #606267",
                        padding: 20,
                        marginBottom: "auto",
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                      <PictureUpload
                        width={width}
                        containerStyle={{
                          marginRight: "20px",
                          display: "flex"
                        }}
                        picture={null}
                        name={`pictureNew`}
                        onUpload={(v, pic) => {
                          change(`pictureNew`, v);
                        }}
                        height={height}
                        label="Picture"
                        disableForMembers={false}
                        verticalCenter={true}
                      />
                      <div
                        style={{
                          display: "flex",
                          marginTop: "2%",
                          flexDirection: "column",
                          width: "calc(100% - 150px)"
                        }}
                      >
                        <LabeledTextBox
                          onBlur={() => {}}
                          name={`nameNew`}
                          width={width}
                          widthpercent={"100%"}
                          height={height}
                          label="Dog Name"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between"
                          }}
                        >
                          <div style={{ width: "65%" }}>
                            <LabeledTextBox
                              onBlur={() => {}}
                              name={`colorNew`}
                              widthpercent={"100%"}
                              width={width}
                              height={height}
                              label="Color"
                            />
                          </div>
                          <div style={{ width: "25%" }}>
                            <LabeledTextBox
                              name={`sexNew`}
                              onBlur={() => {}}
                              options={["M", "F"]}
                              widthpercent={"100%"}
                              width={width}
                              height={height}
                              label="Sex"
                            />
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <LabeledTextBox
                            onBlur={() => {}}
                            name={`birthdateNew`}
                            widthpercent={"100%"}
                            width={width}
                            height={height}
                            label="Birth Date"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 20
                    }}
                  >
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Cancel"
                      width={width}
                      onClick={() => this.handleDogModal()}
                    />
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Add Dog"
                      width={width}
                      onClick={() => this.addDog()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of Dog Modal */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  updateDogsInfo(values, props.client, props.refetch).then(() =>
    addOrUpdateDogVaccinations(values, props.client, props.refetch)
  );
  alert("Saved!");
  return true;
};

const selector = formValueSelector("Vaccinations"); // <-- same as form name
Vaccinations = connect(state => {
  const rabiesDate = selector(state, "rabies");
  const dhlppDate = selector(state, "dhlpp");
  const leptospirosisDate = selector(state, "leptospirosis");
  const bordetellaDate = selector(state, "bordetella");
  const nameNew = selector(state, "nameNew");
  const pictureNew = selector(state, "pictureNew");
  const birthdateNew = selector(state, "birthdateNew");
  const sexNew = selector(state, "sexNew");
  const colorNew = selector(state, "colorNew");
  const vaccinationNew0 = selector(state, "vaccinationNew0");
  const vaccinationNew1 = selector(state, "vaccinationNew1");
  const vaccinationNew2 = selector(state, "vaccinationNew2");
  return {
    rabiesDate,
    dhlppDate,
    leptospirosisDate,
    bordetellaDate,
    nameNew,
    pictureNew,
    birthdateNew,
    sexNew,
    colorNew,
    vaccinationNew0,
    vaccinationNew1,
    vaccinationNew2
  };
})(Vaccinations);

Vaccinations = reduxForm({
  form: "Vaccinations",
  onSubmit: formSubmit
})(Vaccinations);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Vaccinations);
