import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { reduxForm, formValueSelector } from "redux-form";
import {
  USER_UPDATE,
  CONFIRM,
  SELF_CONFIRM,
} from "../../../../store/mutations.js";
import {
  OnboardingHeader,
  LabeledTextBox,
  PictureUpload,
  Button,
  VaccinationUpload,
  MembershipInfo,
  PawIndicator,
  DatePickerBox,
} from "../../../../globalComponents";
import {
  addOrUpdateDogVaccinations,
  updateDogsInfo,
} from "../../../../utils/dog.js";
import BirthCheckBox from "../../../../globalComponents/FetchComponents/BirthCheckBox.jsx";
import {
  placeAutoComplete,
  placeDetails,
} from "../../../../utils/placeapis.jsx";
import SelectAddress from "../../../../globalComponents/FormComponents/selectAddress.jsx";

const stateAbbreviations = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

class ConfirmAccountForm extends Component {
  state = {
    initialize: true,
    texts: false,
    picture: null,
    selected: 0,
    vacs: this.props.initialValues.dogs[0]
      ? this.props.initialValues.dogs[0].vaccinationPictures
      : [],
    dogs: this.props.initialValues.dogs,
    birthStatus: false,
    suggestions: [],
    selectedAddress: "",
  };

  handleFile = (blob, file) => {
    var tempFiles = this.state.vacs;
    tempFiles.push({ name: blob.filename, file: file });

    var formFiles = [];
    for (var i = 0; i < tempFiles.length; i++) {
      formFiles.push(tempFiles[i].signed_id);
    }

    this.props.change("vaccinationPictures", formFiles);
    this.setState({ vacs: tempFiles });
  };

  getPicture() {
    if (this.state.picture) {
      return this.state.picture;
    } else if (this.props.initialValues.profilePicture?.url) {
      return (
        process.env.REACT_APP_API_ENDPOINT +
        this.props.initialValues.profilePicture.url
      );
      //return ('http://fetch-backend-staging.us-east-1.elasticbeanstalk.com/' + this.props.initialValues.profilePicture.url)
    } else {
      return null;
    }
  }

  getDogs(dogs) {
    var temp = {};
    for (var i = 0; i < dogs.length; i++) {
      for (var j = 1; j < dogs.length; j++) {
        if (dogs[i].id > dogs[j].id) {
          temp = dogs[j];
          dogs[j] = dogs[i];
          dogs[i] = temp;
        }
      }
    }

    return dogs;
  }

  componentDidMount() {
    const dogs = this.props.initialValues.dogs;
    console.log("separateLeptospirosis", dogs[0].separateLeptospirosis);
    if (dogs[0]) {
      // breed: values[`breed${i}`],
      for (var i = 0; i < dogs.length; i++) {
        this.props.change(`name${i}`, dogs[i].name);
        this.props.change(`id${i}`, dogs[i].id);
        this.props.change(`sex${i}`, dogs[i].sex === "MALE" ? "M" : "F");
        this.props.change(`color${i}`, dogs[i].color);
        this.props.change(`breed${i}`, dogs[i]?.breed);
        this.props.change(`birthdate${i}`, dogs[i].birthdate);
        this.props.change(`isNeutered${i}`, dogs[i].isNeutered);
      }
      this.props.change(
        "vaccinationPictures",
        dogs[0].vaccinationPictures || []
      );
      this.props.change("rabies", dogs[0].rabies);
      this.props.change("dhlpp", dogs[0].dhlpp);
      this.props.change("leptospirosis", dogs[0].leptospirosis);
      this.props.change("bordetella", dogs[0].bordetella);
      this.props.change("separateLeptospirosis", dogs[0].separateLeptospirosis);
      this.props.change("vaccineDog", parseInt(dogs[0].id));
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.initialValues.dogs !== prevProps.initialValues.dogs) {
      console.log(
        "&&&&&&&&&&&&&&&&&&  CHekc Condition  @@@@@@@@@@@@@@@@@@@@@@"
      );
      const dogs = this.props.initialValues.dogs;
      if (dogs[0]) {
        for (var i = 0; i < dogs.length; i++) {
          this.props.change(`name${i}`, dogs[i].name);
          this.props.change(`id${i}`, dogs[i].id);
          this.props.change(`sex${i}`, dogs[i].sex === "MALE" ? "M" : "F");
          this.props.change(`color${i}`, dogs[i].color);
          this.props.change(`breed${i}`, dogs[i]?.breed);
          this.props.change(`birthdate${i}`, dogs[i].birthdate);
          this.props.change(`isNeutered${i}`, dogs[i].isNeutered);
        }
        this.props.change(
          "vaccinationPictures",
          dogs[0].vaccinationPictures || []
        );
        this.props.change("rabies", dogs[0].rabies);
        this.props.change("dhlpp", dogs[0].dhlpp);
        this.props.change("leptospirosis", dogs[0].leptospirosis);
        this.props.change("bordetella", dogs[0].bordetella);
        this.props.change(
          "separateLeptospirosis",
          dogs[0].separateLeptospirosis
        );
        this.props.change("vaccineDog", parseInt(dogs[0].id));
      }
    }
  }

  handleBirthCheckbox = () => {
    let newState = { ...this.state };
    newState.birthStatus = !newState.birthStatus;
    // console.log(" newState.separateLeptospirosis -------------------------", newState.separateLeptospirosis);
    // this.props.change("leptospirosis", null);
    // this.props.change("separateLeptospirosis", newState.separateLeptospirosis);

    this.setState({ birthStatus: newState.birthStatus });
  };
  getAddressSuggestion = async (e) => {
    let payload = {
      input: e.target.value,
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      radius: "100",
    };
    let suggest = await placeAutoComplete(payload);
    this.setState({
      suggestions: suggest,
    });
  };

  handleSelecteAddress = async (address) => {
    let place_id = address.place_id;
    let payload = {
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      place_id,
    };
    let getAddress = await placeDetails(payload);
    getAddress.address_components.map((item) => {
      switch (item.types[0]) {
        case "country":
          this.props.change("address", getAddress.name);
          this.setState({
            selectedAddress: getAddress.name,
          });
          break;
        case "postal_code":
          this.props.change("zip", item.long_name);
          break;
        case "administrative_area_level_1":
          this.props.change("state", item.short_name);
          break;
        case "locality":
          this.props.change("city", item.long_name);
          break;
        default:
          console.log("Hittttt");
      }
    });
  };
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 725;
    const {
      initialize,
      selected,
      vacs,
      currentVac,
      dogs,
      birthStatus,
      selectedAddress,
      suggestions,
    } = this.state;
    const {
      handleSubmit,
      change,
      history,
      initialValues,
      global,
      userId,
      membershipName,
      rabiesDate,
      dhlppDate,
      leptospirosisDate,
      bordetellaDate,
      vaccinationNew0,
      vaccinationNew1,
      vaccinationNew2,
      separateLeptospirosis,
    } = this.props;
    if (initialize) {
      this.setState({ initialize: false });
    }
    const determineWidth = (width, widthFactor, widthpercent) => {
      if (widthpercent) {
        return widthpercent;
      } else {
        return Theme.getLongInputWidth(width) / (widthFactor || 1);
      }
    };
    // console.log(`USERID: ${userId}`);
    // console.log(`InitialValue ${initialValues}`);

    // console.log("");

    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={false}
        containerStyle={{
          display: shrink ? "block" : "flex",
          flexDirection: "column",
          width: "100%",
        }}
        checkInStatus={false}
      >
        <div
          style={{
            display: shrink ? "block" : "flex",
            flexDirection: shrink ? "column" : "row",
            alignItems: shrink ? "center" : "",
            width: "100%",
          }}
        >
          <div
            style={{
              display: shrink ? "block" : "flex",
              flexDirection: "column",
              width: shrink ? "100%" : "50%",
            }}
          >
            <div
              style={{
                marginBottom: "2%",
                marginTop: "5%",
                textAlign: shrink ? "center" : "",
                fontFamily: Theme.HEADER_FONT,
                fontSize: Theme.getHeaderFont(width),
                color: Theme.HEADER_FONT_COLOR,
              }}
            >
              Pet Owner Info
            </div>
            <div
              style={{
                display: shrink ? "block" : "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <PictureUpload
                width={width}
                containerStyle={{ marginRight: "10px" }}
                picture={this.getPicture()}
                name="profilePicture"
                onUpload={(v, pic) => {
                  this.setState({ picture: pic });
                  change("profilePicture", v);
                }}
                height={height}
                label="Picture"
              />
              <div
                style={{
                  display: shrink ? "block" : "flex",
                  marginTop: "2%",
                  flexDirection: "column",
                  width: shrink ? "100%" : "calc(100% - 150px)",
                }}
              >
                <LabeledTextBox
                  name="firstName"
                  width={width}
                  widthpercent={"100%"}
                  height={height}
                  label="First Name"
                />
                <LabeledTextBox
                  name="lastName"
                  width={width}
                  widthpercent={"100%"}
                  height={height}
                  label="Last Name"
                />
                <div></div>
              </div>
            </div>
            {/*  <LabeledTextBox
              name="authorizedUser"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Authorized User"
            /> */}

            <div
              style={{
                marginBottom: "2%",
                marginTop: "10%",
                fontFamily: Theme.HEADER_FONT,
                fontSize: Theme.getHeaderFont(width),
                color: Theme.HEADER_FONT_COLOR,
              }}
            >
              Member Address
            </div>
            {/* <LabeledTextBox
              name="address"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Address"
            /> */}

            <SelectAddress
              alt={true}
              options={suggestions}
              handleSelect={(address, name) => {
                this.handleSelecteAddress(address);
              }}
              selected={selectedAddress}
              name="address"
              width={width}
              height={height}
              label="Address"
              validate={["required"]}
              getAddressSuggestion={this.getAddressSuggestion}
            />
            <LabeledTextBox
              name="address2"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Address Line 2"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "50%" }}>
                <LabeledTextBox
                  name="city"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="City"
                />
              </div>
              <div style={{ width: "20%" }}>
                <LabeledTextBox
                  name="state"
                  widthpercent={"100%"}
                  options={stateAbbreviations}
                  width={width}
                  height={height}
                  label="State"
                />
              </div>
              <div style={{ width: "20%" }}>
                <LabeledTextBox
                  name="zip"
                  widthpercent={"100%"}
                  width={width}
                  height={height}
                  label="Zipcode"
                />
              </div>
            </div>
            <LabeledTextBox
              name="email"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Email"
            />
            <LabeledTextBox
              name="phoneNumber"
              width={width}
              widthpercent={"100%"}
              height={height}
              validate={["required", "number"]}
              label="Telephone Number"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: shrink ? "" : "2%",
              alignItems: shrink ? "center" : "",
              width: shrink ? "100%" : "48%",
            }}
          >
            <div
              style={{
                marginBottom: "2%",
                marginTop: "5%",
                fontFamily: Theme.HEADER_FONT,
                fontSize: Theme.getHeaderFont(width),
                color: Theme.HEADER_FONT_COLOR,
              }}
            >
              Dog Info
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                justifyContent: "center",
                width: shrink ? "95%" : "100%",
                borderBottom: "2px solid",
                borderColor: Theme.ALT_BUTTON_COLOR,
                paddingBottom: 20,
                // marginRight: 20
              }}
            >
              {dogs.map((dog, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: shrink ? "block" : "flex",
                      flexDirection: "row",
                      backgroundColor: "white",
                      border: selected === index ? "3px solid" : "1px solid",
                      borderColor:
                        selected === index
                          ? Theme.MAIN_COLOR
                          : Theme.LIGHT_TEXT_COLOR,
                      borderRadius: 10,
                      boxShadow: "3px 3px 10px #606267",
                      padding: 20,
                      marginBottom: 20,
                      marginLeft: 10,
                      marginRight: 10,
                      // justifyContent: "center"
                    }}
                    onClick={() => {
                      let newState = { ...this.state };
                      newState.dogs[selected].rabies = rabiesDate;
                      newState.dogs[selected].dhlpp = dhlppDate;
                      newState.dogs[selected].leptospirosis = leptospirosisDate;
                      newState.dogs[selected].bordetella = bordetellaDate;
                      newState.dogs[selected].separateLeptospirosis =
                        separateLeptospirosis;
                      // newState.dogs[selected].package.name = membershipName;
                      if (selected === 0 && vaccinationNew0) {
                        newState.dogs[selected].vaccinations = vaccinationNew0;
                      } else if (selected === 1 && vaccinationNew1) {
                        newState.dogs[selected].vaccinations = vaccinationNew1;
                      } else if (selected === 2 && vaccinationNew2) {
                        newState.dogs[selected].vaccinations = vaccinationNew2;
                      }

                      change("dogsNew", newState.dogs);
                      console.log(`NewState: ${newState.dogs}`);

                      change(
                        "vaccinationPictures",
                        dog.vaccinationPictures || []
                      );
                      change("rabies", dog.rabies);
                      change("dhlpp", dog.dhlpp);
                      change("leptospirosis", dog.leptospirosis);
                      change("bordetella", dog.bordetella);
                      change(
                        "separateLeptospirosis",
                        dog.separateLeptospirosis
                      );
                      change("vaccineDog", parseInt(dog.id));
                      this.setState({
                        selected: index,
                        vacs: dog.vaccinationPictures || [],
                        dogs: newState.dogs,
                      });
                    }}
                  >
                    <PictureUpload
                      width={width}
                      containerStyle={{ marginRight: "20px", display: "flex" }}
                      picture={
                        dog.profilePicture.url
                          ? dog.profilePictureCdn
                          //  process.env.REACT_APP_API_ENDPOINT +
                          //   dog.profilePicture.url
                          : null
                      }
                      name={`picture${index}`}
                      onUpload={(v, pic) => {
                        change(`profilePicture${index}`, v);
                      }}
                      height={height}
                      label="Picture"
                      verticalCenter={true}
                    />
                    <div
                      style={{
                        display: shrink ? "block" : "flex",
                        marginTop: "2%",
                        flexDirection: "column",
                        width: shrink ? "100%" : "calc(100% - 150px)",
                      }}
                    >
                      <LabeledTextBox
                        name={`name${index}`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Dog Name"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "65%" }}>
                          <LabeledTextBox
                            name={`color${index}`}
                            widthpercent={"100%"}
                            width={width}
                            height={height}
                            label="Color"
                          />
                        </div>

                        <div style={{ width: "25%" }}>
                          <LabeledTextBox
                            name={`sex${index}`}
                            options={["M", "F"]}
                            widthpercent={"100%"}
                            width={width}
                            height={height}
                            label="Sex"
                          />
                        </div>
                      </div>
                      <LabeledTextBox
                        name={`breed${index}`}
                        width={width}
                        widthpercent={"100%"}
                        height={height}
                        label="Dog Breed"
                      />
                      {/* ************************************************************************************************ */}
                      <BirthCheckBox
                        isNeuteredStatus={dog.isNeutered}
                        name={`isNeutered${index}`}
                        change={change}
                        index={index}
                      />
                      {/* ************************************************************************************************ */}

                      <div style={{ width: "100%" }}>
                        {/* <LabeledTextBox
                          name={`birthdate${index}`}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Birth Date"
                          type="date"
                        /> */}
                        <DatePickerBox
                          name={`birthdate${index}`}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Birth Date"
                          type="date"
                          placeholder="MM/DD/YYYY"
                          change={change}
                          dateType="birthDate"
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <div
                          name={``}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Package"
                        />
                        {console.log(
                          "------------------------------------>",
                          dog.package.name
                        )}
                        Membership Type: {`${dog.package && dog.package.name}`}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {dogs[selected] && (
          <VaccinationUpload
            dog={dogs[selected]}
            vacDates={{
              rabiesDate,
              dhlppDate,
              leptospirosisDate,
              bordetellaDate,
            }}
            change={change}
            selected={selected}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <Button
            type="button"
            containerStyle={{ margin: "auto" }}
            text="Save and Confirm"
            width={width}
            onClick={handleSubmit((data) => {
              this.props.change("saveAndConfirm", true);
              handleSubmit();
            })}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  var userValues = {
    firstName: values.firstName,
    lastName: values.lastName,
    authorizedUser: values.authorizedUser,
    address: values.address,
    address2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    phoneNumber: values.phoneNumber,
    id: props.userId,
    step: null,
  };
  if (values.profilePicture.url === undefined) {
    userValues.profilePicture = values.profilePicture;
  }
  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: userValues } })
    .then(({ data: { updateUser } }) =>
      updateDogsInfo(values, props.client, props.refetch)
    )
    .then(() => addOrUpdateDogVaccinations(values, props.client, props.refetch))
    .then(() => {
      console.log("0000", props.global.currentUser.type);
      if (
        props.global.currentUser.type === "Employee" ||
        props.global.currentUser.type === "Manager" ||
        props.global.currentUser.type === "Admin"
      ) {
        console.log("------------Employee---------------------");
        props.client
          .mutate({
            mutation: CONFIRM,
            variables: {
              input: {
                id: parseInt(props.userId),
              },
            },
          })
          .then(() => {
            if (
              props.global.currentUser.type === "Employee" ||
              props.global.currentUser.type === "Manager" ||
              props.global.currentUser.type === "Admin"
            ) {
              alert(
                "Please have the member accept the park rules before continuing."
              );
              props.history.push({
                pathname: `/members/parkrules`,
                state: { from: props.location.pathname },
              });
              // props.history.push("/members/parkrules");
            }
            // else {
            //   console.log("--->HIyt");
            //   props.history.push("/members/onboarding/complete");
            // }
          })
          .catch((error) => {
            let error_msg = error.message.replace("GraphQL error:", "");
            alert(error_msg);
            // return false;
          });
      } else {
        console.log("------------Employee Not---------------------");

        props.client
          .mutate({
            mutation: SELF_CONFIRM,
            variables: {
              input: {
                id: parseInt(props.userId),
              },
            },
          })
          .then(() => {
            // if (props.global.currentUser.type === "Employee" || props.global.currentUser.type === "Manager" || props.global.currentUser.type === "Admin") {
            //   alert(
            //     "Please have the member accept the park rules before continuing."
            //   );
            //   props.history.push("/members/parkrules");
            // }
            // else {
            console.log("--->HIyt");
            props.history.push({
              pathname: "/members/onboarding/complete",
              state: { from: props.location.pathname },
            });
            // props.history.push("/members/onboarding/complete");
            // }
          })
          .catch((error) => {
            let error_msg = error.message.replace("GraphQL error:", "");
            alert(error_msg);
            // return false;
          });
        // props.history.push("/members/onboarding/complete");
      }
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return true;
};

ConfirmAccountForm = reduxForm({
  form: "ConfirmAccountForm",
  enableReinitialize: true,
  onSubmit: formSubmit,
})(ConfirmAccountForm);

// Decorate with connect to read form values
const selector = formValueSelector("ConfirmAccountForm"); // <-- same as form name
ConfirmAccountForm = connect((state) => {
  const rabiesDate = selector(state, "rabies");
  const dhlppDate = selector(state, "dhlpp");
  const leptospirosisDate = selector(state, "leptospirosis");
  const bordetellaDate = selector(state, "bordetella");
  const vaccinationNew0 = selector(state, "vaccinationNew0");
  const vaccinationNew1 = selector(state, "vaccinationNew1");
  const vaccinationNew2 = selector(state, "vaccinationNew2");
  const separateLeptospirosis = selector(state, "separateLeptospirosis");
  return {
    rabiesDate,
    dhlppDate,
    leptospirosisDate,
    bordetellaDate,
    vaccinationNew0,
    vaccinationNew1,
    vaccinationNew2,
    separateLeptospirosis,
  };
})(ConfirmAccountForm);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(ConfirmAccountForm);
