import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_MEMBERS_LIST, FETCH_TAG_LIST } from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import { Theme, SearchBar, Button } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import TagSelect from "../../../globalComponents/FormComponents/TagSelect.js";
import { SEND_MESSAGE_MEMBERS } from "../../../store/mutations.js";
import CheckedNeutered from "../../../assets/check_neutered.svg";
import { uniq as _uniq } from "lodash";

class MembersTags extends Component {
  state = {
    tab: 0,
    search: "",
    page: 1,
    per: 20,
    tagName: "Choose tag to add",
    tagId: "",
    tagList: [],
    membersList: [],
    selectedMembers: {},
  };

  handleSelect = (option, name) => {
    this.setState({ [name]: option });
  };

  handleChange = (value) => {
    console.log("Value---->", value);
    this.setState({ search: value });
  };

  searchMember = () => {
    const { client } = this.props;
    const { search } = this.state;
    let searchValue = String(search).trim().replace(/\s/g, "");
    let searchArray = searchValue.split(",");
    client
      .query({
        query: FETCH_MEMBERS_LIST,
        fetchPolicy: "no-cache",
        variables: { keyword: searchArray },
      })
      .then((response) => {
        this.setState({
          membersList: response.data?.fetchMembers,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  sendMessageMembers = () => {
    const { client } = this.props;
    const { tagId, selectedMembers } = this.state;
    let selectedMembersIds = [];
    const checkedMembers = Object.entries(selectedMembers);
    const membersIds = _uniq(checkedMembers.map((val) => val[1]));
    for (const userId of membersIds) {
      selectedMembersIds.push(userId);
    }
    let values = {
      tagId,
      userIds: selectedMembersIds,
    };
    if (tagId !== "" && selectedMembersIds.length > 0) {
      client
        .mutate({
          mutation: SEND_MESSAGE_MEMBERS,
          variables: { input: values },
        })
        .then((response) => {
          alert("Message sent successfully");
          this.setState({
            tagName: "Choose tag to add",
            tagId: "",
            search: "",
            selectedMembers: {},
            membersList: [],
          });
          selectedMembersIds = [];
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    } else {
      alert("Please select Tag and Members");
    }
  };

  selectMembers = (_event, id, index) => {
    let { selectedMembers } = this.state;
    if (id in selectedMembers) {
      delete selectedMembers[id];
    } else {
      selectedMembers[id] = id;
    }
    this.setState({ selectedMembers });
  };

  render() {
    const { search, per, page, tagName, tagId, membersList, selectedMembers } =
      this.state;
    const { global } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const role = global.currentUser.type;
    const verySmall = width < 568;
    const shrink = width < 700;
    return (
      <div>
        <div
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            marginTop: "0%",
            marginBottom: "4%",
            width: "100%",
            color: "white",
            alignItems: "center",
            alignContent: "center",
            // fontSize: Theme.getHeaderFont(width),
            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          Members Tags
        </div>
        <div>
          <div style={{ marginTop: 20 }}>
            <h2
              style={{
                fontSize: "29px",
                fontFamily: "LatoBold",
                color: "#000",
                textAlign: "center",
              }}
            >
              Add Tags To Members
            </h2>
            <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
              {({ loading, error, data, refetch }) => {
                if (loading) return <div></div>;
                if (error) return `Error: ${error.message}`;
                return (
                  <div>
                    <div
                      style={{
                        width: shrink ? "80%" : "50%",
                        margin: "auto",
                      }}
                    >
                      <TagSelect
                        alt={true}
                        options={data.fetchTags}
                        handleSelect={(tag, name) => {
                          this.setState({
                            tagName: tag.title,
                            tagId: tag.id,
                          });
                        }}
                        selected={tagName}
                        name="tagId"
                        width={"100%"}
                        height={height / 25}
                        label="Tags"
                      />
                    </div>
                  </div>
                );
              }}
            </Query>
          </div>
          <div style={{ marginTop: 20 }}>
            <div
              style={{
                width: shrink ? "80%" : "50%",
                margin: "auto",
              }}
            >
              <SearchBar
                value={search}
                containerStyle={{
                  marginBottom: "2%",
                  marginRight: "1%",
                  position: "relative",
                  display: `${width < 900 ? "block" : "inline-block "}`,
                  width: shrink ? "100%" : "89%",
                }}
                placeholder="Search"
                onChange={(v) => this.handleChange(v)}
                width={verySmall ? "94.5%" : "98%"}
                height={verySmall ? "48px" : height / 25}
              />

              <div
                style={{
                  margin: `${
                    width < 900
                      ? width < 568
                        ? "15px auto 10px"
                        : "0px auto 10px"
                      : "0 0 0 auto "
                  }`,
                  display: `${width < 900 ? "block" : "inline-block "}`,
                  flexDirection: `${width < 900 ? "column" : "row"}`,
                  width: "10%",
                }}
              >
                <Button
                  text={`Search`}
                  fullwidth={false}
                  onClick={() => this.searchMember()}
                  disabled={search === ""}
                  width="100%"
                  height={height / 25}
                  href="/staff"
                />
              </div>
            </div>
          </div>

          {membersList.length > 0 && (
            <div style={{ marginTop: 20 }}>
              <div
                style={{
                  width: shrink ? "80%" : "50%",
                  margin: "auto",
                }}
              >
                <h2
                  style={{
                    fontSize: "20px",
                    fontFamily: "LatoBold",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Member List
                </h2>
              </div>

              <div
                style={{
                  width: shrink ? "80%" : "50%",
                  margin: "auto",
                  maxHeight: "175px",
                  overflowY: "auto",
                  paddingLeft: "2px",
                }}
              >
                {membersList.map((data, index) => {
                  let member_id = data.id;
                  let members_name = `${data.firstName} ${data.lastName} `;
                  return (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          marginBottom: "10px",
                        }}
                      >
                        <button
                          onClick={(e) =>
                            this.selectMembers(e, member_id, index)
                          }
                          type="button"
                          style={{
                            width: 25,
                            height: 25,
                            border: "2px solid",
                            cursor: "pointer",
                            borderColor: "#49c172",
                            outline: "none",
                            borderRadius: 5,
                            padding: "0px",
                            background:
                              selectedMembers[member_id] || false
                                ? "#49c172"
                                : "transparent",
                          }}
                        >
                          {selectedMembers[member_id] || false ? (
                            <img
                              active={false}
                              alt="Active"
                              src={CheckedNeutered}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          ) : null}
                        </button>
                        <div
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: "5px",
                            width: "100%",
                          }}
                        >
                          <span> {members_name}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
              <div
          style={{
            width: Theme.getButtonWidth(width),
            margin: "auto",
            marginTop: 25,
          }}
        >
          <Button
            text="Submit"
            alt={false}
            width={width}
            onClick={() => this.sendMessageMembers()}
          />
        </div>
        </div>

    
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(MembersTags);
