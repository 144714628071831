import React, { Component, Fragment } from "react";
import Theme from "../Theme.js";
import Check from "../../assets/paw-inactive.svg";
import { Field } from "redux-form";

export default class CheckBox extends Component {
  getWidth(width) {
    if (width > 400) return 100;
    return 50;
  }
  render() {
    const { label, name, width, containerStyle, onClick } = this.props;

    const renderField = ({ input, meta: { touched, error, warning } }) => (
      <Fragment>
        <button
          onClick={() => {
            input.onChange(!input.value);
            if (onClick) onClick(!input.value);
          }}
          type="button"
          style={{
            width: 25,
            height: 25,
            border: "2px solid",
            cursor: "pointer",
            borderColor: Theme.LINK_FONT_COLOR,
            outline: "none",
            borderRadius: 5,
            backgroundColor: input.checked ? Theme.LINK_FONT_COLOR : "white"
          }}
        >
          {input.checked ? (
            <img
              alt="checked"
              src={Check}
              style={{
                marginLeft: -5,
                marginTop: 1,
                width: 16,
                height: 16,
                color: "white"
              }}
            />
          ) : null}
        </button>
        {touched &&
          ((error && (
            <span
              style={{
                fontSize: Theme.getSmallFont(width),
                color: "red",
                margin: "auto"
              }}
            >
              {error}
            </span>
          )) ||
            (warning && <span>{warning}</span>))}
      </Fragment>
    );

    return (
      <div style={containerStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 5,
            marginBottom: 5,
            width: "100%"
          }}
        >
          <Field name={name} component={renderField} type="checkbox" />
          <div
            style={{
              color: Theme.DARK_TEXT_COLOR,
              fontSize: Theme.getNormalFont(width) / 1.2,
              fontFamily: Theme.MAIN_FONT,
              margin: "auto",
              marginLeft: "2%"
            }}
          >
            {label}
          </div>
        </div>
      </div>
    );
  }
}
