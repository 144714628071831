import React, { Component } from "react";
import Theme from "../Theme.js";
import { LabeledTextBox, Button } from "../../globalComponents";
import Add from "../../assets/plus-add.svg";

export default class Positions extends Component {
  state = {
    positions: this.props.data,
    positionModalOpen: false
  };

  getHeight(width) {
    if (width > 450) return 200;
    else if (width > 400) return 150;
    return 200;
  }
  getWidth(width) {
    if (width > 450) {
      return 130;
    } else if (width > 400) {
      return 80;
    }
    return 50;
  }

  handlePositionModal = () => {
    let newState = { ...this.state };
    this.props.change("positionNew", "");
    this.setState({ positionModalOpen: !newState.positionModalOpen });
  };

  addPosition = () => {
    if (
      this.props.positionNew === "Manager" ||
      this.props.positionNew === "manager"
    ) {
      alert(
        "The position name Manager is reserved for managers. Please use the checkbox to indicate that a Staff member is a manager."
      );
    } else if (!this.props.positionNew) {
      alert("The position name cannot be blank.");
    } else {
      let newState = { ...this.state };
      newState.positions.push(this.props.positionNew);
      this.setState({ positions: newState.positions });
      this.handlePositionModal();
    }
  };

  deletePosition = index => {
    let newState = { ...this.state };
    newState.positions.splice(index, 1);
    this.setState({ positions: newState.positions });
  };

  render() {
    const { widthFactor, data, containerStyle } = this.props;
    const { positions } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    this.props.change("positions", positions);
    return (
      <div style={containerStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2%",
            marginTop: "2%",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Positions
          <button
            style={{
              display: "flex",
              flexDirection: "space-between",
              width: "15%",
              outline: "none",
              margin: "auto 0 auto auto",
              cursor: "pointer",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: Theme.MAIN_COLOR,
              fontSize: Theme.getSmallFont(width)
            }}
            onClick={() => this.handlePositionModal()}
          >
            <img
              src={Add}
              alt=""
              style={{ marginTop: "2%", marginRight: "10%" }}
            />
            add
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            outline: "none",
            height: this.getHeight(width),
            border: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            borderRadius: 10,
            padding: "2%",
            textDecoration: "none",
            width: Theme.getLongInputWidth(width) / (widthFactor || 1),
            textAlign: "left",
            overflow: "-moz-scrolbars-vertical",
            overflowY: "scroll"
          }}
        >
          {positions.map((row, i) => {
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: 50,
                  width: "100%",
                  fontSize: Theme.getSmallFont(width),
                  color: Theme.LIGHT_TEXT_COLOR
                }}
              >
                <div
                  style={{
                    alignContent: "center",
                    justifyItems: "center",
                    display: "flex",
                    height: this.getHeight(width) / 10,
                    marginBottom: "2%",
                    flexDirection: "row",
                    fontFamily: Theme.HEADER_FONT,
                    fontSize: Theme.getHeaderFont(width),
                    color: Theme.HEADER_FONT_COLOR
                  }}
                >
                  {row}
                  <button
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      textDecoration: "none",
                      outline: "none",
                      height: "100%",
                      marginLeft: "auto",
                      fontSize: Theme.getNormalFont(width),
                      cursor: "pointer",
                      color: Theme.MAIN_COLOR
                    }}
                    onClick={() => this.deletePosition(i)}
                  >
                    X
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/* Add Position Modal */}
        <div
          id="positionModal"
          style={{
            display: `${this.state.positionModalOpen ? "flex" : "none"}`,
            position: "fixed",
            zIndex: 999,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.8)"
          }}
        >
          <div
            style={{
              margin: "auto",
              width: "500px",
              height: "200px",
              position: "relative",
              backgroundColor: "white",
              padding: 10,
              borderRadius: "15px"
            }}
          >
            <div id="dogModalContainer" style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex"
                }}
              >
                <div
                  style={{
                    width: "75%",
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "5%",
                      fontSize: 18,
                      fontFamily: "Lato"
                    }}
                  >
                    Add Position
                  </div>
                  <div
                    style={{
                      width: "100%"
                    }}
                  >
                    <LabeledTextBox
                      onBlur={() => {}}
                      name={`positionNew`}
                      width={width}
                      widthpercent={"100%"}
                      height={height}
                      label="Position Name"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 20
                    }}
                  >
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Cancel"
                      width={width}
                      onClick={() => this.handlePositionModal()}
                    />
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Add Position"
                      width={width}
                      onClick={() => this.addPosition()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of Position Modal */}
      </div>
    );
  }
}
