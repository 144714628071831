import gql from "graphql-tag";

export const USER_LOG_IN = gql`
  mutation SignIn($input: SignIn!) {
    signIn(input: $input) {
      auth {
        authenticationToken
      }
      user {
        id
        type
        step
        park {
          id
          name
        }
        email
        completed
        profilePictureCdn
        profilePicture {
          url
        }
      }
    }
  }
`;

export const USER_SIGN_UP = gql`
  mutation SignUp($input: SignUp!) {
    signUp(input: $input) {
      errors {
        message
      }
      user {
        id
        email
      }
      auth {
        authenticationToken
      }
    }
  }
`;

export const USER_UPDATE = gql`
  mutation UpdateUser($input: UpdateUser) {
    updateUser(input: $input) {
      errors {
        message
      }
      user {
        type
        id
        zip
        email
        notes {
          content
          tags
        }
        park{
          id
          name
        }
      }
    }
  }
`;

export const USER_UPDATE_INFO = gql`
  mutation UpdateProfile($input: UpdateProfile!) {
    updateProfile(input: $input) {
      errors {
        message
      }
      user {
        id
        
      }
    }
  }
`;
export const USER_UPDATE_ADDRESS = gql`
  mutation UpdateAddress($input: UpdateAddress!) {
    updateAddress(input: $input) {
      errors {
        message
      }
      user {
        id
        
      }
    }
  }
`;
export const DOG_SPRAYED_UPDATE = gql`
  mutation updateSprayedDog($input: UpdateSprayDog!) {
    updateSprayedDog(input: $input) {
      errors {
        message
      }
      dog{
        id
        isNeutered
      }
    }
  }
`;
export const DOG_STRIKE_UPDATE = gql`
  mutation updateDogStrike($input: UpdateStrikeDog!) {
    updateDogStrike(input: $input) {
      
      dog{
        id
        isNeutered
      }
    }
  }
`;

export const USER_PARK_UPDATE = gql`
  mutation changeHomePark($input: ChangePark!) {
    changeHomePark(input: $input) {
    
      user{
        id
        park{
            id
            name
        }
      }
    }
  }
`;

export const DOG_UPDATE = gql`
  mutation UpdateDog($input: UpdateDog!) {
    updateDog(input: $input) {
      errors {
        message
      }
      dog {
        id
        color
        name
        age
        birthdate
        sex
        package {
          id
          name
          amountString
          description
        }
        subscription {
          status
        }
      }
    }
  }
`;

export const SUBCRIPTION_UPDATE = gql`
  mutation updatePausedStatus($input:EditPausedStatus!) {
    updatePausedStatus(input: $input) {
      errors {
        message
      }
      dog{
        id
        pausedSubscription
      }
    }
  }
`;
export const CREATE_DOG_PACKAGE = gql`
mutation CreateDogPackage($input: [CreateDogPayment!]!, $userId:ID!, $stripeCardToken:String!) {
  createDogPackage(input: $input , userId :$userId, stripeCardToken:$stripeCardToken ) {
    dogs {
        id
        color
        name
        age
        birthdate
        sex
        package {
          id
          name
          amountString
          description
        }
        subscription {
          status
        }
      }
  
  }
}

`;

export const CREATE_NOTE = gql`
  mutation addNote($input: CreateUserNoteInput!) {
    createUserNote(input: $input) {
      note {
        content
        tags
        id
        author {
          firstName
          lastName
        }
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($input: UpdateUserNoteInput!) {
    updateUserNote(input: $input) {
      note {
        content
        tags
        id
        author {
          firstName
          lastName
        }
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const ADD_DOGS = gql`
  mutation AddDogs($input: [CreateDog!]!) {
    addDogs(input: $input) {
      dogs {
        name
        age
        bordetella
        color
        dhlpp
        id
        breed
        leptospirosis
        profilePicture {
          name
          url
        }
        isNeutered
        rabies
        separateLeptospirosis
        sex
        birthdate
        vaccinations {
          expiresOn
          type
          pictures {
            name
            url
          }
        }
      }
    }
  }
`;

export const UPDATE_DOG = gql`
  mutation UpdateDog($input: UpdateDog!) {
    updateDog(input: $input) {
      dog {
        id
      }
      errors {
        message
      }
    }
  }
`;
export const UPDATE_DOG_VACCINATION_DOCUMENT = gql`
  mutation updateDogVaccination($input: UpdateDogVaccinationsInput!) {
    updateDogVaccination(input: $input) {
      dog {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const CHECK_IN_PHONE = gql`
  mutation SendVerificationCode($input: SendVerificationCode!) {
    sendVerificationCode(input: $input) {
      errors {
        message
       
      }
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation verifyCode($input: SubmitVerificationCode!) {
    submitVerificationCode(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const CONFIRM = gql`
  mutation ConfirmMember($input: ConfirmMember!) {
    confirmMember(input: $input) {
      errors {
        message
      }
    }
  }
`;


export const SELF_CONFIRM = gql`
  mutation ConfirmSelfMember($input: ConfirmMember!) {
    confirmSelfMember(input: $input) {
      errors {
        message
      }
    }
  }
`;


export const REQUEST_RESET = gql`
  mutation SendResetPasswordInstructions(
    $input: SendResetPasswordInstructions!
  ) {
    sendResetPasswordInstructions(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const BULK_CHECKIN = gql`
  mutation checkIn($input: CheckInInput!) {
    checkIn(input: $input) {
      users {
        firstName
        lastName
        confirmed
      }
      errors {
        message
        path
      }
    }
  }
`;

export const UPDATE_VACCINATIONS = gql`
  mutation updateVaccinations($input: UpdateVaccinationsInput!) {
    updateVaccinations(input: $input) {
      dog {
        id
        vaccinations {
          expiresOn
          type
          pictures {
            name
            url
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const DELETE_VACCINATIONS = gql`
  mutation deleteVaccinations($input: DeleteVaccinationsInput!) {
    deleteVaccinations(input: $input) {
      dog {
        id
        vaccinations {
          expiresOn
          type
          pictures {
            name
            url
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee($input: CreateUser!) {
    createEmployee(input: $input) {
      user {
        id
      }
    }
  }
`;

export const CREATE_MANAGER = gql`
  mutation createManager($input: CreateUser!) {
    createManager(input: $input) {
      user {
        id
      }
    }
  }
`;

export const CREATE_PARK = gql`
  mutation CreatePark($input: CreatePark!) {
    createPark(input: $input) {
      park {
        id
        name
        phoneNumber
        address
        address2
        city
        state
        timezoneFullname
        timezone
        hours {
          daysOfWeek
          hours
        }
        generalManager
        generalManagerPhoneNumber
        generalManagerEmail
        generalManagerProfilePicture {
          url
          name
        }
      }
    }
  }
`;

export const UPDATE_PARK = gql`
  mutation UpdatePark($input: ParkInput!) {
    updatePark(input: $input) {
      park {
        name
        phoneNumber
        address
        address2
        city
        state
        timezoneFullname
        timezone
        hours {
          daysOfWeek
          hours
        }
        generalManager
        generalManagerPhoneNumber
        generalManagerEmail
        generalManagerProfilePicture {
          url
          name
        }
      }
    }
  }
`;

export const CREATE_COUPON_CODE = gql`
  mutation CreateCouponCodes($input: CreateCouponCodes!) {
    createCouponCodes(input: $input) {
      codes
    }
  }
`;

export const APPLY_COUPON_CODE = gql`
  mutation ApplyCoupon($input: ApplyCoupon!) {
    applyCoupon(input: $input) {
      message
    }
  }
`;

export const DELETE_DOG = gql`
  mutation deleteDog($id: ID!) {
    deleteDog(id: $id) {
      success
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteUserNote($id: ID!) {
    deleteUserNote(id: $id) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($id: ID!) {
    deleteAccount(id: $id) {
      success
    }
  }
`;

export const ACCEPT_RULES = gql`
  mutation AcceptRules($input: AcceptRules!) {
    acceptRules(input: $input) {
      signatureDataUrl
      user {
        id
        acceptedRules
      }
    }
  }
`;



export const PARK_CLOSURE = gql`
  mutation ParkClosure($input: ParkClosure!) {
    parkClosure(input: $input) {
     park{
       name
     }
    }
  }
`;



export const ADD_TAG = gql`
  mutation addTag($input: AddTag!) {
    addTag(input: $input) {
    tag  {
      id
      title
      description
    }
    }
  }
`;

export const ADD_EVENT_TAG = gql`
  mutation addEvent($input: AddEvent!) {
    addEvent(input: $input) {
      event{
        id 
        eventName
        tagName
        eventDate
        startTime
        endTime
        createdBy
        tageId
        eventType
        allDayStatus
        description
        endDate
        park{
          id
          name
        }
        eventTags{
          id
          tagName
          tageId
        }
      }
    }
  }
`;

export const UPDATE_EVENT_TAG = gql`
  mutation updateEvent($input: UpdateEvent!) {
    updateEvent(input: $input) {
      event{
        id 
        eventName
        tagName
        eventDate
        startTime
        endTime
        createdBy
        tageId
        eventType
        allDayStatus
        endDate
        description
        park{
          id
          name
        }
        eventTags{
          id
          tagName
          tageId
        }
      }
    }
  }
`;


export const DELETE_EVENT_TAG = gql`
  mutation destroyEvent($id: ID!) {
    destroyEvent(id: $id) {
      success
    }
  }
`;

export const SEND_MESSAGE_PARK_MEMBERS = gql`
  mutation sentTagMessage($input: SentTagMessage!) {
    sentTagMessage(input: $input) {
    tag  {
      id
    }
    }
  }
`;
export const SEND_MESSAGE_MEMBERS = gql`
  mutation addMemberTag($input: AddMemberTag!) {
    addMemberTag(input: $input) {
      tag{
        id
      }
    }
  }
`;


export const UPDATE_TAG = gql`
  mutation updateTag($input: UpdateTag!) {
    updateTag(input: $input) {
      tag  {
        id
        title
        description
      }
    }
  }
`;


export const BANNED_MEMBER = gql`
  mutation bannedMember($input: BannedMember!) {
    bannedMember(input: $input) {
    dog{
      id
    }
    }
  }
`;