import React from "react";
import { connect } from "react-redux";
import Theme from "../../Theme";
import UP from "../../../assets/up.png";
import DOWN from "../../../assets/down.png";

const styles = {

  header: {
    fontSize: "90%",
    fontFamily: Theme.MAIN_FONT,
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    color: "white"
  },
  altHeader: {
    fontFamily: Theme.MAIN_FONT,
    color: Theme.DARK_TEXT_COLOR,
    borderBottom: "1.4px solid #C8CFD9"
  },
  noLabelHeader: {
    backgroundImage: "linear-gradient(to right, #FC7A4D,#D1400D)",
    width: 10
  },
  altNoLabel: {
    width: 10
  },
  row: {
    fontSize: 23,
    color: "#3F3F3F"
  }
};

let sortStyle = {
  float: "right",
  textAlign: "center",
  color: "#222222",
  opacity: "1",
  margin: "0 0 0px",
  display: "inline-flex",
  position: "relative",
  width: "30px",
  height: "28px",
};

let upStyle = {
  color: "#313131",
  position: "absolute",
  border: "transparent",
  top: "-2px",
  background: "transparent",
  right: "0px",
  padding: "0",
  width: "18px",
  height: "16px",
  fontSize: "18px",
  lineHeight: "4px",
  left: "0px",
  margin: "auto",
  cursor: "pointer"
};

let downStyle = {
  position: "absolute",
  border: "transparent",
  top: "10px",
  background: "transparent",
  right: "0px",
  padding: "0",
  width: "18px",
  height: "16px",
  fontSize: "18px",
  color: "#313131",
  lineHeight: "16px",
  left: "0px",
  margin: "auto",
  cursor: "pointer"
};

function EventCell({ label, renderCell, header, alt, global, sorting }) {
  const obj = global || { dimensions: { height: 0, width: 1000 } };
  const width = obj.dimensions.width;
  const shrink = width < 700;
  return (
    <th
      style={
        header
          ? label
            ? alt
              ? {
                ...styles.altHeader,
                fontSize: shrink ? "11px" : "90%",
              }
              : {
                ...styles.header,
                fontSize: shrink ? "11px" : "90%",
              }
            : alt
              ? styles.altNoLabel
              : styles.noLabelHeader
          : {
            ...styles.row,
            fontSize: shrink ? "11px" : "90%",
          }
      }
    >
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 0 }}>
        <div
          style={{
            marginRight: 20,
            marginBottom: "auto",
            marginTop: "auto",
            width: "100%",
            height: "100%"
          }}
        >
          {renderCell ? renderCell : label}
          &nbsp;{
            (header && label === "Date & Time") && <div style={sortStyle}>
              <button
                style={upStyle}
                onClick={(e) => sorting(e, "event_date", "ASC")}
              >
                <img src={UP} width="12px" alt="ASC" />
              </button>
              <button
                style={downStyle}
                onClick={(e) => sorting(e, "event_date", "DESC")}
              >
                <img src={DOWN} width="12px" alt="DESC" />
              </button>
            </div>
          }
            &nbsp;{(header && label === "Start Time") && <div style={sortStyle}>
            <button
              style={upStyle}
              onClick={(e) => sorting(e, "start_time", "ASC")}
            >
              <img src={UP} width="12px" alt="ASC" />
            </button>
            <button
              style={downStyle}
              onClick={(e) => sorting(e, "start_time", "DESC")}
            >
              <img src={DOWN} width="12px" alt="DESC" />
            </button>
          </div>
          }
            &nbsp;
            {/* {
            (header && label === "Created By") && <div style={sortStyle}>
              <button
                style={upStyle}
                onClick={(e) => sorting(e, "current_user", "ASC")}
              >
                <img src={UP} width="12px" alt="ASC" />
              </button>
              <button
                style={downStyle}
                onClick={(e) => sorting(e, "current_user", "DESC")}
              >
                <img src={DOWN} width="12px" alt="DESC" />
              </button>
            </div>
          } */}
        </div>
      </div>
    </th>
  );
};

const mapStateToProps = state => ({ ...state.global });

export default connect(
  mapStateToProps,
  null
)(EventCell);