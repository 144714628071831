export const updateDimensions = params => dispatch => {
  dispatch({ type: "UPDATE_DIMENSIONS", payload: params });
};

export const updateUser = params => dispatch => {
  dispatch({ type: "CURRENT_USER", payload: params });
};

export const checkInDog = params => dispatch => {
  dispatch({ type: "CHECKED_IN", payload: params });
};

