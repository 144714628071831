import React, { Component } from "react";
import Theme from "../Theme.js";
import DogBone from "../../assets/dogbone.svg";

export default class SelectionCard extends Component {
  getHeight(width) {
    if (width > 450) return 320;
    else if (width > 400) return 270;
    return 250;
  }
  getWidth(width) {
    if (width > 450) {
      return 175;
    } else if (width > 400) {
      return 145;
    }
    return 110;
  }
  getTitle(recur) {
    if (recur === "Daily") {
      return "Daily";
    } else if (recur === "Monthly") {
      return "Monthly";
    } else if (recur === "Yearly") {
      return "Yearly";
    } else if (recur === "Month to Month") {
      return "Month to Month";
    }
  }
  getPer(recur) {
    if (recur === "Daily") {
      return "/Day";
    } else if (recur === "Yearly") {
      return "/YR";
    } else {
      return "/MO";
    }
  }

  render() {
    const {
      selected,
      onClick,
      width,
      containerStyle,
      recurence,
      price,
      description
    } = this.props;

    return (
      <div style={containerStyle}>
        <button
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            margin: "1em auto",
            outline: "none",
            border: "1px solid",
            borderColor: Theme.MAIN_COLOR,
            boxShadow: selected ? "1px 1px 50px #606267" : "none",
            padding: "5%",
            width: selected ? this.getWidth(width) * 1.2 : this.getWidth(width),
            height: selected
              ? this.getHeight(width) * 1.3
              : this.getHeight(width),
            textDecoration: "none",
            textAlign: "center",
            paddingTop: "10%",
            paddingBottom: "10%",
            color: Theme.MAIN_COLOR,
            fontFamily: Theme.INPUT_FONT,
            alignItems: "center"
          }}
          type="button"
          onClick={onClick}
        >
          <img src={DogBone} alt="" />
          <div
            style={{
              fontSize: Theme.getNormalFont(width),
              marginTop: "10%",
              fontFamily: Theme.HEADER_FONT
            }}
          >
            {this.getTitle(recurence)}
          </div>
          <div
            style={{
              color: Theme.DARK_TEXT_COLOR,
              display: "flex",
              flexDirection: "row",
              margin: "10%"
            }}
          >
            <div style={{ marginBottom: "auto" }}>$</div>
            <div style={{ fontSize: Theme.getHeaderFont(width) }}>
              {price.replace('$', '')}
            </div>
            <div
              style={{
                marginTop: "auto",
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: Theme.getSmallFont(width)
              }}
            >
              {this.getPer(recurence)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              fontSize: Theme.getSmallFont(width)
            }}
          >
            {description.map((text, index) => {
              return (
                <p key={index} style={{ marginTop: "-1%" }}>
                  {text}
                </p>
              );
            })}
          </div>
        </button>
      </div>
    );
  }
}
