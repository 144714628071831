import React, { Component } from "react";
import Theme from "../Theme.js";
import { DirectUpload } from "@rails/activestorage";
import imageCompression from 'browser-image-compression';

export default class FileUpload extends Component {
  state = {
    tempPhoto: null
  };
  getHeight(width) {
    if (width > 450) return 50;
    if (width > 400) return 50;
    if (width > 300) return 40;
    return 90;
  }
  getWidth(width) {
    if (width > 450) return 250;
    if (width > 400) return 140;
    if (width > 300) return 120;
    else return 60;
  }

  uploadFile = async event => {
    const url =
      process.env.REACT_APP_API_ENDPOINT +
      "/rails/active_storage/direct_uploads";
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    let file = event.target.files[0];
    let file_type = file.type
    if (file_type === "image/png" || file_type === "image/webp" || file_type === "image/jpeg" || file_type === "image/svg+xml") {
      const compressedFile = await imageCompression(file, options);
      const upload = new DirectUpload(compressedFile, url);
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ tempPhoto: reader.result });
      };
      if (file !== undefined) {
        reader.readAsDataURL(file);
        // try {
        //   upload.create((error, blob) => {
        //     if (error) {
        //       console.error(error);
        //       alert("failed upload");
        //       return;
        //     }
        //     this.props.onUpload(blob, reader.result);
        //   });
        // } catch (error) {
        //   console.log(error);
        // }
        try {
          upload.create((error, blob) => {
            if (error) {
              console.error(error);
              alert("failed upload");
              return;
            }
            console.log("Blob--->", blob);
            this.props.onUpload(blob, reader.result);
          });
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

          // await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(error);
        }
      }
    }
    else {
      const upload = new DirectUpload(event.target.files[0], url);
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        this.setState({ tempPhoto: reader.result });
      };
      if (file !== undefined) {
        reader.readAsDataURL(file);

        upload.create((error, blob) => {
          if (error) {
            console.error(error);
            alert("failed upload");
            return;
          }
          this.props.onUpload(blob, reader.result);
        });

      }
    }

  };

  handleClick = event => {
    const { target = {} } = event || {};
    target.value = "";
  };

  render() {
    const { name, width, height, containerStyle, label } = this.props;
    // const width = obj.dimensions.width;
    const shrink = width < 568;
    return (
      <div style={containerStyle}>
        <input
          id={name}
          name={name}
          type="file"
          style={{
            width: 0.1,
            height: 0.1,
            opacity: 0,
            overflow: "hidden",
            position: "absolute",
            zIndex: -1
          }}
          onChange={this.uploadFile}
          onClick={this.handleClick}

        />
        <label
          htmlFor={name}
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            backgroundColor: Theme.LINK_FONT_COLOR,
            // marginBottom: height / 100,
            borderRadius: 5,
            height: this.getHeight(width),
            width: shrink ? "fit-content" : this.getWidth(width),
            ...this.props.customStyle

          }}
        >
          <div
            style={{
              color: "white",
              fontSize: Theme.getSmallFont(width),
              fontFamily: Theme.HEADER_FONT,
              margin: "auto",
              padding: 10,
              textAlign: "center"
            }}
          >
            {label || "Upload Documents"}
          </div>
        </label>
      </div>
    );
  }
}
