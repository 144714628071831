import React, { Component } from "react";
import { Theme, ProfileButton } from "../";
import Logo from "../../assets/fetch-logo.svg";
import Arrow from "../../assets/left-arrow.svg";
import Menu from "../../assets/menu.png";
import { connect } from "react-redux";
import { updateUser } from "../../store/redux/actions";
import cookie from "js-cookie";
import { USER_DETAILS } from "../../store/queries.js";
import { Query } from "react-apollo";
import { sessionCheck } from "../../utils/session";
//import Theme from '../Theme.js'

class StaffNavBar extends Component {
  state = { tab: 0 };

  render() {
    const { title, backButton, global } = this.props;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    console.log(width);
    const mobile = width < 600;
    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        color: "white",
        fontFamily: Theme.MAIN_FONT,
        fontSize: Theme.getNormalFont(width),
        cursor: "pointer"
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "rgba(250,250,250, .3)",
        borderRadius: 5,
        padding: 10,
        border: "none",
        color: "white",
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getNormalFont(width),
        cursor: "pointer"
      }
    };
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{
          id: global.currentUser.id
        }}
      >
        {({ loading, error, data, refetch }) => {

          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          return (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: "0 2% 0 2%",
                backgroundRepeat: "round",
                backgroundColor: Theme.MAIN_COLOR
              }}
            >
              <div
                name="headerContainer"
                style={{
                  display: "flex",
                  flexDirection: mobile ? "column" : "row",
                  justifyContent: mobile ? null : "space-between",
                  alignItems: "center"
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    flex: 0,
                    flexShrink: -1,
                    alignSelf: "center",
                    width: 100,
                    textAlign: mobile ? "left" : "center"
                  }}
                />
                <ProfileButton
                  history={this.props.history}
                  picture={
                    data &&
                    data.userDetail &&
                    // process.env.REACT_APP_API_ENDPOINT +
                    // data.userDetail.profilePicture.url
                    data.userDetail.profilePictureCdn
                  }
                  width={width}
                  containerStyle={{ marginLeft: "auto", marginRight: 10 }}
                />
              </div>
              <div
                style={{
                  marginLeft: `${backButton ? "0%" : "8%"}`,
                  marginRight: "auto",
                  display: "flex",
                  marginTop: 0,
                  marginBottom: 85,
                  color: "white",
                  alignItems: "center",
                  alignContent: "center",
                  fontSize: Theme.getHeaderFont(width),
                  fontFamily: Theme.HEADER_FONT,
                  height: 50,
                  cursor: "pointer"
                }}
                onClick={() => this.props.history.goBack()}
              >
                {backButton && (
                  <button
                    onClick={() => this.props.history.goBack()}
                    style={styles.navButton}
                  >
                    <img
                      style={{ opacity: 0.3, height: 40, marginTop: 8 }}
                      alt=""
                      src={Arrow}
                    />
                  </button>
                )}
                {/* {title} */}
                {backButton ? "Home" : title}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffNavBar);
