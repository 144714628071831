import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { TextBox, Button } from "../../globalComponents";
import { connect } from "react-redux";
import Logo from "../../assets/fetch-logo.svg";
import Bg from "../../assets/fetch-BG.svg";
import { compose, withApollo } from "react-apollo";
import { reduxForm, SubmissionError } from "redux-form";
import { CHECK_IN_PHONE } from "../../store/mutations.js";

class Phone extends Component {
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, error } = this.props;

    return (
      <form>
        <div
          style={{
            display: "flex",
            flex: 1,
            backgroundRepeat: "round",
            backgroundColor: Theme.BACKGROUND_COLOR,
            height: height,
            backgroundImage: `url(${Bg})`
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "5%",
              height: "60%",
              width: "40%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img
              alt=""
              src={Logo}
              style={{ height: "35%", marginBottom: "10%" }}
            />
            <div
              style={{
                marginBottom: "2%",
                fontSize: Theme.getHeaderFont(width),
                fontFamily: Theme.HEADER_FONT,
                color: Theme.HEADER_FONT_COLOR
              }}
            >
              Check In
            </div>

            <TextBox
              name="phoneNumber"
              containerStyle={{ margin: "2% 0 2% 0" }}
              width={Theme.getInputWidth(width)}
              height={height / 20}
              placeholder="Enter Phone Number"
              type="text"
            />
            {error && (
              <div
                style={{
                  width: "60%",
                  margin: "1em auto",
                  textAlign: "center"
                }}
              >
                <strong style={{ color: "red" }}>{`${error}`}</strong>
              </div>
            )}
            <Button
              text="Submit"
              fullwidth={true}
              width={width}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  return props.client
    .mutate({
      mutation: CHECK_IN_PHONE,
      variables: { input: { phoneNumber: values.phoneNumber } }
    })
    .then(() => {
      props.history.push({
        pathname: "/checkin/code",
        state: { phoneNumber: values.phoneNumber }
      });
    })
    .catch(error => {
      if (error.graphQLErrors.length > 0) {
        throw new SubmissionError({ _error: error.graphQLErrors[0].message });
      } else {
        throw new SubmissionError({
          _error: "Something went wrong. Please try again."
        });
      }
    });
};

Phone = reduxForm({
  form: "DogInfo",
  onSubmit: formSubmit
})(Phone);

export default compose(
  withApollo,
  connect(mapStateToProps)
)(Phone);
