import React, { Component } from "react";
import { Theme, ProfileButton } from "../";
import Logo from "../../assets/fetch-logo.svg";
import Arrow from "../../assets/left-arrow.svg";
import { connect } from "react-redux";
import { Query } from "react-apollo";
import { USER_DETAILS } from "../../store/queries.js";
import cookie from "js-cookie"
import { updateUser } from "../../store/redux/actions";


class StaffNavBar extends Component {
  state = {
    view: this.props.history.location.state
      ? this.props.history.location.state.view
      : ""
  };

  render() {
    const { view } = this.state;
    const { back, title, progress, global, tab } = this.props;
    const role = global.currentUser.type;

    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const mobile = width < 450;
    const shrink = width < 700;
    const styles = {
      navButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        padding: 10,
        color: "white",
        fontFamily: Theme.MAIN_FONT,
        fontSize: Theme.getNormalFont(width),
        cursor: "pointer"
      },
      selectedButton: {
        outline: "none",
        backgroundColor: "rgba(250,250,250, .3)",
        borderRadius: 5,
        padding: 10,
        border: "none",
        color: "white",
        fontFamily: Theme.HEADER_FONT,
        fontSize: Theme.getNormalFont(width),
        cursor: "pointer"
      }
    };
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{
          id: view ? global.currentUser.id : global.currentUser.id
        }}
      >
        {({ loading, error, data, refetch }) => {

          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            console.log("Errooo------------1");
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          return (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                padding: "0 1% 1% 1%",
                marginBottom: shrink ? "-145px" : "-100px",
                height: shrink ? "315px" : "250px",
                width: mobile ? "435px" : "",
                backgroundRepeat: "round",
                backgroundColor: Theme.MAIN_COLOR
              }}
            >
              <div
                name="headerContainer"
                style={{
                  display: shrink ? "block" : "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // width: small ? "90%" : "80%",
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    flex: 0,
                    flexShrink: -1,
                    alignSelf: "center",
                    width: 100,
                    textAlign: mobile || progress === 0 ? "left" : "center"
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginLeft: "auto",
                    // marginRight: "5%"
                    margin: shrink ? "10px 5% 10px auto" : "0px 5% 0px auto",
                  }}
                >
                  <button
                    onClick={() => {
                      this.props.history.push("/manager");
                    }}
                    style={tab === 0 ? styles.selectedButton : styles.navButton}
                  >
                    Home
                  </button>
                  {role === "Admin" && (
                    <button
                      onClick={() => {
                        this.props.history.push("/manager/parks");
                      }}
                      style={
                        tab === 1 ? styles.selectedButton : styles.navButton
                      }
                    >
                      Parks
                    </button>
                  )}
                  <button
                    onClick={() => {
                      this.props.history.push("/manager/members");
                    }}
                    style={tab === 2 ? styles.selectedButton : styles.navButton}
                  >
                    Members
                  </button>
                  <button
                    onClick={() => {
                      this.props.history.push("/manager/rangers");
                    }}
                    style={tab === 3 ? styles.selectedButton : styles.navButton}
                  >
                    Staff
                  </button>
                  <button
                    onClick={() => {
                      this.props.history.push("/manager/coupons");
                    }}
                    style={tab === 4 ? styles.selectedButton : styles.navButton}
                  >
                    Coupons
                  </button>
                  <button
                    onClick={() => {
                      this.props.history.push("/manager/marketing");
                    }}
                    style={tab === 5 ? styles.selectedButton : styles.navButton}
                  >
                    Marketing
                  </button>
                  <button
                    onClick={() => {
                      this.props.history.push("/manager/operations");
                    }}
                    style={tab === 6 ? styles.selectedButton : styles.navButton}
                  >
                    Operations
                  </button>
                </div>
                <ProfileButton
                  picture={
                    data &&
                    data.userDetail &&
                    // process.env.REACT_APP_API_ENDPOINT +
                    // data.userDetail.profilePicture.url
                    data.userDetail.profilePictureCdn
                  }
                  history={this.props.history}
                  width={width}
                />
              </div>
              <div
                style={{
                  marginLeft: "4%",
                  marginRight: "auto",
                  marginTop: "1%",
                  display: "flex",
                  color: "white",
                  alignItems: "center",
                  alignContent: "center",
                  fontSize: Theme.getHeaderFont(width),
                  fontFamily: Theme.HEADER_FONT
                }}
              >
                {back ? (
                  <button style={styles.navButton}>
                    <img
                      style={{ opacity: 0.3, height: 40, marginTop: 8 }}
                      alt=""
                      src={Arrow}
                    />
                  </button>
                ) : null}
                {title}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };
export default connect(mapStateToProps, mapDispatchToProps)(StaffNavBar);
