import '../index.css'

export default{
  STANDARD_ORANGE: '#FF7D50',
  STANDARD_GREEN: 'purple',
  HEADER_FONT_COLOR: '#554D4D',
  INPUT_FONT_COLOR: '#453F3F',
  HEADER_FONT: 'LatoBold',
  INPUT_FONT: 'Lato',
  MAIN_FONT: 'Lato',
  ALT_BUTTON_COLOR: '#D0D2D5',
  LINK_FONT_COLOR: '#FF7D50',
  MAIN_COLOR: '#FF7D50',
  DISABLED_COLOR: '#FBD4C7',
  DISABLED_FONT_COLOR: '#FDEFE9',
  LIGHT_TEXT_COLOR: '#606267',
  DARK_TEXT_COLOR: '#3D3D3D',
  INACTIVE_GREY_COLOR: '#9B9B9B',
  GREEN_COLOR: '#07841B',
  BACKGROUND_COLOR: '#F9F9FA',
  BACKGROUND_COLOR_SELECTED:"#f17d502e",

  //Font Sizes
  getHeaderFont(width){
    if(width > 450) return 24
    else if(width > 380) return 18
    else if(width > 300) return 14
    else return 12
  },
  getNormalFont(width){
    if(width > 450) return 18
    else if(width > 380) return 16
    else if(width > 300) return 14
    else return 12
  },
  getSmallFont(width){
    if(width > 500) return 14
    else if(width > 380) return 12
    else if(width > 300) return 10
    else return 8
  },

  //Form components
  getFormFont(width){
    if(width > 450) return 16
    else if(width > 400) return 14
    else if(width > 300) return 12
    else return 10
  },
  getInputHeight(width){
    if(width > 450) return 3
    if(width > 350) return 2
    else return 1
  },
  getInputWidth(width){
    if(width > 800) return 360
    else if(width > 400) return 255
    else return 200
  },
  getLongInputWidth(width){
    if(width > 450) return 400
    else if(width >300) return 280
    else return 200
  },
  getButtonFullWidth(width){
    if(width > 800) return 380
    else if(width > 400) return 275
    else return 220
  },
  getButtonWidth(width, mobileShrink){
    if(width > 800) return 160
    else if(mobileShrink && width > 500) return 120
    else if(!mobileShrink && width > 500) return 150
    else if(!mobileShrink & width > 400) return 145
    else if(!mobileShrink && width > 300) return 130
    else if (mobileShrink) return 80
    else return 90
  },
  getButtonHeight(width){
    if(width > 400) return 40
    else return 30
  },
}
