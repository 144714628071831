import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { USER_UPDATE } from "../../store/mutations.js";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";

import { LabeledTextBox, Button, CheckBox } from "../../globalComponents/";
import { updateUser } from "../../store/redux/actions";
import { placeAutoComplete, placeDetails } from "../../utils/placeapis.jsx";
import SelectAddress from "../../globalComponents/FormComponents/selectAddress.jsx";

class Form extends Component {
  handlePicture = value => {
    this.props.change("profilePicture", value);
  };

  state = {
    changePassword: false,
    suggestions: [],
    selectedAddress: ""
  };

  getAddressSuggestion = async (e) => {
    let payload = {
      input: e.target.value,
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      radius: '100'
    }
    let suggest = await placeAutoComplete(payload)
    this.setState({
      suggestions: suggest
    })

  }

  handleSelecteAddress = async (address) => {
    let place_id = address.place_id;
    let payload = {
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      place_id
    }
    let getAddress = await placeDetails(payload)
    getAddress.address_components.map(item => {
      switch (item.types[0]) {
        case "country":
          this.props.change("address", getAddress.name)
          this.setState({
            selectedAddress: getAddress.name
          })
          break;
        case "postal_code":
          this.props.change("zip", item.long_name)
          break;
        case "administrative_area_level_1":
          this.props.change("state", item.short_name)
          break;
        case "locality":
          this.props.change("city", item.long_name)
          break;
        default:
          console.log("Hittttt");
      }
    })
  }

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 930;

    const { handleSubmit, change, pristine, submitting, } = this.props;
    const { changePassword, suggestions, selectedAddress } = this.state;
    console.log("-------------Global", this.props.global);
    let park = this.props.global.currentUser.park && this.props.global.currentUser.park.name

    const determineWidth = (width, widthFactor, widthpercent) => {
      if (widthpercent) {
        return widthpercent;
      } else {
        return Theme.getLongInputWidth(width) / (widthFactor || 1);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2%"
        }}
      >
        <div
          style={{ display: "flex", flexDirection: shrink ? "column" : "row" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: shrink ? "" : "5%"
            }}
          >
            <LabeledTextBox
              name="firstName"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="First Name"
            />
            <LabeledTextBox
              name="lastName"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Last Name"
            />

            {/* <LabeledTextBox
              name={park}
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Home Park"
              value="scs"
            /> */}




            <div >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    color: Theme.DARK_TEXT_COLOR,
                    fontSize: Theme.getNormalFont(width),
                    fontFamily: Theme.MAIN_FONT,
                  }}
                >
                  <span>Home Park</span>
                </div>
                <input
                  type={"text"}
                  value={park}
                  style={{
                    width: determineWidth(width, "", "100%"),
                    lineHeight: Theme.getInputHeight(width) / 1.6,
                    outline: "none",
                    fontSize: Theme.getNormalFont(width),
                    marginBottom: height / 100,
                    paddingLeft: "5%",
                    border: "1px solid",
                    borderRadius: 3,
                    textAlign: "",
                    boxSizing: "border-box"
                  }}
                  disabled
                  autoComplete="off"
                />
              </div>
            </div>

            {changePassword ? (
              <div
                style={{
                  display: changePassword ? "flex" : "none",
                  flexDirection: "column"
                }}
              >
                <LabeledTextBox
                  name="oldPassword"
                  containerStyle={{ marginTop: "5%" }}
                  width={width}
                  height={height}
                  label="Current Password"
                />
                <LabeledTextBox
                  name="newPassword"
                  width={width}
                  height={height}
                  label="New Password"
                />
                <LabeledTextBox
                  name="passwordConfirmation"
                  width={width}
                  height={height}
                  label="Confirm Password"
                />
                <button
                  onClick={() => {
                    change("newPassword", "");
                    change("passwordConfirmation", "");
                    this.setState({ changePassword: false });
                  }}
                  style={{
                    outline: "none",
                    border: "none",
                    color: Theme.MAIN_COLOR,
                    marginLeft: "auto",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontFamily: Theme.HEADER_FONT,
                    fontSize: Theme.getNormalFont(width)
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
                <button
                  onClick={() => this.setState({ changePassword: true })}
                  style={{
                    outline: "none",
                    border: "none",
                    color: Theme.MAIN_COLOR,
                    marginLeft: "auto",
                    display: "none", //TODO: change when logged in password reset is created
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontFamily: Theme.HEADER_FONT,
                    fontSize: Theme.getNormalFont(width)
                  }}
                >
                  Reset Password
                </button>
              )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <LabeledTextBox
              name="address"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Address"
            /> */}
            <SelectAddress
              alt={true}
              options={suggestions}
              handleSelect={(address, name) => {
                this.handleSelecteAddress(address);
              }}
              selected={selectedAddress}
              name="address"
              width={width}
              height={height}
              widthpercent={"100%"}
              label="Address"
              getAddressSuggestion={this.getAddressSuggestion}
            />

            <LabeledTextBox
              name="address2"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Address Line 2"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: Theme.getLongInputWidth(width) * 1.05,
                justifyContent: "space-between",
                marginBottom: "5%"
              }}
            >
              <LabeledTextBox
                name="city"
                widthFactor={2.5}
                width={width}
                height={height}
                label="City"
              />
              <LabeledTextBox
                name="state"
                widthFactor={8}
                width={width}
                height={height}
                label="State"
              />
              <LabeledTextBox
                name="zip"
                widthFactor={3.5}
                width={width}
                height={height}
                label="Zipcode"
              />
            </div>
            <LabeledTextBox
              name="email"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Email"
            />
            <LabeledTextBox
              name="phoneNumber"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Telephone Number"
              validate={["required", "number"]}
            />
            { (this.props.global.currentUser.type ==="Manager" || this.props.global.currentUser.type ==="Admin") && <LabeledTextBox
              name="pin"
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Pin"
              validate={["required", "numbers"]}
            />}
            <CheckBox
              name="acceptsSms"
              containerStyle={{ width: Theme.getLongInputWidth(width) }}
              width={width}
              label="Accepts text updates and specials?"
            />
          </div>
        </div>
        <Button
          type="submit"
          disabled={submitting}
          containerStyle={{ marginTop: "3%" }}
          text="Save"
          width={width}
          onClick={handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {

  // if (!values.acceptedRules) {
  //   props.history.push(`/members/parkrules`);
  //   return
  // }
  console.log("Values", values);

  var newValues = Object.assign({}, values);
  newValues.id = props.global.currentUser.id;

  newValues.dogs = null;
  delete newValues.dogs;
  delete newValues.park;
  // delete newValues.email;
  delete newValues.package;
  delete newValues.acceptsSms;
  delete newValues.type;
  delete newValues.profilePicture;
  delete newValues.lastCheckInTime;
  delete newValues.signUpDate;
  delete newValues.confirmed;
  delete newValues.subscription;
  delete newValues.acceptedRules;
  delete newValues.cardLast4
  delete newValues.legacyPlan
  delete newValues.memberTags
  console.log("New Values----->",newValues);
  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
    .then(({ data: { updateUser } }) => {
      console.log("response", updateUser);
      props.refetch();
      alert("Saved!");
    })
    .catch(error => {
      console.error("Error: ", error);
    });
  return true;
};

Form = reduxForm({
  form: "Form",
  onSubmit: formSubmit
})(Form);

const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Form);
