import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { USER_UPDATE } from "../../../store/mutations.js";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { LabeledTextBox, Button, Positions, PawIndicator } from "../../../globalComponents/";

const testPositions = [
  "Bar Tender",
  "Greeter/Check In",
  "Cashier",
  "Inventory",
  "Poop Patrol"
];
class RangerForm extends Component {
  handlePicture = value => {
    this.props.change("profilePicture", value);
  };

  state = {
    changePassword: false,
    positions: this.props.initialValues.positions,
    isTrainee: this.props.initialValues.isTrainee
  };

  componentDidMount() {
    this.props.change("firstNameNew", this.props.initialValues.firstName);
    this.props.change("lastNameNew", this.props.initialValues.lastName);
    this.props.change("emailNew", this.props.initialValues.email);
    this.props.change("phoneNumberNew", this.props.initialValues.phoneNumber);
  }
  handleTrainingCheckbox = event => {
    if (this.props.global.currentUser.type === "Admin" || this.props.global.currentUser.type === "Manager") {
      let newState = { ...this.state };
      newState.isTrainee = !newState.isTrainee;
      this.props.change("isTrainee", newState.isTrainee);
      this.setState({ isTrainee: newState.isTrainee });
    }
    else {
      event.preventDefault()
    }

  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 930;

    const {
      handleSubmit,

      change,
      pristine,
      submitting,
      positionNew
    } = this.props;
    const { changePassword, positions, isTrainee } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2%"
        }}
      >
        <div
          style={{ display: "flex", flexDirection: shrink ? "column" : "row" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "5%"
            }}
          >
            <LabeledTextBox
              name="firstNameNew"
              width={width}
              height={height}
              label="First Name"
            />
            <LabeledTextBox
              name="lastNameNew"
              width={width}
              height={height}
              label="Last Name"
            />
            <LabeledTextBox
              name="emailNew"
              width={width}
              height={height}
              label="Email"
            />
            <LabeledTextBox
              name="phoneNumberNew"
              width={width}
              height={height}
              label="Telephone Number"
            />
             {(
              <div style={{ display: "flex", marginTop: 20 }}>
                <button
                  onClick={e => this.handleTrainingCheckbox(e)}
                  type="button"
                  style={{
                    width: 25,
                    height: 25,
                    border: "2px solid",
                    cursor: "pointer",
                    borderColor: Theme.LINK_FONT_COLOR,
                    outline: "none",
                    borderRadius: 5,
                    backgroundColor: isTrainee ? Theme.LINK_FONT_COLOR : "white"
                  }}
                >
                  {isTrainee ? (
                    <PawIndicator
                      active={false}
                      style={{
                        marginLeft: -5,
                        marginTop: 1,
                        width: 16,
                        height: 16,
                        color: "white"
                      }}
                    />
                  ) : null}
                </button>
                <div
                  style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: 10
                  }}
                >
                  Training
                </div>
              </div>
            )}
            {/* <LabeledTextBox
              name="oldPassword"
              containerStyle={{ marginTop: "5%" }}
              width={width}
              height={height}
              label="Current Password"
            /> */}

            {/* {changePassword ? (
              <div
                style={{
                  display: changePassword ? "flex" : "none",
                  flexDirection: "column"
                }}
              >
                <LabeledTextBox
                  name="newPassword"
                  width={width}
                  height={height}
                  label="New Password"
                />
                <LabeledTextBox
                  name="passwordConfirmation"
                  width={width}
                  height={height}
                  label="Confirm Password"
                />
                <button
                  onClick={() => {
                    change("newPassword", "");
                    change("passwordConfirmation", "");
                    this.setState({ changePassword: false });
                  }}
                  style={{
                    outline: "none",
                    border: "none",
                    color: Theme.MAIN_COLOR,
                    marginLeft: "auto",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    fontFamily: Theme.HEADER_FONT,
                    fontSize: Theme.getNormalFont(width)
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                onClick={() => this.setState({ changePassword: true })}
                style={{
                  outline: "none",
                  border: "none",
                  color: Theme.MAIN_COLOR,
                  marginLeft: "auto",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getNormalFont(width)
                }}
              >
                Reset Password
              </button>
            )} */}
          </div>
          <Positions
            data={positions}
            containerStyle={{
              marginTop: "-3.5%"
            }}
            positionNew={positionNew}
            change={change}
          />
        </div>
        <Button
          type="submit"
          disabled={pristine || submitting}
          containerStyle={{ marginTop: "3%" }}
          text="Save"
          width={width}
          onClick={handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  var userValues = {
    firstName: values.firstNameNew,
    lastName: values.lastNameNew,
    phoneNumber: values.phoneNumberNew,
    email: values.emailNew,
    isTrainee: values.isTrainee,
    id: parseInt(props.global.currentUser.selectedUser)
  };

  if (values.positions) {
    userValues.positions = values.positions;
  }

  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: userValues } })
    .then(({ data: { updateUser } }) => {
      console.log("response", updateUser);
      props.refetch();
      alert("Saved!");
      //props.history.push("profile");
    })
    .catch(error => {
      console.error("Error: ", error);
    });
  return true;
};

const selector = formValueSelector("RangerForm"); // <-- same as form name
RangerForm = connect(state => {
  const positionNew = selector(state, "positionNew");
  return {
    positionNew
  };
})(RangerForm);

RangerForm = reduxForm({
  form: "RangerForm",
  onSubmit: formSubmit
})(RangerForm);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(RangerForm);
