import React from 'react';
import Theme from "../Theme.js";
import { PulseLoader } from "react-spinners";
import Bg from "../../assets/fetch-BG.svg";

export default function LoadingIndicator({ width, height, loading }) {
  return (
    <div
      name="container"
      style={{
        display: "flex",
        flexDirection: "column",
        width: width,
        minHeight: height,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: Theme.BACKGROUND_COLOR,
        backgroundRepeat: "round",
        backgroundImage: `url(${Bg})`
      }}
    >
      <PulseLoader
        style={{ margin: "auto" }}
        size={width / 10}
        color={Theme.LINK_FONT_COLOR}
        loading={loading}
      />
    </div>
  );
};

