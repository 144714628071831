import React, { Component } from "react";
import { Query } from "react-apollo";
import { Button, MemberTable, Theme } from "../../../globalComponents";
import { DateArray } from "../../../globalComponents/Constants";
import SelectDashBoardDateFilter from "../../../globalComponents/FormComponents/SelectDashboardDateFilter";
import SelectDashBoardEventTypeFilter from "../../../globalComponents/FormComponents/SelectDashboardEventTypeFilter";
import SelectDashBoard from "../../../globalComponents/FormComponents/SelectDashboardFilter";
import SelectEventPark from "../../../globalComponents/FormComponents/SelectEventPark";
import {
  FETCH_EVENT_CREATOR,
  FETCH_EVENT_LISTS,
  FETCH_EVENT_TYPE,
  PARK_NAMES,
} from "../../../store/queries";

class EventTable extends Component {
  state = {
    park: "",
    page: 1,
    per: 20,
    date: "",
    dateName: "",
    by_type: "",
    created_by: "",
    sortAttr: "",
    sort: "",
    fetchEventLists: [],
    isLoading: true,
    totalCountEvent: 0,
    parkId: "",
    parkName: "",
  };

  componentDidMount() {
    this.apllyFilter();
  }
  apllyFilter = () => {
    let { created_by, by_type, date, page, per, parkId } = this.state;
    let variables = {
      userId: parseInt(this.props.global.currentUser.selectedUser),
      createdBy: created_by,
      byType: by_type,
      page: page,
      date: date,
      sortAttr: "",
      sort: "",
      parkId
    };
    this.props.client
      .query({
        query: FETCH_EVENT_LISTS,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchEventLists: response.data.fetchEventLists.edges,
          isLoading: response.loading,
          totalCountEvent: response.data.fetchEventLists.totalCount,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  sorting = (e, sortAttr, sort) => {
    e.preventDefault();
    this.setState({
      sortAttr: sortAttr,
      sort: sort,
    });
    let { created_by, by_type, date, page,  parkId } = this.state;
    let variables = {
      userId: parseInt(this.props.global.currentUser.selectedUser),
      createdBy: created_by,
      byType: by_type,
      page: page,
      date: date,
      sortAttr: sortAttr,
      sort: sort,
      parkId
    };
    this.props.client
      .query({
        query: FETCH_EVENT_LISTS,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchEventLists: response.data.fetchEventLists.edges,
          isLoading: response.loading,
          totalCountEvent: response.data.fetchEventLists.totalCount,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  pagination = (e, page) => {
    e.preventDefault();
    this.setState({
      page: page,
    });
    let { created_by, by_type, date, sort, sortAttr, parkId } = this.state;
    let variables = {
      userId: parseInt(this.props.global.currentUser.selectedUser),
      createdBy: created_by,
      byType: by_type,
      page: page,
      date: date,
      sortAttr: sortAttr,
      sort: sort,
      parkId
    };
    this.props.client
      .query({
        query: FETCH_EVENT_LISTS,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchEventLists: response.data.fetchEventLists.edges,
          isLoading: response.loading,
          totalCountEvent: response.data.fetchEventLists.totalCount,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  render() {
    const { global } = this.props;
    const {
      date,
      dateName,
      created_by,
      by_type,
      isLoading,
      fetchEventLists,
      page,
      per,
      totalCountEvent,
      parkName,
      parkId
    } = this.state;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 768;
    const TillTab = width < 1024;
    const small = width < 1170;
    const columnNames = ["date", "parkName", "type", "creator", "Message"];
    const eventColumns = {
      date: {
        label: "Date & Time",
        renderCell: (data) => {
          return (
            <div style={{ display: "flex" }}>
              <span
                style={{
                  lineHeight: "26px",
                  marginLeft: "10px",
                }}
              >
                {data.createdAt}
              </span>
            </div>
          );
        },
      },
      parkName: {
        label: "Park Name ",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {data.park?.name}
          </div>
        ),
      },
      type: {
        label: "Type",
        renderCell: (data) => (
          <div
            style={{
              display: TillTab ? "block" : "flex",
              cursor: "pointer",
              flexDirection: `${width > 1000 ? "row" : "column"}`,
            }}
          >
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: `${width > 1000 ? "12" : "12"}px`,
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginLeft: `${width > 1000 ? "10%" : "auto"}`,
                textAlign: "left",
                marginTop: "auto",
                marginBottom: "auto",
                display: TillTab ? "inline-block" : " ",
                float: TillTab ? "left" : " ",
              }}
            >
              {data.eventName}
            </div>
          </div>
        ),
      },
      creator: {
        label: "Created By",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {data.currentUser}
          </div>
        ),
      },
      Message: {
        label: "Message",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
          >
            {data.message === null ? "No Message" : data.message}
          </div>
        ),
      },
    };

    return (
      <div
        style={{
          flexDirection: "column",
          width: shrink ? "100%" : "100%",
          marginBottom: shrink ? "0" : "20px",
          display: shrink ? "block" : "flex",
          marginTop: shrink ? "0" : "20px",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: shrink ? "block" : "flex",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {/* Date Dropdown */}
          <SelectDashBoardDateFilter
            alt={true}
            options={DateArray}
            handleSelect={(data, name) => {
              this.setState({ date: data.value, dateName: data.name });
            }}
            selected={date === "" ? "Date Range" : dateName}
            width={"100%"}
            height={height / 25}
            label="Date Range"
            containerStyle={{
              margin: shrink ? "20px 10px 10px 0px" : "0 10px 0 0",
              display: shrink ? "inline-flex" : "",
              width: "48%",
            }}
          />

          {/* Park Drop down */}
          <Query fetchPolicy="no-cache" query={PARK_NAMES}>
            {({ loading, error, data, refetch }) => {
              if (loading) return null;
              if (error) return `Error: ${error.message}`;
              return (
               
                <SelectEventPark
                  alt={true}
                  options={data?.restrictedFetchParks}
                  handleSelect={(park, name) => {
                    this.setState({
                      parkName: park.name,
                      parkId: park.id,
                    });
                  }}
                  selected={parkId === "" ? "Select Park" : parkName}
                  name="parkId"
                  width={"100%"}
                  height={height / 25}
                  label="Park Location"
                  containerStyle={{
                    margin: shrink ? "20px 10px 10px 0px" : "0 10px 0 0",
                    display: shrink ? "inline-flex" : "",
                    width: "48%",
                  }}
                />
              );
            }}
          </Query>
          <Query fetchPolicy="no-cache" query={FETCH_EVENT_TYPE}>
            {({ loading, error, data, refetch }) => {
              if (error) return `Error: ${error.message}`;
              return (
                <SelectDashBoardEventTypeFilter
                  alt={true}
                  options={data?.fetchEventType}
                  handleSelect={(type, name) => {
                    this.setState({ by_type: type });
                  }}
                  selected={by_type === "" ? "Type" : by_type}
                  width={"100%"}
                  height={height / 25}
                  label="Type"
                  containerStyle={{
                    margin: shrink ? "20px 10px 10px 0px" : "0 10px 0 0",
                    display: shrink ? "inline-flex" : "",
                    width: "48%",
                  }}
                />
              );
            }}
          </Query>
          <Query fetchPolicy="no-cache" query={FETCH_EVENT_CREATOR}>
            {({ loading, error, data, refetch }) => {
              if(loading)return null
              if (error) return `Error: ${error.message}`;

              return (
                <>
                  <SelectDashBoard
                    alt={true}
                    options={data?.fetchEventCreater?.employees}
                    handleSelect={(option, name) => {
                      this.setState({
                        created_by:
                          (option &&
                            option.first_name + " " + option.last_name) ||
                          "",
                      });
                    }}
                    selected={created_by === "" ? "Creator" : created_by}
                    width={"100%"}
                    height={height / 25}
                    label="Creator"
                    containerStyle={{
                      margin: shrink ? "10px 10px 10px 0px" : "0 10px 0 0",
                      display: shrink ? "inline-flex" : "",
                      width: "48%",
                    }}
                  />
                </>
              );
            }}
          </Query>
          <div
            style={{
              margin: `${
                width < 900
                  ? width < 568
                    ? "15px auto 10px"
                    : "0px auto 10px"
                  : "0 0 0 auto "
              }`,
              display: `${width < 900 ? "inline-flex" : "flex "}`,
              flexDirection: `${width < 900 ? "column" : "row"}`,
            }}
          >
            <Button
              text={`Apply Filter`}
              fullwidth={false}
              onClick={this.apllyFilter}
              width="100%"
              height={height / 20}
            />
          </div>
        </div>
        <MemberTable
          loading={isLoading}
          page={page}
          per={per}
          containerStyle={{ flex: 1, marginLeft: small ? 0 : "5%" }}
          names={columnNames}
          columns={eventColumns}
          onPage={this.pagination}
          rows={fetchEventLists}
          sorting={this.sorting}
          total={totalCountEvent}
          width={width}
        />
      </div>
    );
  }
}

export default EventTable;
