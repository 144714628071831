import React, { Component } from "react";
import { connect } from "react-redux";
import {
  FETCH_CALENDAR_EVENT_LIST_WITH_PARK,
  FETCH_EVENT_LIST_WITH_PARK,
  PARK_NAMES,
} from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import moment from "moment";
import { Theme, Select, Button } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";

import FetchLogo from "../../../assets/fetch-logo.svg";
import { convertTo12HrsFormat } from "../../../utils/session.js";
import { PulseLoader } from "react-spinners";
class EventCalendar extends Component {
  state = {
    eventList: [],
    parkName: "All Parks",
    parkId: "",
    page: 1,
    showLoading: false,
  };

  componentDidMount() {
    this.handleEventList(this.state.parkId);
  }
  handleEventList = (_id) => {
    const { client } = this.props;
    let variables = {
      parkId: _id,
      page: this.state.page,
    };
    this.setState({ showLoading: true });
    client
      .query({
        query: FETCH_CALENDAR_EVENT_LIST_WITH_PARK,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        let newList = this.state.eventList;
        console.log("Response", newList);

        var array1 = this.state.eventList;
        var array2 = response.data?.fetchCalendarEvents;
        var array3 = [...new Set([...array1, ...array2])];

        this.setState({
          // eventList: response.data?.fetchCalendarEvents,
          eventList: array3,
          showLoading: false,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };
  formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    // console.log("Minutes--------->", minutes);
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  };

  loadMoreData = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.handleEventList(this.state.parkId)
    );
  };
  renderList = () => {
    return this.state.eventList.map((data, index) => {
      return (
        <div className="card">
          <div className="card-header" id={`heading${data.eve.id}`}>
            <h2 className="mb-0">
              <div className="side-line"></div>
              <button
                className="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse${data.eve.id}`}
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className="row headerDiv mx-2">
                  <div className="col-md-1 pl-2">
                    <h4>
                      {/* 07 <span>July</span> */}
                      {moment(data?.date).format("D")}
                      <span> {moment(data?.date).format("MMM")}</span>
                    </h4>
                  </div>

                  <div className="col-md-9">
                    <div className="imageBox">
                      <img src={FetchLogo} />
                    </div>
                    <div className="contentArea">
                      <h3>{data.eve.eventName}</h3>
                      <p>{data.eve.description}</p>
                      <ul>
                        <li>
                          <i className="fa fa-map-marker"></i>{" "}
                          {(data.eve.park && data.eve.park.name) || "All Parks"}
                        </li>
                        <li>
                          <i className="fa fa-clock-o"></i>{" "}
                          {convertTo12HrsFormat(data.eve.startTime)}-
                          {convertTo12HrsFormat(data.eve.endTime)}
                          {/* {this.formatAMPM(new Date(data.eve.startTime))}-{" "}
                          {this.formatAMPM(new Date(data.eve.endTime))} */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </button>
            </h2>
          </div>
          <div
            id={`collapse${data.eve.id}`}
            x
            className="collapse "
            aria-labelledby={`heading${data.eve.id}`}
            data-parent="#accordionExample"
          >
            <div className="card-body">
              {data.eve.description || "No description available."}
            </div>
          </div>
        </div>
      );
    });
  };
  getHeight(width) {
    if (width > 450) return 200;
    else if (width > 400) return 150;
    return 200;
  }
  getWidth(width) {
    if (width > 450) return 160;
    if (width > 400) return 140;
    if (width > 300) return 120;
    else return 60;
  }
  render() {
    const { global, change } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 700;
    const verySmall = width < 568;

    const { eventList, parkName } = this.state;
    return (
      <>
        <div>
          <div
            style={{
              marginRight: "auto",
              display: "flex",
              flexDirection: "row",
              marginTop: "0%",
              marginBottom: "4%",
              width: "100%",
              color: "white",
              alignItems: "center",
              alignContent: "center",
              fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
              fontFamily: Theme.HEADER_FONT,
            }}
          >
            Event Calendar
          </div>
          <div style={{ marginTop: 20 }}>
            <div
              style={{
                width: shrink ? "80%" : "50%",
                margin: shrink ? "90px auto 0" : "auto",
                display: "block",
              }}
            >
              <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return <div></div>;
                  if (error) return `Error: ${error.message}`;
                  return (
                    <div>
                      <div
                        style={{
                          width: shrink ? "80%" : "100%",
                          margin: "auto",
                        }}
                      >
                        <Select
                          alt={true}
                          options={data?.restrictedFetchParks}
                          handleSelect={(park, name) => {
                            this.setState({
                              parkName: park.name,
                              parkId: park.id,
                            });
                            this.handleEventList(park?.id);
                          }}
                          selected={parkName}
                          name="tagId"
                          width={"100%"}
                          height={height / 25}
                          label="Parks"
                          statusField="all"
                        />
                      </div>
                    </div>
                  );
                }}
              </Query>
            </div>
          </div>

          {
            <div className="container">
              <div className="accordion" id="accordionExample">
                {this.state.eventList.length > 0 && this.renderList()}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "5%",
                  marginBottom: "5%",
                }}
              >
                {this.state.eventList.length > 0 && (
                  <Button
                    type="button"
                    containerStyle={{ margin: "auto" }}
                    text={"Load More"}
                    loadingText="Loading"
                    width={width}
                    onClick={() => {
                      this.loadMoreData();
                    }}
                    loading={this.state.showLoading}
                  />
                )}
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(EventCalendar);
