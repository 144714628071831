import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../../globalComponents";
import { connect } from "react-redux";
import Logo from "../../assets/fetch-logo.svg";
import Bg from "../../assets/fetch-BG.svg";
import TempDog from "../../assets/tempDog.png";

class CheckIn extends Component {
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundRepeat: "round",
          backgroundColor: Theme.BACKGROUND_COLOR,
          height: height,
          backgroundImage: `url(${Bg})`
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "5%",
            height: "80%",
            width: "40%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            alt="logo"
            src={Logo}
            style={{ height: "25%", marginBottom: "10%" }}
          />
          <div
            style={{
              marginBottom: "2%",
              fontSize: Theme.getHeaderFont(width),
              fontFamily: Theme.HEADER_FONT,
              color: Theme.HEADER_FONT_COLOR
            }}
          >
            "Thanks! You're all set."
          </div>
          <img alt="dog" style={{ margin: "5% 5% 2% 10%" }} src={TempDog} />
          <Button
            text="Close"
            width={width}
            onClick={() =>
              this.props.global.currentUser.type === "Employee"
                ? this.props.history.push("/dashboard")
                : this.props.global.currentUser.type === "Manager" ||
                  this.props.global.currentUser.type === "Admin"
                ? this.props.history.push("/manager/members")
                : this.props.history.push("/")
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

export default connect(mapStateToProps)(CheckIn);
