import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Theme } from "../";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_UPDATE, DOG_UPDATE } from "../../store/mutations.js";
import { Button, PackageSelector } from "../";

class PackagesForm extends Component {

  constructor(props) {
    super(props);
  }

  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }

  render() {
    const {
      dimensions: { height, width }
    } = this.props.global || {
      dimensions: { height: 0, width: 1000 }
    };
    const { dogId } = this.props
    console.log("Package Form Dog Id", dogId)
    const { handleSubmit, onCancel } = this.props;
    return (
      <Fragment>
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Packages
        </div>
        <PackageSelector
          {...{ width, height }}
          onChange={pack => {
            this.props.change("packageId", pack.id);
            this.props.change("isRecurring", pack.recurring);
          }}
          dogId={dogId}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "5%",
            width: Theme.getLongInputWidth(width),
            marginTop: "5%"
          }}
        >
          <Button text="Back" alt={true} width={width} onClick={onCancel} />
          <Button
            containerStyle={{ marginLeft: "auto" }}
            text="Save and Continue"
            width={width}
            onClick={handleSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

export function submitHandler(values, props, beforeSubmit, onUpdateSuccess) {
  const { dogId } = props
  console.log(` ** submitHandler ** dogId: ${dogId}`)
  if (dogId) {
    // beforeSubmit({ dogId: dogId, packageId: values.packageId, isRecurring: values.isRecurring });
    let newValues = { id: dogId, packageId: values.packageId };
    const isRecurring = values.isRecurring;

    props.client
      .mutate({ mutation: DOG_UPDATE, variables: { input: newValues } })
      .then(({ data: { updatedDog } }) => {
        if (onUpdateSuccess) {
          onUpdateSuccess(isRecurring);
        }
      })
      .catch(error => {
        console.error("Error: ", error);
      });
    return true;
  }
  else {
    let newValues = { id: null, packageId: values.packageId };
    newValues.id =
      props.global.currentUser.selectedUser || props.global.currentUser.id;

    const isRecurring = values.isRecurring;

    props.client
      .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
      .then(({ data: { updateUser } }) => {
        if (onUpdateSuccess) {
          onUpdateSuccess(isRecurring);
        }
      })
      .catch(error => {
        console.error("Error: ", error);
      });
    return true;
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  return submitHandler(values, props, props.beforeSubmit, props.onUserUpdated);
};

PackagesForm = reduxForm({
  form: "PackagesForm",
  onSubmit: formSubmit
})(PackagesForm);

export default compose(
  withApollo,
  connect(mapStateToProps)
)(PackagesForm);
