import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import {
  LabeledTextBox,
  PictureUpload,
  Button,
  VaccinationUpload,
  PawIndicator,
  DatePickerBox,
} from "../../globalComponents/";
import { ADD_DOGS, DELETE_DOG } from "../../store/mutations.js";
import { addOrUpdateDogVaccinations, updateDogsInfo } from "../../utils/dog.js";
import DeleteIcon from "../../assets/delete_forever.svg";
import BirthCheckBox from "../../globalComponents/FetchComponents/BirthCheckBox.jsx";

class Vaccinations extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    selected: 0,
    vacs: this.props.initialValues.dogs[0]
      ? this.props.initialValues.dogs[0].vaccinationPictures
      : [],
    currentVac: "Rabies",
    dogs: this.props.initialValues.dogs,
    dogModalOpen: false,
    deleting: false,
  };

  componentDidMount() {
    const dogs = this.props.initialValues.dogs;
    if (dogs[0]) {
      for (var i = 0; i < dogs.length; i++) {
        this.props.change(`name${i}`, dogs[i].name);
        this.props.change(`id${i}`, dogs[i].id);
        this.props.change(`sex${i}`, dogs[i].sex === "MALE" ? "M" : "F");
        this.props.change(`color${i}`, dogs[i].color);
        this.props.change(`breed${i}`, dogs[i]?.breed);
        this.props.change(`birthdate${i}`, dogs[i].birthdate);
        this.props.change(`isNeutered${i}`, dogs[i].isNeutered);
      }
      this.props.change(
        "vaccinationPictures",
        dogs[0].vaccinationPictures || []
      );
      this.props.change("rabies", dogs[0].rabies);
      this.props.change("dhlpp", dogs[0].dhlpp);
      this.props.change("leptospirosis", dogs[0].leptospirosis);
      this.props.change("bordetella", dogs[0].bordetella);
      this.props.change("separateLeptospirosis", dogs[0].separateLeptospirosis);

      this.props.change("vaccineDog", parseInt(dogs[0].id));
    }
  }

  selectCurrentVac = (selectedVac) => {
    this.setState({ currentVac: selectedVac });
  };

  dogCount = (dogs) => {
    if (!dogs[0]) {
      return 0;
    } else if (!dogs[1]) {
      return 1;
    } else if (!dogs[2]) {
      return 2;
    } else {
      return 3;
    }
  };

  handleDogModal = () => {
    let newState = { ...this.state };
    this.props.change("pictureNew", null);
    this.props.change("nameNew", "");
    this.props.change("birthdateNew", null);
    this.props.change("sexNew", "");
    this.props.change("colorNew", "");
    this.props.change("isNeuteredNew", "");
    this.props.change("breedNew", "");

    this.setState({ dogModalOpen: !newState.dogModalOpen });
  };

  addDog = () => {
    if (
      this.props.nameNew &&
      this.props.sexNew &&
      this.props.colorNew &&
      this.props.pictureNew
    ) {
      let currentUserId = this.props.global.currentUser.id;
      if (this.props.isManager) {
        currentUserId = this.props.global.currentUser.selectedUser;
      }
      let dogs = {
        userId: parseInt(currentUserId),
        profilePicture: this.props.pictureNew,
        name: this.props.nameNew,
        birthdate: this.props.birthdateNew,
        sex: this.props.sexNew === "F" ? "FEMALE" : "MALE",
        color: this.props.colorNew,
        isNeutered: this.props.isNeuteredNew,
        breed: this.props.breedNew,
      };
      this.props.client
        .mutate({ mutation: ADD_DOGS, variables: { input: dogs } })
        .then((data) => {
          if (data.errors && data.errors.length > 0) {
            console.error("Submission Error: ", data.errors);
          } else {
            let newState = { ...this.state };
            newState.dogs.push(data.data.addDogs.dogs[0]);
            for (var i = 0; i < newState.dogs.length; i++) {
              this.props.change(`name${i}`, newState.dogs[i].name);
              this.props.change(`id${i}`, newState.dogs[i].id);
              this.props.change(
                `sex${i}`,
                newState.dogs[i].sex === "MALE" ? "M" : "F"
              );
              this.props.change(`color${i}`, newState.dogs[i].color);
              this.props.change(`birthdate${i}`, newState.dogs[i].birthdate);
              this.props.change(`isNeutered${i}`, newState.dogs[i].isNeutered);
              this.props.change(`breed${i}`, newState.dogs[i].breed);
            }
            this.props.change(
              "vaccinationPictures",
              newState.dogs[0].vaccinationPictures || []
            );
            this.props.change("rabies", newState.dogs[0].rabies);
            this.props.change("dhlpp", newState.dogs[0].dhlpp);
            this.props.change("leptospirosis", newState.dogs[0].leptospirosis);
            this.props.change("bordetella", newState.dogs[0].bordetella);
            this.props.change(
              "separateLeptospirosis",
              newState.dogs[0].separateLeptospirosis
            );
            this.props.change("vaccineDog", parseInt(newState.dogs[0].id));

            this.setState({ dogs: newState.dogs });
            this.handleDogModal();
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    } else {
      alert(
        "Error: All fields must be filled out and a dog picture must be uploaded in order to add a new dog."
      );
    }
  };

  handleDeleteDog = (index) => {
    let newState = { ...this.state };
    let confirmDelete = window.confirm(
      `Are you sure that you want to delete ${newState.dogs[index].name}?`
    );
    if (confirmDelete) {
      this.props.client
        .mutate({
          mutation: DELETE_DOG,
          variables: { id: newState.dogs[index].id },
        })
        .then((response) => {
          if (response.data.deleteDog.success) {
            alert("Success");
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
    }
  };

  isPortrait = () => {
    if (window.innerHeight > window.innerWidth) {
      return true;
    } else {
      return false;
    }
  };
  isMobile = () => {
    if (window.innerWidth < 700) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { selected, vacs, currentVac, dogs, deleting } = this.state;
    const {
      handleSubmit,
      change,
      pristine,
      submitting,
      rabiesDate,
      dhlppDate,
      leptospirosisDate,
      bordetellaDate,
      vaccinationNew0,
      vaccinationNew1,
      vaccinationNew2,
      separateLeptospirosisStatus,
      global,
    } = this.props;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 1025;
    const verySmall = width < 568;
    let numDogs = this.dogCount(dogs);
    console.log(width, "<--------verySmall");
    return (
      <Fragment>
        <div
          style={{
            display: shrink ? "block" : "flex",
            flexDirection: "row",
            margin: "0 auto 0",
            width: this.isMobile() ? "100%" : "95%",
            // width: "100%",
            // alignItems: "center"
          }}
        >
          <div
            style={{
              display: shrink ? "block" : "inline-flex",
              flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              width: shrink ? "90%" : "30%",
              paddingRight: shrink ? " " : "1%",
              borderRight: shrink ? " " : "2px solid",
              borderColor: Theme.ALT_BUTTON_COLOR,
              paddingBottom: 20,
              margin: shrink ? " 5% auto 0" : "5% 0 0",
            }}
          >
            {dogs.map((dog, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor:
                      selected === index
                        ? Theme.BACKGROUND_COLOR_SELECTED
                        : "white",
                    border: selected === index ? "10px solid" : "1px solid",
                    borderColor:
                      selected === index
                        ? Theme.MAIN_COLOR
                        : Theme.LIGHT_TEXT_COLOR,
                    borderRadius: 10,
                    boxShadow: "3px 3px 10px #606267",
                    padding: 20,
                    marginBottom: `${deleting ? "45px" : "20px"}`,
                    marginLeft: 10,
                    marginRight: 10,
                    position: "relative",
                  }}
                  onClick={() => {
                    let newState = { ...this.state };
                    newState.dogs[selected].rabies = rabiesDate;
                    newState.dogs[selected].dhlpp = dhlppDate;
                    newState.dogs[selected].leptospirosis = leptospirosisDate;
                    newState.dogs[selected].separateLeptospirosis =
                      separateLeptospirosisStatus;
                    newState.dogs[selected].bordetella = bordetellaDate;
                    if (selected === 0 && vaccinationNew0) {
                      newState.dogs[selected].vaccinations = vaccinationNew0;
                    } else if (selected === 1 && vaccinationNew1) {
                      newState.dogs[selected].vaccinations = vaccinationNew1;
                    } else if (selected === 2 && vaccinationNew2) {
                      newState.dogs[selected].vaccinations = vaccinationNew2;
                    }

                    change("dogsNew", newState.dogs);

                    change(
                      "vaccinationPictures",
                      dog.vaccinationPictures || []
                    );
                    change("rabies", dog.rabies);
                    change("dhlpp", dog.dhlpp);
                    change("leptospirosis", dog.leptospirosis);
                    change("bordetella", dog.bordetella);
                    change("separateLeptospirosis", dog.separateLeptospirosis);
                    change("vaccineDog", parseInt(dog.id));
                    this.setState({
                      selected: index,
                      vacs: dog.vaccinationPictures || [],
                      dogs: newState.dogs,
                    });
                  }}
                >
                  {deleting && (
                    <div
                      style={{
                        position: "absolute",
                        top: -35,
                        right: -35,
                        border: "2px solid black",
                        borderRadius: "100px",
                        backgroundColor: Theme.MAIN_COLOR,
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleDeleteDog(index)}
                    >
                      <img
                        alt="icon"
                        src={DeleteIcon}
                        style={{
                          margin: 5,
                          width: 50,
                        }}
                      />
                    </div>
                  )}
                  <PictureUpload
                    width={width}
                    containerStyle={{ marginRight: "20px", display: "flex" }}
                    picture={
                      dog.profilePicture.tempPic ||
                      (dog.profilePicture.url
                        ? 
                        dog.profilePictureCdn
                        // process.env.REACT_APP_API_ENDPOINT +
                        //   dog.profilePicture.url
                        : null)
                    }
                    name={`picture${index}`}
                    onUpload={(v, pic) => {
                      let newState = { ...this.state };
                      newState.dogs[index].profilePicture.tempPic = pic;
                      this.setState({ dogs: newState.dogs });
                      change(`profilePicture${index}`, v);
                    }}
                    height={height}
                    label="Picture"
                    disableForMembers={
                      global.currentUser.type === "Member" ? true : false
                    }
                    verticalCenter={true}
                    notSelected={!(selected === index)}
                    // picturePresent={dog.profilePicture.url}
                    picturePresent ={dog.profilePictureCdn}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: "2%",
                      flexDirection: "column",
                      width: "calc(100% - 150px)",
                    }}
                  >
                    <LabeledTextBox
                      name={`name${index}`}
                      width={width}
                      widthpercent={"100%"}
                      height={height}
                      label="Dog Name"
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "55%" }}>
                        <LabeledTextBox
                          name={`color${index}`}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Color"
                        />
                      </div>
                      <div style={{ width: "40%" }}>
                        <LabeledTextBox
                          name={`sex${index}`}
                          options={["M", "F"]}
                          widthpercent={"100%"}
                          width={width}
                          height={height}
                          label="Sex"
                        />
                      </div>
                    </div>
                    <LabeledTextBox
                      name={`breed${index}`}
                      width={width}
                      widthpercent={"100%"}
                      height={height}
                      label="Dog Breed"
                    />
                    {/* ************************************************************************************************ */}

                    <BirthCheckBox
                      isNeuteredStatus={dog.isNeutered}
                      name={`isNeutered${index}`}
                      change={change}
                      index={index}
                    />
                    {/* ************************************************************************************************ */}

                    <div style={{ width: "100%" }}>
                      {/* <LabeledTextBox
                        name={`birthdate${index}`}
                        widthpercent={"100%"}
                        width={width}
                        height={height}
                        label="Birth Date"
                        type="date"
                      /> */}

                      <DatePickerBox
                        name={`birthdate${index}`}
                        widthpercent={"100%"}
                        width={width}
                        height={height}
                        label="Birth Date"
                        type="date"
                        placeholder="MM/DD/YYYY"
                        change={change}
                        dateType="birthDate"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div style={{ margin: "10px auto 0px" }}>{numDogs} / 3 Dogs</div>
            <div
              style={{
                display: "flex",
                flexDirection: `${this.isPortrait() ? "column" : "row"}`,
                margin: "20px auto",
              }}
            >
              {numDogs < 3 && (
                <Button
                  type="button"
                  containerStyle={{
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: `${this.isPortrait() ? "auto" : "10px"}`,
                  }}
                  text="Add Dog"
                  width={width}
                  onClick={() => this.handleDogModal()}
                />
              )}
              <Button
                type="button"
                containerStyle={{
                  marginTop: 10,
                  marginLeft: `${this.isPortrait() ? "auto" : "10px"}`,
                  marginRight: "auto",
                }}
                text={`${deleting ? "Cancel Deleting" : "Delete Dog"}`}
                width={width}
                onClick={() =>
                  this.setState({ deleting: !this.state.deleting })
                }
              />
            </div>
          </div>
          <div
            style={{
              // width: "70%",
              display: shrink ? "block" : "inline-flex",
              width: this.isMobile() ? "100%" : "70%",
              paddingBottom: 20,
              flexDirection: "column",
              // marginRight: 20
            }}
          >
            {dogs[selected] && (
              <VaccinationUpload
                dog={dogs[selected]}
                vacDates={{
                  rabiesDate,
                  dhlppDate,
                  leptospirosisDate,
                  bordetellaDate,
                  separateLeptospirosisStatus,
                }}
                change={change}
                selected={selected}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "5%",
                marginBottom: "5%",
                justifyContent: "center",
              }}
            >
              {numDogs > 0 && (
                <Button
                  type="button"
                  disabled={pristine || submitting}
                  containerStyle={{ marginTop: "3%" }}
                  text="Save"
                  width={width}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>

        {/* Add Dog Modal */}
        <div
          id="dogModal"
          style={{
            display: `${this.state.dogModalOpen ? "flex" : "none"}`,
            position: "fixed",
            zIndex: 999,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
          }}
        >
          <div
            style={{
              margin: "auto",
              width: `${width < 600 ? "100%" : "600px"}`,
              height: `${width < 400 ? "450px" : "400px"}`,
              position: "relative",
              backgroundColor: "white",
              padding: 10,
              borderRadius: "15px",
            }}
          >
            <div id="dogModalContainer" style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: `${width < 600 ? "100%" : "75%"}`,
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "5%",
                      fontSize: 18,
                      fontFamily: "Lato",
                    }}
                  >
                    Add Dog
                  </div>
                  {/* Dog Card */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: `${width < 400 ? "column" : "row"}`,
                        backgroundColor: "white",
                        border: "3px solid",
                        borderColor: Theme.MAIN_COLOR,
                        borderRadius: 10,
                        boxShadow: "3px 3px 10px #606267",
                        padding: 20,
                        marginBottom: "auto",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <PictureUpload
                        width={width}
                        containerStyle={{
                          marginRight: `${width < 400 ? "auto" : "20px"}`,
                          marginLeft: `${width < 400 ? "auto" : "0px"}`,
                          display: "flex",
                        }}
                        picture={null}
                        name={`pictureNew`}
                        onUpload={(v, pic) => {
                          change(`pictureNew`, v);
                        }}
                        height={height}
                        label="Picture"
                        disableForMembers={false}
                        verticalCenter={true}
                      />
                      <div
                        style={{
                          display: "flex",
                          marginTop: "2%",
                          flexDirection: "column",
                          width: `${
                            width < 400 ? "100%" : "calc(100% - 150px)"
                          }`,
                        }}
                      >
                        <LabeledTextBox
                          onBlur={() => {}}
                          name={`nameNew`}
                          width={width}
                          widthpercent={"100%"}
                          height={height}
                          label="Dog Name"
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "55%" }}>
                            <LabeledTextBox
                              onBlur={() => {}}
                              name={`colorNew`}
                              widthpercent={"100%"}
                              width={width}
                              height={height}
                              label="Color"
                            />
                          </div>
                          <div style={{ width: "40%" }}>
                            <LabeledTextBox
                              name={`sexNew`}
                              onBlur={() => {}}
                              options={["M", "F"]}
                              widthpercent={"100%"}
                              width={width}
                              height={height}
                              label="Sex"
                            />
                          </div>
                        </div>

                        <LabeledTextBox
                          name={`breedNew`}
                          width={width}
                          widthpercent={"100%"}
                          height={height}
                          label="Dog Breed"
                        />
                        {/* ************************************************************************************************ */}

                        <BirthCheckBox
                          isNeuteredStatus={false}
                          name={`isNeuteredNew`}
                          change={change}
                          // index={index}
                          //  selected={selected}
                        />

                        {/* ************************************************************************************************ */}

                        <div style={{ width: "100%" }}>
                          {/* <LabeledTextBox
                            onBlur={() => {}}
                            name={`birthdateNew`}
                            widthpercent={"100%"}
                            width={width}
                            height={height}
                            label="Birthdate"
                            type="date"
                          /> */}
                          <DatePickerBox
                            onBlur={() => {}}
                            name={`birthdateNew`}
                            widthpercent={"100%"}
                            width={width}
                            height={height}
                            label="Birthdate"
                            type="date"
                            placeholder="MM/DD/YYYY"
                            change={change}
                            dateType="birthDate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Cancel"
                      width={width}
                      onClick={() => this.handleDogModal()}
                    />
                    <Button
                      type="button"
                      containerStyle={{ margin: "auto" }}
                      text="Add Dog"
                      width={width}
                      onClick={() => this.addDog()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End of Dog Modal */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  updateDogsInfo(values, props.client, props.refetch).then(() =>
    addOrUpdateDogVaccinations(values, props.client, props.refetch)
  );
  alert("Saved!");
  return true;
};

const selector = formValueSelector("Vaccinations"); // <-- same as form name
Vaccinations = connect((state) => {
  const rabiesDate = selector(state, "rabies");
  const dhlppDate = selector(state, "dhlpp");
  const leptospirosisDate = selector(state, "leptospirosis");
  const bordetellaDate = selector(state, "bordetella");
  const nameNew = selector(state, "nameNew");
  const pictureNew = selector(state, "pictureNew");
  const birthdateNew = selector(state, "birthdateNew");
  const sexNew = selector(state, "sexNew");
  const colorNew = selector(state, "colorNew");
  const breedNew = selector(state, "breedNew");

  const separateLeptospirosisStatus = selector(state, "separateLeptospirosis");
  const isNeuteredNew = selector(state, "isNeuteredNew");
  const vaccinationNew0 = selector(state, "vaccinationNew0");
  const vaccinationNew1 = selector(state, "vaccinationNew1");
  const vaccinationNew2 = selector(state, "vaccinationNew2");
  return {
    rabiesDate,
    dhlppDate,
    leptospirosisDate,
    bordetellaDate,
    nameNew,
    pictureNew,
    birthdateNew,
    sexNew,
    colorNew,
    vaccinationNew0,
    vaccinationNew1,
    vaccinationNew2,
    separateLeptospirosisStatus,
    isNeuteredNew,
    breedNew,
  };
})(Vaccinations);

Vaccinations = reduxForm({
  form: "Vaccinations",
  onSubmit: formSubmit,
})(Vaccinations);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Vaccinations);
