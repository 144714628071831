import React, { Component } from "react";

import { CSVLink } from "react-csv";
import { Theme } from "../../../../globalComponents";
// import { formattedDate } from '../methods';

const DownloadCSV = ({
  list,
  roll,
  eventName,
  width,
  fullwidth,
  mobileShrink,
  disabled,
  height,
  alt,
  text,
  onClick,
}) => {
  const ParkEventList = [
    { label: "Date & Time", key: "DateTime" },
    { label: "Member", key: "MembersName" },
    { label: "Email", key: "MembersEmail" },
    { label: "Park", key: "ParkName" },
    { label: "Dog", key: "DogName" },
    { label: "Package", key: "PackageName" },
    { label: "Type", key: "Type" },
    { label: "Created By", key: "CreatedBy" },
    { label: "Message", key: "Message" },
  ];

  const data = [];

  list.forEach((eventData) => {
    // let rolleventData = {}
    // define the columns we want and their titles

    if (roll === "parkEventList") {
      let rollEventData = {
        DateTime: eventData.createdAt,
        MembersName: eventData.member
          ? `${eventData.member.firstName} ${eventData.member.lastName}`
          : "N/A",
        MembersEmail: eventData.member ? eventData.member.email : "N/A",
        ParkName: eventData?.park?.name,
        DogName: eventData.dog ? `${eventData.dog.name}` : "N/A",
        PackageName: eventData.dog ? `${eventData.dog.packageName}` : "N/A",
        Type: eventData.eventName,
        CreatedBy: eventData.currentUser,
        Message: eventData.message,
      };
      data.push(rollEventData);
    }

    // push each tickcet's info into a row
  });
  // data.push(Listdata)
  const csvReport = {
    data: data,
    headers: ParkEventList,
    filename: `${eventName}.csv`,
  };

  return (
    <CSVLink
      type="button"
      {...csvReport}
      style={{
        // width: fullwidth
        //     ? Theme.getButtonFullWidth(width)
        //     : Theme.getButtonWidth(width, mobileShrink),
        width: "133px",
        borderRadius: 5,
        outline: "none",
        borderColor: "transparent",
        cursor: "pointer",
        // height: height || Theme.getButtonHeight(width),
        textAlign: "center",
        backgroundColor: disabled
          ? Theme.DISABLED_COLOR
          : alt
          ? Theme.ALT_BUTTON_COLOR
          : Theme.LINK_FONT_COLOR,
        textDecoration: "none",
        margin: "1% 2px 1% 2px",
        verticalAlign: "center",
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        color: "#ffff",
      }}
      onClick={onClick}
      disable={disabled}
    >
      {text}
    </CSVLink>
  );
};

export default DownloadCSV;
