import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm } from "redux-form";
import {
  OnboardingHeader,
  Theme,
  Button,
  PackageSelector
} from "../../../globalComponents/";
import { submitHandler } from "../../../globalComponents/FetchComponents/PackageForm";
import { USER_DETAILS, DOG_DETAILS } from "../../../store/queries.js";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class Packages extends Component {
  constructor(props) {
    super(props)

    const { dogId } = queryString.parse(props.location.search);
    console.log("dogId ", dogId)
    this.state = {
      dogId
    }
  }

  componentDidMount() {
    // bind the dogId to 
    console.log("componentDidMount: ", this.state.dogId)
    this.props.change("dogId", this.state.dogId);
  }

  getId() {
    const {
      global: { currentUser }
    } = this.props;
    if (currentUser.selectedUser) {
      return currentUser.selectedUser;
    } else {
      return currentUser.id;
    }
  }

  onSkip = () => {
    this.props.history.push("payment");
  };

  renderUserCurrentPackage(loading, error, data, refetch, width) {
    return (<div>
      {!loading && !error && data?.userDetail && data.userDetail.package && (
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Current Package: {data.userDetail.package.name}
        </div>
      )}
    </div>)
  }

  renderDogCurrentPackage(loading, error, data, refetch, width) {
    return (<div>
      {!loading && !error && data.dogDetail && data.dogDetail.package && (
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Current package for {data.dogDetail.name}: {data.dogDetail.package.name}
        </div>
      )}
    </div>)
  }

  render() {
    const {
      dimensions: { height, width }
    } = this.props.global || {
      dimensions: { height: 0, width: 1000 }
    },
      { handleSubmit, history } = this.props;
    // console.log("_--------------->", this.state.dogId );
    return (
      <Query
        fetchPolicy="no-cache"
        query={this.state.dogId ? DOG_DETAILS : USER_DETAILS}
        variables={{ id: this.state.dogId ? this.state.dogId : this.getId() }}
      >
        {({ loading, error, data, refetch }) => (
          <OnboardingHeader
            back={() => history.push("/staff/member")}
            progress={false}
            containerStyle={{}}
            checkInStatus={false}
          >

            {
              this.state.dogId ? this.renderDogCurrentPackage(loading, error, data, refetch, width) : this.renderUserCurrentPackage(loading, error, data, refetch, width)
            }

            <PackageSelector
              {...{ width, height }}
              hiddenPackages={true}
              onChange={pack => {
                this.props.change("packageId", pack.id);
                this.props.change("isRecurring", pack.recurring);
              }}
              dogId={this.state.dogId}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "5%",
                width: Theme.getLongInputWidth(width),
                marginTop: "5%"
              }}
            >
              <Button
                text="Skip"
                containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
                alt={true}
                width={width}
                onClick={this.onSkip}
              />
              <Button
                containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
                text="Save and Continue"
                width={width}
                onClick={handleSubmit}
              />
            </div>
          </OnboardingHeader>
        )}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global }),
  formSubmit = (values, dispatch, props) => {
    console.log(" ** formSubmit ** ", props)
    const { dogId, packageId, isRecurring } = props.values;
    console.log({ dogId, packageId, isRecurring })

    const onUserUpdated = isRecurring => {
      if (isRecurring) {
        props.history.push("/staff/member/recurring");
      } else {
        props.history.push("payment");
      }
    };

    const onDogUpdated = isRecurring => {
      console.log(" onDogUpdated ", isRecurring)
      if (isRecurring) {
        props.history.push(`/staff/member/recurring?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
      } else {
        props.history.push(`payment?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
      }
    };

    return submitHandler(values, props, null, dogId ? onDogUpdated : onUserUpdated);
  };

Packages = reduxForm({
  form: "Packages",
  onSubmit: formSubmit
})(Packages);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Packages);
