import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { ACCEPT_RULES, BULK_CHECKIN } from "../../../store/mutations.js";
import { OnboardingHeader, Button } from "../../../globalComponents";
import SignaturePad from "../../../globalComponents/SignaturePad/SignaturePad.js";
import { USER_NOTES } from "../../../store/queries.js";
import { uniq as _uniq } from "lodash";

class ParkRules extends Component {
  state = {
    enabled: false,
    noOverflow: true,
    loading: false,
    signatureDataUrl: null,
  };

  constructor(props) {
    super(props);
    this.onSignatureDrawn = this.onSignatureDrawn.bind(this);
  }

  onSignatureDrawn(base64Url) {
    console.log(base64Url);
    this.props.change(`signatureDataUrl`, base64Url);
    this.setState({
      signatureDataUrl: base64Url,
    });
  }

  handleLoading = () => {
    if (this.state.signatureDataUrl) {
      this.setState({
        loading: true,
      });
      this.props.handleSubmit();
    } else {
      alert("Please have the member accept the park rules before continuing. ");
    }
  };
  render() {
    const { enabled, noOverflow, loading } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 767;
    const { handleSubmit, history } = this.props;

    // var divContainer = document.getElementById("termsContainer");
    // if (divContainer) {
    //   if (
    //     noOverflow !==
    //     divContainer.offsetHeight > divContainer.scrollHeight
    //   ) {
    //     this.setState({
    //       noOverflow: divContainer.offsetHeight > divContainer.scrollHeight
    //     });
    //   }
    // }
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
            ? this.props.history.push("/manager/members")
            : this.props.history.push("/")
        }
        progress={3}
        containerStyle={{
          display: shrink ? "block" : "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5%",
          alignContent: "center",
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginBottom: "2%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR,
          }}
        >
          Park Rules
        </div>
        <div
          style={{
            height: height / 2,
            border: "1px solid",
            width: shrink ? "95%" : width / 1.3,
            borderRadius: 10,
            padding: "2%",
            backgroundColor: "white",
            borderColor: Theme.MAIN_COLOR,
            margin: shrink ? "0 auto 15px" : " ",
          }}
        >
          <div
            id="termsContainer"
            style={{
              width: shrink ? "95%" : width / 1.38,
              height: height / 2.2,
              overflowY: "scroll",
              padding: "2%",
            }}
            onScroll={(event) => {
              if (
                event.currentTarget.scrollHeight -
                  event.currentTarget.scrollTop <
                Math.round(event.currentTarget.getBoundingClientRect().height)
              ) {
                console.log("hrere");
                this.setState({
                  enabled: true,
                });
              }
            }}
          >
            <p>
              <u>PARK RULES</u>
            </p>
            <p>
              As a member or visitor of Fetch Park, I understand the following
              rules: - I will clean up after my dog - I will not bring in
              outside food or drinks (including dog treats) - I will respect the
              opinion of the Bark Rangers on staff regarding my pet's behavior -
              I understand that my pet must be up to date on all vaccinations
              (Rabies, DHLPP(Including Leptospirosis), and Bordetella) - I
              understand that I assume all liability and resposibility for any
              damage to park property
            </p>
            <p>&nbsp;</p>
            <p>
              <u>DOG DISCLOSURES</u>
            </p>
            <p>
              Participant hereby declares, represents, and warrants that
              Participant&rsquo;s dog:
            </p>
            <ul>
              <li>Is at least 4 months old</li>
              <li>Is friendly and social around people and other dogs</li>
              <li>Is clean and does not have fleas or ticks</li>
              <li>
                Is spayed/neutered if 8 months of age or older, and is current
                within the calendar year on vaccinations, including Rabies,
                DHLPP and Bordetella.{" "}
              </li>
            </ul>
            <p>
              PLEASE READ THIS CAREFULLY: It releases Fetch ATL LLC
              (&ldquo;Fetch Park&rdquo;) from any liability to you, your dog or
              anybody accompanying you except for gross negligence. It affects
              any rights you may have if you, your dog(s) or anybody you bring
              along to otherwise suffer(s) damages while using the Dog Park. The
              &ldquo;Dog Park&rdquo; is that portion of Fetch&rsquo;s entire
              grounds (&ldquo;PARK&rdquo;) for which you have voluntarily paid a
              separate membership fee to use, and is fenced off from the other
              Grounds.
            </p>
            <br />
            <p>
              I (&ldquo;PARTICIPANT&rdquo;) hereby agree to the following
              regarding the Dog Park (applies to each of participant&rsquo;s
              dogs):
            </p>
            <p>
              <u>BEHAVIOR OF PARTICIPANT&rsquo;S DOG</u>
            </p>
            <ol>
              <li>
                Participant further understands and agrees that in admitting
                his/her dog to the Dog Park, the Dog Park and Fetch&rsquo;s
                Staff have relied on Participant&rsquo;s representation and
                warranty that such dog is in good health and has not harmed or
                shown aggressive, threatening, or vicious behavior towards any
                person or any other dog.
              </li>
              <li>
                Owners are responsible for behavior of their dog and must
                promptly break up any disturbances or fights involving their
                dog(s).
              </li>
              <li>
                If Participant brings any dog(s) that exhibit aggressive,
                threatening, or vicious behavior toward any person(s) or any
                other dog(s), and they knew or should have known of the dog(s)
                tendency to behave in this manner, then the Participant will be
                liable for any damage to the PARK or injuries to any person(s)
                and other dog(s) caused by Participant&rsquo;s dog(s).
                Participant may also be subject to liability for damage to the
                PARK and Fetch Park&rsquo;s reputation for their dog(s)
                aggressive, threating, or vicious behavior.
              </li>
            </ol>
            <p>
              <u>RULES</u>
            </p>
            <ol>
              <li>
                Participation at the Dog Park is voluntary and at your own risk.
                We reserve the right to excuse any person or dog from the Dog
                Park at any time for negative behavior which could be harmful to
                people (including Staff) or other dogs. It is the
                Participant&rsquo;s sole responsibility to ensure the safety and
                well-being of your dog, your guests and yourself. Membership may
                be suspended or revoked at any time if it is found that a
                participant has jeopardized the safety of other Dog Park users.
              </li>
              <li>
                Participant agrees to keep his or her dog leashed when not
                inside the Dog Park. Participant may release his or her dog from
                leash inside of the Dog Park, but must remain at the Grounds at
                all times while their dog is anywhere in the Dog Park or on the
                Grounds. Abandoning dogs is prohibited and is punishable by law.
                Violators are subject to revocation of memberships and will be
                banned from using the Dog Park, as well as being reported to the
                City of Atlanta as appropriate.
              </li>
              <li>
                Children under the age of twelve years must be under strict
                adult supervision.
              </li>
              <li>
                Participant understands that Staff will be on hand inside the
                Dog Park and such Staff will continuously clean the Dog Park;
                however, outside of the Dog Park, whether on the Grounds or
                neighboring properties, Participant must immediately bag and
                dispose of any dog waste left by their dogs.
              </li>
              <li>
                Participant understands that if they or their dog(s) negligently
                damages any sign(s) inside the Dog Park or on the Grounds, that
                they are personally liable for the cost of repairs or
                replacement the sign(s) and that Fetch Park reserves the right
                to ban you and your dog(s).
              </li>
              <li>
                Participant understands that if they are asked to leave the Dog
                Park and Grounds because of damaged caused by them or their
                dog(s) or because of their dog(s) behavior, that they will not
                receive a refund for their day pass or membership.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <u>RELEASE</u>
            </p>
            <p>
              I, undersigned Participant, for good an valuable consideration,
              including the right to use the Dog Park, do hereby (i) agree to be
              solely responsible for, and shall indemnify and hold harmless
              Fetch Park and any of its officers, owners, members, managers,
              principals, employees, servants, agents, and related parties from
              any loss, cost or damage (including, without limitation,
              attorney&rsquo;s fees) suffered or incurred as a result of the
              presence or activity of Participant or any dog owned or brought to
              the Park by Participant (hereinafter referred to as
              &ldquo;RELEASEES&rdquo;), (ii) release, waive, discharge and
              covenant not to sue RELEASEES, for any loss, liability, cost,
              claim, damage of every kind or nature (including, without
              limitation, incidental and consequential damages), expense
              (including, without limitation, costs of investigation, defense
              and settlement, and reasonable attorney&rsquo;s fees and expenses)
              and/or cause of action, whether or not involving a third-party
              claim (Collectively &ldquo;Damages&rdquo;) arising, directly or
              indirectly, out of or related to any loss, damage, injury, death,
              that occurs to person or dog, as a result of my electing to use
              the Dog Park (including Damages caused in whole or in part by
              negligence of Fetch or Staff), and (iii) knowingly and voluntarily
              assume any and all risk of harm to person or dog (including
              Damages caused in whole or in part by negligence of Fetch or
              Staff). Any time groups of dogs play off leash together there is
              chance for injury to a dog. By signing below I&rsquo;m giving
              permission for my dog(s) to play off leash with other dogs and
              play on agility equipment while at the Dog Park. Participant
              agrees that the Dog Park has made every effort to provide a safe
              and clean environment for his or her dog.
            </p>
            <p>(PARTICIPANT MUST BE AT LEAST 18 YEARS OF AGE)</p>
            <p>
              By signing this Disclaimer, Agreement and Waiver, I state that: I
              have read and understand the conditions set forth in this
              Disclosure, Rules and Release; I am 18 years of age or older; I
              agree to all conditions set forth herein, and I sign this
              voluntary. This is a release of liability. Do not sign if you do
              not understand or do not agree with its terms.
            </p>
          </div>
        </div>

        <SignaturePad onSignatureDrawn={this.onSignatureDrawn}></SignaturePad>

        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10%",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <Button
            text="Cancel"
            alt={true}
            width={width}
            onClick={() =>
              this.props.global.currentUser.type === "Employee"
                ? this.props.history.push("/dashboard")
                : this.props.global.currentUser.type === "Manager" ||
                  this.props.global.currentUser.type === "Admin"
                ? this.props.history.push("/manager/members")
                : this.props.history.push("/")
            }
          />
          <Button
            containerStyle={{ marginLeft: "auto" }}
            text="Accept"
            width={width}
            loading={loading}
            loadingText="Wait"
            onClick={this.handleLoading}
            disabled={!enabled && !noOverflow || loading}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  console.log(props.global.currentUser);

  const getUserIdToUpdateAcceptRules = () => {
    try {
      if (props.global.currentUser.selectedUser) {
        // if employee accepts the rules on behalf of user
        return parseInt(props.global.currentUser.selectedUser);
      } else {
        // this is new flow to make it work for user who accept ruels by themself
        return parseInt(props.global.currentUser.id);
      }
    } catch (e) {
      return null;
    }
  };

  const input = {
    id: getUserIdToUpdateAcceptRules(),
    parkId: props.global.currentUser?.park?.id || "",
  };
  if (values.signatureDataUrl)
    input["signatureDataUrl"] = values.signatureDataUrl;

  if (values.signatureDataUrl) {
    console.log("input ", input);
    props.client
      .mutate({
        mutation: ACCEPT_RULES,
        variables: {
          input,
        },
      })
      .then(({ data: { updateUser } }) => {
        alert("Thanks. You’re all set.");
        if (props.global.currentUser.checkingInDogs) {
          let checkingInDogs = props.global.currentUser.checkingInDogs;
          let selectedUserId = props.global.currentUser.selectedUser;
          startCheckIn(checkingInDogs, selectedUserId, props);
        } else {
          const userType = props.global.currentUser.type.toLowerCase();
          switch (userType) {
            case "employee": {
              props.history.push("/dashboard");
              break;
            }
            case "manager":
            case "admin": {
              props.history.push("/manager/members");
              break;
            }
            case "member": {
              props.history.push("/profile");
              break;
            }
            default: {
              props.history.push("/");
            }
          }
        }

        //old flow
        // const userType = (props.global.currentUser.type).toLowerCase()
        // userType === "employee"
        //   ? props.history.push("/dashboard")
        //   : props.global.currentUser.type === "manager" ||
        //     props.global.currentUser.type === "admin"
        //     ? props.history.push("/manager/members")
        //     : props.history.push("/");
        return true;
      })
      .catch((error) => {
        alert(error.message.slice(15));
        props.history.push("/");
        console.error(error.message);
      });
  } else {
    alert("Please have the member accept the park rules before continuing. ");
  }
};
const startCheckIn = (checkingInDogs, _selectedUserId, props) => {
  // let { selectedUserId } = this.state;
  if (_selectedUserId) {
    const checkedinArr = Object.entries(checkingInDogs);
    const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));
    for (const userId of allUsersIds) {
      if (_selectedUserId === userId) {
        checkedNoteCheckIn(checkingInDogs, userId, props);
        break;
      } else {
        checkedNoteCheckIn(checkingInDogs, userId, props);
        break;
      }
    }
  } else {
    alert("Need to select at least one dog.");
  }
};
const checkedNoteCheckIn = (checkingInDogs, userId, props) => {
  let { client } = props;
  if (userId) {
    // Check IN start

    const values = {
      id: Number(userId),
    };
    client
      .query({
        query: USER_NOTES,
        variables: values,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        const {
          data: {
            userDetail: { notes, firstName, lastName },
          },
        } = response;

        if (notes.length > 0) {
          let note = notes && notes[0].content;
          if (window.confirm(`${firstName + " " + lastName} NOTE : ${note}`)) {
            return new Promise((resolve, reject) => {
              handleBulkCheckIn(checkingInDogs, props);
            });
          }
        } else {
          if (window.confirm("Do you want to check-in the selected members?")) {
            return new Promise((resolve, reject) => {
              handleBulkCheckIn(checkingInDogs, props);
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
    // Check IN end
  }
};

const handleBulkCheckIn = (checkingInDogs, props) => {
  const checkingIn = [];
  const tempArr = [];
  // console.log("[handleBulkCheckIn] state.checkingIn: ", this.state.checkingInDogs)

  const checkedinArr = Object.entries(checkingInDogs);
  // console.log("[handleBulkCheckIn] checkedinArr: ", checkedinArr)
  const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));

  const countDogsCheckedInForUser = (forUserId) => {
    let count = 0;
    let dogIdArr = [];

    for (let [dogId, userId] of checkedinArr) {
      if (forUserId == userId) dogIdArr.push(Number(dogId));
    }
    return dogIdArr;
  };
  for (const userId of allUsersIds) {
    const dogIds = countDogsCheckedInForUser(userId);
    checkingIn.push({ userId, dogCount: dogIds.length, dogIds });
  }

  console.log("************ CheckingIn ***********", checkingIn);

  props.client
    .mutate({
      mutation: BULK_CHECKIN,
      variables: {
        input: {
          viaApp: "web",
          users: checkingIn,
          parkId:
            props.global.currentUser.park && props.global.currentUser.park.id,
          // ||
          // this.state.parkId,
        },
      },
    })
    .then((response) => {
      if (response.data.checkIn.errors[0]) {
        alert(response.data.checkIn.errors[0].message);
      } else {
        alert("All selected member(s) successfully checked in!");
      }
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      const userType = props.global.currentUser.type.toLowerCase();
      switch (userType) {
        case "employee": {
          props.history.push("/dashboard");
          break;
        }
        case "manager":
        case "admin": {
          props.history.push("/manager/members");
          break;
        }
        case "member": {
          props.history.push("/profile");
          break;
        }
        default: {
          props.history.push("/");
        }
      }
    })
    .catch((error) => {
      let error_msg = error.message.replace("GraphQL error:", "");
      alert(error_msg);
    });
};

ParkRules = reduxForm({
  form: "ParkRules",
  onSubmit: formSubmit,
})(ParkRules);

export default compose(withApollo, connect(mapStateToProps))(ParkRules);
