import ReactPixel from 'react-facebook-pixel';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';

const initializePixel = () => {
  if (ENVIRONMENT === 'production') {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
  }
};

initializePixel();

const logEvent = (eventName, eventParams) => {
  switch (ENVIRONMENT) {
    case 'production':
      console.log(`[PROD] Pixel event: ${eventName}`, eventParams);
      break;
    case 'staging':
      console.log(`[STAGING] Pixel event would be: ${eventName}`, eventParams);
      break;
    default:
      console.log(`[DEV] Pixel event simulation: ${eventName}`, eventParams);
  }
};

export const trackEvent = (eventName, eventParams = {}) => {
  if (ENVIRONMENT === 'production') {
    ReactPixel.track(eventName, eventParams);
  }
  logEvent(eventName, eventParams);
};

export const trackPageView = () => {
  if (ENVIRONMENT === 'production') {
    ReactPixel.pageView();
  }
  logEvent('PageView');
};

