import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { compose, withApollo, Query } from "react-apollo";
import Clock from "../../assets/hour-arrow.svg";
import Mobile from "../../assets/mobile.svg";
import Desktop from "../../assets/web.svg";
import DogPlaceholder from "../../assets/dog_placeholder.png";
import UserPlaceholder from "../../assets/placeholder.jpeg";
import HomeIcon from "../../assets/home.svg";
import OutsideHomeIcon from "../../assets/outsideHome.svg";

import Theme from "../../globalComponents/Theme.js";
import { updateUser } from "../../store/redux/actions.js";
import { ACTIVITY_FEED } from "../../store/queries";
import Button from "../FormComponents/Button";
const width = window.innerWidth;
const shrink = width > 991;

const styles = {
  web: {
    display: "flex",
    flexDirection: "column",
    marginLeft: shrink ? "auto" : "0px",
    width: shrink ? "30%" : "100%",
    border: "1px solid",
    borderColor: Theme.LINK_FONT_COLOR,
    borderRadius: 10,
    marginTop: "0px",
    height: `${window.innerHeight > window.innerWidth ? 70 : 65}vh`,
    overflowY: "scroll",
  },
};

function uniqueUserCount(events) {
  return events
    .map(({ user: { id } }) => id)
    .filter((id, index, self) => self.indexOf(id) === index).length;
}

const FromNowTimer = function (timestamp) {
  const [count, setCounter] = useState(0);
  const ts = timestamp.timestamp;

  useEffect(() => {
    let interval = setInterval(() => {
      setCounter((count) => count + 1);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [count]);
  return <span>{ts.fromNow()}</span>;
};

class ParkLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logsList: [],
      logsData: {},
      isLoading: false,
      page: 1,
      perPage: 10,
    };
  }
  componentDidMount() {
    this.parkLogsList(this.props.parkId);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.parkId !== prevProps.parkId ||
      this.props.reRender !== prevProps.reRender
    ) {
      this.parkLogsList(this.props.parkId);
    }
  }

  parkLogsList = async (parkId) => {
    const { client } = this.props;
    let variables = {
      page: String(this.state.page),
      perPage: String(this.state.perPage),
      parkId,
      type: "check_in",
      start: moment().subtract(12, "hours").toISOString(),
      end: moment().toISOString(),
      requestPage: "dashboard",
    };
    this.setState({
      isLoading: true,
    });

    client
      .query({
        query: ACTIVITY_FEED,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        let arr = [];
        arr.push(
          ...this.state.logsList,
          ...response.data?.fetchActivityList.events
        );
        console.log("Arrr", arr);
        console.log("_______", ...response.data?.fetchActivityList.events);
        this.setState({
          logsList: arr,
          isLoading: false,
          logsData: response.data?.fetchActivityList,
        });
      })
      .catch((error) => {
        console.error("Park Error: ", error);
        this.setState({
          isLoading: false,
        });
        return false;
      });
  };
  handleStatusCheckIn = (type) => {
    let status = {
      alt: "clock",
      src: Clock,
    };
    switch (type) {
      case "mobile":
        status = {
          alt: "Mobile",
          src: Mobile,
        };
        break;
      case "web":
        status = {
          alt: "Web",
          src: Desktop,
        };
        break;
      default:
        break;
    }
    return status;
  };

  loadMoreData = async () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.parkLogsList(this.props.parkId)
    );
  };

  handleVaccineExpiredStatus = (vaccineStatus, checkinStatus) => {
    let status = {
      border: "#e0fffc00",
      background: "1px solid #7c867c",
    };
    if (checkinStatus === "completed") {
      //Add  check
      status.background = "#00f6196b";
      status.border = "1px solid green";
    } else if (
      vaccineStatus === "expiring_soon" &&
      checkinStatus === "completed"
    ) {
      status.border = "1px solid #bfae0d";
      status.background = "#fac81275";
    } else if (vaccineStatus === "expired" && checkinStatus === "completed") {
      status.border = "1px solid #b12443";
      status.background = "#e9101052";
    } else if (
      vaccineStatus === "vaccinated" &&
      checkinStatus === "completed"
    ) {
      status.background = "#00f6196b";
      status.border = "1px solid green";
    } else if (checkinStatus === "failed") {
      //Add  check
      status.border = "1px solid #b12443";
      status.background = "#e9101052";
    }

    return status;
  };
  displayMember = (data) => {
    let userId = data.user.id;

    this.props.updateUser({ selectedUser: userId });
    this.props.history.push({
      pathname: `/staff/member`,
      params: { id: userId },
    });
    // }
  };

  render() {
    const width = window.innerWidth;
    const { logsList, isLoading } = this.state;
    return (
      <div style={styles.web}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: Theme.LINK_FONT_COLOR,
            height: 50,
            alignItems: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: Theme.getSmallFont(width),
              height: 35,
              display: "flex",
            }}
          >
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              Total members in park: {uniqueUserCount(logsList)}
            </div>
          </div>
          <div
            style={{
              color: "white",
              marginLeft: "auto",
              fontSize: Theme.getSmallFont(width),
            }}
          >
            {moment().format("L")}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 10,
            paddingBottom: "3%",
            // paddingLeft: "5%",
          }}
        >
          {logsList &&
            logsList.map((event, index) => {
              let status = {
                border: this.handleVaccineExpiredStatus(
                  event.checkedInDog.vaccineStatus,
                  event.checkinStatus
                ).border,
                background: this.handleVaccineExpiredStatus(
                  event.checkedInDog.vaccineStatus,
                  event.checkinStatus
                ).background,
              };
              return (
                <div
                  key={event.id}
                  style={{
                    paddingLeft: "5%",

                    borderRadius: "12px",
                    margin: "3%  3% 0 ",
                    ...status,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "10px",
                      marginBottom: "0px",
                      width: "100%",
                      minHeight: 52,
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        // width: "45px",
                        width: "40px",
                        flexDirection: "column",
                        overflow: "hidden",
                        borderRadius: "5px",
                        height: "40px",
                        marginLeft: "3px",
                      }}
                    >
                      <img
                        // key={data.id}
                        alt={this.handleStatusCheckIn(event?.checkedInApp).alt}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={this.handleStatusCheckIn(event?.checkedInApp).src}
                        onError={(event) =>
                          event.target.setAttribute("src", Clock)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        // width: "45px",
                        width: "40px",
                        flexDirection: "column",
                        overflow: "hidden",
                        borderRadius: "5px",
                        height: "40px",
                        marginLeft: "3px",
                      }}
                    >
                      <img
                        // key={data.id}
                        alt={event.user.profilePicture.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={
                          (logsList &&
                            // process.env.REACT_APP_API_ENDPOINT +
                            //   event.user.profilePicture.url) ||
                            event.user.profilePictureCdn) ||
                          UserPlaceholder
                        }
                        onError={(event) =>
                          event.target.setAttribute("src", UserPlaceholder)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 5,
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                      onClick={() => this.displayMember(event)}
                    >
                      {/* TimeStamp Section */}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            color: Theme.LINK_FONT_COLOR,
                            fontFamily: Theme.INPUT_FONT,
                            fontSize: Theme.getNormalFont(width),
                            marginRight: 4,
                          }}
                        >
                          {"In: " +
                            moment(event.timestamp).format("LT") +
                            " | "}
                          <FromNowTimer timestamp={moment(event.timestamp)} />
                        </div>
                      </div>
                      {/* User Name Section */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            fontSize: Theme.getNormalFont(width),
                            color: Theme.DARK_TEXT_COLOR,
                            fontFamily: Theme.HEADER_FONT,
                          }}
                        >
                          {event.user.firstName + " " + event.user.lastName}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "0px",
                      marginBottom: "10px",
                      width: "100%",
                      minHeight: 42,
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        // width: "45px",
                        width: "40px",
                        flexDirection: "column",
                        overflow: "hidden",
                        borderRadius: "5px",
                        height: "40px",
                        marginLeft: "3px",
                      }}
                    >
                      {console.log(event.isHomeParkCheckin)}
                      <img
                        // key={data.id}
                        alt={event.isHomeParkCheckin ? "home" : "outsideHome"}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={
                          event.isHomeParkCheckin ? HomeIcon : OutsideHomeIcon
                        }
                        onError={(event) =>
                          event.target.setAttribute("src", Clock)
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        // width: "45px",
                        width: "40px",
                        flexDirection: "column",
                        overflow: "hidden",
                        borderRadius: "5px",
                        height: "40px",
                        marginLeft: "46px",
                      }}
                    >
                      <img
                        alt="clock"
                        src={
                          logsList &&
                          // process.env.REACT_APP_API_ENDPOINT +
                          //   event.checkedInDog.profilePicture.url
                          event.checkedInDog.profilePictureCdn ||
                          DogPlaceholder
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        onError={(event) =>
                          event.target.setAttribute("src", DogPlaceholder)
                        }
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: 5,
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            fontSize: Theme.getNormalFont(width),
                            color: Theme.DARK_TEXT_COLOR,
                            fontFamily: Theme.HEADER_FONT,
                          }}
                        >
                          {event.checkedInDog.name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 5,
                      marginTop: "0px",
                      marginBottom: "0px",
                    }}
                    onClick={() => this.displayMember(event)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          fontSize: Theme.getNormalFont(width),
                          color: Theme.DARK_TEXT_COLOR,
                          fontFamily: Theme.HEADER_FONT,
                        }}
                      >
                        {event.checkinStatus === "failed" &&
                          event.checkinMessage}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          >
            {this.state.logsData.totalPages > this.state.page && (
              <Button
                type="button"
                containerStyle={{ margin: "auto" }}
                text={"Load More"}
                loadingText="Loading"
                width={width}
                onClick={() => {
                  this.loadMoreData();
                }}
                loading={false}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };
export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(ParkLog);
