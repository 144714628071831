import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";

const styles = {
  tag: {
    float: "left",
    padding: "0 1%",
    margin: "0 .75%",
    border: "2px solid",
    borderColor: Theme.INACTIVE_GREY_COLOR,
    borderRadius: 5,
    transition: "transform 300ms ease-in-out",
    cursor: "pointer"
  },

  tagOnHover: {
    float: "left",
    padding: "0 1%",
    margin: "0 .75%",
    border: "2px solid",
    borderColor: Theme.STANDARD_ORANGE,
    color: Theme.STANDARD_ORANGE,
    borderRadius: 10,
    transition: "transform 300ms ease-in-out",
    cursor: "pointer"
  }
};

export default class Tag extends Component {
  state = {
    hover: false
  };

  toggleHover = () => {
    // console.log("HIt ",this.state.hover );
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { tagText, handleTag, selectedTags } = this.props;
    const { hover } = this.state;
    return (
      <div
        onClick={() => handleTag(tagText)}
        style={
          hover
            ? styles.tagOnHover
            : selectedTags.includes(tagText)
            ? styles.tagOnHover
            : styles.tag
        }
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        {tagText}
      </div>
    );
  }
}
