import React, { Component, } from "react";
import { withRouter } from "react-router-dom";
import {
  DOG_STRIKE_UPDATE,
} from "../../store/mutations.js";

import RedCross from "../../assets/redCross.svg";

class StrikesCheckBox extends Component {
  state = {
    isStrike: false,
    nameDogStrike: false,
  };
  componentDidMount() {
    if (this.props.isStrikeStatus === true) {
      this.setState({ isStrike: true });
    } else if (this.props.isStrikeStatus === false) {
      this.setState({ isStrike: false });
    } else {
      this.setState({ isStrike: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isStrikeStatus !== prevProps.isStrikeStatus) {
      this.setState({ isStrike: this.props.isStrikeStatus });
    }
    if (this.props.name !== this.state.nameDogStrike) {
      this.setState({
        nameDogStrike: this.props.name,
        isStrike: this.props.isStrikeStatus,
      });
    }
  }
  handleStrikeStatus = (id, isStrikeCount, refetch) => {
    let { client } = this.props;
    this.setState({
      isLoading: true,
    });
    // if (isStrikeCount == 3) {
    //     this.props.refetch()
    // }
    client
      .mutate({
        mutation: DOG_STRIKE_UPDATE,
        variables: {
          input: {
            dogId: id,
            strikeCount: Number(isStrikeCount),
          },
        },
      })
      .then((response) => {
        // // console.log("Dog Updated: ", refetch)
        if (isStrikeCount == 3) {
          this.props.refetch();
          // this.props.change(this.props.banneName, true);
        }
        isStrikeCount += 1;
        this.props.change(this.props.name, isStrikeCount);
      })
      .catch((error) => {
        this.setState({ validation: error });
      });
  };
  handleStrikesCheckbox = (event) => {
    let newState = { ...this.state };

    if (!newState.isStrike) {
      if (
        this.props.dog_id &&
        this.props.location.pathname === "/staff/member"
      ) {
        newState.isStrike = !newState.isStrike;
        this.setState({ isStrike: newState.isStrike });
        console.log(this.props.dog_id, "Status", this.props.strikeCount);
        this.handleStrikeStatus(this.props.dog_id, this.props.strikeCount);
      }
    } else {
      event.preventDefault();
    }
  };
  render() {
    const { isStrike } = this.state;
    
    return (
      <div 
        style={{ width: "33.3%", display: "inline-block", textAlign: "center" }}
      >
        <div style={{ position: "relative", marginBottom: "10px" }}>
          <button
            onClick={(e) => this.handleStrikesCheckbox(e)}
            type="button"
            style={{
              width: 25,
              height: 25,
              border: "2px solid",
              cursor: "pointer",
              borderColor: "#ef3939",
              outline: "none",
              borderRadius: 5,
              marginLeft: "-1px",
              padding: "0px",
              background: isStrike ? "#ef3939" : "transparent",
            }}
          >
            {isStrike ? (
              <img
                active={false}
                alt="Active"
                src={RedCross}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}
export default withRouter(StrikesCheckBox);
