import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DOG_SPRAYED_UPDATE } from "../../store/mutations.js";
import { PawIndicator } from "../index.js";
import Theme from "../Theme.js";

class BirthCheckBox extends Component {

    state = {
        isNeutered: false,
        nameDogNeutered: false
    };
    componentDidMount() {
        if (
            this.props.isNeuteredStatus === true
        ) {
            this.setState({ isNeutered: true });
        }
        else if (
            this.props.isNeuteredStatus === false
        ) {

            this.setState({ isNeutered: false });
        }
        else {

            this.setState({ isNeutered: false });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isNeuteredStatus !== prevProps.isNeuteredStatus) {
            this.setState({ isNeutered: this.props.isNeuteredStatus })
        }
        if (this.props.name !== this.state.nameDogNeutered) {
            // console.log("-------------->",this.props.name !== this.state.nameDog);
            this.setState({ nameDogNeutered: this.props.name, isNeutered: this.props.isNeuteredStatus });
        }
    }
    handleSPStatus = (id, isNeutered, refetch) => {
        let { client } = this.props;
        this.setState({
            isLoading: true
        })
        client
            .mutate({
                mutation: DOG_SPRAYED_UPDATE,
                variables: {
                    input: {
                        id: id,
                        isNeutered: isNeutered
                    }
                }
            })
            .then((response) => {
                // // console.log("Dog Updated: ", refetch)
                // refetch()
                this.props.change(this.props.name, isNeutered);

            })
            .catch(error => {
                this.setState({ validation: error });
            });

    }
    handleBirthCheckbox = () => {
        let newState = { ...this.state };
        newState.isNeutered = !newState.isNeutered;
        this.setState({ isNeutered: newState.isNeutered });
        // Check for Members details confirmation
        if (this.props.dog_id && this.props.location.pathname === "/staff/member") {
            this.handleSPStatus(this.props.dog_id, newState.isNeutered)
        }
        else {
            this.props.change(this.props.name, newState.isNeutered);
        }
        // Check for create Account confirmation
        if (this.props.dog_id && this.props.location.pathname === "/members/onboarding/confirmAccount") {
            this.handleSPStatus(this.props.dog_id, newState.isNeutered)
        }
        else {
            this.props.change(this.props.name, newState.isNeutered);
        }

        if (!this.props.dog_id) {
            this.props.change(this.props.name, newState.isNeutered);
        }
        if (this.props.location.pathname === "/members/onboarding/dogInfo") {
            this.props.setIndexNeutered(this.props.index, newState.isNeutered)
        }
    };
    render() {
        const {
            isNeutered
        } = this.state;

        return (
            <div style={{ width: "100%", }}>
                <div style={{ display: "flex", position: "relative", marginBottom: "10px" }}>
                    <button
                        onClick={e => this.handleBirthCheckbox(e)}
                        type="button"
                        style={{
                            width: 25,
                            height: 25,
                            border: "2px solid",
                            cursor: "pointer",
                            borderColor: Theme.LINK_FONT_COLOR,
                            outline: "none",
                            borderRadius: 5,
                            marginLeft: "-1px",
                            backgroundColor: isNeutered
                                ? Theme.LINK_FONT_COLOR
                                : "white"
                        }}
                    >
                        {isNeutered ? (
                            <PawIndicator
                                active={false}
                                style={{
                                    marginLeft: -4,
                                    marginTop: 1,
                                    width: 16,
                                    height: 16,
                                    color: "white"
                                }}
                            />

                        ) : null}
                    </button>
                    <div
                        style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: "5px",
                            width: "100%",

                        }}
                    >
                        <span> Spayed/Neutered</span>
                        {/* <HoverTag text="Some vets give dogs the leptospirosis vaccine as its own separate vaccination. If your vaccination records show leptospirosis with its own expiration date, then click the separate leptospirosis document button and an orange paw icon will be show." /> */}
                    </div>
                </div>

            </div>
        );
    }
}
export default withRouter(BirthCheckBox)
