import React, { Component } from "react";
import Theme from "../Theme.js";

export default class Button extends Component {
  render() {
    const {
      text,
      width,
      containerStyle,
      fullwidth, //fullwidth button
      mobileShrink,
      onClick,
      disabled,
      type,
      id,
      alt, //grey button style
      height,
      memberStatus,
      floatState,
      loading,
      loadingText,
    } = this.props;
    return (
      <div style={containerStyle}>
        <button
          id={id}
          type={type || "button"}
          style={{
            width: fullwidth
              ? Theme.getButtonFullWidth(width)
              : Theme.getButtonWidth(width, mobileShrink),
            borderRadius: 5,
            outline: "none",
            borderColor: "transparent",
            cursor: "pointer",
            height: height || Theme.getButtonHeight(width),
            textAlign: "center",
            backgroundColor: disabled
              ? Theme.DISABLED_COLOR
              : alt
              ? Theme.ALT_BUTTON_COLOR
              : Theme.LINK_FONT_COLOR,
            textDecoration: "none",
            margin: "1% 2px 1% 2px",
            verticalAlign: "center",
            display: memberStatus ? "none" : "flex",
            justifyContent: "center",
            float: floatState ? "right" : "",
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {loading && (
            <i
              className="fa fa-spinner fa-spin"
              style={{ marginRight: "5px", marginTop: "8px", color: "white" }}
            />
          )}
          {loading && (
            <div
              style={{
                margin: loading ? "5px" : "auto",
                fontSize: Theme.getFormFont(width),
                fontFamily: Theme.HEADER_FONT,
                color: disabled
                  ? Theme.DISABLED_FONT_COLOR
                  : alt
                  ? Theme.DARK_TEXT_COLOR
                  : "white",
              }}
            >
              {loadingText}
            </div>
          )}
          {!loading && (
            <div
              style={{
                margin: "auto",
                fontSize: Theme.getFormFont(width),
                fontFamily: Theme.HEADER_FONT,
                color: disabled
                  ? Theme.DISABLED_FONT_COLOR
                  : alt
                  ? Theme.DARK_TEXT_COLOR
                  : "white",
              }}
            >
              {text}
            </div>
          )}
          {/* <div
            style={{
              margin: "auto",
              fontSize: Theme.getFormFont(width),
              fontFamily: Theme.HEADER_FONT,
              color: disabled
                ? Theme.DISABLED_FONT_COLOR
                : alt
                ? Theme.DARK_TEXT_COLOR
                : "white",
            }}
          >
            {text}
          </div> */}
        </button>
      </div>
    );
  }
}
