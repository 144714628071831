import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_UPDATE } from "../../../store/mutations.js";
import { OnboardingHeader, Button } from "../../../globalComponents/";

class Terms extends Component {
  state = {
    enabled: false,
    noOverflow: true,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { enabled, noOverflow } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, history } = this.props;

    // var divContainer = document.getElementById("termsContainer");
    // if (divContainer) {
    //   if (
    //     noOverflow !==
    //     divContainer.offsetHeight > divContainer.scrollHeight
    //   ) {
    //     this.setState({
    //       noOverflow: divContainer.offsetHeight > divContainer.scrollHeight
    //     });
    //   }
    // }
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
            ? this.props.history.push("/manager/members")
            : this.props.history.push("/")
        }
        progress={3}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5%",
          alignContent: "center",
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginBottom: "2%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR,
          }}
        >
          Terms and Conditions
        </div>
        <div
          style={{
            height: height / 2,
            border: "1px solid",
            width: width / 1.3,
            borderRadius: 10,
            padding: "2%",
            backgroundColor: "white",
            borderColor: Theme.MAIN_COLOR,
          }}
        >
          <div
            id="termsContainer"
            style={{
              width: width / 1.38,
              height: height / 2.2,
              overflowY: "scroll",
              padding: "2%",
            }}
            onScroll={(event) => {
              if (
                event.currentTarget.scrollHeight -
                  event.currentTarget.scrollTop <
                Math.round(event.currentTarget.getBoundingClientRect().height)
              ) {
                console.log("hrere");
                this.setState({
                  enabled: true,
                });
              }
            }}
          >
            <p>
              Fetch Park respects your privacy and does not collect any
              personally identifiable information except for such personal
              information that you provide to us - including information
              directly related to your membership through our website and mobile
              app. We will not submit any personally identifiable information to
              any third party without your express authorization except as
              required by law.
            </p>
            <p>&nbsp;</p>
            <p>
              Fetch Park uses cookies to collect certain information about your
              visit to this website and on our mobile app.If you do not want us
              to use cookies, you may adjust your internet browser to refuse
              cookies. Through your use of the Fetch Park website and mobile
              app, you consent to the terms of this privacy statement, including
              the collection of certain information through cookies. We may
              change our privacy statement without advance notice by posting a
              new version to this website.
            </p>
            <p>&nbsp;</p>
            <p>
              The copyrights in the text, images, and all data (the “content”)
              on this website are owned or licensed by Fetch Park. None of the
              content on this website may be copied, reproduced, downloaded,
              distributed, displayed, or transmitted without the express written
              permission of Fetch Park and the relevant copyright holders.
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10%",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <Button
            text="Back"
            alt={true}
            width={width}
            // href={"/members/onboarding/address"}
            onClick={() =>
              this.props.history.push(history.location?.state?.from || "/login")
            }
          />
          <Button
            containerStyle={{ marginLeft: "auto" }}
            text="Accept"
            width={width}
            onClick={handleSubmit}
            disabled={!enabled && !noOverflow}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  console.log("submit: ", values);
  var newValues = values;
  newValues.id =
    props.global.currentUser.selected || props.global.currentUser.id;
  newValues.step = 3;
  newValues.acceptedTerms = true;
  props.client
    .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
    .then(({ data: { updateUser } }) => {
      console.log("response", updateUser);
      props.history.push({
        pathname: "doginfo",
        state: { from: props.location.pathname },
      });
    })
    .catch((error) => {
      console.error("Error: ", error);
    });

  return true;
};

Terms = reduxForm({
  form: "Terms",
  onSubmit: formSubmit,
})(Terms);

export default compose(withApollo, connect(mapStateToProps))(Terms);
