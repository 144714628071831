import React, { Component, Fragment } from "react";
import Theme from "../Theme.js";
import { Field } from "redux-form";
const required = value => (value ? undefined : "Required");
const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "Invalid email address"
        : undefined;

const number = value => {
    if (value.length > 10 || value.length < 10) {
        return "Invalid number"
    }
    else {
        return value && isNaN(Number(value)) ? "Numbers only" : undefined;
    }
}

const validations = {
    required,
    email,
    number
};

const determineWidth = (width, widthFactor, widthpercent) => {
    if (widthpercent) {
        return widthpercent;
    } else {
        return Theme.getLongInputWidth(width) / (widthFactor || 1);
    }
};

const renderField = ({
    input,
    widthfactor,
    width,
    widthpercent,
    value,
    height,
    center,
    placeholder,
    type,
    meta: { touched, error, warning }
}) => (
    <Fragment>
        
        <input
            {...input}
            placeholder={placeholder}
            type={type}
            value={input.value}
            style={{
                width: determineWidth(width, widthfactor, widthpercent),
                lineHeight: Theme.getInputHeight(width) / 1.6,
                outline: "none",
                fontSize: Theme.getNormalFont(width),
                marginBottom: height / 100,
                paddingLeft: center ? 0 : "5%",
                border: "1px solid",
                borderRadius: 3,
                textAlign: center ? "center" : "",
                boxSizing: "border-box",
                // height: height
            }}
            autoComplete="off"

        />
        
        {touched &&
            ((error && (
                <span
                    style={{
                        fontSize: Theme.getSmallFont(width),
                        color: "red",
                        margin: "auto"
                    }}
                >
                    {error}
                </span>
            ))
                ||
                (warning && <span>{warning}</span>))
        }
    </Fragment>
);

//todo cleanup this component
export default class LabeledTextBox extends Component {
    determineWidth = (width, widthFactor, widthpercent) => {
        if (widthpercent) {
            return widthpercent;
        } else {
            return Theme.getLongInputWidth(width) / (widthFactor || 1);
        }
    };
    render() {
        const {
            name,
            options,
            onFocus,
            error,
            touched,
            placeholder,
            containerStyle,
            validate,
            onBlur,
            type,
            label,
            width,
            height,
            center,
            widthFactor,
            widthpercent,
            onChange,
            info,
        } = this.props;
        const validates = [];
        validate &&
            validate.map(valid => {
                return validates.push(validations[`${valid}`]);
            });
        return (
            <div style={containerStyle}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                        style={{
                            color: Theme.DARK_TEXT_COLOR,
                            fontSize: Theme.getNormalFont(width),
                            fontFamily: Theme.MAIN_FONT,
                        }}
                    >
                        {/* Add label in span and info "? */}
                        <span>{label}</span>   {info}
                    </div>
                    <Field
                        onBlur={onBlur}
                        name={name}
                        style={
                            options
                                ? {
                                    width: this.determineWidth(
                                        width,
                                        widthFactor,
                                        widthpercent
                                    ),
                                    // height: Theme.getInputHeight(width) * 12.5,
                                    fontSize: Theme.getNormalFont(width),
                                    outline: "none",
                                    marginBottom: height / 100,
                                    paddingLeft: center ? 0 : "5%",
                                    border: "1px solid",
                                    borderRadius: 3,
                                    textAlign: center ? "center" : ""
                                }
                                : null
                        }
                        onChange={onChange}
                        parse={value => (type === "number" ? Number(value) : (type === "date" ? value : value))}
                        validate={validates}
                        // component={options ? "select" : renderField}
                        component={renderField}

                        onFocus={onFocus || null}
                        placeholder={placeholder}
                        width={width}
                        height={height}
                        widthfactor={widthFactor}
                        widthpercent={widthpercent}
                        center={center}
                        type={type || "text"}
                    >
                        <option key="null" />
                        {/* {console.log("---hjjhjh---", options)} */}
                        {options
                            ? options.map(option => {
                                return <option key={option}>{option}</option>;
                            })
                            : null}
                    </Field>
                    {touched && (error && <span>{error}</span>)}
                </div>
            </div>
        );
    }
}
