import React, { Component, Fragment } from "react";
import Theme from "../Theme.js";
import { change, Field } from "redux-form";
import DatePicker from "react-datepicker";
import { getDate } from "../../utils/session.js";
import moment from "moment";
const required = (value) => (value ? undefined : "Required");
const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const number = (value) => {
  if (value.length > 10 || value.length < 10) {
    return "Invalid number";
  } else {
    return value && isNaN(Number(value)) ? "Numbers only" : undefined;
  }
};

const validations = {
  required,
  email,
  number,
};

const determineWidth = (width, widthFactor, widthpercent) => {
  if (widthpercent) {
    return widthpercent;
  } else {
    return Theme.getLongInputWidth(width) / (widthFactor || 1);
  }
};

const renderField = ({
  input,
  widthfactor,
  width,
  widthpercent,
  height,
  center,
  placeholder,
  setStartDate,
  dateType,
}) => {
  function convertUTCToLocalDate(date) {
    if (!date) {
      return new Date(date);
    }
    date = new Date(date);
    // date = getDate(date)
    // date = new Date(
    //   date.getUTCFullYear(),
    //   date.getUTCMonth(),
    //   date.getUTCDate()
    // );
    {
      moment(date).format("MM/DD/YYYY");
    }
    date = new Date(date);
    moment(date).format("MM/DD/YYYY");
    return date;
  }

  const minVaccineYearsValidation = () => {
    const date = new Date();
    return new Date(date.setFullYear(date.getFullYear() - 2));
  };
  
  const maxVaccineYearsValidation = () => {
    const date = new Date();
    return new Date(date.setFullYear(date.getFullYear() + 10));
  };

  const minDOBValidation = () => {
    const date = new Date();
    return new Date(date.setFullYear(date.getFullYear() - 32));
  };

  const maxDOBValidation = () => {
    const date = new Date();
    return new Date();
  };

  const isValid = (date) => {
    if (
      dateType === "Vaccine" &&
      (date > maxVaccineYearsValidation() || date < minVaccineYearsValidation())
    ) {
      alert("Invalid Vaccine date");
    } else if (
      dateType === "birthDate" &&
      (date > maxDOBValidation() || date < minDOBValidation())
    ) {
      alert("Invalid DOB");
    } else {
      return true;
    }
  };
  const handleOnBlur = ({ target: { value } }) => {
    const date = new Date(value);
    if (isValid(date)) {
      // console.log("date: %s", format(date, "dd/MM/yyyy"));
    } else {
    }
  };
  return (
    <DatePicker
      dateFormat="MM/dd/yyyy"
      selected={input.value && convertUTCToLocalDate(input.value)}
      onChange={(date) => setStartDate(date)}
      placeholderText={placeholder}
      showMonthDropdown
      showYearDropdown
      locale="us"
      dropdownMode="select"
      name={input.name}
      onBlur={handleOnBlur}
      // minDate={checkDateValidations(dateType).minDate}
      // maxDate={checkDateValidations(dateType).maxDate}
      customInput={
        <input
          name={input.name}
          style={{
            width: determineWidth(width, widthfactor, widthpercent),
            lineHeight: Theme.getInputHeight(width) / 1.6,
            outline: "none",
            fontSize: Theme.getNormalFont(width),
            marginBottom: height / 100,
            paddingLeft: center ? 0 : "5%",
            border: "1px solid",
            borderRadius: 3,
            textAlign: center ? "center" : "",
            boxSizing: "border-box",
          }}
          autoComplete="off"
          min="0"
          max="99"
          placeholder={placeholder}
        />
      }
    />
  );
};

//todo cleanup this component
export default class DatePickerBox extends Component {
  state = {
    selectedDate: "",
  };
  determineWidth = (width, widthFactor, widthpercent) => {
    if (widthpercent) {
      return widthpercent;
    } else {
      return Theme.getLongInputWidth(width) / (widthFactor || 1);
    }
  };
  setStartDate = (date) => {
    let state = this.props.name;
    this.props.change(state, getDate(date));
  };
  render() {
    const {
      name,
      options,
      onFocus,
      error,
      touched,
      placeholder,
      containerStyle,
      validate,
      onBlur,
      type,
      label,
      width,
      height,
      center,
      widthFactor,
      widthpercent,
      info,
      dateType,
    } = this.props;
    const validates = [];
    validate &&
      validate.map((valid) => {
        return validates.push(validations[`${valid}`]);
      });

    return (
      <div style={containerStyle}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              color: Theme.DARK_TEXT_COLOR,
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.MAIN_FONT,
            }}
          >
            {/* Add label in span and info "? */}
            <span>{label}</span> {info}
          </div>
          <Field
            onBlur={onBlur}
            name={name}
            value={this.state.selectedDate}
            style={
              options
                ? {
                    width: this.determineWidth(
                      width,
                      widthFactor,
                      widthpercent
                    ),
                    fontSize: Theme.getNormalFont(width),
                    outline: "none",
                    marginBottom: height / 100,
                    paddingLeft: center ? 0 : "5%",
                    border: "1px solid",
                    borderRadius: 3,
                    textAlign: center ? "center" : "",
                  }
                : null
            }
            dateType={dateType}
            validate={validates}
            component={renderField}
            onFocus={onFocus || null}
            placeholder={placeholder}
            width={width}
            height={height}
            widthfactor={widthFactor}
            widthpercent={widthpercent}
            center={center}
            type={type || "text"}
            setStartDate={this.setStartDate}
            selectedDate={this.state.selectedDate}
          ></Field>
          {touched && error && <span>{error}</span>}
        </div>
      </div>
    );
  }
}
