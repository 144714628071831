import React, { Component, createContext, Fragment } from "react";
import actionCable from "actioncable";
import cookie from "js-cookie";

const CableApp = {};
CableApp.cable = actionCable.createConsumer(
  `${process.env.REACT_APP_API_ENDPOINT}/cable?auth=${cookie.get("token")}`
);
export const CheckInContext = createContext(null);

class ConnectActionCable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInMessage: {},
      loadStatus: false,
    };
  }
  componentDidMount() {
    console.log("Conecte", this.props.parkId);

    let session_token = cookie.get("token");
    if (
      session_token === "" ||
      session_token === undefined ||
      session_token === null ||
      session_token === "null"
    ) {
    } else {
      console.log("Conecte", this.props.parkId);
      this.setState({});
      if (this.props.parkId) {
        this.createSocket();
      }

      // this.unseenMessageCount()
      // this.unseenNotificationCount()
    }
  }

  createSocket() {
    this.notify = CableApp.cable.subscriptions.create(
      {
        channel: "ParkNotifyChannel",
        park_id: this.props.parkId,
      },
      {
        connected: () => {
          console.log("******* Connected *******");
        },
        received: (messages) => {
          console.log("******* Received *******", messages);

          this.setState({
            checkInMessage: messages,
          });
          this.props.setCheckIN(messages);
        },
      }
    );
  }
  componentWillUnmount() {
    console.log("Hello Unmount");
    this.notify && CableApp.cable.subscriptions.remove(this.notify);
  }

  render() {
    const { children } = this.props;
    const { checkInMessage } = this.state;
    // console.log("******* checkInMessage *******", checkInMessage);
    return (
      <CheckInContext.Provider
        value={{
          checkInMessage: checkInMessage,
        }}
      >
        {children}
      </CheckInContext.Provider>
    );
  }
}

export default ConnectActionCable;

// export default ConnectActionCable;
