import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../globalComponents/Theme.js";
import {
  PawIndicator,
  LoadingIndicator,
  MembershipInfo
} from "../../globalComponents";
import { compose, withApollo, Query } from "react-apollo";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { USER_UPDATE } from "../../store/mutations.js";
import { FETCH_MEMBERSHIP } from "../../store/queries.js";

class ConfirmAccountForm extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    selected: 1
  };

  isMobile = () => {
    if (window.innerWidth < 700) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const shrink = width < 960;
    const mobile = width < 450;
    const styles = {
      membershipText: {
        display: "flex",
        flexDirection: "row",
        height: 30,
        paddingTop: 16,
        color: Theme.DARK_TEXT_COLOR,
        fontSize: Theme.getNormalFont(width),
        borderRight: "1px solid #D0D2D5",
        width: "100%",
        justifyContent: "center"
      },
      mobileMembership: {
        color: Theme.DARK_TEXT_COLOR,
        marginBottom: "5%",
        marginLeft: "5%",
        fontSize: Theme.getNormalFont(width)
      }
    };

    return (
      <Query
        fetchPolicy="no-cache"
        query={FETCH_MEMBERSHIP}
        variables={{
          id: this.props.global.currentUser.id
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <LoadingIndicator
                width={this.props.global.dimensions.width}
                height={this.props.global.dimensions.height}
                loading={loading}
              />
            );

          if (error) return `Error: ${error.message}`;
          let dogs = data?.userDetail.dogs
          let users = data.userDetail
          return (
            <div style={{ width: "90%", margin: "auto" }}>
              {
                dogs.map((data, index) => {
                  let dogName = data.name;
                  let dogID = data.id
                  return (
                    <>
                      <h2>Membership For: {dogName}</h2>
                      {console.log("*********************8 Package ****************", users)}
                      {data && data.package ? (
                        <MembershipInfo
                          package={data.package}
                          subscription={data.subscription}
                          width={width}
                          //shrink={shrink}
                          onClick={() => { }}
                          users={users}
                          dogsStatus={data}
                        />
                      ) : <Fragment>
                          No Membership Package.
                      {
                            dogs.length > 0 ?
                              <a href={`/staff/member/packages?dogId=${dogID}`} style={{ marginLeft: 5 }}>
                                Choose a package
                              </a>
                              : ''
                          }
                        </Fragment>}
                    </>
                  )
                })
              }

              {/*    {dogs[selected] && dogs[selected]['package'] ? (
                <MembershipInfo
                  package={dogs[selected]['package']}
                  subscription={dogs[selected]['package']['subscription']}
                  width={width}
                  shrink={shrink}
                  onClick={() => {
                    // history.push(`/dog/packages/${dogs[selected]['id']}`)
                    history.push(`/staff/member/packages?dogId=${dogs[selected]['id']}`)
                  }}
                />
              ) : (
                  <Fragment>
                    No Membership Package.
                    {
                      dogs.length > 0 ?
                        <a href={`/staff/member/packages?dogId=${dogs[selected]['id']}`} style={{ marginLeft: 5 }}>
                          Choose a package
                          </a>
                        : ''
                    }
                  </Fragment>
                )} */}

            </div>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const formSubmit = (values, dispatch, props) => {
  var newValues = values;
  newValues.id = props.global.currentUser.id;
  newValues.step = null;
  newValues.completed = true;
  props.client
    .mutate({ mutation: USER_UPDATE, variables: newValues })
    .then(({ data: { updateUser } }) => {
      console.log("response", updateUser);
      props.history.push("complete");
    })
    .catch(error => {
      console.error("Error: ", error);
    });
  return true;
};

ConfirmAccountForm = reduxForm({
  form: "ConfirmAccountForm",
  enableReinitialize: true,
  onSubmit: formSubmit
})(ConfirmAccountForm);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(ConfirmAccountForm);
