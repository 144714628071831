import React, { Component } from "react";
import { connect } from "react-redux";
import { USER_DETAILS } from "../../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import ConfirmAccountForm from "./ConfirmAccountForm";
import { PulseLoader } from "react-spinners";
import Bg from "../../../../assets/fetch-BG.svg";
import Theme from "../../../../globalComponents/Theme.js";
import cookie from "js-cookie";
import { updateUser } from "../../../../store/redux/actions";
import { withRouter } from "react-router-dom";

class ConfirmAccount extends Component {
  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }

  render() {
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <div
                name="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: this.props.global.dimensions.width,
                  minHeight: this.props.global.dimensions.height,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`
                }}
              >
                <PulseLoader
                  style={{ margin: "auto" }}
                  size={this.props.global.dimensions.width / 10}
                  color={Theme.LINK_FONT_COLOR}
                  loading={loading}
                />
              </div>
            );
          // if (error) return `Error: ${error.message}`;
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          return (
            <ConfirmAccountForm
              initialValues={data.userDetail}
              userId={this.getId()}
              refetch={refetch}
            />
          );
        }}
      </Query>
    );
  }
}
const mapDispatchToProps = { updateUser };

const mapStateToProps = state => ({ ...state.global });
export default compose(
  withApollo,
  // withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmAccount);
