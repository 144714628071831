import React, { Component } from "react";
import Cell from "./Cell";
import LeftArrow from "../../../assets/left-arrow.svg";
import RightArrow from "../../../assets/right-arrow.svg";
import Theme from "../../Theme.js";
import { connect } from "react-redux";

const styles = {
  pageLabel: {
    color: "black",
    textDecoration: "none",
    marginLeft: "1%",
    marginRight: "1%",
    fontSize: "80%",
    marginTop: "auto",
    marginBottom: "auto",
    fontColor: "#3D3D3D",
    fontWeight: 600,
    border: "none",
    outline: "none",
    background: "none",
    cursor: "pointer"
  },
  emptyButton: {
    outline: "none",
    border: "none",
    background: "none",
    cursor: "pointer"
  }
};

export class Table extends Component {
  changePage(page) {
    if (page === "next") {
      if (this.props.page * this.props.per < this.props.total) {
        this.props.onPage(this.props.page + 1);
      }
      return;
    } else if (page === "prev") {
      if (this.props.page !== 1) this.props.onPage(this.props.page - 1);
      return;
    } else {
      this.props.onPage(page);
    }
  }
  renderHeaderRow = (name, cellIndex) => {
    const { columns, alt } = this.props;
    const column = columns[name];

    return (
      <Cell
        key={`header-${cellIndex}`}
        label={column.label}
        header={true}
        alt={alt}
      />
    );
  };

  renderRow = (_row, rowIndex) => {
    const { rows, names, columns, onClick, alt } = this.props;
    return (
      <tr
        onClick={() => onClick(_row.id)}
        key={`row-${rowIndex}`}
        style={
          rowIndex % 2 === 0
            ? { backgroundColor: "white", height: 40, cursor: onClick && 'pointer' }
            : { backgroundColor: "#ECECEC", height: 40, cursor: onClick && 'pointer' }
        }
      >
        {names.map((name, cellIndex) => {
          const column = columns[name];
          return (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              alt={alt}
              renderCell={column.renderCell(rows[rowIndex])}
            />
          );
        })}
      </tr>
    );
  };

  renderPaginationNumbers = (pages) => {
    for (var i = 1; i <= pages; i++) {
      return (
        <button
          onClick={() => this.changePage(i)}
          style={styles.pageLabel}
        >
          {i}
        </button>
      )
    }
  }

  render() {
    const { rows, names, total, page = 1, per = 10, loading, global } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const shrink = width < 700;
    let dogs = []
    if (rows) {
      for (let val of rows) {
        dogs = dogs.concat(val.dogs)
      }
    }
    const start = page * per - per;
    const end = page * per < total ? page * per : total;
    const pages = Math.ceil(total / per)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <table
          cellSpacing={0}
          cellPadding={5}
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <thead style={{ cursor: "pointer" }}>
            <tr
              style={{
                backgroundColor: "white",
                height: 40
              }}
            >
              {names.map(this.renderHeaderRow)}
            </tr>
          </thead>
          {
            loading ?
              <tbody>
                {/* <PropagateLoader
                size={40}
                color={Theme.LINK_FONT_COLOR}
                loading={loading}
              /> */}
              </tbody>
              :
              <tbody>{rows && rows.map(this.renderRow)}</tbody>
          }
        </table>
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "row",
            marginTop: "1%"
          }}
        >
          <button
            style={styles.emptyButton}
            onClick={() => this.changePage("prev")}
          >
            <img alt="" src={LeftArrow} style={{ height: "100%" }} />
          </button>
          <button
            onClick={() => this.changePage("prev")}
            style={styles.pageLabel}
          >
            PREV
          </button>
          {this.renderPaginationNumbers(pages)}
          <button
            onClick={() => this.changePage("next")}
            style={styles.pageLabel}
          >
            NEXT
          </button>
          <button
            style={styles.emptyButton}
            onClick={() => this.changePage("next")}
          >
            <img alt="" src={RightArrow} style={{ height: "100%" }} />
          </button>

          <div
            style={{
              color: Theme.MAIN_COLOR,
              fontFamily: Theme.MAIN_FONT,
              // fontSize: "90%",
              fontSize: shrink ? "12px" : "90%",
              marginTop:shrink ? "1.2%" :  ".2%",
              marginLeft: "auto",
              marginRight: "1%"
            }}
          >
            {`Now Showing ${start || 0}-${end || 0} of ${total || 0} `}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });

export default connect(
  mapStateToProps,
  null
)(Table);