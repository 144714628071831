import React from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { OnboardingHeader, PaymentForm } from "../../../globalComponents/";
import { withRouter, RouteComponentProps } from "react-router";
import queryString from 'query-string';
import { trackEvent, trackPageView } from '../../../utils/pixelTracker';

function Payment(props) {

  const {
    history,
    global: {
      dimensions: { width, height }
    }
  } = props

  const { dogId, packageId, isRecurring } = queryString.parse(props.location.search);

  const onCancel = () => {
    history.push("billingOptions");
  };

  const onUserUpdated = () => {
    if (dogId && packageId) {
      history.push(`billingOptions?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
      // Track completion of sign-up process
      trackEvent('Payment Sent', {
      content_name: 'PaymentComplete',
      status: 'success'
      });
    }
    else {
      history.push("billingOptions");
    }
  };


  return (
    <OnboardingHeader
      back={() => history.push("/")}
      progress={false}
      recenterLogo={true}
      containerStyle={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center"
      }}
      checkInStatus={false}
    >
      <PaymentForm
        dogId={dogId}
        packageId={packageId}
        isRecurring={isRecurring}
        {...{ onCancel, onUserUpdated }}
        cancelLabel="Skip"
        onUserUpdated={onUserUpdated}
        dogPaymentDetails={[{
          dogId,
          packageId

        }]}
      // forUserId=
      />
    </OnboardingHeader>
  );
}

const mapStateToProps = state => ({ ...state.global });

Payment = reduxForm({
  form: "Payment"
})(Payment);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Payment);
