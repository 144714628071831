import React, { Component } from "react";
import Theme from "../Theme.js";
import { DirectUpload } from "@rails/activestorage";
import Icon from "../../assets/camera-icon.svg";

export default class Camera extends Component {
  state = {
    cameraOn: false,
    name: "",
    showPicture: false,
    error: null
  };
  getHeight(width) {
    if (width > 450) return 50;
    if (width > 400) return 50;
    if (width > 300) return 40;
    return 90;
  }
  getWidth(width) {
    if (width > 450) return 160;
    if (width > 400) return 140;
    if (width > 300) return 120;
    else return 60;
  }

  componentDidMount() {
    const self = this;
    document.addEventListener("mousedown", self.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  openCamera() {
    const self = this;
    var canvas = document.getElementById("canvas");
    var context = canvas.getContext("2d");
    var video = document.getElementById("video");
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Not adding `{ audio: true }` since we only want video now
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function(stream) {
          self.video.srcObject = stream;
          self.video.play();
        });
      document.getElementById("snap").addEventListener(
        "click",
        function() {
          context.drawImage(
            video,
            0,
            0,
            this.getWidth(this.props.width) * 1.9,
            this.getHeight(this.props.width) * 3.2
          );
          this.setState({ showPicture: true });
        }.bind(this)
      );
      document.getElementById("save").addEventListener(
        "click",
        function() {
          canvas.toBlob(this.uploadFile);
        }.bind(this)
      );
    }
  }

  handleClick = e => {
    var canvas = document.getElementById("canvas");
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.video.pause();
          this.video.srcObject.getTracks()[0].stop();
          canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
          this.setState({ cameraOn: false, video: {} });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  uploadFile = file => {
    if (!this.state.name) {
      this.setState({ error: "File must be named" });
      return;
    }
    file = new File([file], this.state.name);
    const url =
      process.env.REACT_APP_API_ENDPOINT +
      "/rails/active_storage/direct_uploads";
    const upload = new DirectUpload(file, url);

    this.setState({ cameraOn: false });
    upload.create((error, blob) => {
      if (error) {
        console.error(error);
        alert("failed upload");
        return;
      }
      this.props.onUpload(blob, file);
    });
  };

  render() {
    const { width, height, containerStyle } = this.props;
    const { cameraOn, showPicture, error } = this.state;

    return (
      <div id="camera" style={containerStyle || { display: "flex" }}>
        <button
          style={{
            outline: "none",
            border: "none",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            backgroundColor: Theme.LINK_FONT_COLOR,
            marginBottom: height / 100,
            borderRadius: 5,
            textAlign: "center",
            height: this.getHeight(width),
            width: this.getWidth(width)
          }}
          type="button"
          onClick={() => {
            this.openCamera();
            this.setState({ cameraOn: !cameraOn });
          }}
        >
          <div
            style={{
              display: "flex",
              color: "white",
              width: this.getWidth(width),
              textAlign: "center",
              fontSize: Theme.getSmallFont(width),
              fontFamily: Theme.HEADER_FONT,
              margin: "auto"
            }}
          >
            <img style={{ margin: "0 10% 0 6%" }} src={Icon} alt="" /> Take
            Photo
          </div>
        </button>
        <div
          style={
            !cameraOn
              ? { display: "none" }
              : {
                  width: width,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  alignItems: "center",
                  backgroundColor: "rgba(0,0,0,0.5)"
                }
          }
        >
          <div
            ref={node => (this.node = node)}
            style={{
              border: "1px solid",
              display: "flex",
              flexDirection: "column",
              borderColor: Theme.LIGHT_TEXT_COLOR,
              width: width / 1.5,
              margin: "auto",
              verticalAlign: "center",
              horizontalAlign: "center",
              justifySelf: "center",
              top: 0,
              left: 0,
              backgroundColor: "rgba(255,255,255,.9)"
            }}
          >
            <video
              id="video"
              ref={ref => (this.video = ref)}
              width="100%"
              style={{
                display: showPicture ? "none" : "flex"
              }}
              autoPlay
            />
            <canvas
              style={{
                margin: "auto",
                display: showPicture ? "flex" : "none",
                width: "100%",
                height: width / 2
              }}
              id="canvas"
            />
            <div
              style={{
                flexDirection: "row",
                flex: 1,
                display: "flex",
                margin: "5% 0 5% 0",
                justifyContent: "center"
              }}
            >
              <button
                style={{
                  display: "flex",
                  outline: "none",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor: Theme.LINK_FONT_COLOR,
                  marginBottom: height / 100,
                  borderRadius: 5,
                  textAlign: "center",
                  height: height / 18
                }}
                id="snap"
                type="button"
                onClick={() => this.setState({ cameraOn: true })}
              >
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    textAlign: "center",
                    width: width / 10,
                    justifyContent: "center",
                    fontSize: Theme.getNormalFont(width),
                    fontFamily: Theme.HEADER_FONT,
                    margin: "auto"
                  }}
                >
                  Snap
                </div>
              </button>
              <button
                style={{
                  display: "flex",
                  outline: "none",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor: Theme.LIGHT_TEXT_COLOR,
                  marginBottom: height / 100,
                  borderRadius: 5,
                  textAlign: "center",
                  height: height / 18
                }}
                id="snap"
                type="button"
                onClick={() => this.setState({ showPicture: false })}
              >
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    textAlign: "center",
                    width: width / 10,
                    justifyContent: "center",
                    fontSize: Theme.getNormalFont(width),
                    fontFamily: Theme.HEADER_FONT,
                    margin: "auto"
                  }}
                >
                  Delete
                </div>
              </button>
              <input
                placeholder={"Picture Name"}
                type="text"
                onChange={event => this.setState({ name: event.target.value })}
                style={{
                  width: width / 4,

                  outline: "none",
                  fontSize: Theme.getNormalFont(width),
                  marginBottom: height / 100,
                  paddingLeft: "2%",
                  margin: "0 2% 8px 2%",
                  border: "1px solid",
                  borderRadius: 3
                }}
              />
              <button
                style={{
                  display: "flex",
                  outline: "none",
                  flexDirection: "column",
                  cursor: "pointer",
                  backgroundColor: Theme.LINK_FONT_COLOR,
                  marginBottom: height / 100,
                  borderRadius: 5,
                  textAlign: "center",
                  height: height / 18
                }}
                id="save"
                type="button"
                onClick={() => this.setState({ cameraOn: true })}
              >
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    textAlign: "center",
                    width: width / 10,
                    justifyContent: "center",
                    fontSize: Theme.getNormalFont(width),
                    fontFamily: Theme.HEADER_FONT,
                    margin: "auto"
                  }}
                >
                  Save
                </div>
              </button>
            </div>
            {error ? (
              <div
                style={{
                  textAlign: "center",
                  color: "red",
                  fontSize: Theme.getNormalFont(width)
                }}
              >
                {error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
