import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../";
import { BANNED_MEMBER } from "../../store/mutations";
import { reduxForm } from "redux-form";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const styles = {
    pageContainer: {
        // border: "solid blue",
        position: "fixed",
        display: "flex",
        flex: 1,
        alignItems: "center",
        top: "0",
        left: "0",
        backgroundColor: "rgba(0,0,0,.2)",
        height: "100vh",
        width: "100vw",
        zIndex: 100
    },

    modalContainer: function (width) {
        return {
            // border: "solid green",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",

            marginLeft: "auto",
            marginRight: "auto",
            padding: "1vw",
            height: "auto",
            width: width,
            fontSize: Theme.getNormalFont(width),
            fontFamily: Theme.HEADER_FONT,
            backgroundColor: "white",
            color: Theme.DARK_TEXT_COLOR,
            boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
        };
    },

    header: {
        // borderBottom: "3px solid",
        // borderBottomColor: Theme.INACTIVE_GREY_COLOR,
        width: "97%",
        marginBottom:"5px"

    },

    subtext: function (width) {
        return {
            fontSize: Theme.getNormalFont(width),
            color: Theme.LIGHT_TEXT_COLOR
        };
    },

    tagSection: {
        // border:"solid black",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "1vw",
        paddingLeft: "0"
    },

    tagListContainer: function (width) {
        return {
            // border:"solid red",
            listStyleType: "none",
            margin: "0",
            width: width / 1.2,
            paddingLeft: "1vw",
            color: Theme.INACTIVE_GREY_COLOR
        };
    },

    textArea: function (width, textFontSize) {
        return {
            resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
            borderRadius: 5,
            height: width / 6, // UPDATE HEIGHT CALULCATION
            fontSize: textFontSize
        };
    },

    submitButtonWrapper: {
        alignSelf: "flex-end",
        margin: ".5em 0"
    }
};

class BannedModal extends Component {
    state = {
        hover: false,
        bannedComment: "",
        width: 0,
        height: 0,
        selectedTags: ["Account Note"]
    };

    componentDidMount() {
        console.log("HIt com");
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        document.addEventListener("mousedown", this.handleClick, false);

    }

    componentWillReceiveProps(props) { }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
        document.addEventListener("mousedown", this.handleClick, false);
    }

    recordBannedComment = e => {
        this.setState({ bannedComment: e.target.value });
    };

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    getTextAreaWidth(width) {
        if (width > 1900) return "54.5vw";
        else if (width > 1650) return "64.5vw";
        else if (width > 1200) return "74.5vw";
        else if (width > 800) return "84.5vw";
        else return "79vw";
    }

    getModalWidth(width) {
        if (width > 1900) return "55vw";
        else if (width > 1650) return "65vw";
        else if (width > 1200) return "75vw";
        else if (width > 800) return "85vw";
        else return "80vw";
    }

    handleClick = e => {
        if (e.target) {
            try {
                if (this.node.contains(e.target)) {
                    return;
                } else {
                    this.props.closeModal();
                }
            } catch (error) { }
        }
    };


    handleSubmit = () => {
        const { bannedComment, } = this.state;
        const { client, global, bannedStatus, closeModal , selectedDog} = this.props;

        // console.log("Global", selectedDog.id);
        if(bannedComment){
            const values = {
                dogId: selectedDog?.id,
                bannedBy: global.currentUser?.email,
                bannedMessage: bannedComment,
                isBanned: true
            };
            client
                .mutate({ mutation: BANNED_MEMBER, variables: { input: values } })
                .then(response => {
                    this.props.closeModal()
    
                    if (global.currentUser.type === "Employee") {
                        this.props.history.push("/dashboard");
                      } else {
                        this.props.history.push("/manager/members");
                      }
                })
                .catch(error => {
                    console.error("Error: ", error);
                    return false;
                });
        }
        else{
            alert("Please add comment.")
        }
      

    };



    render() {
        const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
        const width = obj.dimensions.width;
        const textFontSize = Theme.getNormalFont(width);
        const { bannedComment } = this.state;
        const { isModalDisplayed, closeModal } = this.props;
        if (!isModalDisplayed) {
            return null;
        } else {
            return (
                // Page Container
                <div style={styles.pageContainer}>
                    {/* Modal Container */}
                    <div
                        ref={node => (this.node = node)}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "10%",
                            padding: "2vw",
                            height: "auto",
                            width: this.getModalWidth(this.state.width),
                            fontSize: Theme.getNormalFont(width),
                            fontFamily: Theme.HEADER_FONT,
                            backgroundColor: "white",
                            color: Theme.DARK_TEXT_COLOR,
                            boxShadow:
                                "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
                        }}
                    >
                        {/* Header */}
                        <div style={styles.header}> Comment </div>


                        {/* Text Box */}
                        <textarea
                            value={bannedComment}
                            style={{
                                outline: "none",
                                resize: "none",
                                borderRadius: 5,
                                width: this.getTextAreaWidth(this.state.width),
                                height: width / 6,
                                fontSize: textFontSize
                            }}
                            onChange={this.recordBannedComment}
                        />

                        {/* Button Wraper to Right-Align */}
                        <div style={styles.submitButtonWrapper}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Button
                                    text="Add Comment"
                                    type="button"
                                    width={width}
                                    onClick={this.handleSubmit}
                                    containerStyle={{ marginRight: 10 }}
                                />
                                <Button
                                    text="Cancel"
                                    type="button"
                                    width={width}
                                    onClick={() => closeModal()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

const mapStateToProps = state => ({ ...state.global });

BannedModal = reduxForm({
    form: "BannedModal"
})(BannedModal);

export default compose(
    withApollo,
    withRouter,
    connect(mapStateToProps)
)(BannedModal);
