import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { compose } from "redux";
import { Theme } from "../../../globalComponents";
import { CouponTable } from "../../../globalComponents/FormComponents/CouponTable";
import { CREATE_COUPON_CODE } from "../../../store/mutations";
import { FETCH_COUPON_TYPES } from "../../../store/queries";
import { updateUser } from "../../../store/redux/actions";

class CouponTypeTab extends Component {
    state = {


        page: 1,
        per: 20,
        couponTypeList: [],
        couponTypeId: "",
        couponCodes: [],
        type:""
    };

    componentDidMount() {
        this.handleCouponTypeList()
    }


    handleCouponTypeList = () => {
        const { client } = this.props;
        client
            .query({ query: FETCH_COUPON_TYPES, fetchPolicy: "no-cache" })
            .then(response => {
                this.setState({
                    couponTypeList: response.data?.couponTypes
                })
            })
            .catch(error => {
                console.error("Error: ", error);
                return false;
            })
    }

    generateCode = (couponTypeId, type) => {
        console.log("----", couponTypeId);
        if (couponTypeId) {
            this.props.client
                .mutate({
                    mutation: CREATE_COUPON_CODE,
                    variables: {
                        input: {
                            couponTypeId: couponTypeId,
                            count: 1
                        }
                    }
                })
                .then(response => {
                    let newState = { ...this.state };
                    response.data.createCouponCodes.codes.map(code => {
                        newState.couponCodes.push({
                            type: type,
                            coupon: code
                        });
                    });
                    this.setState({ couponCodes: newState.couponCodes });
                })
                .catch(error => {
                    console.error("Error: ", error);
                });
        } else {
            alert("Must select a coupon type in order to generate coupon codes.");
        }
    };

    render() {
        const { couponTypeList, couponTypeId, couponCodes } = this.state;
        const { global } = this.props;
        const obj = global || { dimensions: { height: 0, width: 1000 } };
        const width = obj.dimensions.width;
        const shrink = width < 768;
        const TillTab = width < 1024;
        const small = width < 1170;
        const columnNames = [
            "couponType",
            "description",
            "action"
        ];

        const columns = {
            couponType: {
                label: "Coupon Type",
                renderCell: data => (
                    <div
                        style={{
                            color: Theme.LIGHT_TEXT_COLOR,
                            fontWeight: 100,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT
                        }}
                    >
                        {console.log("Name", data.name)}
                        {`${data.name}`}
                    </div>
                )
            },

            description: {
                label: "Description",
                renderCell: data => (
                    <div
                        style={{
                            color: Theme.LIGHT_TEXT_COLOR,
                            fontWeight: 100,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT
                        }}
                    >
                        {data?.description || "-"}
                    </div>
                )
            },
            action: {
                label: " Genrate Coupon Code ",
                renderCell: (data, index) => (

                    <button
                        onClick={() => this.generateCode(data.id, data.name)}
                        style={{
                            fontWeight: 100,
                            outline: "none",
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: Theme.MAIN_COLOR,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT,
                            paddingLeft: 0
                        }}
                    >
                        Generate
                    </button>
                )
            }
        };

        console.log("Coupoun list", couponTypeList);
        return (
            <div>
                <>
                    <div
                        style={{
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "0%",
                            marginBottom: "4%",
                            width: "100%",
                            color: "white",
                            alignItems: "center",
                            alignContent: "center",
                            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                            fontFamily: Theme.HEADER_FONT
                        }}
                    >
                        Coupon Types

                    {/* <button
                            onClick={() => this.openModal(null, null, false)}
                            style={{
                                outline: "none",
                                backgroundColor: "rgba(250,250,250, .3)",
                                borderRadius: 5,
                                padding: "5px 10px 5px 10px",
                                border: "none",
                                marginLeft: "auto",
                                color: "white",
                                fontFamily: Theme.HEADER_FONT,
                                fontSize: shrink ? "12px" : Theme.getSmallFont(width),
                                cursor: "pointer"
                            }}
                        >
                            Add New Coupon Type
                    </button> */}

                    </div>

                    <CouponTable
                        total={5}
                        per={1}
                        page={10}
                        onPage={page => this.setState({ page: 1 })}
                        onPer={per => this.setState({ per: 10 })}
                        containerStyle={{ flex: 1 }}
                        names={columnNames}
                        pages={["", "", ""]}
                        columns={columns}
                        rows={couponTypeList}
                    />

<div
                        style={{
                            marginTop: 50,
                            marginBottom: 50,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "80%"
                        }}
                    >
                        {couponCodes.map((code, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: Theme.getHeaderFont(width) * 1.5,
                                            fontFamily: shrink ? "13px" : Theme.HEADER_FONT,
                                            textAlign: "center",
                                            marginRight: "auto",
                                            border: "2px solid black",
                                            padding: 10,
                                            width: shrink ? "80%" : "50%",
                                            backgroundColor: "white"
                                        }}
                                    >
                                        {code.type}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: Theme.getHeaderFont(width) * 1.5,
                                            fontFamily: shrink ? "13px" : Theme.HEADER_FONT,
                                            textAlign: "center",
                                            marginLefT: "auto",
                                            border: "2px solid black",
                                            padding: 10,
                                            width: shrink ? "80%" : "50%",
                                            backgroundColor: "white"
                                        }}
                                    >
                                        {code.coupon}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            </div>

        )
    }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
    withApollo,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(CouponTypeTab);
