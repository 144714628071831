import {
  UPDATE_DOG,
  UPDATE_DOG_VACCINATION_DOCUMENT,
} from "../store/mutations.js";
import { setOtherZone } from "./datePicker.jsx";

export async function updateDogsInfo(values, client, refetch) {
  let status = false;
  let promise = Promise.resolve();
  for (var i = 0; values[`name${i}`]; i++) {
    // console.log(i, "Vlue---------------------------", values, values[`separateLeptospirosis${i}`]);
    const input = {
      id: parseInt(values[`id${i}`]),
      profilePicture: values[`profilePicture${i}`],
      name: values[`name${i}`],
      age: parseInt(values[`age${i}`]),
      sex: values[`sex${i}`] === "M" ? "MALE" : "FEMALE",
      color: values[`color${i}`],
      birthdate: values[`birthdate${i}`]
        ? setOtherZone(values[`birthdate${i}`])
        : null,
      // separateLeptospirosis:false,
      isNeutered: values[`isNeutered${i}`],
      breed: values[`breed${i}`],

      // isNeutered: false,
      // is_neutered: true
    };

    promise = promise
      .then(() => {
        console.log("Updating dog:", input);
        return client.mutate({ mutation: UPDATE_DOG, variables: { input } });
      })
      .then((res) => {
        // console.log("Dog updated:-------------->", res.data.updateDog);
        // if (res.data.updateDog.errors && res.data.updateDog.errors.length > 0) {
        //   alert(res.data.updateDog.errors[0].message)
        //   status = false;
        // }
        // else {
        // console.log("***** efetch  *******************");
        if (refetch !== null) {
          refetch();
          // }
        }
        return true;
      })

      .catch((error) => {
        console.error("Dog Error: ", error);
        alert(error.message.slice(15));
        status = false;
      });
  }
  console.log(status);
  return await promise;
}

export async function addOrUpdateDogVaccinations(values, client, refetch) {
  let dogsNew = values.dogsNew;
  if (dogsNew) {
    return await Promise.all(
      dogsNew.map(async (dog) => {
        if (parseInt(dog.id) === parseInt(values.vaccineDog)) {
          // console.log("Value-----------------------------------------------------&&>", values);
          return await updateDog(
            { ...values, id: values.vaccineDog },
            client,
            refetch
          );
        } else if (values.vaccineDog) {
          return await updateDog(dog, client, refetch);
        }
      })
    );
  } else {
    return await updateDog(
      { ...values, id: values.vaccineDog },
      client,
      refetch
    );
  }
}

async function updateDog(
  {
    id,
    leptospirosis,
    rabies,
    dhlpp,
    bordetella,
    separateLeptospirosis,
    isNeutered,
  },
  client,
  refetch
) {
  let status = false;
  try {
    await client
      .mutate({
        mutation: UPDATE_DOG,
        variables: {
          input: {
            id,
            leptospirosis: leptospirosis ? setOtherZone(leptospirosis) : null,
            rabies: rabies ? setOtherZone(rabies) : null,
            dhlpp: dhlpp ? setOtherZone(dhlpp) : null,
            bordetella: bordetella ? setOtherZone(bordetella) : null,
            separateLeptospirosis,
            // isNeutered
          },
        },
      })
      .then((res) => {
        // console.log("Dog updated Vacc: ------------------------->", res.data.updateDog);
        // if (res.data.updateDog.errors && res.data.updateDog.errors.length > 0) {
        //   alert(res.data.updateDog.errors[0].message)
        //   status = false
        // }
        // else {
        // console.log("***** efetch  twi *******************");
        if (refetch !== null) {
          refetch();
        }
        // status = true;
        // }
      });
    console.log("Status", status);
    return true;
  } catch (error) {
    console.error("Dog Error: ", error.message);
    if (
      error.message ===
      "FetchPark API GraphQL error: Variable input of type UpdateDog! was provided invalid value for id (Expected value to not be null)"
    ) {
      alert("Must add at least one dog!");
    } else {
      alert(error);
    }
    return false;
  }
}

export async function updateDogsInfoNew(values, client, refetch, global) {
  let promise = Promise.resolve();
  let status = false;
  // console.log("Hit", values.dogs);
  let parkId = global?.currentUser?.park?.id;
  for (let index = 0; index < values.dogs.length; index++) {
    const element = values.dogs[index];
    // console.log("----> Index", index);
    for (var i = index; values[`name${i}`]; i++) {
      let sex = values[`sex${i}`] === "M" ? "MALE" : "FEMALE";
      console.log(
        element.profilePicture?.url,
        "------------>",
        values[`profilePicture${i}`]
      );
      if (
        element.name !== values[`name${i}`] ||
        element.birthdate !== values[`birthdate${i}`] ||
        // element.isNeutered !== values[`isNeutered${i}`] ||
        element.color !== values[`color${i}`] ||
        element.sex !== sex ||
        element.breed !== values[`breed${i}`]
      ) {
        if (element.profilePicture?.url !== values[`profilePicture${i}`]) {
          const input = {
            id: parseInt(values[`id${i}`]),
            profilePicture: values[`profilePicture${i}`],
            name: values[`name${i}`],
            age: parseInt(values[`age${i}`]),
            sex: sex,
            color: values[`color${i}`],
            birthdate: values[`birthdate${i}`]
              ? setOtherZone(values[`birthdate${i}`])
              : null,
            isNeutered: values[`isNeutered${i}`],
            breed: values[`breed${i}`],
          };
          // promise = promise
          let changeStatus = await client
            .mutate({
              mutation: UPDATE_DOG,
              variables: { input },
            })
            .then((res) => {
              {
                refetch();
              }
              return true;
            })
            .catch((error) => {
              alert(error.message.slice(15));
            });
          status = changeStatus;
        } else {
          const input = {
            id: parseInt(values[`id${i}`]),
            // profilePicture: values[`profilePicture${i}`],
            name: values[`name${i}`],
            age: parseInt(values[`age${i}`]),
            sex: sex,
            color: values[`color${i}`],
            birthdate: values[`birthdate${i}`]
              ? setOtherZone(values[`birthdate${i}`])
              : null,
            isNeutered: values[`isNeutered${i}`],
            breed: values[`breed${i}`],
          };
          // promise = promise
          let changeStatus = await client
            .mutate({
              mutation: UPDATE_DOG,
              variables: { input },
            })
            .then((res) => {
              {
                refetch();
              }
              return true;
            })
            .catch((error) => {
              alert(error.message.slice(15));
            });
          status = changeStatus;
        }
      } else {
        if (element.profilePicture?.url !== values[`profilePicture${i}`]) {
          const input = {
            id: parseInt(values[`id${i}`]),
            profilePicture: values[`profilePicture${i}`],
            name: values[`name${i}`],
            age: parseInt(values[`age${i}`]),
            sex: sex,
            color: values[`color${i}`],
            birthdate: values[`birthdate${i}`]
              ? setOtherZone(values[`birthdate${i}`])
              : null,
            isNeutered: values[`isNeutered${i}`],
            breed: values[`breed${i}`],
          };
          // promise = promise
          let changeStatus = await client
            .mutate({
              mutation: UPDATE_DOG,
              variables: { input },
            })
            .then((res) => {
              {
                refetch();
              }
              return true;
            })
            .catch((error) => {
              alert(error.message.slice(15));
            });
          status = changeStatus;
        } else {
          status = true;
        }
      }
      break;
    }
  }
  // return await promise;
  return status;
}

export async function addOrUpdateDogVaccinationsNew(
  values,
  client,
  refetch,
  global
) {
  let dogsNew = values.dogsNew;
  // console.log("Dog value ------------------>", values);
  let status = false;
  // console.log("Dog New-----------------------------------------------------&&>", dogsNew);
  if (dogsNew) {
    for (let i = 0; i < dogsNew.length; i++) {
      const element = dogsNew[i];
      for (let j = i; values[`name${j}`]; j++) {
        const rabiesOld = values[`oldRabiesDate${j}`];
        const bordetellaOld = values[`oldBordetellaDate${j}`];
        const dhlppOld = values[`olddhlppDate${j}`];
        const leptospirosisOld = values[`oldLeptospirosisDate${j}`];
        const separateLeptospirosisOld =
          values[`oldSeparateLeptospirosisStatus${j}`];

        let newDate = {
          rabies: element.rabies,
          dhlpp: element.dhlpp,
          leptospirosis: element.leptospirosis,
          bordetella: element.bordetella,
          id: element.id,
          separateLeptospirosis: element.separateLeptospirosis,
        };
        let selectedNewDate = {
          rabies: values.rabies,
          dhlpp: values.dhlpp,
          leptospirosis: values.leptospirosis,
          bordetella: values.bordetella,
          separateLeptospirosis: values.separateLeptospirosis,
        };
        // console.log(element.name, "-- New-->", newDate, "---Old-----", rabiesOld, bordetellaOld);
        console.log("New Date", selectedNewDate);
        // let oldDate = {
        //   rabies: values[`oldRabiesDate${j}`],
        //   dhlpp: values[`olddhlppDate${j}`],
        //   leptospirosis: values[`oldLeptospirosisDate${j}`],
        //   bordetella: values[`oldBordetellaDate${j}`]
        // }
        // console.log("--->", (element.id), values.vaccineDog);

        if (parseInt(element.id) === parseInt(values.vaccineDog)) {
          // console.log("Log Hiteter");
          // console.log(i, "---------- Selected ----------", j);

          if (
            values.rabies !== rabiesOld ||
            values.dhlpp !== dhlppOld ||
            values.bordetella !== bordetellaOld ||
            values.leptospirosis !== leptospirosisOld ||
            values.separateLeptospirosis !== separateLeptospirosisOld
          ) {
            // console.log("---- Hir ----", element.name, "-- New-->", element.rabies, "---Old-----", rabiesOld);
            // console.log(i, j, element.name, " selected ******* OLD ****** ", oldDate);
            // console.log(" Selcted ******* New ****** ", selectedNewDate);
            status = await updateDogNew(
              { ...selectedNewDate, id: values.vaccineDog },
              client,
              refetch,
              global
            );
            // console.log("___________________________________________Demi", demo);
            // status = demo
          } else {
            status = true;
          }
        } else if (values.vaccineDog) {
          console.log(i, "---------- Not Selected ---------- ", j);
          if (
            element.rabies !== rabiesOld ||
            element.dhlpp !== dhlppOld ||
            element.leptospirosis !== leptospirosisOld ||
            element.bordetella !== bordetellaOld ||
            element.separateLeptospirosis !== separateLeptospirosisOld
          ) {
            // console.log("---- Hir ----", element.name, "-- New-->", element.rabies, "---Old-----", rabiesOld);
            // console.log(i, j, element.name, " ******* OLD ****** ", oldDate);
            // console.log(" ******* New ****** ", newDate);
            status = await updateDogNew(newDate, client, refetch, global);
          } else {
            status = true;
          }
        } else {
          status = true;
        }
        break;
      }
    }
    return status;
  } else {
    for (let j = 0; values[`name${j}`]; j++) {
      const rabiesOld = values[`oldRabiesDate${j}`];
      const bordetellaOld = values[`oldBordetellaDate${j}`];
      const dhlppOld = values[`olddhlppDate${j}`];
      const leptospirosisOld = values[`oldLeptospirosisDate${j}`];
      const separateLeptospirosisOld =
        values[`oldSeparateLeptospirosisStatus${j}`];
      let selectedNewDate = {
        rabies: values.rabies,
        dhlpp: values.dhlpp,
        leptospirosis: values.leptospirosis,
        bordetella: values.bordetella,
        separateLeptospirosis: values.separateLeptospirosis,
      };
      if (
        values.rabies !== rabiesOld ||
        values.dhlpp !== dhlppOld ||
        values.leptospirosis !== leptospirosisOld ||
        values.bordetella !== bordetellaOld ||
        values.separateLeptospirosis !== separateLeptospirosisOld
      ) {
        console.log("----------_Seperated-------------");
        // console.log(j, "---- Hir ----", values[`oldRabiesDate${j}`], "-- New-->", values.rabies, "---Old-----", rabiesOld);
        // console.log(i, j, element.name, " selected ******* OLD ****** ", oldDate);
        // console.log(" Selcted ******* New ****** ", selectedNewDate);
        status = await updateDogNew(
          { ...selectedNewDate, id: values.vaccineDog },
          client,
          refetch,
          global
        );
      } else {
        status = true;
      }
      break;
    }
    return status;
  }
}

async function updateDogNew(
  { id, leptospirosis, rabies, dhlpp, bordetella, separateLeptospirosis },
  client,
  refetch,
  global
) {
  // console.log("Rabies************88PArk***************",setOtherZone(rabies), rabies);

  try {
    await client.mutate({
      // mutation: UPDATE_DOG,
      mutation: UPDATE_DOG_VACCINATION_DOCUMENT,
      variables: {
        input: {
          dogId: id,
          leptospirosis: leptospirosis ? setOtherZone(leptospirosis) : null,
          rabies: rabies ? setOtherZone(rabies) : null,
          dhlpp: dhlpp ? setOtherZone(dhlpp) : null,
          bordetella: bordetella ? setOtherZone(bordetella) : null,
          separateLeptospirosis,
        },
      },
    });
    if (refetch !== null) {
      refetch();
    }
    return true;
  } catch (error) {
    console.error("Dog Error: ", error.message);
    if (
      error.message ===
      "FetchPark API GraphQL error: Variable input of type UpdateDog! was provided invalid value for id (Expected value to not be null)"
    ) {
      alert("Must add at least one dog!");
    } else {
      alert(error);
    }
    return false;
  }
}
