import React, { Component } from "react";
import Theme from "../../../globalComponents/Theme.js";
import { Button } from "../../../globalComponents";
import { connect } from "react-redux";
import Logo from "../../../assets/fetch-logo.svg";
import Bg from "../../../assets/fetch-BG.svg";
import cookie from "js-cookie";
import { withRouter } from "react-router-dom";
import { updateUser } from "../../../store/redux/actions";
import { trackEvent, trackPageView } from '../../../utils/pixelTracker';

class Complete extends Component {
  componentDidMount() {
    // Track completion of sign-up process
    trackEvent('RegistrationComplete', {
      content_name: 'Registration Process Completed',
      status: 'success'
    });
  }

  check = () => {
    cookie.remove("token");
    this.props.updateUser("reset");
    this.props.history.push("/login");
  }

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          backgroundColor: Theme.BACKGROUND_COLOR,
          height: height,
          backgroundImage: `url(${Bg})`
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "5%",
            height: "80%",
            width: "40%",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img
            alt="logo"
            src={Logo}
            style={{ height: "25%", marginBottom: "10%" }}
          />
          <p
            style={{
              marginBottom: "2%",
              fontSize: Theme.getHeaderFont(width),
              textAlign: "center",
              fontFamily: Theme.HEADER_FONT,
              color: Theme.HEADER_FONT_COLOR
            }}
          >
          "Great! Let our staff know you're all done so they can verify the information and confirm your account."
          </p>
          
          <Button
            text="Close"
            width={width}
            onClick={() =>
              this.props.global.currentUser.type === "Employee"
                ? this.props.history.push("/dashboard")
                : this.props.global.currentUser.type === "Manager" ||
                  this.props.global.currentUser.type === "Admin"
                  ? this.props.history.push("/manager/members")
                  : this.props.global.currentUser.type === "Member"
                    ? this.check()
                    : this.props.history.push("/")
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Complete));

