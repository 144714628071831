import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_TAG_LIST } from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import {
    Theme,
} from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import TagModal from "../../../globalComponents/FetchComponents/TagModal.js";
import { TagTable } from "../../../globalComponents/FormComponents/TagTable/index.js";

class Tags extends Component {
    state = {
        tab: 0,
        search: "",
        page: 1,
        per: 20,
        isOpen: false,
        tagData: null,
        data: this.props.data,
        currentIndex: 0,
        isUpdate: false,
        tagList: []
    };

    componentDidMount() {
        this.handleTagList()
    }


    handleTagList = () => {
        const { client } = this.props;
        client
            .query({ query: FETCH_TAG_LIST, fetchPolicy: "no-cache" })
            .then(response => {
                this.setState({
                    tagList: response.data?.fetchTags.reverse()
                })
            })
            .catch(error => {
                console.error("Error: ", error);
                return false;
            })
    }

    handleSelect = (option, name) => {
        this.setState({ [name]: option });
    };

    handleChange = value => {
        this.setState({ search: value });
    };

    openModal = (tagData, currentIndex, isUpdate) => {
        this.setState({ tagData, currentIndex, isUpdate }, () => {
            this.setState({ isModalDisplayed: true });
        });
    };

    closeModal = () => {
        this.setState({ isModalDisplayed: false });
    };

    updateTags = newNote => {
        let newState = { ...this.state };
        if (this.state.isUpdate) {
            newState.tagList[this.state.currentIndex] = newNote;
        } else {
            newState.tagList.splice(0, 0, newNote);
        }
        this.setState({ tagList: newState.tagList });
    };

    render() {
        const {
            search,
            per,
            page,
            tagData,
            isModalDisplayed,
            data,
            isUpdate,
            currentIndex, tagList
        } = this.state;
        const { global } = this.props;
        const obj = global || { dimensions: { height: 0, width: 1000 } };
        const width = obj.dimensions.width;
        const shrink = width < 700;
        const columnNames = [
            "tagName",
            "description",
            "action"
        ];

        const columns = {
            tagName: {
                label: "Tag Name",
                renderCell: data => (
                    <div
                        style={{
                            color: Theme.LIGHT_TEXT_COLOR,
                            fontWeight: 100,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT
                        }}
                    >
                        {`${data.title}`}
                    </div>
                )
            },

            description: {
                label: "Description",
                renderCell: data => (
                    <div
                        style={{
                            color: Theme.LIGHT_TEXT_COLOR,
                            fontWeight: 100,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT
                        }}
                    >
                        {data.description}
                    </div>
                )
            },
            action: {
                label: " ",
                renderCell: (data, index) => (

                    <button
                        onClick={() => this.openModal(data, index, true)}
                        style={{
                            fontWeight: 100,
                            outline: "none",
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            color: Theme.MAIN_COLOR,
                            fontSize: shrink ? "12px" : Theme.getNormalFont(width),
                            fontFamily: Theme.INPUT_FONT,
                            paddingLeft: 0
                        }}
                    >
                        Edit
                    </button>
                )
            }
        };

        return (
            <div>
                <>
                    <div
                        style={{
                            marginRight: "auto",
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "0%",
                            marginBottom: "4%",
                            width: "100%",
                            color: "white",
                            alignItems: "center",
                            alignContent: "center",
                            fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                            fontFamily: Theme.HEADER_FONT
                        }}
                    >
                        Tag Editor

                        <button
                            onClick={() => this.openModal(null, null, false)}
                            style={{
                                outline: "none",
                                backgroundColor: "rgba(250,250,250, .3)",
                                borderRadius: 5,
                                padding: "5px 10px 5px 10px",
                                border: "none",
                                marginLeft: "auto",
                                color: "white",
                                fontFamily: Theme.HEADER_FONT,
                                fontSize: shrink ? "12px" : Theme.getSmallFont(width),
                                cursor: "pointer"
                            }}
                        >
                            Add New Tag
                        </button>
                    </div>

                    <TagTable
                        total={5}
                        per={per}
                        page={page}
                        onPage={page => this.setState({ page: page })}
                        onPer={per => this.setState({ per: per })}
                        containerStyle={{ flex: 1 }}
                        names={columnNames}
                        pages={["", "", ""]}
                        columns={columns}
                        rows={tagList}
                        onClick={
                            id => {
                                this.props.updateUser({ selectedUser: id });
                                // this.props.history.push("/profile", { view: "ranger" });
                            }}
                    />

                    {isModalDisplayed && (
                        <TagModal
                            tagData={tagData}
                            isModalDisplayed={isModalDisplayed}
                            closeModal={this.closeModal}
                            updateTags={this.updateTags}
                            deleteNotes={this.deleteNotes}
                            isUpdate={isUpdate}
                            currentIndex={currentIndex}
                        />
                    )}
                </>
            </div>
        );
    }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
    withApollo,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Tags);
