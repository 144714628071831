export default (state = {}, action) => {
  let global = {
    dimensions: {
      height: state.global ? state.global.dimensions.height : 0,
      width: state.global ? state.global.dimensions.width : 0,
    },
    currentUser: {
      id: state.global ? state.global.currentUser.id : null,
      type: state.global ? state.global.currentUser.type : null,
      email: state.global ? state.global.currentUser.email : null,
      picture: state.global ? state.global.currentUser.picture : null,
      park: state.global ? state.global.currentUser.park : null,
      selectedUser: state.global ? state.global.currentUser.selectedUser : null,
      checkingInDogs: state.global ? state.global.checkingInDogs : null,
    },
    chekcIn: {
      checkingInDogs: state.global ? state.global.checkingInDogs : null,
    },
  };

  switch (action.type) {
    // UPDATE
    case "UPDATE_DIMENSIONS": {
      global.dimensions.height = action.payload.height;
      global.dimensions.width = action.payload.width;
      return Object.assign({}, state, {
        global,
      });
    }
    case "CURRENT_USER": {
      if (action.payload === "reset") {
        global.currentUser = {
          id: null,
          type: null,
          email: null,
          picture: null,
          park: { id: null },
          selectedUser: null,
          checkingInDogs:{}
        };
        return Object.assign({}, state, {
          global,
        });
      }
      global.currentUser.id = action.payload.id || state.global.currentUser.id;
      global.currentUser.type =
        action.payload.type || state.global.currentUser.type;
      global.currentUser.email =
        action.payload.email || state.global.currentUser.email;
      global.currentUser.park =
        action.payload.park || state.global.currentUser.park;
      global.currentUser.picture =
        action.payload.picture || state.global.currentUser.picture;
      global.currentUser.selectedUser =
        action.payload.selectedUser || state.global.currentUser.selectedUser;
        global.currentUser.checkingInDogs =
        action.payload.checkingInDogs;
        
      return Object.assign({}, state, {
        global,
      });
    }
    case "CHECKED_IN": {
      // if (action.payload === "reset") {
      //   console.log("Reser");
      //   global.chekcIn = {
      //     checkingInDogs: {},
      //   };
      //   return Object.assign({}, state, {
      //     global,
      //   });
      // }

      global.chekcIn.checkingInDogs = action.payload.checkingInDogs;
      return Object.assign({}, state, {
        global,
      });
    }
    default:
      return state;
  }
};
