import React, { useState } from "react";
import Theme from "../../globalComponents/Theme.js";

const styles = {
    tag: {
        padding: "0px 7px",
        margin: "0 .75%",
        border: "2px solid",
        borderColor: Theme.INACTIVE_GREY_COLOR,
        borderRadius: 15,
        transition: "transform 300ms ease-in-out",
        cursor: "pointer",
        position: "relative",
        display: "Inline",
        backgroundColor: "#ddd",
        fontSize: "17px",
        lineHeight: "30px"
    },

    tagOnHover: {

        float: "right",
        padding: "2px 1%",
        margin: "0 0",
        border: "2px solid",
        backgroundColor: "#fff",
        borderColor: Theme.STANDARD_ORANGE,
        color: Theme.STANDARD_ORANGE,
        borderRadius: 10,
        transition: "transform 300ms ease-in-out",
        cursor: "pointer",
        position: "absolute",
        top: "-10px",
        right: "-10px",
        // left:"0px",
        // margin : "auto",
        width: "240px",
        hieght: "50px",
        display: "block",
        zIndex: "999",
        fontSize: "16px",

    }
};
const HoverableDiv = ({ handleMouseOver, handleMouseOut, handleClick }) => {
    return (
        <div style={styles.tag} onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            ?
        </div>
    );
};

const HoverText = ({ text }) => {
    return (
        <div style={styles.tagOnHover}>
            {text}
        </div>
    );
};

const HoverTag = ({ text }) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleClick = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    const width = window.innerWidth;
    const verySmall = width < 768;
    return (
        <div style={{
            display: verySmall ? "block" : "inline",
        }}>
            {/* Hover over this div to hide/show <HoverText /> */}
            <HoverableDiv
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
                handleClick={handleClick}
            />
            {isHovering && <HoverText text={text} />}
        </div>
    );
};
export default HoverTag;