import React, { Component } from "react";
import { Query } from "react-apollo";
import {
  LabeledTextBox,
  PictureUpload,
  Theme,
} from "../../../globalComponents";
import SelectAddress from "../../../globalComponents/FormComponents/selectAddress";
import SelectDashBoardMember from "../../../globalComponents/FormComponents/selectDashboardMember";
import { PARK_NAMES } from "../../../store/queries";
import { placeAutoComplete, placeDetails } from "../../../utils/placeapis";

class OwnerInfo extends Component {
  state = {
    suggestions: [],
    selectedAddress: "",
  };

  // componentDidMount() {
  //     this.props.change("tagName", "Static Tag")

  // }
  getAddressSuggestion = async (e) => {
    let payload = {
      input: e.target.value,
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      radius: "100",
    };
    let suggest = await placeAutoComplete(payload);
    this.setState({
      suggestions: suggest,
    });
  };

  handleSelecteAddress = async (address) => {
    let place_id = address.place_id;
    let payload = {
      key: "AIzaSyBHhYPkIHwkS3RHaesJWNHbWKVKYBihNdY",
      place_id,
    };
    let getAddress = await placeDetails(payload);
    getAddress.address_components.map((item) => {
      switch (item.types[0]) {
        case "country":
          this.props.change("address", getAddress.name);
          this.setState({
            selectedAddress: getAddress.name,
          });
          break;
        case "postal_code":
          this.props.change("zip", item.long_name);
          break;
        case "administrative_area_level_1":
          this.props.change("state", item.short_name);
          break;
        case "locality":
          this.props.change("city", item.long_name);
          break;
        default:
      }
    });
  };
  render() {
    const { stateAbbreviations, userData, change, global, role } = this.props;
    const obj = global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 768;
    const { suggestions, selectedAddress } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: shrink ? "100%" : "50%",
        }}
      >
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: shrink ? "center" : "",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR,
          }}
        >
          Pet Owner Info
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <PictureUpload
            width={width}
            containerStyle={{ marginRight: "10px" }}
            picture={this.props.getPicture()}
            name="profilePicture"
            onUpload={(v, pic) => {
              change("profilePicture", v);
              this.props.setState(pic);
            }}
            height={height}
            label="Picture"
          />
          <div
            style={{
              display: "flex",
              marginTop: "2%",
              flexDirection: "column",
              width: "calc(100% - 150px)",
            }}
          >
            {/* <ParlorForm /> */}
            <LabeledTextBox
              name="firstName"
              onBlur={() => {}}
              width={width}
              widthpercent={"100%"}
              height={height}
              label="First Name"
            />
            <LabeledTextBox
              name="lastName"
              onBlur={() => {}}
              width={width}
              widthpercent={"100%"}
              height={height}
              label="Last Name"
            />
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    color: Theme.DARK_TEXT_COLOR,
                    fontSize: Theme.getNormalFont(width),
                    fontFamily: Theme.MAIN_FONT,
                  }}
                >
                  <span>Home Park</span>
                </div>

                <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                  {({ loading, error, data, refetch }) => {
                    if (error) return `Error: ${error.message}`;
                    return (
                      <SelectDashBoardMember
                        alt={true}
                        options={data?.restrictedFetchParks}
                        handleSelect={(park, name) => {
                          this.props.change(name, park.id);
                          // this.props.change("parkId", park.name);
                          this.props.setStatePark(park.name);
                          this.props.handleParkName(park.id);
                        }}
                        selected={
                          this.props.parkName || userData.userDetail.park?.name
                        }
                        name="parkId"
                        width={"100%"}
                        height={height / 25}
                        label="Park Location"
                      />
                    );
                  }}
                </Query>
              </div>
            </div>
          </div>
        </div>
        {
          <LabeledTextBox
            name="tagName"
            onBlur={() => {}}
            width={width}
            widthpercent={"100%"}
            height={height}
            label="Tag"
            onClick={(e) => e.preventDefault()}
            onChange={(e) => e.preventDefault()}
          />
        }

        <div
          style={{
            marginBottom: "2%",
            marginTop: "10%",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR,
          }}
        >
          Member Address
        </div>
        {/* <LabeledTextBox
                    name="address"
                    onBlur={() => { }}
                    width={width}
                    widthpercent={"100%"}
                    height={height}
                    label="Address"
                    // onChange={(e) => this.getAddressSuggestion(e)}
                    options={suggestions}
                /> */}

        <SelectAddress
          alt={true}
          options={suggestions}
          handleSelect={(address, name) => {
            this.handleSelecteAddress(address);
          }}
          selected={selectedAddress}
          name="address"
          width={width}
          height={height}
          widthpercent={"100%"}
          label="Address"
          getAddressSuggestion={this.getAddressSuggestion}
        />
        <LabeledTextBox
          name="address2"
          onBlur={() => {}}
          width={width}
          widthpercent={"100%"}
          height={height}
          label="Address Line 2"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%" }}>
            <LabeledTextBox
              name="city"
              onBlur={() => {}}
              widthpercent={"100%"}
              width={width}
              height={height}
              label="City"
            />
          </div>
          <div style={{ width: "20%" }}>
            <LabeledTextBox
              name="state"
              widthpercent={"100%"}
              options={stateAbbreviations}
              // validate={["required"]}
              width={width}
              height={height}
              label="State"
            />
          </div>
          <div style={{ width: "20%" }}>
            <LabeledTextBox
              name="zip"
              onBlur={() => {}}
              widthpercent={"100%"}
              width={width}
              height={height}
              label="Zipcode"
            />
          </div>
        </div>
        <LabeledTextBox
          name="email"
          onBlur={() => {}}
          width={width}
          widthpercent={"100%"}
          height={height}
          label="Email"
        />
        <LabeledTextBox
          name="phoneNumber"
          onBlur={() => {}}
          width={width}
          widthpercent={"100%"}
          height={height}
          validate={["required", "number"]}
          label="Telephone Number"
        />
      </div>
    );
  }
}

export default OwnerInfo;
