import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import cookie from "js-cookie"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"
import { persistCache } from "apollo-cache-persist"
import jstz from "jstz";
const timezone = jstz.determine();
let timezoneName = timezone.name();
const cache = new InMemoryCache()
persistCache({
  cache,
  storage: window.localStorage
})
var authLink = setContext((_, { headers }) => {
  const token = cookie.get("token")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      timezone:timezoneName
    }
  }
})
const link = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
})

var client = new ApolloClient({
  link: authLink.concat(link),
  cache: cache
})

export default client
