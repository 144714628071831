import React, { Component } from "react";
import Theme from "../../globalComponents/Theme.js";
import { Button } from "../";
import {updateUser, checkInDog } from "../../store/redux/actions.js";

import {
  BULK_CHECKIN,
} from "../../store/mutations";
import { reduxForm } from "redux-form";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { PARK_RULE_CHECK, USER_NOTES } from "../../store/queries.js";
import CheckedNeutered from "../../assets/check_neutered.svg";
import { uniq as _uniq } from "lodash";

import Warning from "../../assets/paw_warning.svg";
import Vaccinated from "../../assets/active_green.svg";
import Expired from "../../assets/paw-expired.svg";
import RedCross from "../../assets/redCross.svg";

const styles = {
  pageContainer: {
    position: "absolute",
    // display: "flex",
    flex: 1,
    alignItems: "center",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0,0,0,.2)",
    height: "100vh",
    width: "100vw",
    zIndex: 100,
  },

  modalContainer: function (width) {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "1vw",
      height: "auto",
      width: width,
      fontSize: Theme.getNormalFont(width),
      fontFamily: Theme.HEADER_FONT,
      backgroundColor: "white",
      color: Theme.DARK_TEXT_COLOR,
      boxShadow: "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    };
  },

  header: {
    borderBottom: "3px solid",
    borderBottomColor: Theme.INACTIVE_GREY_COLOR,
    width: "97%",
    textAlign: "center",
    fontSize: "30px",
    marginBottom: "5px",
  },

  heading: {
    marginBottom: "0px",
    marginTop: "0px",
    fontSize: "18px",
    fontFamily: "Lato",
  },

  subtext: function (width) {
    return {
      fontSize: Theme.getNormalFont(width),
      color: Theme.LIGHT_TEXT_COLOR,
    };
  },

  tagSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1vw",
    paddingLeft: "0",
  },

  tagListContainer: function (width) {
    return {
      listStyleType: "none",
      margin: "0",
      width: width / 1.2,
      paddingLeft: "1vw",
      color: Theme.INACTIVE_GREY_COLOR,
    };
  },

  textArea: function (width, textFontSize) {
    return {
      resize: "none", //This removes those diagonal lines that appear in the iner bottom-right corner
      borderRadius: 5,
      height: width / 6, // UPDATE HEIGHT CALULCATION
      fontSize: textFontSize,
    };
  },

  submitButtonWrapper: {
    alignSelf: "flex-end",
    margin: ".5em 0",
  },
};

class CheckedInModal extends Component {
  state = {
    checkingInDogs: {},
    dogIds: [],
    checkingLoading: false,
    selectedUserId: "",
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    document.addEventListener("mousedown", this.handleClick, false);
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  handleTagFields = (e) => {
    let name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getTextAreaWidth(width) {
    if (width > 1900) return "54.5vw";
    else if (width > 1650) return "64.5vw";
    else if (width > 1200) return "70.5vw";
    else if (width > 800) return "84.5vw";
    else return "79vw";
  }

  getModalWidth(width) {
    // if (width > 1900) return "55vw";
    // else if (width > 1650) return "65vw";
    // else if (width > 1200) return "75vw";
    // else if (width > 800) return "85vw";
    // else return "80vw";
    if (width > 1900) return "30%";
    else if (width > 1440) return "30%";
    else if (width > 1200) return "30%";
    else if (width > 800) return "50%";
    else if (width > 400) return "80%";
    else return "100%";
  }

  handleClick = (e) => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.props.closeModal();
        }
      } catch (error) {}
    }
  };

  getFont(width) {
    if (width > 400) return 18;
    else return 12;
  }

  getChekInValue = (data) => {
    let isConfirmed = this.props.userData.userDetail.confirmed;

    let { isBanned, vaccineStatus, pausedSubscription } = data;

    if (
      !isBanned &&
      ((isConfirmed && vaccineStatus !== "expired") || pausedSubscription)
    ) {
      return true;
    } else {
      return false;
    }

  };
  checkParkRule = async (userId) => {
    let parkId = this.props.global?.currentUser?.park?.id;
    let variables = {
      userId,
      parkId,
    };
    let { selectedUserId } = this.state;
    if (userId !== selectedUserId) {
      let parkRuleStatus = await this.props.client
        .query({
          query: PARK_RULE_CHECK,
          variables: variables,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          return response.data?.fetchUserParkRuleStatus?.status;
        })
        .catch((error) => {
          console.error("Park Error: ", error);
          return false;
        });
      return parkRuleStatus;
    } else {
      return true;
    }
  };

  selectDog = async ({ id, parkId, notes, name, renewNeeded,  }, acceptedRules) => {
    const { checkingInDogs } = this.state;
    let userId = this.props.userData.userDetail.id;
    // let checkStatus = await this.checkParkRule(userId);
    console.log("acceptedRules", acceptedRules);
    if (acceptedRules) {
      // ------ Selectection Start -----
      if (id in checkingInDogs) {
        delete checkingInDogs[id];
        this.setState({
          selectedUserId: userId,
        });
        this.props.checkInDog({ checkingInDogs });
      } else {
        if (renewNeeded) {
          if (
            window.confirm("Are you sure you want to renew this membership?")
          ) {
            checkingInDogs[id] = userId;
            this.props.checkInDog({ checkingInDogs });
            this.setState({
              selectedUserId: userId,
            });
          } else {
          }
        } else {
          checkingInDogs[id] = userId;
          this.props.checkInDog({ checkingInDogs });
          this.setState({
            selectedUserId: userId,
          });
        }
      }
      // ------ Selectection End -----
      this.setState({ checkingInDogs, parkId });
    } else {
      // alert("Please have the member accept the park rules before continuing.");
      checkingInDogs[id] = userId;
      this.props.checkInDog({ checkingInDogs });
      this.props.updateUser({ selectedUser: userId, checkingInDogs });
      alert("Member needs to sign a park specific waiver.");

      this.props.history.push("/members/parkrules");
    }
    // if (id in checkingInDogs) {
    //   delete checkingInDogs[id];
    // } else {
    //   checkingInDogs[id] = userId;
    // }
    // this.setState({ checkingInDogs });
  };
  checkedNoteCheckIn = (userId) => {
    let { client } = this.props;
    if (userId) {
      // Check IN start
      // this.setState({
      //   checkInButtonDisabled: true,
      // });
      const values = {
        id: Number(userId),
      };
      client
        .query({
          query: USER_NOTES,
          variables: values,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const {
            data: {
              userDetail: { notes, firstName, lastName },
            },
          } = response;

          if (notes.length > 0) {
            let note = notes && notes[0].content;
            if (
              window.confirm(`${firstName + " " + lastName} NOTE : ${note}`)
            ) {
              return new Promise((resolve, reject) => {
                this.handleCheckIn();
              });
            } else {
              this.setState({
                checkInButtonDisabled: false,
              });
            }
          } else {
            if (
              window.confirm("Do you want to check-in the selected members?")
            ) {
              return new Promise((resolve, reject) => {
                this.handleCheckIn();
              });
            } else {
              this.setState({
                checkInButtonDisabled: false,
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
      // Check IN end
    }
  };
  handleCheckIn = () => {
    let { dogIds } = this.state;
    let checkedDog = [];
    const tempArr = [];
    const checkedinArr = Object.entries(this.state.checkingInDogs);
    const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));

    const countDogsCheckedInForUser = (forUserId) => {
      let count = 0;
      let dogIdArr = [];

      for (let [dogId, userId] of checkedinArr) {
        if (forUserId == userId) dogIdArr.push(Number(dogId));
      }
      return dogIdArr;
    };
    for (const userId of allUsersIds) {
      const dogIds = countDogsCheckedInForUser(userId);
      checkedDog.push({ userId, dogCount: dogIds.length, dogIds });
    }

    let variables = {
      viaApp: "web",
      users: checkedDog,
      parkId:
        this.props.global.currentUser.park &&
        this.props.global.currentUser.park.id,
    };

    this.setState({ checkingLoading: true });
    this.props.client
      .mutate({
        mutation: BULK_CHECKIN,
        variables: { input: variables },
      })
      .then((response) => {
        this.setState({
          checkInButtonDisabled: false,
        });
        if (response.data.checkIn.errors[0]) {
          alert(response.data.checkIn.errors[0].message);
        } else {
          alert("All selected member(s) successfully checked in!");
        }
        this.props.closeModal();
        this.setState({ checkingLoading: false, checkingInDogs: {} });
        // eslint-disable-next-line no-restricted-globals
        // location.reload();
      })
      .catch((error) => {
        let error_msg = error.message.replace("GraphQL error:", "");
        alert(error_msg);
        this.setState({
          checkInButtonDisabled: false,
        });
      });
  };
  handleActiveStatus = (confirmed, subActive, subPaused, isBanned) => {
    let status = {
      text: "",
    };
    if (isBanned) {
      status.text = "Banned";
    } else if (subPaused) {
      status.text = "Paused";
    } else if (confirmed && subActive) {
      status.text = "Active";
    } else if (!confirmed) {
      status.text = "Not Confirmed";
    } else if (!subActive) {
      status.text = "Inactive";
    }

    return status;
  };
  handleVaccineExpiredStatus = (vaccineStatus) => {
    let status = {
      text: "",
      icon: null,
      alt: "",
    };
    if (vaccineStatus === "expiring_soon") {
      status.text = vaccineStatus;
      status.icon = Warning;
      status.alt = "warning";
    } else if (vaccineStatus === "expired") {
      status.text = vaccineStatus;
      status.icon = Expired;
      status.alt = "expired";
    } else if (vaccineStatus === "vaccinated") {
      status.text = vaccineStatus;
      status.icon = Vaccinated;
      status.alt = "vaccinated";
    }

    return status;
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const { isModalDisplayed, isUpdate, closeModal, dogs, userData } =
      this.props;
    const { checkingInDogs, dogIds, checkingLoading } = this.state;
    let lengthCheckingDogs = Object.keys(checkingInDogs).length;
    let userConfirmed = userData.userDetail.confirmed;
    let acceptedRules = userData.userDetail.acceptedRules;

    const TillTab = width < 1024;
    if (!isModalDisplayed) {
      return null;
    } else
      return (
        // Page Container
        <div style={styles.pageContainer}>
          {/* Modal Container */}
          <div
            ref={(node) => (this.node = node)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10%",
              marginTop: "5%",
              padding: "2vw",
              height: "auto",
              width: this.getModalWidth(width),
              fontSize: Theme.getNormalFont(width),
              fontFamily: Theme.HEADER_FONT,
              backgroundColor: "white",
              color: Theme.DARK_TEXT_COLOR,
              boxShadow:
                "0 10px 15px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
            }}
          >
            <div style={styles.header}>
              Check In{" "}
              {userData.userDetail.firstName +
                " " +
                userData.userDetail.lastName}
            </div>
            {/* Header */}
            {/* Park Dropdown Start */}

            <div style={{ marginTop: 20 }}>
              {/* <div style={styles.heading}> Dog Name </div> */}
              {dogs.map((data, index) => {
                return (
                  <div
                    style={{
                      // width: shrink ? "80%" : "50%",
                      margin: "auto",
                      maxHeight: "100px",
                      overflowY: "auto",
                      paddingLeft: "2px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          <button
                            onClick={(e) => this.selectDog(data, acceptedRules)}
                            disabled={!this.getChekInValue(data)}
                            type="button"
                            style={{
                              width: 25,
                              // width: !this.getChekInValue(data) ? "25%" : "25%",

                              height: 25,
                              border: "2px solid",
                              cursor: !this.getChekInValue(data)
                                ? "not-allowed"
                                : "pointer",
                              borderColor: !this.getChekInValue(data)
                                ? "#ef3939"
                                : "#49c172",
                              outline: "none",
                              borderRadius: !this.getChekInValue(data) ? 8 : 5,
                              padding: "0px",
                              background:
                                checkingInDogs[data.id] || false
                                  ? !this.getChekInValue(data)
                                    ? "#ef3939"
                                    : "#49c172"
                                  : "transparent",
                            }}
                          >
                            {checkingInDogs[data.id] || false ? (
                              <img
                                active={false}
                                alt="Active"
                                src={
                                  !this.getChekInValue(data)
                                    ? RedCross
                                    : CheckedNeutered
                                }
                                style={{
                                  // width: "100%",
                                  height: "100%",
                                  marginBottom: "10px",
                                }}
                              />
                            ) : !this.getChekInValue(data) ? (
                              <img
                                active={false}
                                alt="Active"
                                src={RedCross}
                                style={{
                                  // width: "100%",
                                  height: "100%",
                                  marginBottom: "12px",
                                }}
                              />
                            ) : null}
                          </button>
                        </div>

                        <div
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            marginLeft: "5px",
                            width: "100%",
                          }}
                        >
                          <span>
                            {data.name}
                            {`(${
                              this.handleActiveStatus(
                                userConfirmed,
                                data.subscriptionActive,
                                data.pausedSubscription,
                                data.isBanned
                              ).text
                            })`}
                          </span>
                        </div>
                        {userConfirmed && (
                          <img
                            alt={
                              this.handleVaccineExpiredStatus(
                                data.vaccineStatus
                              ).alt
                            }
                            style={
                              data.vaccineStatus === "expired"
                                ? {
                                    marginRight: TillTab ? "0px" : "10%",
                                    marginLeft: TillTab ? "10px" : "auto",
                                    marginTop: `${width > 1000 ? "0" : "0"}px`,
                                    float: TillTab ? "right" : " ",
                                    background: "#404040",
                                    padding: " 5px",
                                    borderRadius: "6px",
                                  }
                                : {
                                    marginRight: TillTab ? "0px" : "10%",
                                    marginLeft: TillTab ? "10px" : "auto",
                                    marginTop: `${width > 1000 ? "0" : "0"}px`,
                                    float: TillTab ? "right" : " ",
                                    padding: " 5px",
                                    borderRadius: "6px",
                                  }
                            }
                            src={
                              this.handleVaccineExpiredStatus(
                                data.vaccineStatus
                              ).icon
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Button Wraper to Right-Align */}
            <div style={styles.submitButtonWrapper}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  text="Check IN"
                  type="button"
                  width={width}
                  // onClick={this.handleCheckIn}
                  onClick={() => {
                    this.checkedNoteCheckIn(userData.userDetail.id);
                  }}
                  containerStyle={{ marginRight: 10 }}
                  loadingText="Wait"
                  disabled={lengthCheckingDogs === 0 || checkingLoading}
                  loading={checkingLoading}
                />
                <Button
                  text="Cancel"
                  type="button"
                  width={width}
                  onClick={() => closeModal()}
                />
              </div>
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = {updateUser, checkInDog };

CheckedInModal = reduxForm({
  form: "CheckedInModal",
})(CheckedInModal);

export default compose(withApollo, connect(mapStateToProps, mapDispatchToProps))(CheckedInModal);
