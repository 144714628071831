import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../Theme.js";
import { compose, Query, withApollo } from "react-apollo";
import { reduxForm, formValueSelector } from "redux-form";
import { USER_UPDATE, DOG_UPDATE, APPLY_COUPON_CODE, CREATE_DOG_PACKAGE } from "../../store/mutations.js";
import { Button, LabeledTextBox, PawIndicator } from "../";
import Card1 from "../../assets/card-1.svg";
import Card2 from "../../assets/card-2.svg";
import Card3 from "../../assets/card-3.svg";
import Card4 from "../../assets/card-4.svg";
import Card5 from "../../assets/card-5.svg";
import CheckedNeutered from "../../assets/check_neutered.svg"
import cookie from "js-cookie"
import { updateUser } from "../../store/redux/actions";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  StripeProvider,
  Elements,
  injectStripe
} from "react-stripe-elements";
import { USER_PAYMENT } from "../../store/queries.js";
import { withRouter } from "react-router-dom";

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const cards = {
  Card1,
  Card2,
  Card3,
  Card4,
  Card5
};

const style = () => {
  return {
    style: {
      base: {
        fontSize: "18px",
        fontWeight: "400",
        "::placeholder": {
          color: "#606267"
        }
      },
      invalid: {
        color: "#d9534f"
      },
      complete: {
        color: "#5cb85c"
      }
    }
  };
};

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validation: "",
      isPaymentInProgress: false,
      savedCard: false,
      userId: "",
      stripeCardToken: ""
    };
  }


  componentDidMount = () => {
    // console.log("  this.props.global", this.props.global.currentUser);
    if (
      this.props.global.currentUser &&
      (this.props.global.currentUser.type === "Employee"
        || this.props.global.currentUser.type === "Admin"
        || this.props.global.currentUser.type === "Manager"
      )
    ) {
      this.setState({
        userId: this.props.global.currentUser.selectedUser
      }, () => this.getUserPaymentDetail())
    }
    // else if (this.props.global.currentUser && this.props.global.currentUser.type === "Admin") {
    //   this.setState({
    //     userId: this.props.global.currentUser.selectedUser
    //   }, () => this.getUserPaymentDetail())
    // }
    // else if (this.props.global.currentUser && this.props.global.currentUser.type === "Manager") {
    //   this.setState({
    //     userId: this.props.global.currentUser.selectedUser
    //   }, () => this.getUserPaymentDetail())
    // }
    else {
      if (this.props.global.currentUser) {
        this.setState({
          userId: this.props.global.currentUser.id
        }, () => this.getUserPaymentDetail())
      }
    }
    this.props.change("couponCode", "");
  };


  getUserPaymentDetail() {
    const { client, global, closeModal } = this.props;
    const { currentUser } = global;
    // console.log("^^^^^*********** Hit *********^^^^^^^^^^^^^^");

    let { userId } = this.state;
    if (this.state.userId) {
      const values = {
        id: (userId),
      }
      client
        .query({ query: USER_PAYMENT, variables: values })
        .then(response => {
          const { data: { userDetail: { stripeCardToken } } } = response
          // console.log("^^^^^*********** Token present *********^^^^^^^^^^^^^^", response,);

          if (stripeCardToken === null) {
            // console.log("^^^^^*********** Token Not present *********^^^^^^^^^^^^^^");

            this.setState({
              savedCard: false
            })
          }
          else {
            // console.log("^^^^^*********** Token present *********^^^^^^^^^^^^^^");
            this.setState({
              savedCard: true,
              stripeCardToken,
            })
          }
        })
        .catch(error => {
          console.error("Error: ", error);
          return false;
        })
    }
  }

  handleSavedCard = () => {

    this.setState({ savedCard: false, stripeCardToken: "" });

  }
  handleStripeCardID = (id) => {
    this.setState({ savedCard: true, stripeCardToken: id });
  }

  handleSubmit = e => {
    e.preventDefault();
    let { userId } = this.state;
    if (this.props.stripe && this.state.stripeCardToken === "") {
      console.log("Id Not present");
      this.setState({ isPaymentInProgress: true })
      this.props.stripe.createToken().then(payload => {
        const { client, global, onUserUpdated, dogPaymentDetails, forUserId } = this.props;
        if (payload.token) {
          const { dogId, isRecurring, packageId } = this.props
          // console.log("Dog-------------------->", dogId);

          console.log({ dogId, isRecurring, packageId })

          console.log("&&&&&&&&&&&&&&&&&&", {
            input: dogPaymentDetails,
            stripeCardToken: payload.token.id,
            userId
          });
          if (dogId) {
            // for dogs execute dog membership code
            client
              .mutate({
                mutation: CREATE_DOG_PACKAGE,
                variables: {
                  input: dogPaymentDetails,
                  userId: Number(userId),
                  stripeCardToken: payload.token.id,
                }
              })
              .then(({ data: { updateUser } }) => {
                console.log("Dog Updated: ", updateUser)
                onUserUpdated();
              })
              .catch(error => {
                this.setState({ validation: error });
              });
          }
          else {
            client
              .mutate({
                mutation: USER_UPDATE,
                variables: {
                  input: {
                    id: global.currentUser.selectedUser || global.currentUser.id,
                    stripeCardToken: payload.token.id,

                  }
                }
              })
              .then(({ data: { updateUser } }) => {
                onUserUpdated();
              })
              .catch(error => {
                this.setState({ validation: error });
              });
          }
        } else {
          this.setState({ validation: payload.error.message, isPaymentInProgress: false });
        }
      }).catch(e => {
        console.log("Exception in Stripe:PackageForm :", e)
        this.setState({ isPaymentInProgress: false })
      });
    }
    else {
      console.log("Id  present");
      const { dogId, isRecurring, packageId, client, global, onUserUpdated, dogPaymentDetails, forUserId } = this.props
      this.setState({ isPaymentInProgress: true })
      if (dogId) {
        console.log("&&&&&&&&&&&&&&&&&&", {
          input: dogPaymentDetails,
          stripeCardToken: this.state.stripeCardToken,
          userId
        });
        // for dogs execute dog membership code
        client
          .mutate({
            mutation: CREATE_DOG_PACKAGE,
            variables: {
              input: dogPaymentDetails,
              stripeCardToken: this.state.stripeCardToken,
              userId: Number(userId),
            }
          })
          .then(({ data: { updateUser } }) => {
            // console.log("Dog Updated:---> ", updateUser)
            onUserUpdated();
            // this.props.history.push("confirmAccount");
          })
          .catch(error => {
            this.setState({ validation: error });
          });
      }
      else {
        client
          .mutate({
            mutation: USER_UPDATE,
            variables: {
              input: {
                id: global.currentUser.selectedUser || global.currentUser.id,
                stripeCardToken: this.state.stripeCardToken
              }
            }
          })
          .then(({ data: { updateUser } }) => {
            onUserUpdated();
          })
          .catch(error => {
            this.setState({ validation: error, isPaymentInProgress: false });
          });
      }
    }
  };

  handleCouponCode = couponCode => {
    if (couponCode) {
      this.props.client
        .mutate({
          mutation: APPLY_COUPON_CODE,
          variables: {
            input: {
              code: couponCode,
              userId:
                this.props.global.currentUser.selectedUser ||
                this.props.global.currentUser.id
            }
          }
        })
        .then(response => {
          alert(response.data.applyCoupon.message);
        })
        .catch(error => {
          console.error("Error: ", error);
          alert(
            "There was an error processing this coupon code. It may be expired, entered incorrectly, or has already been used."
          );
        });
    } else {
      alert("No coupon code entered.");
    }
  };

  render() {
    const {
      width,
      focused,
      handleFocus,
      onCancel,
      cancelLabel,
      couponCode
    } = this.props;
    const { validation, savedCard, userId, stripeCardToken } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    console.log("User", userId, stripeCardToken);
    const { dogId, isRecurring, packageId } = this.props
    console.log("Dog-------------------->", dogId);
    return (
      <Fragment>
        <div
          style={{
            marginBottom: "2%",
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Payment
        </div>
        {userId !== "" && <Query
          fetchPolicy="no-cache"
          query={USER_PAYMENT}
          variables={{
            id: userId
          }}
        >
          {({ loading, error, data, refetch }) => {
            // if (error) return `Error: ${error.message}`;
            if (loading) {
              return null
            }

            if (error) {
              if (
                error?.message ===
                "GraphQL error: You are not authorized to access that resource"
              ) {
                cookie.remove("token");
                this.props.updateUser("reset");
                this.props.history.push("/login");
              }
              return `Error: ${error?.message}`;
              // sessionCheck(error, this.props.updateUser)
            }
            // console.log("Data ---------------------", loading, (data.userDetail && data.userDetail.cardLast4));
            if (data?.userDetail && (data.userDetail.cardLast4 === null && data.userDetail.stripeCardToken === null)) return null

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "100%",
                  border: "2px solid black",
                  borderRadius: "20px",
                  backgroundColor: "white",
                  padding: 20
                }}
              >
                <div
                  style={{
                    marginBottom: "2%",
                    marginTop: "5%",
                    textAlign: "left",
                    fontFamily: Theme.HEADER_FONT,
                    fontSize: Theme.getNormalFont(width),
                    color: Theme.HEADER_FONT_COLOR
                  }}
                >
                  Saved Card
            </div>
                <div style={{ display: "flex", position: "relative", marginBottom: "10px" }}>
                  <button
                    onClick={() => this.handleStripeCardID(data.userDetail.stripeCardToken)}
                    type="button"
                    style={{
                      width: 25,
                      height: 25,
                      border: "2px solid",
                      cursor: "pointer",
                      borderColor: "#49c172",
                      outline: "none",
                      borderRadius: 5,
                      padding: "0px",
                      background: savedCard ? "#49c172" : "transparent",
                    }}
                  >
                    {savedCard ? (
                      <img
                        active={false}
                        alt="Active"
                        src={CheckedNeutered}
                        style={{

                          width: "100%",
                          height: "100%"
                        }}
                      />
                    ) : null}
                  </button>
                  <div
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                      width: "100%",

                    }}
                  >
                    <span> XXXX-XXXX-XXXX-{data && data.userDetail.cardLast4}</span>
                  </div>

                </div>

                {savedCard && <Button
                  type="button"
                  containerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                  text="Add Card"
                  width={width}
                  onClick={(e) => this.handleSavedCard(e)}
                />}
              </div>

            );
          }}
        </Query>

        }

        { !savedCard &&
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                style={{ alignSelf: "center", width: "70%", margin: "5% 0 5% 0" }}
                src={cards[`Card${focused}`]}
                alt=""
              />
              {validation && <p style={{ color: "red" }}>{validation}</p>}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <label>
                  Card number
              <CardNumberElement onFocus={() => handleFocus(2)} {...style()} />
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <label>
                    Expiration date
                <CardExpiryElement
                      className="smallStripeElement"
                      onFocus={() => handleFocus(3)}
                      {...style()}
                    />
                  </label>
                  <label>
                    CVC
                <CardCvcElement
                      className="smallStripeElement"
                      onFocus={() => handleFocus(5)}
                      {...style()}
                    />
                  </label>
                </div>
              </div>
            </div>


            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5%",
                marginLeft: "auto",
                marginRight: "auto",
                width: "70%",
                border: "2px solid black",
                borderRadius: "20px",
                backgroundColor: "white",
                padding: 20
              }}
            >
              <LabeledTextBox
                onBlur={() => { }}
                name={`couponCode`}
                width={width}
                widthpercent={"100%"}
                height={height}
                label="Coupon Code"
              />
              <Button
                type="button"
                containerStyle={{ marginLeft: "auto", marginRight: "auto" }}
                text="Apply"
                width={width}
                onClick={() => this.handleCouponCode(couponCode)}
              />
            </div>
          </>
        }
        {/* {savedCard && <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
            width: "100%"
          }}
        >

          <Button
            type="button"
            containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
            text={"Add Card"}
            width={width}
            // disabled={this.state.isPaymentInProgress}
            onClick={e => this.handleSavedCard(e)}
          />
        </div>} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
            width: "50%"
          }}
        >
          <Button
            containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
            text={cancelLabel || "Back"}
            alt={true}
            width={width}
            onClick={onCancel}
          />
          <Button
            type="button"
            containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
            text={savedCard ? "Continue" : "Save and Continue"}
            width={width}
            disabled={this.state.isPaymentInProgress}
            onClick={this.handleSubmit}
          />
        </div>
      </Fragment>
    );
  }
}

const CardForm = injectStripe(Form);

class PaymentForm extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    focused: 1
  };
  handleFocus = index => {
    this.setState({ focused: index });
  };
  render() {
    const { focused } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const shrink = width < 870;

    return (
      <div>
        <StripeProvider apiKey={stripePublishableKey}>
          <Elements>
            <CardForm
              focused={focused}
              height={height}
              width={width}
              shrink={shrink}
              handleFocus={this.handleFocus}
              {...this.props}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

const selector = formValueSelector("PaymentForm"); // <-- same as form name
PaymentForm = connect(state => {
  const couponCode = selector(state, "couponCode");
  return {
    couponCode
  };
})(PaymentForm);

PaymentForm = reduxForm({
  form: "PaymentForm"
})(PaymentForm);
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentForm);
