import React, { Component } from "react";
import { Query } from "react-apollo";
import { Button, Select, Theme } from "../../../../globalComponents";
import ParkSelect from "../../../../globalComponents/FormComponents/parkSelect";
import { PARK_CLOSURE } from "../../../../store/mutations";
import { PARK_NAMES } from "../../../../store/queries";
import ParkLogEventTable from "./parkLogEventTable";

class ParkLog extends Component {
  state = {
    parkName: "Select Park",
    parkId: "",
  };

  render() {
    const { width, height, global } = this.props;
    const shrink = width < 700;
    const textFontSize = Theme.getNormalFont(width);
    let { parkName, parkId, isLoading } = this.state;
    return (
      <div
        style={{
          marginTop: 50,
          marginLeft: "auto",
          marginRight: "auto",
          width: "90%",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div style={{ marginTop: 75, marginLeft: "auto", marginRight: "auto" }}>
          <Query fetchPolicy="no-cache" query={PARK_NAMES}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <div></div>;
              if (error) return `Error: ${error.message}`;
              if (!loading) {
                return (
                  <div>
                    <div
                      style={{
                        width: shrink ? "80%" : "50%",
                        margin: "auto",
                      }}
                    >
                      <ParkSelect
                        alt={true}
                        options={data?.restrictedFetchParks}
                        handleSelect={(park, name) => {
                          this.setState({
                            parkName: park.name,
                            parkId: park.id,
                          });
                        }}
                        selected={parkName}
                        name="parkId"
                        width={"100%"}
                        height={height / 25}
                        label="Park Location"
                      />
                    </div>
                  </div>
                );
              }
            }}
          </Query>
          {parkId !== "" && (
            <ParkLogEventTable
              global={global}
              client={this.props.client}
              parkId={parkId}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ParkLog;
