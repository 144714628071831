import React, { Component } from "react";
import Active from "../../assets/paw-active.svg";
import Inactive from "../../assets/paw-inactive.svg";

export default class PawIndicator extends Component {
  render() {
    const { style, active } = this.props,
      alt = active ? "Active" : "Inactive",
      imageSrc = active ? Active : Inactive;
    return <img alt={alt} style={style} src={imageSrc} />;
  }
}
