import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { withRouter } from 'react-router-dom';
import { OnboardingHeader, PackageForm } from "../../globalComponents";

class Packages extends Component {
  constructor(props) {
    super(props);
    console.info(" Packages Constructor ")
    console.log("component constructor", props.match.params.dogId)

    this.state = {
      dogId: props.match.params.hasOwnProperty("dogId") ? props.match.params.dogId : null
    }
    // if (props.match.params.hasOwnProperty("dogId")) {
    //   this.setState({
    //     dogId: props.match.params.dogId
    //   })
    // }
  }



  onCancel = () => {
    this.props.history.push("vaccinations");
  };

  beforeSubmit = newValues => {
    console.log(" beforeSubmit ", newValues)
    const { dogId, packageId, isRecurring } = newValues
    if(dogId && packageId){
      if (isRecurring) {
        this.props.history.push(`/members/onboarding/recurring?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
      } else {
        this.props.history.push(`payment?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
      }
    }
    else{
      // console.log(" beforeSubmit ", newValues)
      newValues.step = 6;
    }
  };

  onUserUpdated = isRecurring => {
    if (isRecurring) {
      this.props.history.push("/members/onboarding/recurring");
    } else {
      this.props.history.push("payment");
    }
  };

  render() {
    const { history } = this.props;
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={6}
        containerStyle={{}}
        checkInStatus={false}
      >
        <h2>Dog Id : {this.state.dogId}</h2>
        <PackageForm
          dogId={this.state.dogId}
          onCancel={this.onCancel}
          beforeSubmit={this.beforeSubmit}
          onUserUpdated={this.onUserUpdated}
        />
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

Packages = reduxForm({
  form: "Packages"
})(Packages);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Packages);
