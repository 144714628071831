import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_DETAILS, DOG_DETAILS } from "../../../store/queries.js";
import { USER_UPDATE, DOG_UPDATE } from "../../../store/mutations.js";
import { get as ObjectGet } from "lodash";
import {
  OnboardingHeader,
  LabeledTextBox,
  Button,
  LoadingIndicator,
  CheckBox
} from "../../../globalComponents/";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class BillingOptions extends Component {
  // state = {
  //   initialized: false,
  //   chargeOnCheckIn: true
  // };

  constructor(props) {
    super(props)

    const { dogId, isRecurring, packageId } = queryString.parse(props.location.search);
    console.log({ dogId, isRecurring, packageId })

    this.state = {
      initialized: false,
      chargeOnCheckIn: true,
      dogId,
      isRecurring,
      packageId
    }
  }

  componentDidMount() {
    this.props.change("dogId", this.state.dogId)
    this.props.change("packageId", this.state.packageId)
  }

  getId() {
    const {
      global: { currentUser }
    } = this.props;
    if (currentUser.selectedUser) {
      return currentUser.selectedUser;
    } else {
      return currentUser.id;
    }
  }

  initializeForm(data) {
    let chargeOnDate = null;
    if (this.state.dogId) {
      // const { dogDetail: { chargeOnDate } } = data
      chargeOnDate = ObjectGet("dogDetail.chargeOnDate", null)
    }
    else {
      // const { userDetail: { chargeOnDate } } = data
      chargeOnDate = ObjectGet("userDetail.chargeOnDate", null)
    }
    console.info("chargeOnDate: ", chargeOnDate)
    const { change } = this.props;
    setTimeout(() => {
      change("chargeOnDate", chargeOnDate);
      let newState = { initialized: true }
      if (chargeOnDate) {
        newState.chargeOnCheckIn = false;
      }
      this.setState(newState);
    }, 1);
  }

  toggleChargeOnCheckIn = () => {
    const { change } = this.props;
    this.setState({ chargeOnCheckIn: !this.state.chargeOnCheckIn });
    change("chargeOnDate", "");
  };

  render() {
    const {
      dimensions: { height, width }
    } = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const { handleSubmit, history } = this.props;
    const { initialized, chargeOnCheckIn } = this.state;

    return (
      <Query
        fetchPolicy="no-cache"
        query={this.state.dogId ? DOG_DETAILS : USER_DETAILS}
        variables={{ id: this.state.dogId ? this.state.dogId : this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return <LoadingIndicator {...{ width, height, loading }} />;
          if (error) return `Error: ${error.message}`;
          if (!initialized) {
            this.initializeForm(data);
          }
          return (
            <OnboardingHeader
              back={() => history.push("payment")}
              progress={false}
              handleSubmit={handleSubmit}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                marginTop: "5%"
              }}
              checkInStatus={false}
            >
              <div
                style={{
                  marginBottom: "2%",
                  textAlign: "center",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getHeaderFont(width),
                  color: Theme.HEADER_FONT_COLOR
                }}
              >
                Billing Options
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: Theme.DARK_TEXT_COLOR,
                  fontSize: Theme.getSmallFont(width)
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginTop: "2%",
                    marginBottom: 20,
                    flexDirection: "column"
                  }}
                >
                  <label htmlFor="charge-on-checkin">
                    <input
                      id="charge-on-checkin"
                      type="checkbox"
                      name="chargeOnCheckIn"
                      checked={chargeOnCheckIn}
                      onChange={this.toggleChargeOnCheckIn}
                    />
                    Charge on check in
                  </label>

                  {!chargeOnCheckIn && (
                    <LabeledTextBox
                      containerStyle={{
                        marginTop: '0.5em',
                        marginLeft: '2em'
                      }}
                      name="chargeOnDate"
                      type="date"
                      widthFactor={10}
                      width={width}
                      height={height}
                      label="Charge on Date"
                      widthpercent={"100%"}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                  marginBottom: "10%",
                  width: Theme.getLongInputWidth(width) * 1.07
                }}
              >
                <Button
                  containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
                  text="Skip"
                  alt={true}
                  width={width}
                  onClick={() => history.push("/staff/member")}
                />
                <Button
                  type="button"
                  containerStyle={{ marginLeft: "0.5em", marginRight: "0.5em" }}
                  text="Save"
                  width={width}
                  onClick={handleSubmit}
                />
              </div>
            </OnboardingHeader>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

let submittingForm = false;
const formSubmit = (values, dispatch, props) => {
  console.log(" formSubmit ", values, props)
  if (submittingForm) return;
  
  const {
    global: { currentUser },
    history
  } = props;

  if (values.dogId) {
    submittingForm = true;


    let newValues = {
      id: values.dogId,
      chargeOnDate: values.chargeOnDate || null
    };

    props.client
      .mutate({ mutation: DOG_UPDATE, variables: { input: newValues } })
      .then(({ data: { updateDog } }) => {
        history.push("/staff/member");
      })
      .catch(error => console.error("Error: ", error))
      .finally(() => (submittingForm = false));

    return true;
  }
  else {
    submittingForm = true;

    let newValues = {
      id: currentUser.selectedUser || currentUser.id,
      chargeOnDate: values.chargeOnDate || null
    };

    props.client
      .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
      .then(({ data: { updateUser } }) => {
        history.push("/staff/member");
      })
      .catch(error => console.error("Error: ", error))
      .finally(() => (submittingForm = false));

    return true;
  }

};

BillingOptions = reduxForm({
  form: "BillingOptions",
  onSubmit: formSubmit,
  onSubmitFail: e => {
    console.error(e);
  }
})(BillingOptions);

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(BillingOptions);
