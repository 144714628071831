import React, { Component, Fragment } from "react";
import { withApollo, Query, compose } from "react-apollo";
import { PACKAGES } from "../../store/queries.js";
import { updateUser } from "../../store/redux/actions";

import { SelectionCard, LoadingIndicator, Select } from "../";
import cookie from "js-cookie"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const planOrder = ["Daily", "Monthly", "Month to Month", "Yearly"];
function sortPlans(plans) {
  return plans.sort((a, b) => {
    let i1 = planOrder.indexOf(a.name),
      i2 = planOrder.indexOf(b.name);
    if (i1 < 0) i1 = plans.length;
    if (i2 < 0) i2 = plans.length;
    return i1 - i2;
  });
}

function rejectHidden(plans) {
  return plans.filter(p => !p.hidden);
}
function acceptHidden(plans) {
  return plans.filter(p => p.hidden);
}

class PackageSelector extends Component {
  state = {
    selected: null
  };

  selectPlan = plan => {
    this.props.onChange(plan);
    this.setState({ selected: plan.id });
  };

  render() {
    const { selected } = this.state;
    const { width, height, hiddenPackages, dogId } = this.props;

    console.log("Package selector", dogId);
    let variables = {
      showHidden: !!hiddenPackages,
      dogId: dogId
    };
    return (
      <Fragment>
        <Query fetchPolicy="no-cache" query={PACKAGES} variables={variables}>
          {({ loading, error, data, refetch }) => {
            if (loading) {
              return <LoadingIndicator {...{ width, height, loading }} />;
            } else  if (error) {
              if (
                error?.message ===
                "GraphQL error: You are not authorized to access that resource"
              ) {
                cookie.remove("token");
                this.props.updateUser("reset");
                // localStorage.clear()
                this.props.history.push("/login");
              }
              return `Error: ${error?.message}`;
            } else if (data?.packages) {
              const customerPlans = sortPlans(rejectHidden(data?.packages));
              const hiddenPlans = acceptHidden(data?.packages);
              const selectedHiddenPlan = hiddenPlans.find(
                p => p.id === selected
              );
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    maxWidth: "780px",
                    margin: "3em auto"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      flexWrap: "wrap"
                    }}
                  >
                    {customerPlans.map((pack, index) => (
                      <SelectionCard
                        key={index}
                        height={height}
                        width={width}
                        recurence={pack.name}
                        price={pack.amountString}
                        description={pack.description}
                        selected={selected === pack.id}
                        onClick={() => this.selectPlan(pack)}
                      />
                    ))}
                  </div>
                  {hiddenPackages && hiddenPlans.length > 0 && (
                    <label
                      htmlFor="hidden-plans"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Other Plans:
                      <Select
                        containerStyle={{ margin: 5 }}
                        options={hiddenPlans}
                        handleSelect={this.selectPlan}
                        name="hiddenPlan"
                        selected={
                          (selectedHiddenPlan && selectedHiddenPlan.name) || ""
                        }
                        width={300}
                        height={height / 25}
                      />
                    </label>
                  )}
                </div>
              );
            }
          }}
        </Query>
      </Fragment>
    );
  }
}

const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  withRouter,
  connect(null, mapDispatchToProps)
)(PackageSelector);

// export default withApollo(PackageSelector);
