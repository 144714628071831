import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm } from "redux-form";
import { ADD_DOGS, USER_UPDATE } from "../../../store/mutations.js";
import { USER_DETAILS } from "../../../store/queries.js";
import { PulseLoader } from "react-spinners";
import { updateUser } from "../../../store/redux/actions";

import Bg from "../../../assets/fetch-BG.svg";
import {
  OnboardingHeader,
  LabeledTextBox,
  PictureUpload,
  Button,
  PawIndicator,
  DatePickerBox
} from "../../../globalComponents/";
import { updateDogsInfo } from "../../../utils/dog.js";
import BirthCheckBox from "../../../globalComponents/FetchComponents/BirthCheckBox.jsx";
import cookie from "js-cookie"

const options = ["M", "F"];
class DogInfo extends Component {
  state = {
    pictures: [null, null, null],
    dogs: [],
    initialize: true,
    add0: true,
    add1: false,
    add2: false,
    index: 0,
    validate: null,
    birthStatus: false,
    neutereds: [false, false, false]
  };

  // handlePhoto = (v, i) => {
  //   this.props.change(`renderParkDetailsBoxes${i}`, v)(
  //     i === 1
  //       ? this.setState({ add1: true })
  //       : i === 2
  //       ? this.setState({ add2: true })
  //       : null
  //   );
  // };

  getDogs(dogs) {
    var temp = {};
    for (var i = 0; i < dogs.length; i++) {
      for (var j = 1; j < dogs.length; j++) {
        if (dogs[i].id > dogs[j].id) {
          temp = dogs[j];
          dogs[j] = dogs[i];
          dogs[i] = temp;
        }
      }
    }
    return dogs;
  }
  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }
  next = (index) => {
    this.setState({
      index,
    })
  }
  prev = (index) => {
    this.setState({ index })
  }
  setIndexNeutered = (index, status) => {
    let newArr = this.state.neutereds
    newArr[index] = status
    this.setState({ neutereds: newArr })
  }

  render() {
    const { pictures, initialize, index, birthStatus, neutereds } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const verySmall = width < 568;
    const { handleSubmit, change, history } = this.props;

    // const setStatus = () => {
    //   const validate = this.state[`add${index}`] ? ["required"] : null;
    //   console.log("Inner Index ***************", index, "[Validate **************]", validate);
    //   console.log("Index valduate", index, "----->", this.state[`add${index}`]);
    //   this.setState({})
    // }
    const renderDog = i => {
      const validate = this.state[`add${i}`] ? ["required"] : null;
      console.log("Inner Index ***************", i, "[Validate **************]", neutereds);
      // console.log("Index valduate", validate);
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderBottom: "2px solid gray",
            marginBottom: 20
          }}
        >
          <PictureUpload
            width={width}
            containerStyle={{ marginRight: "3%", display: "flex" }}
            name={`picture${i}`}
            picture={pictures[i]}
            onUpload={(v, pic) => {
              change(`profilePicture${i}`, v);
              if (i === 1) this.setState({ add1: true });
              else if (i === 2) this.setState({ add2: true });
            }}
            height={height}
            label="Picture"
            verticalCenter={true}
          />
          <div
            style={{
              display: "flex",
              marginTop: "2%",
              marginBottom: 20,
              flexDirection: "column"
            }}
          >
            <LabeledTextBox
              name={`name${i}`}
              width={width}
              widthFactor={1.8}
              height={height}
              label="Dog Name"
              validate={validate}
              widthpercent={"100%"}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: "65%" }}>
                <LabeledTextBox
                  name={`color${i}`}
                  validate={validate}
                  widthFactor={3.8}
                  width={width}
                  height={height}
                  label="Color"
                  widthpercent={"100%"}
                />
              </div>
              <div style={{ width: "25%" }}>
                <LabeledTextBox
                  name={`sex${i}`}
                  options={options}
                  validate={validate}
                  widthFactor={7}
                  width={width}
                  height={height}
                  label="Sex"
                  widthpercent={"100%"}
                />
              </div>
            </div>
            <LabeledTextBox
              name={`breed${i}`}
              width={width}
              widthFactor={1.8}
              height={height}
              label="Dog Breed"
              validate={validate}
              widthpercent={"100%"}
            />
            {/* ************************************************************************************************ */}
            <BirthCheckBox
              isNeuteredStatus={neutereds[i]}
              name={`isNeutered${i}`}
              change={change}
              index={i}
              setIndexNeutered={this.setIndexNeutered}
            />
            {/* ************************************************************************************************ */}

            <div style={{ width: "100%" }}>
              {/* <LabeledTextBox
                name={`birthdate${i}`}
                type="date"
                validate={validate}
                widthFactor={10}
                width={width}
                height={height}
                label="Birth Date"
                widthpercent={"100%"}
              /> */}

              <DatePickerBox
                name={`birthdate${i}`}
                type="date"
                validate={validate}
                widthFactor={10}
                width={width}
                height={height}
                label="Birth Date"
                widthpercent={"100%"}
                placeholder="MM/DD/YYYY"
                change={change}
                dateType="birthDate"


              />
            </div>
          </div>
        </div>
      );
    };
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <div
                name="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: width,
                  minHeight: height,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`
                }}
              >
                <PulseLoader
                  style={{ margin: "auto" }}
                  size={width / 10}
                  color={Theme.LINK_FONT_COLOR}
                  loading={loading}
                />
              </div>
            );
          // if (error) return `Error: ${error.message}`;
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          if (initialize) {
            data.userDetail?.dogs&&  data.userDetail.dogs.map((dog, index) => {
              console.log("---------------- Do info ------------------", dog);
              change(`name${index}`, dog.name);
              change(`sex${index}`, dog.sex === "MALE" ? "M" : "F");
              change(`breed${index}`, dog.breed);
              change(`gender${index}`, dog.gender);
              change(`birthdate${index}`, dog.birthdate);
              change(`color${index}`, dog.color);
              change(`id${index}`, parseInt(dog.id));
              change(`isNeutered${index}`, dog.isNeutered)
              var updatedStatus = this.state.neutereds
              updatedStatus[index] = dog.isNeutered ? dog.isNeutered : false;
              this.setState({ neutereds: updatedStatus })
              var newPics = this.state.pictures;
              // newPics[index] = dog.profilePicture.url
              //   ? process.env.REACT_APP_API_ENDPOINT + dog.profilePicture.url
              //   : null;
              newPics[index] = dog.profilePictureCdn?
              // ? process.env.REACT_APP_API_ENDPOINT + dog.profilePicture.url
              dog.profilePictureCdn
              : null;
              this.setState({ pictures: newPics });
            });
            this.setState({ initialize: false });
          }
          return (
            <OnboardingHeader
              back={() =>
                this.props.global.currentUser.type === "Employee"
                  ? this.props.history.push("/dashboard")
                  : this.props.global.currentUser.type === "Manager" ||
                    this.props.global.currentUser.type === "Admin"
                    ? this.props.history.push("/manager/members")
                    : this.props.history.push("/")
              }
              progress={4}
              handleSubmit={handleSubmit}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
                marginTop: "5%"
              }}
              checkInStatus={false}
            >
              <div
                style={{
                  marginBottom: "2%",
                  textAlign: "center",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getHeaderFont(width),
                  color: Theme.HEADER_FONT_COLOR
                }}
              >
                Dog Info {index + 1}
              </div>
              {/* <Button
                containerStyle={{ marginLeft: "auto" }}
                text={index <= 1 ? "Next" : " Save and Continue"}
                width={width}
                onClick={() => {
                  (index === 2
                    ?
                    handleSubmit()
                    :
                    this.next(index + 1))
                }}
              /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: Theme.DARK_TEXT_COLOR,
                  fontSize: Theme.getSmallFont(width)
                }}
              >
                {renderDog(index)}
                {/* {renderDog(0)}
                {renderDog(1)}
                {renderDog(2)} */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                  marginBottom: "10%",
                  width: verySmall ? "100%" : Theme.getLongInputWidth(width) * 1.07
                }}
              >
                <Button
                  text={index === 0 ? "Back" : "Prev"}
                  alt={true}
                  // width={width}
                  width={verySmall ? "fit-content" : width}
                  // onClick={() => this.props.history.push(history.location.state.from || "/login")}
                  onClick={() => (index === 0 ? this.props.history.push(history.location?.state?.from || "/login") : this.prev(index - 1))}
                />
                {index <= 1 && <Button
                  containerStyle={{ marginLeft: "auto" }}
                  text="Add Another Dog"
                  // width={width}
                  width={verySmall ? "fit-content" : width}
                  onClick={() => {
                    this.next(index + 1)
                  }}
                />}
                <Button
                  containerStyle={{ marginLeft: "auto" }}
                  text="Done Adding Dogs"
                  // width={width}
                  width={verySmall ? "fit-content" : width}
                  onClick={() => {
                    handleSubmit();
                  }}
                  type="submit"
                />
                {/* <Button
                  containerStyle={{ marginLeft: "auto" }}
                  text={index <= 1 ? "Next" : " Save and Continue"}
                  width={width}
                  onClick={() => {
                    (index === 2
                      ?
                      handleSubmit()
                      :
                      this.next(index + 1))
                  }}
                // type={index <= 1 ? "" : "submit"}
                /> */}
              </div>
            </OnboardingHeader>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

let submittingForm = false;
const formSubmit = (values, dispatch, props) => {
  if (submittingForm) return;
  submittingForm = true;

  var dogs = [];
  var update = [];
  for (var i = 0; i < 3; i++) {
    if (values[`id${i}`]) {
      update.push({
        id: parseInt(values[`id${i}`]),
        name: values[`name${i}`],
        birthdate: parseInt(values[`birthdate${i}`]),
        sex: values[`sex${i}`] === "M" ? "MALE" : "FEMALE",
        isNeutered: values[`isNeutered${i}`],
        breed: values[`breed${i}`],
      });
    } else if (values[`name${i}`]) {
      dogs.push({
        userId: parseInt(
          props.global.currentUser.selectedUser || props.global.currentUser.id
        ),
        profilePicture: values[`profilePicture${i}`],
        name: values[`name${i}`],
        birthdate: values[`birthdate${i}`],
        sex: values[`sex${i}`] === "M" ? "MALE" : "FEMALE",
        color: values[`color${i}`],
        breed: values[`breed${i}`],
        isNeutered: values[`isNeutered${i}`]
      });
    }
  }
  console.log(update);

  let promise = Promise.resolve();
  if (update.length > 0) {
    promise = promise.then(() => updateDogsInfo(values, props.client, null));
  }
  if (dogs.length > 0) {
    promise = promise
      .then(() =>
        props.client.mutate({ mutation: ADD_DOGS, variables: { input: dogs } })
      )
      .then(data => {
        console.log("Response", data);
        if (data.errors && data.errors.length > 0) {
          return Promise.reject(data.errors);
        } else {
          return Promise.resolve(data);
        }
      });
  }
  let userId = props.global.currentUser.selected || props.global.currentUser?.id;
  promise
    .then(() => {
      // Add for steps Update start 
      props.client
        .mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 4 } } })
        .then(({ data: { updateUser } }) => {
          // console.log("response", updateUser);
          // props.history.push("dogInfo");
          props.history.push({
            pathname: 'vaccinations',
            state: { from: props.location.pathname }
          })
        })
        .catch(error => {
          console.error("Error: ", error);
        });
      // Add for steps Update End 
      // props.history.push("vaccinations")
    })
    .catch(error => console.error("Error: ", error))
    .finally(() => (submittingForm = false));

  return true;
};

DogInfo = reduxForm({
  form: "DogInfo",
  onSubmit: formSubmit,
  onSubmitFail: e => {
    console.error(e);
  }
})(DogInfo);
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(DogInfo);
