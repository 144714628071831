import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { compose, withApollo, Query } from "react-apollo";
import Clock from "../../assets/hour-arrow.svg";
import Mobile from "../../assets/mobile.svg";
import Desktop from "../../assets/web.svg";
import DogPlaceholder from "../../assets/dog_placeholder.png";
import UserPlaceholder from "../../assets/placeholder.jpeg";
import HomeIcon from "../../assets/home.svg";
import OutsideHomeIcon from "../../assets/outsideHome.svg";
import fakJson from "../../utils/fakeJson.json";
import Theme from "../../globalComponents/Theme.js";
import { useQuery } from "@apollo/client";
import { ACTIVITY_FEED } from "../../store/queries";
import ConnectActionCable from "../Connection";
// import { updateUser } from "../../store/redux/actions.js";
// import { ACTIVITY_FEED } from "../../store/queries";
// import { CheckInContext } from "../Connection";
const width = window.innerWidth;
const shrink = width > 991;

const styles = {
  web: {
    display: "flex",
    flexDirection: "column",
    marginLeft: shrink ? "auto" : "0px",
    // width: "30%",
    width: shrink ? "30%" : "100%",
    // minWidth: 300,
    border: "1px solid",
    borderColor: Theme.LINK_FONT_COLOR,
    borderRadius: 10,
    marginTop: "0px",
    height: `${window.innerHeight > window.innerWidth ? 70 : 65}vh`,
    overflowY: "scroll",
  },
};
function uniqueUserCount(events) {
  return events
    .map(({ user: { id } }) => id)
    .filter((id, index, self) => self.indexOf(id) === index).length;
}

const FromNowTimer = function (timestamp) {
  const [count, setCounter] = useState(0);
  const ts = timestamp.timestamp;

  useEffect(() => {
    let interval = setInterval(() => {
      setCounter((count) => count + 1);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [count]);
  return <span>{ts.fromNow()}</span>;
};

const CheckInList = ({
  checkInResponse,
  loadMoreData,
  checkInList,
  checkInpage,
}) => {
  const handleStatusCheckIn = (type) => {
    let status = {
      alt: "clock",
      src: Clock,
    };
    switch (type) {
      case "mobile":
        status = {
          alt: "Mobile",
          src: Mobile,
        };
        break;
      case "web":
        status = {
          alt: "Web",
          src: Desktop,
        };
        break;
      default:
        break;
    }
    return status;
  };

  const handleVaccineExpiredStatus = (vaccineStatus, checkinStatus) => {
    let status = {
      border: "#e0fffc00",
      background: "1px solid #7c867c",
    };
    if (checkinStatus === "completed") {
      //Add  check
      status.background = "#00f6196b";
      status.border = "1px solid green";
    } else if (
      vaccineStatus === "expiring_soon" &&
      checkinStatus === "completed"
    ) {
      status.border = "1px solid #bfae0d";
      status.background = "#fac81275";
    } else if (vaccineStatus === "expired" && checkinStatus === "completed") {
      status.border = "1px solid #b12443";
      status.background = "#e9101052";
    } else if (
      vaccineStatus === "vaccinated" &&
      checkinStatus === "completed"
    ) {
      status.background = "#00f6196b";
      status.border = "1px solid green";
    } else if (checkinStatus === "failed") {
      //Add  check
      status.border = "1px solid #b12443";
      status.background = "#e9101052";
    }
    return status;
  };

  //   const { loading, error, data } = useQuery(ACTIVITY_FEED, {
  //     variables: {
  //       parkId: 1,
  //       // parkId: this.state.parkId,
  //       // this.props.global.currentUser.park &&
  //       // this.props.global.currentUser.park.id,
  //       type: "check_in",
  //       start: moment().subtract(12, "hours").toISOString(),
  //       end: moment().toISOString(),
  //     },
  //   });
  //   if (loading) return <p>Loading ...</p>;
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <div style={{ display: "flex", margin: "0 0 0% 2%" }}>
        {checkInList.map((event, index) => {
          let status = {
            border: handleVaccineExpiredStatus(
              event.checkedInDog.vaccineStatus,
              event.checkinStatus
            ).border,
            background: handleVaccineExpiredStatus(
              event.checkedInDog.vaccineStatus,
              event.checkinStatus
            ).background,
          };
          return (
            <div
              key={index}
              style={{
                paddingLeft: "1.3%",
                borderRadius: "12px",
                minWidth: "363px",
                marginBottom: "1%",
                marginRight: "1%",

                ...status,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                  marginBottom: "0px",
                  width: "100%",
                  minHeight: 52,
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    // width: "45px",
                    width: "40px",
                    flexDirection: "column",
                    overflow: "hidden",
                    borderRadius: "5px",
                    height: "40px",
                    marginLeft: "3px",
                  }}
                >
                  <img
                    // key={data.id}
                    alt={handleStatusCheckIn(event?.checkedInApp).alt}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={handleStatusCheckIn(event?.checkedInApp).src}
                    onError={(event) => event.target.setAttribute("src", Clock)}
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    // width: "45px",
                    width: "40px",
                    flexDirection: "column",
                    overflow: "hidden",
                    borderRadius: "5px",
                    height: "40px",
                    marginLeft: "3px",
                  }}
                >
                  <img
                    // key={data.id}
                    alt={event.user.profilePicture.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={
                      (fakJson.data.fetchActivityList &&
                        // process.env.REACT_APP_API_ENDPOINT +
                          // event.user.profilePicture.url) ||
                          event.user.profilePictureCdn) ||
                      UserPlaceholder
                    }
                    onError={(event) =>
                      event.target.setAttribute("src", UserPlaceholder)
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 5,
                    marginTop: "0px",
                    marginBottom: "0px",
                    cursor: "pointer",
                  }}
                >
                  {/* TimeStamp Section */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: Theme.LINK_FONT_COLOR,
                        fontFamily: Theme.INPUT_FONT,
                        fontSize: Theme.getNormalFont(width),
                        marginRight: 4,
                      }}
                    >
                      {"In: " + moment(event.timestamp).format("LT") + " | "}
                      <FromNowTimer timestamp={moment(event.timestamp)} />
                    </div>
                  </div>
                  {/* User Name Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontSize: Theme.getNormalFont(width),
                        color: Theme.DARK_TEXT_COLOR,
                        fontFamily: Theme.HEADER_FONT,
                      }}
                    >
                      {event.user.firstName + " " + event.user.lastName}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "0px",
                  marginBottom: "10px",
                  width: "100%",
                  minHeight: 42,
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    // width: "45px",
                    width: "40px",
                    flexDirection: "column",
                    overflow: "hidden",
                    borderRadius: "5px",
                    height: "40px",
                    marginLeft: "3px",
                  }}
                >
                  <img
                    // key={data.id}
                    alt={event.isHomeParkCheckin ? "home" : "outsideHome"}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={event.isHomeParkCheckin ? HomeIcon : OutsideHomeIcon}
                    onError={(event) => event.target.setAttribute("src", Clock)}
                  />
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    // width: "45px",
                    width: "40px",
                    flexDirection: "column",
                    overflow: "hidden",
                    borderRadius: "5px",
                    height: "40px",
                    marginLeft: "46px",
                  }}
                >
                  <img
                    alt="clock"
                    src={
                      (fakJson.data.fetchActivityList &&
                        // process.env.REACT_APP_API_ENDPOINT +
                          // event.checkedInDog.profilePicture.url
                          event.checkedInDog.profilePictureCdn) ||
                      DogPlaceholder
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onError={(event) =>
                      event.target.setAttribute("src", DogPlaceholder)
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 5,
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontSize: Theme.getNormalFont(width),
                        color: Theme.DARK_TEXT_COLOR,
                        fontFamily: Theme.HEADER_FONT,
                      }}
                    >
                      {event.checkedInDog.name}
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "0px",
                  marginBottom: "10px",
                  width: "100%",
                  minHeight: 42,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 5,
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        fontSize: Theme.getNormalFont(width),
                        color: Theme.DARK_TEXT_COLOR,
                        fontFamily: Theme.HEADER_FONT,
                        width: "300px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "40px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "12px",
                        }}
                      >
                        {event.checkinStatus === "failed" &&
                          event.checkinMessage}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* ----See More Sec----- */}
        {checkInResponse.data?.fetchActivityList.totalPages > checkInpage && (
          <div
            style={{
              paddingLeft: "1.3%",
              borderRadius: "12px",
              minWidth: "363px",
              marginBottom: "1%",
              marginRight: "1%",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgb(255 125 80 / 11%)",
              border: "1px solid #ff7d50",
              fontSize: "18px",
              color: "rgb(255, 125, 80)",
              fontFamily: "LatoBold",
              cursor: "pointer",
            }}
            onClick={loadMoreData}
          >
            See More
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckInList;
