import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { OnboardingHeader, PackageForm } from "../../../globalComponents/";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { USER_DETAILS, USER_DOG_DETAILS } from "../../../store/queries";
import { USER_UPDATE } from "../../../store/mutations";
import cookie from "js-cookie"
import { updateUser } from "../../../store/redux/actions";

class Packages extends Component {
  constructor(props) {
    super(props)
    const { dogId, onboardingDogIdx, allDogIds, forUserId } = queryString.parse(props.location.search);
    this.state = {
      dogId,
      onboardingDogIdx,
      currentDogData: null,
      allDogIds,
      forUserId,
      currentUserDogs: [],
      historyState: {}
    }
  }

  onCancel = () => {
    // this.props.history.push("vaccinations");
    console.log("---HIt-----", this.state.historyState);
    this.props.history.push(this.props.history.location.state?.from + this.props.history.location?.search || "/login")

  };
  static getDerivedStateFromProps(props, state) {
    const { dogId, onboardingDogIdx, allDogIds, forUserId } = queryString.parse(props.location.search);
    console.log("-------------->", onboardingDogIdx);
    if (onboardingDogIdx !== state.onboardingDogIdx) {
      console.log("Hit", queryString.parse(props.location.search));
      return {
        onboardingDogIdx

      }
     
    }
    // if(state.historyState !== props.history){
    //   console.log("Previ-------------------", state.historyState);
    //   console.log("-------------------------------", props.history);
    // }

    return null
  }
  componentDidUpdate(prevProps, prevState) {
    const { dogId, onboardingDogIdx, allDogIds, forUserId } = queryString.parse(this.props.location.search);
    if (onboardingDogIdx > 0 && onboardingDogIdx !== prevState.onboardingDogIdx) {
      console.log(onboardingDogIdx, "Did Update Hit ----------------->", prevState.onboardingDogIdx);
      this.getCurrentUserDogs()
      console.log("Histor********************", this.props.history);
      this.setState({
        historyState: this.props.history
      })
      // scop.getCurrentUserDogs()
    }
  }
  beforeSubmit = newValues => {
    console.log(" beforeSubmit ", newValues)
    const { dogId, packageId, isRecurring } = newValues
    if (dogId && packageId) {
      if (isRecurring) {
        // this.props.history.push(`/members/onboarding/recurring?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
        this.props.history.push(
          `/members/onboarding/recurring?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`,
          { from: this.props.location.pathname }
        );
      } else {
        // this.props.history.push(`payment?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`);
        this.props.history.push(
          `payment?dogId=${dogId}&packageId=${packageId}&isRecurring=${isRecurring}`,
          { from: this.props.location.pathname }
        );
      }
    }
    else {
      // console.log(" beforeSubmit ", newValues)
      newValues.step = 6;
    }
  };

  onUserUpdated = isRecurring => {
    // executes when selected package for user or dog package has updated in db
    const { client, global } = this.props;

    const queryObj = {
      onboardingDogIdx: this.state.onboardingDogIdx,
      forUserId: this.state.forUserId,
      allDogIds: this.state.allDogIds,
      dogId: this.state.dogId
    }
    let userId = global.currentUser.selected || global.currentUser?.id;
    if (isRecurring) {
      if (
        this.state.onboardingDogIdx != undefined
        && this.state.onboardingDogIdx >= 0
      ) {
        // for onboarding we have to loop through all the dogs to selet packages
        const currentIndex = Number(this.state.onboardingDogIdx)
        if (currentIndex == this.state.currentUserDogs.length - 1) {
          // we have finished iterating all dogs of the user
          // this.props.history.push("confirmAccount");
          console.log(" ******************** Final HIt *************************************");
          const tempDogArr = []

          if (this.state.forUserId) {
            const values = {
              id: Number(this.state.forUserId)
            }
            client
              .query({ query: USER_DOG_DETAILS, variables: values })
              .then(response => {
                const { data: { userDetail: { dogs } } } = response
                // console.log("All Dogs :", dogs)
                // console.log("onboardingDogIdx :", this.state.onboardingDogIdx)
                if (
                  this.state.onboardingDogIdx != undefined
                  && this.state.onboardingDogIdx >= 0
                  && dogs[this.state.onboardingDogIdx]
                ) {

                  // console.log("Current Dog Data: ", dogs)
                  for (let index = 0; index < dogs.length; index++) {
                    // console.log("LOoop", dogs[index].package.id);
                    const element = dogs[index].package.id;
                    tempDogArr.push(element)
                  }
                  // console.log("------------->", tempDogArr, tempDogArr.join(","));
                  const queryObjFinal = {
                    onboardingDogIdx: this.state.onboardingDogIdx,
                    forUserId: this.state.forUserId,
                    allDogIds: this.state.allDogIds,
                    dogId: this.state.dogId,
                    packageIds: tempDogArr.join(",")

                  }
                  // Add for steps Update start 
                  // client
                  //   .mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 6 } } })
                  //   .then(({ data: { updateUser } }) => {
                  // console.log("response", updateUser);
                  // props.history.push("dogInfo");
                  // props.history.push("vaccinations")
                  // this.props.history.push(`/members/onboarding/recurring?${queryString.stringify(queryParamObj)}`);
                  // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
                  this.props.history.push(
                    `payment?${queryString.stringify(queryObjFinal)}`,
                    { from: this.props.location.pathname }
                  );
                  // })
                  // .catch(error => {
                  //   console.error("Error: ", error);
                  // });
                  // Add for steps Update End 
                  // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
                }
              })
              .catch(error => {
                console.error("Error: ", error);
                return false;
              })
          }

          // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
        }
        else {
          console.log(" ******************** Procces running*************************************");

          const nextOnboardingDogIdx = Number(this.state.onboardingDogIdx) + 1
          const queryParamObj = {
            onboardingDogIdx: nextOnboardingDogIdx,
            forUserId: this.state.forUserId,
            final: false,
            allDogIds: this.state.allDogIds,
            dogId: this.state.dogId,
          }
          console.log("Next Dog Dteail ********************************>", this.props.history);
          // this.props.history.push(`/members/onboarding/packages?${queryString.stringify(queryParamObj)}`);
          // Add for steps Update start 
          // client
          //   .mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 6 } } })
          //   .then(({ data: { updateUser } }) => {
          //     // console.log("response", updateUser);
          //     // props.history.push("dogInfo");
          //     // props.history.push("vaccinations")
          this.props.history.push(
            `/members/onboarding/recurring?${queryString.stringify(queryParamObj)}`,
            { from: this.props.location.pathname }
          );
          // this.props.history.push(`/members/onboarding/recurring?${queryString.stringify(queryParamObj)}`);
          //   })
          //   .catch(error => {
          //     console.error("Error: ", error);
          //   });
          // Add for steps Update End 
          // this.props.history.push(`/members/onboarding/recurring?${queryString.stringify(queryParamObj)}`);

        }
      }

    } else {

      if (
        this.state.onboardingDogIdx != undefined
        && this.state.onboardingDogIdx >= 0
      ) {
        // for onboarding we have to loop through all the dogs to selet packages
        // this.getCurrentUserDogs()
        const currentIndex = Number(this.state.onboardingDogIdx)
        if (currentIndex == this.state.currentUserDogs.length - 1) {
          // we have finished iterating all dogs of the user
          // this.props.history.push("confirmAccount");
          console.log(" ******************** Final HIt ELse *************************************", this.state.currentUserDogs);
          const tempDogArr = []
          if (this.state.forUserId) {
            const values = {
              id: Number(this.state.forUserId)
            }
            client
              .query({ query: USER_DOG_DETAILS, variables: values })
              .then(response => {
                const { data: { userDetail: { dogs } } } = response

                if (
                  this.state.onboardingDogIdx != undefined
                  && this.state.onboardingDogIdx >= 0
                  && dogs[this.state.onboardingDogIdx]
                ) {

                  for (let index = 0; index < dogs.length; index++) {
                    const element = dogs[index].package.id;
                    tempDogArr.push(element)
                  }
                  const queryObjFinal = {
                    onboardingDogIdx: this.state.onboardingDogIdx,
                    forUserId: this.state.forUserId,
                    allDogIds: this.state.allDogIds,
                    dogId: this.state.dogId,
                    packageIds: tempDogArr.join(",")

                  }

                  // Add for steps Update start 
                  // client
                  //   .mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 6 } } })
                  //   .then(({ data: { updateUser } }) => {
                  // console.log("response", updateUser);
                  // props.history.push("dogInfo");
                  // props.history.push("vaccinations")
                  // this.props.history.push(`/members/onboarding/recurring?${queryString.stringify(queryParamObj)}`);
                  // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
                  // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
                  this.props.history.push(
                    `payment?${queryString.stringify(queryObjFinal)}`,
                    { from: this.props.location.pathname }
                  );

                  // })
                  // .catch(error => {
                  //   console.error("Error: ", error);
                  // });
                  // Add for steps Update End 
                  // this.props.history.push(`payment?${queryString.stringify(queryObjFinal)}`);
                }
              })
              .catch(error => {
                console.error("Error: ", error);
                return false;
              })
          }


        }
        else {
          // console.log(" ******************** Procces running*************************************");

          const nextOnboardingDogIdx = Number(this.state.onboardingDogIdx) + 1
          const queryParamObj = {
            onboardingDogIdx: nextOnboardingDogIdx,
            forUserId: this.state.forUserId,
            allDogIds: this.state.allDogIds,
            dogId: this.state.dogId
          }
          console.log("Next Dog Dteail ********************************>", this.props.history);
          this.props.history.push(
            `/members/onboarding/packages?${queryString.stringify(queryParamObj)}`,
            { from: this.props.location.pathname }
          );
        }
      }
    }
  };

  getCurrentUserDogs() {
    const { client, global, closeModal } = this.props;
    const { currentUser } = global;
    // console.log("%c currentUser.selectedUser: ", currentUser)
    if (this.state.forUserId) {
      const values = {
        id: Number(this.state.forUserId)
      }
      client
        .query({ query: USER_DETAILS, variables: values })
        .then(response => {
          const { data: { userDetail: { dogs } } } = response
          // console.log("All Dogs :", dogs)
          // console.log("onboardingDogIdx :", this.state.onboardingDogIdx)
          if (
            this.state.onboardingDogIdx != undefined
            && this.state.onboardingDogIdx >= 0
            && dogs[this.state.onboardingDogIdx]
          ) {
            const currentDogData = dogs[this.state.onboardingDogIdx]
            this.setState({
              currentDogData,
              dogId: currentDogData.id,
              currentUserDogs: dogs
            })
            console.log("Current Dog Data: ", this.state.currentDogData)
          }
        })
        .catch(error => {
          console.error("Error: ", error);
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            // return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          return false;
        })
    }
  }

  componentDidMount() {
    this.getCurrentUserDogs()
  }

  render() {
    const { history } = this.props;
    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={6}
        containerStyle={{}}
        checkInStatus={false}
      >
        <h2>For {this.state.currentDogData ? this.state.currentDogData.name : ""}</h2>
        <PackageForm
          dogId={this.state.dogId}
          onboardingDogIdx={this.state.onboardingDogIdx}
          onCancel={this.onCancel}
          beforeSubmit={this.beforeSubmit}
          onUserUpdated={this.onUserUpdated}
        />
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

Packages = reduxForm({
  form: "Packages"
})(Packages);
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Packages);
