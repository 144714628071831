/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, Query, withApollo } from "react-apollo";
import {
  ActivityCard,
  Select,
  Button,
  TextBox,
  Theme,
  StaffNavBar,
  PictureUpload,
} from "../../../globalComponents";
import _ from "lodash";
import ArrowLeft from "../../../assets/left-arrow.svg";
import { reduxForm, formValueSelector, reset } from "redux-form";
import { CREATE_PARK, UPDATE_PARK } from "../../../store/mutations.js";
import Add from "../../../assets/plus-add.svg";
import { statesList } from "../../../utils/constant";
import { getTimeOptions } from "../../../utils/util";
import { FETCH_TIMEZONE } from "../../../store/queries";

const Days = [
  { name: "Mon" },
  { name: "Tue" },
  { name: "Wed" },
  { name: "Thu" },
  { name: "Fri" },
  { name: "Sat" },
  { name: "Sun" },
];

class NewPark extends Component {
  constructor(props) {
    super(props);
    const { location, client } = this.props;

    const parkData = location.state && location.state.parkData;
    if (parkData) {
      Object.keys(parkData).map((key) => [
        this.props.change(key, parkData[key]),
      ]);
    }
    this.state = {
      selfcheckin: location.state?.parkData?.selfCheckinEnabled,
      // timezone: null,
      timezoneName: "",
    };
  }

  handleSelect = (option, name) => {
    this.props.change(name, option.name);
  };
  handleSelectTimeZone = (option, name) => {
    this.props.change(name, option.value);
    this.setState({
      timezoneName: option.name,
    });
  };

  handleTimeChange = (option, name, index) => {
    const hours = _.cloneDeep(this.props.hours || [{}]);
    hours[index][name] = option.name;
    this.props.change("hours", hours);
  };

  removeFromHours = (index) => {
    const hours = _.cloneDeep(this.props.hours || [{}]);
    hours.splice(index, 1);
    this.props.change("hours", hours);
  };

  addToHours = () => {
    const hours = _.cloneDeep(this.props.hours || [{}]);
    hours.push({ dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" });
    this.props.change("hours", hours);
  };

  handleSelfCheckin = (event) => {
    this.props.change("selfCheckinEnabled", event.target.checked);
    this.setState({ selfcheckin: event.target.checked });
  };

  renderScreenHeader = () => {
    const { global, handleSubmit, change, state } = this.props;
    const hours = this.props.hours || [
      { dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" },
    ];
    const { height = 0, width = 1000 } = global.dimensions;
    return (
      <div
        style={{
          marginRight: "auto",
          display: "flex",
          flexDirection: "row",
          marginTop: "-5%",
          marginBottom: "1%",
          width: "100%",
          color: "white",
          alignItems: "center",
          alignContent: "center",
          fontSize: Theme.getHeaderFont(width),
          fontFamily: Theme.HEADER_FONT,
        }}
      >
        <button
          type="button"
          style={{
            outline: "none",
            backgroundColor: "transparent",
            border: "none",
            padding: 10,
            color: "white",
            fontFamily: Theme.MAIN_FONT,
            fontSize: Theme.getNormalFont(width),
            cursor: "pointer",
          }}
          onClick={() => this.props.history.goBack()}
        >
          <img
            style={{ opacity: 0.3, height: 40, marginTop: 8 }}
            alt=""
            src={ArrowLeft}
          />
        </button>
        <TextBox
          name={"name"}
          width={width / 6}
          height={width / 30}
          placeholder="Park Name"
        />
      </div>
    );
  };

  renderStatBoxes = () => {
    const { global, handleSubmit, change, state } = this.props;
    const hours = this.props.hours || [
      { dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" },
    ];
    const { height = 0, width = 1000 } = global.dimensions;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ActivityCard width={width / 3.5} stat={0} type="checkin" />
        <ActivityCard width={width / 3.5} stat={0} type="signup" />
        <ActivityCard width={width / 3.5} stat={0} type="team" />
      </div>
    );
  };

  renderParkInfoBox = () => {
    const { global, handleSubmit, change, state, timezone, timezoneFullname } =
      this.props;
    const hours = this.props.hours || [
      { dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" },
    ];
    const { height = 0, width = 1000 } = global.dimensions;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          width: "48%",
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          padding: "2% 0",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "3%",
            // margin: "0 -5% 0 -5%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          Park Information
        </div>
        <div
          style={{
            padding: "0 3% 0 3%",
          }}
        >
          <TextBox
            name={"address"}
            containerStyle={{ margin: "2% 1%" }}
            width={"90%"}
            height={height / 25}
            placeholder="Address"
          />
          <TextBox
            name={"address2"}
            width={"90%"}
            height={height / 25}
            containerStyle={{ margin: "1%" }}
            placeholder="Street Address"
          />
          <TextBox
            name={"phoneNumber"}
            width={"90%"}
            type={"number"}
            height={height / 25}
            containerStyle={{ margin: "1%" }}
            placeholder="Phone Number"
          />
          <TextBox
            name={"city"}
            width={"90%"}
            height={height / 25}
            containerStyle={{ margin: "1%" }}
            placeholder="City"
          />
          <Select
            name={"state"}
            height={height / 22}
            width={"100%"}
            options={statesList}
            selected={state}
            handleSelect={this.handleSelect}
            containerStyle={{
              margin: "1%",
              width: "95%",
            }}
            placeholder="State"
            label="State"
            alt={true}
          />{" "}
          <Query fetchPolicy="no-cache" query={FETCH_TIMEZONE}>
            {({ loading, error, data, refetch }) => {
              if (error) return `Error: ${error.message}`;
              if (loading) return null;
              if (!loading) {
                return (
                  <Select
                    name={"timezone"}
                    height={height / 22}
                    width={"100%"}
                    options={data?.fetchTimezones?.zones}
                    selected={this.state.timezoneName || timezoneFullname}
                    handleSelect={this.handleSelectTimeZone}
                    containerStyle={{
                      margin: "1%",
                      width: "95%",
                    }}
                    placeholder="Timezone"
                    label="Timezone"
                    alt={true}
                  />
                );
              }
            }}
          </Query>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <label
              style={{
                position: "relative",
                display: "flex",
                margin: "auto",
              }}
            >
              <div>Self Check-in</div>
              <input
                type="checkbox"
                style={{
                  marginLeft: "20px",
                  marginTop: "auto",
                  transform: "scale(2)",
                }}
                checked={this.state.selfcheckin}
                onChange={this.handleSelfCheckin}
              />
            </label>
          </div>
        </div>
      </div>
    );
  };

  renderManagerInfoBox = () => {
    const { global, change, generalManagerProfilePicture } = this.props;
    const { height = 0, width = 1000 } = global.dimensions;
    const { picture } = this.state;
    return (
      <div
        id="manager"
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          width: "48%",
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
        }}
      >
        <div
          id="header"
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            paddingLeft: "5%",
            paddingRight: "3%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          General Manager
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0 3%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              paddingTop: "2%",
              fontSize: Theme.getSmallFont(width),
            }}
          >
            <TextBox
              width={width / 6}
              height={height / 25}
              name={"generalManager"}
              containerStyle={{ margin: "1%" }}
              placeholder="Name"
            />
            <TextBox
              width={width / 6}
              height={height / 25}
              name={"generalManagerPhoneNumber"}
              containerStyle={{ margin: "1%" }}
              placeholder="Phone"
            />
            <TextBox
              width={width / 6}
              height={height / 25}
              name={"generalManagerEmail"}
              containerStyle={{ margin: "1%" }}
              placeholder="Email"
            />
          </div>
          <PictureUpload
            width={width / 1.2}
            containerStyle={{ display: "flex", marginTop: "1%" }}
            name={`generalManagerProfilePicture`}
            picture={
              picture ||
              (generalManagerProfilePicture &&
                process.env.REACT_APP_API_ENDPOINT +
                  generalManagerProfilePicture)
            }
            onUpload={(v, pic) => {
              this.setState({ picture: pic });
              change(`generalManagerProfilePicture`, v);
            }}
            label="Picture"
            verticalCenter={true}
          />
        </div>
      </div>
    );
  };

  renderNoData = () => {
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "80%",
          margin: "2% 0 2% 0",
          fontSize: Theme.getSmallFont(width),
        }}
      >
        No Data Available
      </div>
    );
  };

  renderStaffInformationBox = () => {
    const { location } = this.props;
    const rangerData = (location.state && location.state.rangerData) || [];
    const { height = 1000, width = 1000 } = this.props.global.dimensions;

    return (
      <div
        id="staffInfo"
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          maxHeight: 407,
          width: width / 3.5,
          paddingBottom: 6,
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          marginTop: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 40,
            borderBottom: "1px solid",
            borderColor: Theme.LIGHT_TEXT_COLOR,
            fontFamily: Theme.HEADER_FONT,
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "5%",
            paddingRight: "5%",
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
          }}
        >
          Staff Information
          <div />
          {rangerData.totalCount || 0}
        </div>
        <div
          style={{
            paddingLeft: "10%",
            overflow: "auto",
            fontFamily: Theme.HEADER_FONT,
          }}
        >
          {rangerData.edges && rangerData.edges.length !== 0
            ? rangerData.edges.map((staff, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      borderBottom:
                        index + 1 !== rangerData.length ? "1px solid" : "",
                      borderColor: "#c8cfd9",
                      paddingBottom: "5%",
                      flexDirection: "column",
                      width: "80%",
                      margin: "2% 0 2% 0",
                      fontSize: Theme.getNormalFont(width),
                    }}
                  >
                    {staff.firstName + " " + staff.lastName}
                    <div
                      style={{
                        marginTop: 5,
                        fontFamily: Theme.MAIN_FONT,
                        color: Theme.LIGHT_TEXT_COLOR,
                      }}
                    >
                      {staff.phoneNumber}
                    </div>
                  </div>
                );
              })
            : this.renderNoData()}
        </div>
      </div>
    );
  };

  renderHoursBox = () => {
    const { global, handleSubmit, change, state, timezone } = this.props;
    const hours = this.props.hours || [
      { dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" },
    ];
    const { height = 0, width = 1000 } = global.dimensions;
    return (
      <div
        id="hours"
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid",
          borderColor: Theme.LIGHT_TEXT_COLOR,
          borderRadius: 10,
          fontFamily: Theme.MAIN_FONT,
          fontSize: Theme.getNormalFont(width),
          marginTop: "2%",
          padding: "2% 2%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              fontFamily: Theme.HEADER_FONT,
            }}
          >
            Hours:
          </div>
          <button
            onClick={this.addToHours}
            type="button"
            style={{
              display: "flex",
              flexDirection: "space-between",
              outline: "none",
              cursor: "pointer",
              backgroundColor: "transparent",
              borderColor: "transparent",
              color: Theme.MAIN_COLOR,
              fontSize: Theme.getSmallFont(width),
            }}
          >
            <img
              src={Add}
              alt=""
              style={{ marginTop: "2%", marginRight: "10%" }}
            />
            add
          </button>
        </div>
        <div
          style={{
            fontFamily: Theme.HEADER_FONT,
            fontSize: height / 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <span>Day Range</span>
            <span>Time Range</span>
          </div>

          {hours.map((day, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "1% 0 1% 0",
                  width: "100%",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "1% 0 1% 0",
                    width: "48%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Select
                    options={Days}
                    name={"dayStart"}
                    containerStyle={{ width: "45%" }}
                    handleSelect={(option, name) =>
                      this.handleTimeChange(option, name, index)
                    }
                    selected={day.dayStart}
                    height={height / 25}
                  />
                  -
                  <Select
                    options={Days}
                    name={"dayEnd"}
                    containerStyle={{ width: "45%" }}
                    handleSelect={(option, name) =>
                      this.handleTimeChange(option, name, index)
                    }
                    selected={day.dayEnd}
                    height={height / 25}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "1% 0 1% 0",
                    width: "48%",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Select
                    name={"timeStart"}
                    selected={day.timeStart}
                    containerStyle={{ width: "45%" }}
                    handleSelect={(option, name) =>
                      this.handleTimeChange(option, name, index)
                    }
                    options={getTimeOptions()}
                    height={height / 25}
                  />
                  -
                  <Select
                    name={"timeEnd"}
                    containerStyle={{ width: "45%" }}
                    handleSelect={(option, name) =>
                      this.handleTimeChange(option, name, index)
                    }
                    selected={day.timeEnd}
                    options={getTimeOptions()}
                    height={height / 25}
                  />
                </div>
                {hours.length > 1 && (
                  <div
                    onClick={() => this.removeFromHours(index)}
                    style={{
                      borderRadius: 200,
                      height: 15,
                      width: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "red",
                      color: "#fff",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                  >
                    -
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderParkDetailsBoxes = () => {
    return (
      <div
        id="parkInfo"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "66%",
            marginTop: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {this.renderParkInfoBox()}
            {this.renderManagerInfoBox()}
          </div>
          {this.renderHoursBox()}
        </div>
        {this.renderStaffInformationBox()}
      </div>
    );
  };

  render() {
    const { global, handleSubmit, change, state } = this.props;
    const hours = this.props.hours || [
      { dayStart: "", dayEnd: "", timeStart: "", timeEnd: "" },
    ];
    const { height = 0, width = 1000 } = global.dimensions;

    return (
      <form onSubmit={handleSubmit}>
        <StaffNavBar
          tab={1}
          history={this.props.history}
          width={global.dimensions.width}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            justifyContent: "center",
            marginTop: -80,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {this.renderScreenHeader()}
          {this.renderStatBoxes()}
          {this.renderParkDetailsBoxes()}
          <Button
            containerStyle={{ margin: "5% auto auto auto" }}
            text="Save"
            type="submit"
            fullwidth={true}
            width={width}
          />
        </div>
      </form>
    );
  }
}

const selector = formValueSelector("Park");

const mapStateToProps = (state) => {
  const parkValues = selector(
    state,
    "hours",
    "state",
    "generalManagerProfilePicture",
    "name",
    "timezone",
    "timezoneFullname"
  );
  return {
    ...state.global,
    ...parkValues,
  };
};
const formSubmit = (values, dispatch, props) => {
  let mutation,
    park = _.cloneDeep(values);
  park.hours = park.hours
    ? park.hours.map(({ dayStart, dayEnd, timeStart, timeEnd }) => ({
        daysOfWeek: `${dayStart}-${dayEnd}`,
        hours: `${timeStart}-${timeEnd}`,
      }))
    : [];

  if (park.generalManagerProfilePicture) {
    if (park.generalManagerProfilePicture.includes("active_storage")) {
      delete park.generalManagerProfilePicture;
    }
  }

  if (park.id) {
    delete park.timezoneFullname;
    mutation = UPDATE_PARK;
    delete park.isUpdate;
  } else {
    delete park.timezoneFullname;
    mutation = CREATE_PARK;
  }

  if (!park.name || park.name === null || park.name === "") {
    return alert("Park Name is mandatory.");
  }
  if (!park.timezone ) {
    return alert("Park Timezone is mandatory.");
  }
  props.client
    .mutate({ mutation, variables: { input: park } })
    .then((response) => {
      dispatch(reset("Park"));
      props.history.goBack();
    })
    .catch((error) => {
      //console.error("Error: ", error);
    });
};

NewPark = reduxForm({
  form: "Park",
  onSubmit: formSubmit,
})(NewPark);

export default compose(withApollo, connect(mapStateToProps, null))(NewPark);
