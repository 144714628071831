import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import { reduxForm, SubmissionError, formValueSelector } from "redux-form";
import { USER_DETAILS } from "../../../store/queries.js";
import {
  OnboardingHeader,
  Button,
  DogCard,
  VaccinationUpload
} from "../../../globalComponents/";
import { PulseLoader } from "react-spinners";
import Bg from "../../../assets/fetch-BG.svg";
import { addOrUpdateDogVaccinations } from "../../../utils/dog.js";
import { USER_UPDATE } from "../../../store/mutations.js";
import cookie from "js-cookie"
import { updateUser } from "../../../store/redux/actions";

class Vaccinations extends Component {
  state = {
    authorized: false,
    texts: false,
    picture: null,
    selected: 0,
    separate: null,
    files: [],
    initialize: true
  };

  //returns dogs orderd by id
  getDogs(dogs) {
    var temp = {};
    for (var i = 0; i < dogs.length; i++) {
      for (var j = 1; j < dogs.length; j++) {
        if (dogs[i].id > dogs[j].id) {
          temp = dogs[j];
          dogs[j] = dogs[i];
          dogs[i] = temp;
        }
      }
    }
    return dogs;
  }
  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }

  populateDogIds(dogs) {
    const tempDogArr = []
    for (const dog of dogs) {
      tempDogArr.push(dog.id)
    }
    this.props.change('allDogIds', tempDogArr)
  }

  render() {
    const { selected, files, initialize, separate, dogs } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const {
      handleSubmit,
      change,
      history,
      rabiesDate,
      dhlppDate,
      leptospirosisDate,
      bordetellaDate,
      vaccinationNew0,
      vaccinationNew1,
      vaccinationNew2,
      separateLeptospirosisStatus
    } = this.props;
    const shrink = width < 900;

    return (
      <OnboardingHeader
        back={() =>
          this.props.global.currentUser.type === "Employee"
            ? this.props.history.push("/dashboard")
            : this.props.global.currentUser.type === "Manager" ||
              this.props.global.currentUser.type === "Admin"
              ? this.props.history.push("/manager/members")
              : this.props.history.push("/")
        }
        progress={5}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          alignContent: "center"
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginTop: "5%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Vaccinations
        </div>
        <Query
          fetchPolicy="no-cache"
          query={USER_DETAILS}
          variables={{ id: this.getId() }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading)
              return (
                <div
                  name="container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: width,
                    minHeight: height,
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    backgroundColor: Theme.BACKGROUND_COLOR,
                    backgroundRepeat: "round",
                    backgroundImage: `url(${Bg})`
                  }}
                >
                  <PulseLoader
                    style={{ margin: "auto" }}
                    size={width / 10}
                    color={Theme.LINK_FONT_COLOR}
                    loading={loading}
                  />
                </div>
              );
            // if (error) return `Error: ${error.message}`;
            if (error) {
              if (
                error?.message ===
                "GraphQL error: You are not authorized to access that resource"
              ) {
                cookie.remove("token");
                this.props.updateUser("reset");
                this.props.history.push("/login");
              }
              return `Error: ${error?.message}`;
              // sessionCheck(error, this.props.updateUser)
            }
            if (initialize) {
              const dog = data?.userDetail.dogs[selected];

              this.populateDogIds(data?.userDetail.dogs)

              if (dog) {
                change("id", parseInt(dog.id));
                change("rabies", dog.rabies);
                change("dhlpp", dog.dhlpp);
                change("leptospirosis", dog.leptospirosis);
                change("bordetella", dog.bordetella);
                change("separateLeptospirosis", dog.separateLeptospirosis);
                change("vaccineDog", parseInt(dog.id));
                this.setState({
                  initialize: !initialize,
                  files: dog.vaccinationPictures || []
                });
              }
            }
            if (data) {
              let dogs = data?.userDetail.dogs;

              return (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "5%",
                      marginBottom: "5%"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        paddingRight: 10,
                        marginRight: 20
                      }}
                    >
                      {dogs.map((dog, index) => (
                        <DogCard
                          key={index}
                          selected={selected === index}
                          picture={
                            // process.env.REACT_APP_API_ENDPOINT +
                            // dog.profilePicture.url
                            dog.profilePictureCdn
                          }
                          dogName={dog.name}
                          widthFactor={1.3}
                          onClick={() => {
                            dogs[selected].rabies = rabiesDate;
                            dogs[selected].dhlpp = dhlppDate;
                            dogs[selected].leptospirosis = leptospirosisDate;
                            dogs[selected].bordetella = bordetellaDate;
                            dogs[selected].separateLeptospirosis = separateLeptospirosisStatus

                            if (selected === 0 && vaccinationNew0) {
                              dogs[selected].vaccinations = vaccinationNew0;
                            } else if (selected === 1 && vaccinationNew1) {
                              dogs[selected].vaccinations = vaccinationNew1;
                            } else if (selected === 2 && vaccinationNew2) {
                              dogs[selected].vaccinations = vaccinationNew2;
                            }

                            change("dogsNew", dogs);

                            change(
                              "vaccinationPictures",
                              dog.vaccinationPictures || []
                            );
                            change("rabies", dog.rabies);
                            change("dhlpp", dog.dhlpp);
                            change("leptospirosis", dog.leptospirosis);
                            change("bordetella", dog.bordetella);
                            change(
                              "separateLeptospirosis",
                              dog.separateLeptospirosis
                            );
                            change("vaccineDog", parseInt(dog.id));
                            this.setState({
                              selected: index,
                              vacs: dog.vaccinationPictures || []
                            });
                          }}
                          containerStyle={{ margin: 5 }}
                          width={width}
                          height={height}
                        />
                      ))}
                    </div>
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    {dogs[selected] && (
                      <VaccinationUpload
                        dog={dogs[selected]}
                        vacDates={{
                          rabiesDate,
                          dhlppDate,
                          leptospirosisDate,
                          bordetellaDate,
                          separateLeptospirosisStatus
                        }}
                        change={change}
                        selected={selected}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "auto",
                      width: Theme.getLongInputWidth(width) * 1.07,
                      marginBottom: 30
                    }}
                  >
                    <Button
                      text="Back"
                      alt={true}
                      width={width}
                      onClick={() => this.props.history.push(history.location?.state?.from || "/login")}
                    />
                    <Button
                      containerStyle={{ marginLeft: "auto" }}
                      type="button"
                      disabled={selected !== dogs.length - 1}
                      text="Save and Continue"
                      width={width}
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  </div>
                </Fragment>
              );
            }
          }}
        </Query>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });
const formSubmit = async (values, dispatch, props) => {
  console.log(" --------------------------- ")
  console.log(values, props)

  const getForUserID = () => {
    if (props.global.currentUser.selectedUser) {
      return props.global.currentUser.selectedUser;
    } else {
      return props.global.currentUser.id;
    }
  }
  let userId = props.global.currentUser.selected || props.global.currentUser?.id;
  addOrUpdateDogVaccinations(values, props.client, null).then(() =>
    // Add for steps Update start 
    props.client
      .mutate({ mutation: USER_UPDATE, variables: { input: { id: userId, step: 5 } } })
      .then(({ data: { updateUser } }) => {
        // console.log("response", updateUser);
        // props.history.push("dogInfo");
        // props.history.push("vaccinations")
        // props.history.push(`packages?onboardingDogIdx=0&forUserId=${getForUserID()}&allDogIds=${values.allDogIds.join(",")}`,)

        props.history.push(
          `packages?onboardingDogIdx=0&forUserId=${getForUserID()}&allDogIds=${values.allDogIds.join(",")}`,
          { from: props.location.pathname }
        )
        console.log("Histor", props.history);
      })
      .catch(error => {
        console.error("Error: ", error);
      })
    // Add for steps Update End 
    // props.history.push(`packages?onboardingDogIdx=0&forUserId=${getForUserID()}&allDogIds=${values.allDogIds.join(",")}`)
  );
  // return true;
};

const selector = formValueSelector("Vaccinations"); // <-- same as form name
Vaccinations = connect(state => {
  const rabiesDate = selector(state, "rabies");
  const dhlppDate = selector(state, "dhlpp");
  const leptospirosisDate = selector(state, "leptospirosis");
  const bordetellaDate = selector(state, "bordetella");
  const vaccinationNew0 = selector(state, "vaccinationNew0");
  const vaccinationNew1 = selector(state, "vaccinationNew1");
  const vaccinationNew2 = selector(state, "vaccinationNew2");
  const separateLeptospirosisStatus = selector(state, "separateLeptospirosis")
  return {
    rabiesDate,
    dhlppDate,
    leptospirosisDate,
    bordetellaDate,
    vaccinationNew0,
    vaccinationNew1,
    vaccinationNew2,
    separateLeptospirosisStatus
  };
})(Vaccinations);

Vaccinations = reduxForm({
  form: "Vaccinations",
  onSubmit: formSubmit
})(Vaccinations);
const mapDispatchToProps = { updateUser };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Vaccinations);
