import React, { Component } from "react";
import { Button } from "../";
import Logo from "../../assets/fetch-logo.svg";
import Progress1 from "../../assets/w-1.svg";
import Progress2 from "../../assets/w-2.svg";
import Progress3 from "../../assets/w-3.svg";
import Progress4 from "../../assets/w-4.svg";
import Progress5 from "../../assets/w-5.svg";
import Progress6 from "../../assets/w-6.svg";
import Progress7 from "../../assets/w-7.svg";
import Progress8 from "../../assets/w-8.svg";
import Bg from "../../assets/fetch-BG.svg";
import { Theme } from "../";
import { connect } from "react-redux";
import CheckInList from "./CheckInList";
import ReactPixel from 'react-facebook-pixel';
//import Theme from '../Theme.js'

const progressImports = {
  Progress1,
  Progress2,
  Progress3,
  Progress4,
  Progress5,
  Progress6,
  Progress7,
  Progress8,
};

class OnboardingHeader extends Component {
  render() {
    const {
      back,
      progress,
      containerStyle,
      handleSubmit,
      recenterLogo,
      memberStatus,
      checkInStatus,
      checkInResponse,
      loadMoreData,
      checkInBtn,
      saveBtn,
      checkInList,
      checkInpage
    } = this.props;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const mobile = width < 800;
    let role = this.props.global?.currentUser?.type;
    let checkInProps={
      checkInList,
      checkInResponse,
      checkInpage,
      loadMoreData
    }
    return (
      <>
      {checkInStatus&&  <div
          style={{
            position: "fixed",
            width:"100%",
            background: "white",
            zIndex: "9",
            display: "flex",
            flex: 1,
            padding: "1% 1% 0",
          }}
        >
          {/* style={{width:"150px"}} */}
          <div>
            <Button
              mobileShrink
              containerStyle={
                mobile || progress === 0
                  ? { marginRight: "auto" }
                  : { marginBottom: "13%" }
              }
              text="Home"
              fullwidth={false}
              width={width}
              onClick={back}
              // memberStatus={memberStatus}
              memberStatus={role === "Member" || role === null ? true : false}
            />
            {checkInBtn}
            {saveBtn}
          </div>

          <CheckInList {...checkInProps} />
        </div>}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingLeft: "5%",
            paddingRight: "5%",
            minHeight: height,
            backgroundColor: Theme.BACKGROUND_COLOR,
            backgroundRepeat: "round",
            backgroundImage: `url(${Bg})`,
          }}
        >
          <div
            name="headerContainer"
            style={{
              display: "flex",
              flexDirection: mobile || progress === 0 ? "column" : "row",
              justifyContent: mobile || progress === 0 ? null : "space-between",
              alignItems: "center",
              // marginTop: "2%",
              marginTop: checkInStatus ? "" : "2%",
            }}
          >
            {!checkInStatus && (
              <Button
                mobileShrink
                containerStyle={
                  mobile || progress === 0
                    ? { marginRight: "auto" }
                    : { marginBottom: "auto" }
                }
                text="Home"
                fullwidth={false}
                width={width}
                onClick={back}
                // memberStatus={memberStatus}
                memberStatus={role === "Member" || role === null ? true : false}
              />
            )}

            {!checkInStatus && (
              <img
                src={Logo}
                alt="logo"
                style={{
                  flex: 0,
                  flexShrink: -1,
                  alignSelf: "center",
                  // width: mobile || progress === 0 ? "25%" : "90%",
                  height: 120,
                  width: 150,
                  marginLeft: mobile
                    ? ""
                    : recenterLogo
                    ? `-${Theme.getButtonWidth(width, true)}px`
                    : "",
                  textAlign: mobile || progress === 0 ? "left" : "center",
                }}
              />
            )}

            {!mobile || !progress ? (
              <img
                src={progressImports[`Progress${progress}`]}
                alt=""
                style={{
                  flex: 0,
                  // marginLeft: 'auto',
                  width: "30%",
                }}
              />
            ) : null}
          </div>
          <div style={containerStyle}>
            <form onSubmit={handleSubmit}>{this.props.children}</form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });

export default connect(mapStateToProps)(OnboardingHeader);
