import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_TAG_LIST, PARK_NAMES } from "../../../store/queries.js";
import { compose, withApollo, Query } from "react-apollo";
import {
    Theme,
    Button,
    Select
} from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import TagSelect from "../../../globalComponents/FormComponents/TagSelect.js";
import { SEND_MESSAGE_PARK_MEMBERS } from "../../../store/mutations.js";
import CheckedNeutered from "../../../assets/check_neutered.svg"


let YES = "Yes";
let NO = "No"
class SendMessageTags extends Component {

    state = {
        tagName: "Choose Tag ",
        tagId: "",
        parkName: "All Parks",
        parkId: "",
        message: "",
        couponStatus: false,
        couponCode: "",
        subject: "",
        isLoading: false
    };
    handleSelect = (option, name) => {
        this.setState({ [name]: option });
    };

    handleMessage = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendMessage = () => {
        const { message, parkId, tagId, couponStatus, subject } = this.state;
        const { client } = this.props;

        this.setState({ isLoading: true })
        const values = {
            message: message,
            parkId: parkId,
            tagId: tagId,
            isCoupon: couponStatus,
            subject
        }

        if (message !== "" && tagId !== "") {
            this.setState({ isLoading: true })
            client
                .mutate({ mutation: SEND_MESSAGE_PARK_MEMBERS, variables: { input: values } })
                .then(response => {
                    alert("Message sent successfully")
                    this.setState({
                        tagName: "Choose tag ",
                        tagId: "",
                        parkName: "All Parks",
                        parkId: "",
                        message: "",
                        subject: "",
                        couponStatus: false,
                        isLoading: false
                    })
                })
                .catch(error => {
                    console.error("Error: ", error);
                    this.setState({ isLoading: false })
                    return false;
                });
        }
        else {
            alert("Please fill all Fields.")
        }

    }
    selectedCoupon = (e,) => {
        let newState = { ...this.state };
        newState.couponStatus = !newState.couponStatus;
        this.setState({ couponStatus: newState.couponStatus, });
    }
    handleTagFields = (e) => {
        this.setState({
            couponCode: e.target.value
        })
    }
    render() {
        const { tagName, message, parkName, couponStatus, subject, isLoading } = this.state;
        const { global } = this.props;
        const obj = global || { dimensions: { height: 0, width: 1000 } };
        const height = obj.dimensions.height;
        const width = obj.dimensions.width;
        const role = global.currentUser.type;
        const textFontSize = Theme.getNormalFont(width);
        const shrink = width < 700;

        return (
            <div>

                <div
                    style={{
                        marginRight: "auto",
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "0%",
                        marginBottom: "4%",
                        width: "100%",
                        color: "white",
                        alignItems: "center",
                        alignContent: "center",
                        // fontSize: Theme.getHeaderFont(width),
                        fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                        fontFamily: Theme.HEADER_FONT
                    }}
                >
                    Send  Message
                </div>

                <div
                    style={{
                        marginTop: 50,
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "90%",
                        alignItems: "center",
                        alignContent: "center"
                    }}
                >
                    <div
                        style={{ marginTop: 75, marginLeft: "auto", marginRight: "auto" }}
                    >
                        <h2
                            style={{
                                fontSize: "29px",
                                fontFamily: "LatoBold",
                                color: "#000",
                                textAlign: "center"
                            }}>
                            Send Message To Tag
                            </h2>
                        <div style={{ marginTop: 20 }}>
                            <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div></div>;
                                    if (error) return `Error: ${error.message}`;
                                    return (
                                        <div>
                                            <div style={{
                                                width: shrink ? "80%" : "50%",
                                                margin: "auto"
                                            }}>
                                                <Select
                                                    alt={true}
                                                    options={data?.restrictedFetchParks}
                                                    handleSelect={(park, name) => {
                                                        this.setState({
                                                            parkName: park.name,
                                                            parkId: park.id
                                                        })
                                                    }}
                                                    selected={parkName}
                                                    name="tagId"
                                                    width={"100%"}
                                                    height={height / 25}
                                                    label="Parks"
                                                    statusField="all"
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                            </Query>
                        </div>

                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                            <Query fetchPolicy="no-cache" query={FETCH_TAG_LIST}>
                                {({ loading, error, data, refetch }) => {
                                    if (loading) return <div></div>;
                                    if (error) return `Error: ${error.message}`;
                                    return (
                                        <div>
                                            <div style={{
                                                width: shrink ? "80%" : "50%",
                                                margin: "auto"
                                            }}>
                                                <TagSelect
                                                    alt={true}
                                                    options={data.fetchTags}
                                                    handleSelect={(tag, name) => {
                                                        this.setState({
                                                            tagName: tag.title,
                                                            tagId: tag.id
                                                        })
                                                    }}
                                                    selected={tagName}
                                                    name="tagId"
                                                    width={"100%"}
                                                    height={height / 25}
                                                    label="Tags"
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                            </Query>
                        </div>

                        <div
                            style={{
                                width: shrink ? "80%" : "50%",
                                margin: "auto",
                                marginBottom: 20
                            }}
                        >
                            <div
                                style={{
                                    fontSize: (height / 25) / 2.2,
                                    fontFamily: Theme.HEADER_FONT,
                                    color: true ? Theme.DARK_TEXT_COLOR : "white",
                                    marginBottom: "10px"
                                }}
                            >
                                Subject
                            </div>
                            <input
                                value={subject}
                                style={{
                                    outline: "none",
                                    resize: "none",
                                    borderRadius: 5,
                                    width: "100%",
                                    height: width / 45,
                                    fontSize: textFontSize,
                                    padding: 0
                                }}
                                name="subject"
                                onChange={this.handleMessage}
                            />
                        </div>

                        <div style={{
                            width: shrink ? "80%" : "50%",
                            margin: "auto"
                        }}>
                            <div
                                style={{
                                    fontSize: (height / 25) / 2.2,
                                    fontFamily: Theme.HEADER_FONT,
                                    color: true ? Theme.DARK_TEXT_COLOR : "white",
                                    marginBottom: "10px"
                                }}
                            >
                                Message
                            </div>
                            <textarea
                                value={message}
                                style={{
                                    outline: "none",
                                    resize: "none",
                                    borderRadius: 5,
                                    width: "100%",
                                    height: width / 13,
                                    fontSize: textFontSize
                                }}
                                name="message"
                                onChange={this.handleMessage}
                            />
                        </div>

                        {/* <div style={{ marginTop: 20 }}>
                            <div style={{
                                width: shrink ? "80%" : "50%",
                                margin: "auto",
                                maxHeight: "100px",
                                overflowY: "auto",
                                paddingLeft: "2px"
                            }}>
                                <div style={{ width: "100%", }}>
                                    <div style={{ display: "flex", position: "relative", marginBottom: "10px" }}>
                                        <button
                                            onClick={(e) => this.selectedCoupon(e)}
                                            type="button"
                                            style={{
                                                width: 25,
                                                height: 25,
                                                border: "2px solid",
                                                cursor: "pointer",
                                                borderColor: "#49c172",
                                                outline: "none",
                                                borderRadius: 5,
                                                padding: "0px",
                                                background: ((couponStatus) || false) ? "#49c172" : "transparent",
                                            }}

                                        >
                                            {((couponStatus) || false) ? (
                                                <img
                                                    active={false}
                                                    alt="Active"
                                                    src={CheckedNeutered}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                />
                                            ) : null}
                                        </button>
                                        <div
                                            style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "5px",
                                                width: "100%",

                                            }}
                                        >
                                            <span> Add coupon to the message? </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div
                            style={{
                                width: Theme.getButtonWidth(width),
                                margin: "auto",
                                marginTop: 25
                            }}
                        >
                            <Button
                                text="Submit"
                                alt={false}
                                width={width}
                                disabled={isLoading}
                                onClick={() => this.sendMessage()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default compose(
    withApollo,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(SendMessageTags);
