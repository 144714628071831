export const DateArray =[
    {
        name: "Date Range",
        value: ""
    },
    // {
    //     name: "Today",
    //     value: "today"
    // },
    // {
    //     name: "Yesterday",
    //     value: "yesterday"
    // },
    {
        name: "This Week",
        value: "week"
    }, {
        name: "This Month",
        value: "month"
    },
    {
        name: "This Year",
        value: "year"
    },
    {
        name: "Last Week",
        value: "last_week"
    }, {
        name: "Last Month",
        value: "last_month"
    },
    {
        name: "Last Year",
        value: "last_year"
    },
]