import React from "react";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import "./App.css";
import { connect } from "react-redux";
import { updateDimensions } from "./store/redux/actions";

import Login from "./pages/Login";
import AccountCreation from "./pages/members/onboarding/AccountCreation.js";
import Info from "./pages/members/onboarding/Info.js";
import Address from "./pages/members/onboarding/Address.js";
import Terms from "./pages/members/onboarding/Terms.js";
import ParkRules from "./pages/members/onboarding/ParkRules.js";
import DogInfo from "./pages/members/onboarding/DogInfo.js";
import Vaccinations from "./pages/members/onboarding/Vaccinations.js";
import Complete from "./pages/members/onboarding/Complete.js";
import ConfirmAccount from "./pages/members/onboarding/ConfirmAccount";
import Packages from "./pages/members/onboarding/Packages.js";
import Payment from "./pages/members/onboarding/Payment.js";
import OnboardingRecurring from "./pages/members/onboarding/Recurring.js";
import Phone from "./pages/checkin/Phone.js";
import Code from "./pages/checkin/Code.js";
import CompleteCheckin from "./pages/checkin/Complete.js";
import Dashboard from "./pages/staff/dashboard.js";
import MemberProfile from "./pages/staff/member";
import MemberPackages from "./pages/staff/member/Packages";
import DogPackages from "./pages/dogs/Packages";
import MemberPayment from "./pages/staff/member/Payment";
import MemberRecurring from "./pages/staff/member/Recurring";
import BillingOptions from "./pages/staff/member/BillingOptions";
import Profile from "./pages/profile";
import Manager from "./pages/manager";
import Members from "./pages/manager/members";
import Parks from "./pages/manager/parks";
import Park from "./pages/manager/parks/stats";
import Rangers from "./pages/manager/rangers";
import AddPark from "./pages/manager/parks/new";
import NewBarkRanger from "./pages/manager/newBarkRanger";
import Coupons from "./pages/manager/coupons/index";
import RequestReset from "./pages/reset/RequestReset.js";
import ParkInfo from "./pages/members/onboarding/ParkInfo";
import Marketing from "./pages/manager/marketing/index";
import Operations from "./pages/manager/operations";
import ParkSelection from "./pages/staff/parkSelection";
import FacebookPixel from "./globalComponents/FetchComponents/FacebookPixel"; // Adjust the path as needed
import GoogleTagManager from './globalComponents/FetchComponents/GoogleTagManager';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.props.updateDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  employeeRoutes() {
    console.log("Employeeroutes");
    return (
      <Switch>
        <Route exact path="/profile" component={Profile} />
        <Route
          path="/members/onboarding/accountCreation"
          component={AccountCreation}
        />
        <Route path="/members/onboarding/parkInfo" component={ParkInfo} />
        <Route path="/members/onboarding/info" component={Info} />
        <Route path="/members/onboarding/address" component={Address} />
        <Route
          path="/members/onboarding/recurring"
          component={OnboardingRecurring}
        />
        <Route exact path="/staff/parkInfo" component={ParkSelection} />

        <Route exact path="/staff/member" component={MemberProfile} />
        <Route exact path="/staff/member/packages" component={MemberPackages} />
        <Route exact path="/dog/packages/:dogId" component={DogPackages} />
        <Route exact path="/dog/packages/payment" component={DogPackages} />
        <Route exact path="/staff/member/payment" component={MemberPayment} />
        <Route path="/staff/member/billingOptions" component={BillingOptions} />
        <Route path="/staff/member/recurring" component={MemberRecurring} />
        <Route path="/members/onboarding/terms" component={Terms} />
        <Route path="/members/onboarding/dogInfo" component={DogInfo} />
        <Route
          path="/members/onboarding/vaccinations"
          component={Vaccinations}
        />
        <Route path="/members/onboarding/packages" component={Packages} />
        <Route path="/members/onboarding/payment" component={Payment} />
        <Route
          path="/members/onboarding/confirmAccount"
          component={ConfirmAccount}
        />
        <Route path="/members/parkrules" component={ParkRules} />
        <Route path="/members/onboarding/complete" component={Complete} />
        <Route path="/checkin/phone" component={Phone} />
        <Route path="/checkin/code" component={Code} />
        <Route path="/checkin/complete" component={CompleteCheckin} />
        <Route path="/staff/dashboard" component={Dashboard} />
        <Redirect exact path="/staff" to="/staff/dashboard" />
        <Redirect exact path="/checkin" to="/checkin/phone" />
        <Redirect
          exact
          path="/members/onboarding"
          to="/members/onboarding/accountCreation"
        />
        <Redirect from="/*" to="/staff" />
      </Switch>
    );
  }
  managerRoutes() {
    console.log("Manageroutes");
    return (
      <Switch>
        <Route exact path="/manager/new/ranger" component={NewBarkRanger} />
        <Route exact path="/manager/coupons" component={Coupons} />
        <Route exact path="/staff/parkInfo" component={ParkSelection} />
        <Route exact path="/staff/member" component={MemberProfile} />
        <Route exact path="/staff/member/packages" component={MemberPackages} />
        <Route exact path="/dog/packages/:dogId" component={DogPackages} />
        <Route exact path="/staff/member/payment" component={MemberPayment} />
        <Route path="/staff/member/billingOptions" component={BillingOptions} />
        <Route path="/staff/member/recurring" component={MemberRecurring} />
        <Route path="/staff/dashboard" component={Dashboard} />
        <Route exact path="/manager" component={Manager} />
        <Route path="/manager/rangers" component={Rangers} />
        <Route path="/manager/members" component={Members} />
        <Route path="/manager/marketing" component={Marketing} />
        <Route path="/manager/operations" component={Operations} />
        <Route exact path="/manager/parks" component={Parks} />
        <Route path="/manager/parks/stats" component={Park} />
        <Route path="/manager/parks/new" component={AddPark} />
        <Route exact path="/profile" component={Profile} />
        <Route
          path="/members/onboarding/accountCreation"
          component={AccountCreation}
        />
        <Route exact path="/staff/parkInfo" component={ParkSelection} />

        <Route path="/members/onboarding/info" component={Info} />
        <Route path="/members/onboarding/address" component={Address} />
        <Route path="/members/onboarding/terms" component={Terms} />
        <Route path="/members/onboarding/dogInfo" component={DogInfo} />
        <Route
          path="/members/onboarding/vaccinations"
          component={Vaccinations}
        />
        <Route path="/members/onboarding/packages" component={Packages} />
        <Route path="/members/onboarding/payment" component={Payment} />
        <Route
          path="/members/onboarding/recurring"
          component={OnboardingRecurring}
        />
        <Route
          path="/members/onboarding/confirmAccount"
          component={ConfirmAccount}
        />
        <Route path="/members/parkrules" component={ParkRules} />
        <Route path="/members/onboarding/complete" component={Complete} />
        <Route path="/checkin/phone" component={Phone} />
        <Route path="/checkin/code" component={Code} />
        <Route path="/checkin/complete" component={CompleteCheckin} />
        <Redirect exact path="/checkin" to="/checkin/phone" />
        <Redirect exact path="/staff" to="/staff/dashboard" />
        <Redirect
          exact
          path="/members/onboarding"
          to="/members/onboarding/accountCreation"
        />
        <Redirect from="/*" to={"/manager"} />
      </Switch>
    );
  }
  adminRoutes() {
    return (
      <Switch>
        <Route exact path="/manager/new/ranger" component={NewBarkRanger} />
        <Route exact path="/manager/coupons" component={Coupons} />
        <Route exact path="/staff/parkInfo" component={ParkSelection} />

        <Route exact path="/staff/member" component={MemberProfile} />
        <Route exact path="/staff/member/packages" component={MemberPackages} />
        <Route exact path="/dog/packages/:dogId" component={DogPackages} />
        <Route exact path="/staff/member/payment" component={MemberPayment} />
        <Route path="/staff/member/billingOptions" component={BillingOptions} />
        <Route path="/staff/member/recurring" component={MemberRecurring} />
        <Route path="/staff/dashboard" component={Dashboard} />
        <Route exact path="/manager" component={Manager} />
        <Route path="/manager/rangers" component={Rangers} />
        <Route path="/manager/members" component={Members} />
        <Route path="/manager/marketing" component={Marketing} />
        <Route path="/manager/operations" component={Operations} />
        <Route exact path="/manager/parks" component={Parks} />
        <Route path="/manager/parks/stats" component={Park} />
        <Route path="/manager/parks/new" component={AddPark} />
        <Route exact path="/profile" component={Profile} />
        <Route
          path="/members/onboarding/accountCreation"
          component={AccountCreation}
        />
        
        <Route path="/members/onboarding/parkInfo" component={ParkInfo} />
        <Route path="/members/onboarding/info" component={Info} />
        <Route path="/members/onboarding/address" component={Address} />
        <Route path="/members/onboarding/terms" component={Terms} />
        <Route path="/members/onboarding/dogInfo" component={DogInfo} />
        <Route
          path="/members/onboarding/vaccinations"
          component={Vaccinations}
        />
        <Route path="/members/onboarding/packages" component={Packages} />
        <Route path="/members/onboarding/payment" component={Payment} />
        <Route
          path="/members/onboarding/recurring"
          component={OnboardingRecurring}
        />
        <Route
          path="/members/onboarding/confirmAccount"
          component={ConfirmAccount}
        />
        <Route path="/members/parkrules" component={ParkRules} />
        <Route path="/members/onboarding/complete" component={Complete} />
        <Route path="/checkin/phone" component={Phone} />
        <Route path="/checkin/code" component={Code} />
        <Route path="/checkin/complete" component={CompleteCheckin} />
        <Redirect exact path="/checkin" to="/checkin/phone" />
        <Redirect
          exact
          path="/members/onboarding"
          to="/members/onboarding/accountCreation"
        />
        <Redirect exact path="/staff" to="/staff/dashboard" />
        <Redirect from="/*" to={"/manager"} />
      </Switch>
    );
  }
  generalRoutes() {
    console.log("Generalroutes");
    return (
      <Switch>
        <Route exact path="/profile" component={Profile} />
        <Route
          path="/members/onboarding/accountCreation"
          component={AccountCreation}
        />
        <Route path="/members/onboarding/parkInfo" component={ParkInfo} />
        <Route path="/members/onboarding/info" component={Info} />
        <Route path="/members/onboarding/address" component={Address} />
        <Route path="/members/onboarding/terms" component={Terms} />
        <Route path="/members/onboarding/dogInfo" component={DogInfo} />
        {/* ** start ** Added these routes in member  */}
        <Route exact path="/staff/member/payment" component={MemberPayment} />
        <Route exact path="/staff/member/packages" component={MemberPackages} />
        <Route exact path="/dog/packages/:dogId" component={DogPackages} />
        <Route path="/staff/member/billingOptions" component={BillingOptions} />
        {/* ** End  ** Added these routes in member */}


        <Route
          path="/members/onboarding/vaccinations"
          component={Vaccinations}
        />
        <Route path="/members/onboarding/packages" component={Packages} />
        <Route path="/members/onboarding/payment" component={Payment} />
        <Route
          path="/members/onboarding/recurring"
          component={OnboardingRecurring}
        />
        <Route
          path="/members/onboarding/confirmAccount"
          component={ConfirmAccount}
        />
        <Route path="/members/parkrules" component={ParkRules} />
        <Route path="/members/onboarding/complete" component={Complete} />
        <Route path="/checkin/phone" component={Phone} />
        <Route path="/checkin/code" component={Code} />
        <Route path="/checkin/complete" component={CompleteCheckin} />
        <Redirect exact path="/checkin" to="/checkin/phone" />
        <Redirect
          exact
          path="/members/onboarding"
          to="/members/onboarding/accountCreation"
        />
        <Redirect from="/*" to={"/profile"} />
      </Switch>
    );
  }

  getRedirect(type) {
    let route = "";
    switch (type) {
      case "Admin":
        route = "/manager";
        break;
      case "Manager":
        route = "/manager";
        break;
      case "Employee":
        route = "/staff";
        break;
      case "Member":
        route = "/member";
        break;
      default:
        route = "/login";
        break;
    }
    return route;
  }

  render() {
    const type = this.props.global ? this.props.global.currentUser.type : false;
    const admin = type === "Admin";
    const manager = type === "Manager";
    const member = type === "Member";
    const employee = type === "Employee";
    const none = !type || (!admin && !manager && !member && !employee);

    return (
      <Router>
        <FacebookPixel />
        <GoogleTagManager gtmId="AW-16607243629" />
        {member && this.generalRoutes()}
        {employee && this.employeeRoutes()}
        {manager && this.managerRoutes()}
        {admin && this.adminRoutes()}
        {none && (
          <Switch>
            <Route
              path="/members/onboarding/accountCreation"
              component={AccountCreation}
            />
            <Redirect
              exact
              path="/members/onboarding"
              to="/members/onboarding/accountCreation"
            />
            <Route path="/reset" component={RequestReset} />
            <Route path="/login" component={Login} />
            <Route path="/checkin/phone" component={Phone} />
            <Route path="/checkin/code" component={Code} />
            <Route path="/checkin/complete" component={CompleteCheckin} />
            <Redirect from="/*" to={"/login"} />
          </Switch>
        )}
      </Router>
    );
  }
}
const mapStateToProps = state => ({ ...state.global });
const mapDispatchToProps = { updateDimensions };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
