import React, { Component } from "react";
import { connect } from "react-redux";
import Theme from "../../../globalComponents/Theme.js";
import { compose, withApollo } from "react-apollo";
import { reduxForm } from "redux-form";
import { USER_UPDATE } from "../../../store/mutations.js";
import { OnboardingHeader, Button } from "../../../globalComponents/";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

class Recurring extends Component {
  constructor(props){
    super(props)

    const { dogId, packageId, isRecurring } = queryString.parse(props.location.search);

    this.state = {
      enabled: false,
      noOverflow: true,
      dogId, packageId, isRecurring
    };
  }

  render() {
    const { enabled, noOverflow } = this.state;
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const { handleSubmit, history } = this.props;

    return (
      <OnboardingHeader
        back={() => history.push("/")}
        progress={3}
        containerStyle={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5%",
          alignContent: "center"
        }}
        checkInStatus={false}
      >
        <div
          style={{
            marginBottom: "2%",
            textAlign: "center",
            fontFamily: Theme.HEADER_FONT,
            fontSize: Theme.getHeaderFont(width),
            color: Theme.HEADER_FONT_COLOR
          }}
        >
          Auto-Pay Authorization
        </div>
        <div
          style={{
            height: height / 2,
            border: "1px solid",
            width: width / 1.3,
            borderRadius: 10,
            padding: "2%",
            backgroundColor: "white",
            borderColor: Theme.MAIN_COLOR
          }}
        >
          <div
            id="termsContainer"
            style={{
              width: width / 1.38,
              height: height / 2.2,
              overflowY: "scroll",
              padding: "2%"
            }}
            onScroll={event => {
              if (
                event.currentTarget.scrollHeight -
                  event.currentTarget.scrollTop <
                Math.round(event.currentTarget.getBoundingClientRect().height)
              ) {
                this.setState({
                  enabled: true
                });
              }
            }}
          >
            <p>
              I authorize Fetch Atl LLC and Fetch Worldwide LLC (collectively
              referred to as &ldquo;Fetch Park &amp; Ice House&rdquo;) to
              automatically take payment from me using the following payment
              method:
              <br />
              <br />
              Credit Card / Debit Card
            </p>
            <p>
              For good and valuable consideration, the receipt and sufficiency
              is hereby acknowledged, I hereby authorize Fetch ATL LLC to charge
              the indicated credit card for all amounts due. I agree that if I
              have any problems or questions regarding any charges from Fetch, I
              will contact Fetch for assistance, using the contact information
              located on http://fetchpark.com.
            </p>
            <p>
              I agree that I will not dispute any charges from Fetch unless and
              until I have already attempted to rectify the situation directly
              with Fetch, and those attempts were not satisfactory to me. I
              represent and warrant that I am the legal cardholder for this
              credit card, and that I am legally authorized to enter into this
              recurring billing agreement with Fetch. I agree to indemnify,
              defend and hold Fetch, its officers, members, employees and agents
              harmless, against any liability pursuant to this authorization.
            </p>
            <p>I agree to the following additional terms and conditions:</p>
            <ul>
              <li>
                This is a periodic charge that will be made each monthly billing
                cycle as my service fees become due under my Fetch Park
                Agreement.
              </li>
              <li>
                All payments are non-refundable and charges made to the credit
                card above under this agreement will constitute in effect a
                "sales receipt" and that services were rendered and received.
              </li>
              <li>
                To terminate the recurring billing process I must terminate my
                service agreement in person or arrange for an alternative method
                of payment with at least 30 days&rsquo; notice to Fetch; and I
                will not dispute Fetch&rsquo;s recurring billing charges with my
                credit card issuer so long as the amount in question was for
                periods prior to the receipt of my written request to terminate
                my Fetch Park services (at least 30 days prior to an upcoming
                billing date).
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10%",
            flexDirection: "row",
            marginTop: "5%"
          }}
        >
          <Button
            text="Cancel"
            alt={true}
            width={width}
            onClick={() => this.props.history.push(this.state.dogId ? `/staff/member/packages?dogId=${this.state.dogId}&packageId=${this.state.packageId}&isRecurring=${this.state.isRecurring}` : `/staff/member/packages`)}
          />
          <Button
            containerStyle={{ marginLeft: "auto" }}
            text="Accept"
            width={width}
            onClick={() => this.props.history.push( this.state.dogId ? `/staff/member/payment?dogId=${this.state.dogId}&packageId=${this.state.packageId}&isRecurring=${this.state.isRecurring}` : `/staff/member/payment`)}
            disabled={!enabled && !noOverflow}
          />
        </div>
      </OnboardingHeader>
    );
  }
}

const mapStateToProps = state => ({ ...state.global });

export default compose(
  withApollo,
  withRouter,
  connect(mapStateToProps)
)(Recurring);
