export const legalAgreementHTML = `<div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.6; color: #333; padding: 20px;">
  <h1 style="text-align: center; color: #2c3e50; font-size: 24px; margin-bottom: 20px;">AGREEMENT FOR PARK ENTRY</h1>
  
  <p>Participant (as named below and sometimes referred to as "<u>I</u>" or "<u>me</u>"), by execution of this Agreement for Park Entry and in consideration of Fetch Park allowing Participant and Participant's dog(s) to enter the Park, agrees to abide by and comply with the rules and policies of Fetch Park as set forth below, warrants his or her dog meets the qualifications necessary to enter the Dog Park, as hereinafter defined, and agrees to the provisions stated hereinbelow. Participant understands that his or her failure to comply with the Park Rules and Dog Rules, as determined in Fetch's sole discretion, shall allow Fetch to remove Participant from the Dog Park, with no refund of the park entrance fee, to suspend Participant's membership for a length of time in Fetch Park's sole discretion, to ban Participant from all current or future Parks, or to hold Participant liable for any and all damages resulting from Participant's entry of the Dog Park.</p>
  
  <p>The "<u>Dog Park</u>" is defined as that portion of Fetch Park's entire real property (the "<u>Grounds</u>") for which Participant has voluntarily paid a separate membership fee or daily fee to use and is fenced off from the non-fenced real property.</p>

  <h2 style="color: #2c3e50; font-size: 20px; margin-top: 30px; margin-bottom: 15px; border-bottom: 1px solid #eee; padding-bottom: 5px;">I. Park Rules:</h2>
  <p>As a member or visitor of Fetch Park, I agree to abide by and comply with the following rules (the "<u>Park Rules</u>"):</p>
  <ol style="margin-bottom: 15px; padding-left: 30px;">
    <li style="margin-bottom: 10px;">Participant shall clean up after my dog.</li>
    <li style="margin-bottom: 10px;">No outside dog toys are allowed in the Dog Park.</li>
    <li style="margin-bottom: 10px;">Tobacco products and gum shall not be used within the Dog Park.</li>
    <li style="margin-bottom: 10px;">Outside food and drinks (including personal water bottles or glassware of any kind) are not allowed within the Dog Park.</li>
    <li style="margin-bottom: 10px;">Participant shall respect the opinion of the BARk Bouncers, Woof Wardens, and management staff (the "<u>Staff</u>") regarding my dog's behavior.</li>
    <li style="margin-bottom: 10px;">I will ensure my dog complies with and does not violate the Dog Rules.</li>
    <li style="margin-bottom: 10px;">Participant shall keep his or her dog leashed when not inside the Dog Park. Participant shall release his or her dog from the leash inside of the Dog Park but must remain inside the Dog Park at all times while his or her dog is anywhere in the Dog Park. Abandoning dogs is prohibited and is punishable by law. Violators are subject to revocation of membership and will be banned from using the Dog Park, as well as being reported to the local jurisdiction, as appropriate.</li>
    <li style="margin-bottom: 10px;">Children under the age of thirteen (13) years shall be under strict adult supervision. No person under the age of eighteen (18) years is allowed to enter and remain at Fetch Park without adult supervision if the child is the sole party responsible for his or her dog. Participant agrees to monitor the actions of his or her child and shall ensure such child does not provoke unknown dogs or act in a way to cause a dog to react in a defensive, harmful, or threatening way.</li>
    <li style="margin-bottom: 10px;">Participant shall immediately bag and dispose of any dog waste left by his or her dog.</li>
  </ol>

  <h2 style="color: #2c3e50; font-size: 20px; margin-top: 30px; margin-bottom: 15px; border-bottom: 1px solid #eee; padding-bottom: 5px;">II. Dog-Specific Rules and Warranties:</h2>
  <p>Fetch Park prioritizes safety for all people and dogs who enter the Dog Park. Accordingly, Fetch Park has implemented safety standards that all dogs must meet prior to entry into the Dog Park. Participant hereby declares, represents, and warrants that Participant's dog meets the following criteria (the "<u>Dog Rules</u>"):</p>
  <ul style="margin-bottom: 15px; padding-left: 30px;">
    <li style="margin-bottom: 10px;">Is at least four (4) months old;</li>
    <li style="margin-bottom: 10px;">Is friendly, non-aggressive, and social around people and other dogs and has not exhibited vicious, threatening, or aggressive behavior toward people or dogs;</li>
    <li style="margin-bottom: 10px;">Is clean and does not have fleas or ticks;</li>
    <li style="margin-bottom: 10px;">Is spayed or neutered if at least eight (8) months of age; and</li>
    <li style="margin-bottom: 10px;">Is current on vaccinations, including Rabies, DHPP, Bordetella and Leptospirosis with reminder and expiration dates.</li>
  </ul>

  <p>Participant further agrees to the following regarding his or her dog's behavior while in the Dog Park:</p>
  <ol style="margin-bottom: 15px; padding-left: 30px;">
    <li style="margin-bottom: 10px;">Participant further understands and agrees that in admitting his or her dog to the Dog Park, the Staff have relied on Participant's representation and warranty that such dog is in good health, is in compliance with the Dog Rules, has not caused harm to a human or dog, or has not shown aggressive, threatening, or vicious behavior towards any person or any other dog. Should Participant's dog exhibit aggressive, threatening, or vicious behavior towards any person or any other dog in the Dog Park or outside of the Dog Park at any point after the execution of this Agreement for Park Entry, Participant shall notify Fetch Park of such dog's behavior prior to his or her next entry to the Dog Park. Should Participant fail to update or notify Fetch Park of such change in behavior, Participant and his or her dog shall be subject to a suspension or ban from the Dog Park and all entities operating under the trade name of "Fetch Park" regardless of Participant's dog's behavior in the Dog Park.</li>
    <li style="margin-bottom: 10px;">Participant is responsible for the behavior of his or her dog and must promptly break up any disturbances, altercations, incidents, or fights involving his or her dog(s) as they may relate to people or other dogs.</li>
    <li style="margin-bottom: 10px;">If Participant brings a dog that exhibits aggressive, threatening, or vicious behavior toward any person(s) or any other dog(s) whatsoever, then the Participant shall be liable for any damage to Fetch Park or injuries, harm, or damage to any person(s) and other dog(s) resulting from the Participant's dog's actions. Participant shall be further liable for any harm or damage to the reputation of Fetch Park, Fetch Park LLC, Fetch Worldwide, LLC, or any of entity holding a license to utilize or a party to a license agreement regarding the intellectual property of Fetch Park resulting from Participant's dog's aggressive, threatening, unprovoked, or vicious behavior and behavior that violates the Dog Rules.</li>
    <li style="margin-bottom: 10px;">If the Staff asks Participant to leave the Dog Park or the Participant's dog's membership is suspended or revoked due to the Participant or the Participant's dog's behavior, Participant shall not receive a refund of the day pass or membership, as the case may be.</li>
  </ol>

  <h2 style="color: #2c3e50; font-size: 20px; margin-top: 30px; margin-bottom: 15px; border-bottom: 1px solid #eee; padding-bottom: 5px;">III. Additional Provisions, Warranties, and Obligations:</h2>
  <ol style="margin-bottom: 15px; padding-left: 30px;">
    <li style="margin-bottom: 10px;">Participant agrees that entry to the Dog Park is voluntary and at Participant's own risk. Fetch Park reserves the right to excuse any person or dog from the Dog Park at any time for behavior that violates the Park Rules or Dog Rules which could be harmful to people (including Staff) or other dogs. It is the Participant's sole responsibility to ensure the safety and well-being of his or her dog, his or her guests, and him- or herself. Fetch Park, in its sole discretion, may suspend or revoke membership at any time if it is determined that a Participant has jeopardized the safety of the other Dog Park users or Staff. Should membership be suspended or revoked, Participant shall not be entitled to a refund of the Participant's day pass or membership, as the case may be.</li>
    <li style="margin-bottom: 10px;">Participant agrees to assume all liability and responsibility for any harm or damage caused to Fetch Park property, Fetch Park members or visitors, or Staff resulting from Participant's or his or her dog's actions.</li>
    <li style="margin-bottom: 10px;">If Participant or his or her dog negligently, intentionally, unintentionally, directly, or indirectly damages any property or fixtures of Fetch Park, including but not limited to, the turf, the digital displays, the lighted signage, the airstream, seating area furniture, gates and fencing inside the Dog Park or on the Grounds, Participant agrees he or she is personally and solely liable for the cost of repairs or replacement of the damaged property and that Fetch Park reserves the right to ban the Participant and the Participant's dog(s) from the Dog Park or any Dog Park operating under the trade name of "Fetch Park" or licensing the intellectual property of Fetch Park.</li>
    <li style="margin-bottom: 10px;">Participant understands and agrees that if he or she is asked to leave the Dog Park and Grounds because of harm or damage caused by him or her, his or her child, or his or her dog(s) because of Participant's behavior (including, but not limited to, excessive intoxication, unsafe behavior toward or interaction with dogs, or disrespectfulness towards the Staff) or because his or her dog's behavior violates the Dog Rules as determined in the Staff's sole discretion, he or she will not receive a refund for his or her day pass or membership, as the case may be.</li>
    <li style="margin-bottom: 10px;">In the event Participant or Participant's dog causes harm, injury, or damage to another person, including a person's property, or another dog, Participant authorizes Fetch Park to release and share his or her contact information as shared to Fetch Park herein or hereafter to or with the injured person or dog's owner (the "Injured Party"). Participant consents to such release and waives any and all actual or potential claims arising out of a breach, infringement, or violation of his or her privacy. Participant further authorizes Fetch to put Participant in contact with the Injured Party for the purpose of resolving or discussing said harm, injury, or damage.</li>
  </ol>

  <h2 style="color: #2c3e50; font-size: 20px; margin-top: 30px; margin-bottom: 15px; border-bottom: 1px solid #eee; padding-bottom: 5px;">IV. Photo and Media Release:</h2>
  <p>Participant hereby grants Fetch Park and any of its subsidiaries or related entities, including, but not limited to, Fetch Worldwide LLC (the "Fetch Parties"), on behalf of myself, my successors, executors, and assigns, the non-exclusive, royalty-free license, right, and permission to photograph and/or video record me, my child, or my dog while in attendance at Fetch Park or on the Grounds. Participant further allows the Fetch Parties to use the photograph and/or video recording for promotional or marketing purposes, on their website(s), and on their social media pages. Participant waives any right to inspect or approve the use of the photograph and/or video recording and acknowledges and agrees that the right granted by this release is without compensation of any kind.</p>

  <p>Participant hereby covenants not to sue the Fetch Parties and fully and forever discharges and releases the Fetch Parties from any litigation or claim for damages of any kind (including, but not limited to, invasion of privacy, defamation, false light or misappropriate of name, likeness, or image) arising out of the use or publication of any photographs or video recordings of Participant or Participant's dog by the Fetch Parties.</p>

  <h2 style="color: #2c3e50; font-size: 20px; margin-top: 30px; margin-bottom: 15px; border-bottom: 1px solid #eee; padding-bottom: 5px;">V. Liability Waiver and Release:</h2>
  <p>I, the undersigned Participant, am aware and understand that despite Fetch Park and the Staff's best efforts to provide a safe environment for all visitors, members, and dogs, entry to and usage of Fetch Park is a potentially dangerous activity and involves the risk of serious injury, disability, and/or property damage. I acknowledge that I am voluntarily and knowingly entering into Fetch Park with the knowledge of the potential danger involved and hereby agree to accept and assume any and all risks of injury, disability, and/or death to a human or dog, and/or property damage arising from my, my child's, my guest's, or my dog's entry to and usage of Fetch Park, whether caused by the ordinary negligence of Fetch Park or the Staff. I specifically acknowledge and understand the risk that exists any time groups of dogs play off leash with other dogs and play on agility equipment while at the Dog Park. I agree that the Dog Park has made every effort to provide a safe and clean environment for my dog and all dogs.</p>

  <p>I hereby expressly waive, release, and forever discharge any and all claims, now or hereafter known, against Fetch Park, Fetch Park LLC, Fetch Worldwide, LLC and their officers, directors, manager(s), employees, agents, affiliates (to include any dog park establishment doing business as "Fetch Park," including but not limited to Fetch Atl, LLC, Fetch Park Buckhead, LLC, Fetch Park Columbus, LLC, and Fetch Westside, LLC, or management company, or a franchising entity), members, successors, and assigns (collectively, "Releasees"), on account of injury to a human or dog, disability of a human or dog, or property damage arising out of or attributable to my or my child's entry to or usage of Fetch Park or the entry of a dog I bring into the Dog Park and on the Grounds, whether arising out of the ordinary negligence of Fetch Park or any Releasees or otherwise. I covenant not to make or bring any such claim against Fetch Park or any other Releasee, and forever release and discharge Fetch Park and all other Releasees from any and all liability under such claims.</p>

  <p>I further agree, at my sole cost and expense, to indemnify, protect, defend, and hold harmless Fetch Park and the Releasees to the fullest extent allowable by law against any and all claims, lawsuits, demands, causes of action, liability, loss, obligations, damage, and/or injury, of any kind whatsoever (including without limitation all claims for monetary loss, property damage, equitable relief, personal injury, and/or wrongful death), including those caused in whole or in part by ordinary negligence of Fetch Park or the Staff, whether brought by an individual or other entity, or imposed by a court of law or by an administrative action of any federal, state, or local governmental body or agency, arising out of, in any way whatsoever, any acts, omissions, negligence, or willful misconduct on the part of me, my child, or my guest, or the dog I bring into the Dog Park and on the Grounds as related to my, my child's, my guest's, or such dog's entry to and usage of Fetch Park. Such indemnification shall apply to and include, without limitation, incidental or consequential damages, the payment of all penalties,;
`;

